import { getSystemDomain } from '@/utils/utils'
import { getLoginPageConfInfo } from '@/api/login'
export default {
  data () {
    return {
      params: {
        code: '',
        state: '',
        callback: document.location.protocol + '//' + location.host
      },
      // 企业配置数据
      confInfo: {},
      loading: false,
      envType: this.$store.state.env.VUE_APP_ENV_TYPE
    }
  },
  methods: {
    // 查询配置
    getConfInfo () {
      this.loading = true
      this.systemDomain = getSystemDomain()
      getLoginPageConfInfo({ systemDomain: this.systemDomain, redirectUri: this.params.callback }).then((response) => {
        // 初始默认值
        let commonArr = [{
          envType: 'GLOBAL',
          lang: 'en-US',
          serviceAgreementName: 'Quectel Service Agreement',
          serviceAgreementUrl: '/html/fw_global_en.html',
          privacyPolicyName: 'Quectel Privacy Policy',
          privacyPolicyUrl: '/html/ys_global_en.html'
        }, {
          envType: 'GLOBAL',
          lang: 'zh-CN',
          serviceAgreementName: '《移远服务协议》',
          serviceAgreementUrl: '/html/fw_global_zh.html',
          privacyPolicyName: '《移远隐私政策》',
          privacyPolicyUrl: '/html/ys_global_zh.html'
        }, {
          envType: 'CHINA',
          lang: 'en-US',
          serviceAgreementName: 'Quectel Service Agreement',
          serviceAgreementUrl: '/html/fw_zh_en.html',
          privacyPolicyName: 'Quectel Privacy Policy',
          privacyPolicyUrl: '/html/ys_zh_en.html'
        }, {
          envType: 'CHINA',
          lang: 'zh-CN',
          serviceAgreementName: '《移远服务协议》',
          serviceAgreementUrl: '/html/fw_zh_zh.html',
          privacyPolicyName: '《移远隐私政策》',
          privacyPolicyUrl: '/html/ys_zh_zh.html'
        }]

        let currentObj = {
          serviceAgreementName: null,
          serviceAgreementUrl: null,
          privacyPolicyName: null,
          privacyPolicyUrl: null
        }

        commonArr.forEach(item => {
          if (this.envType === item.envType && this.lang === item.lang) {
            // 没有值就用原来初始值
            item.serviceAgreementName = (item.lang === 'zh-CN' ? response.data?.serviceAgreementCnName : response.data?.serviceAgreementEnName) || item.serviceAgreementName

            item.serviceAgreementUrl = (item.lang === 'zh-CN' ? response.data?.serviceAgreementCnUrl : response.data?.serviceAgreementEnUrl) || item.serviceAgreementUrl

            item.privacyPolicyName = (item.lang === 'zh-CN' ? response.data?.privacyPolicyCnName : response.data?.privacyPolicyEnName) || item.privacyPolicyName

            item.privacyPolicyUrl = (item.lang === 'zh-CN' ? response.data?.privacyPolicyCnUrl : response.data?.privacyPolicyEnUrl) || item.privacyPolicyUrl

            // FIXME 2.1.0 资产管理特殊逻辑 配置指定隐私协议
            // 后续场景管理会和协议绑定 完成后删除当前特殊逻辑
            const { lang, systemDomain } = this
            const privacyMap = new Map()
            privacyMap.set('eam.iotomp.com', { // China: eam.iotomp.com
              'zh-CN': {
                name: '《资产管理平台隐私政策》',
                path: 'https://quec-saas-aep.oss-cn-shanghai.aliyuncs.com/html/',
                file: '%E3%80%8A%E8%B5%84%E4%BA%A7%E7%AE%A1%E7%90%86%E5%B9%B3%E5%8F%B0%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96%E3%80%8B.pdf'
              },
              'en-US': {
                name: 'Asset Management Platform Privacy',
                path: 'https://quec-saas-aep.oss-cn-shanghai.aliyuncs.com/html/',
                file: 'Asset%20Management%20Platform_Privacy_Policy.pdf'
              }
            })
            privacyMap.set('eueam.iotomp.com', { // EU: eueam.iotomp.com
              'zh-CN': {
                name: '《资产管理平台隐私政策》',
                path: 'https://general-eu1.s3.eu-central-1.amazonaws.com/html/',
                file: '%E3%80%8A%E8%B5%84%E4%BA%A7%E7%AE%A1%E7%90%86%E5%B9%B3%E5%8F%B0%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96%E3%80%8B.pdf'
              },
              'en-US': {
                name: 'Asset Management Platform Privacy',
                path: 'https://general-eu1.s3.eu-central-1.amazonaws.com/html/',
                file: 'Asset%20Management%20Platform_Privacy_Policy.pdf'
              }
            })
            privacyMap.set('naeam.iotomp.com', { // NA: naeam.iotomp.com
              'zh-CN': {
                name: '《资产管理平台隐私政策》',
                path: 'https://saas-general-us.s3.us-west-2.amazonaws.com/html/',
                file: '%E3%80%8A%E8%B5%84%E4%BA%A7%E7%AE%A1%E7%90%86%E5%B9%B3%E5%8F%B0%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96%E3%80%8B.pdf'
              },
              'en-US': {
                name: 'Asset Management Platform Privacy',
                path: 'https://saas-general-us.s3.us-west-2.amazonaws.com/html/',
                file: 'Asset%20Management%20Platform_Privacy_Policy.pdf'
              }
            })
            privacyMap.set('naeam.iotomp.com', { // NA: naeam.iotomp.com
              'zh-CN': {
                name: '《资产管理平台隐私政策》',
                path: 'https://saas-general-us.s3.us-west-2.amazonaws.com/html/',
                file: '%E3%80%8A%E8%B5%84%E4%BA%A7%E7%AE%A1%E7%90%86%E5%B9%B3%E5%8F%B0%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96%E3%80%8B.pdf'
              },
              'en-US': {
                name: 'Asset Management Platform Privacy',
                path: 'https://saas-general-us.s3.us-west-2.amazonaws.com/html/',
                file: 'Asset%20Management%20Platform_Privacy_Policy.pdf'
              }
            })

            if (privacyMap.has(systemDomain)) {
              const privacy = privacyMap.get(systemDomain)[lang]
              item.privacyPolicyName = privacy.name
              item.privacyPolicyUrl = privacy.path + privacy.file
            }

            currentObj = { ...item }
          }
        })
        // response.data.loginTemplate = '4'
        this.confInfo = { ...response.data, ...currentObj }
        this.$store.dispatch('ConfInfo', this.confInfo)
        this.loading = false
      })
    }
  }
}
