export default {
  common: {
    fieldName: 'Please enter a field name',
    fieldCode: 'Please enter a field code',
    selectType: 'Please select a type',
    createTime: 'Creation Time',
    updateTime: 'Update Time',
    time: 'Time',
    confirm: 'Confirm',
    cancel: 'Cancel',
    search: 'Search',
    reset: 'Reset',
    restore: 'Reset Default',
    action: 'Action',
    config: 'Config',
    edit: 'Edit',
    close: 'Close',
    save: 'Save',
    success: 'Successful',
    show: 'Display',
    hide: 'Hide',
    open: 'Enable',
    delete: 'Delete',
    remove: 'Remove',
    removeAll: 'Remove All',
    change: 'Replace',
    multiDelete: 'Delete in Batches',
    publish: 'Release',
    add: 'Add',
    addTo: 'Add',
    view: 'View',
    next: 'Next',
    prev: 'Previous',
    editSuccess: 'Edited successfully',
    deleteSuccess: 'Deleted successfully',
    recycleSuccess: 'Recycled successfully',
    paySuccess: 'Payed successfully',
    deleteFail: 'Deletion failed',
    addSuccess: 'Added successfully',
    addToSuccess: 'Added successfully',
    publishSuccess: 'Released successfully',
    modifySuccess: 'Modified successfully',
    bindSuccess: 'Bound successfully',
    unbindSuccess: 'Unbound successfully',
    unbindCancel: 'Unbinding request is cancelled.',
    saveSuccess: 'Saved successfully',
    updateSuccess: 'Updated successfully',
    actionSuccess: 'Action successfully',
    successfulTip: '{0} successfully',
    uploadFail: 'Upload failed',
    tipUpload: 'Click to Upload',
    tipUploadImg: 'Please upload an image in JPG or PNG format.',
    tipUploadXls: 'Note: Only XLS or XLSX format is supported.',
    tipUploadXls2M: '1.Only XLS or XLSX format is supported, , no more than 2 MB',
    tipUploadImg10M: 'Please upload jpg/png image and no more than 10 MB',
    tipUploadPdf: 'Only PDF files are supported.',
    uploadMaxLength: 'The number of files exceeds the limit',
    tipUploadIco: 'Please upload an image in ICO format.',
    sure: 'Are you sure you want to',
    sureDel: 'Are you sure you want to delete?',
    sureUpdate: 'Are you sure you want to change?',
    warn: 'Alert',
    import: 'Import',
    export: 'Export',
    exportSuccess: 'Exported successfully',
    exportFail: 'Export failed',
    importRecords: 'Import Records',
    uploadFiles: 'Upload File',
    dragFile: 'Drag the file here, or',
    productName: 'Product Name',
    prompt: 'Note',
    see: 'View',
    seeDetails: 'Details',
    basicInfo: 'Basic Information',
    info: 'Information',
    back: 'Back',
    start: 'Start',
    pause: 'Suspend',
    noData: 'No data',
    noDevice: 'No Device',
    to: 'to',
    minute: 'Minute(s)',
    hour: 'Hour(s)',
    second: 'Second(s)',
    location: 'Location',
    email: 'Email',
    sms: 'SMS',
    voice: 'Voice',
    enter: 'Enter',
    no: 'No',
    yes: 'Yes',
    zh: 'Chinese',
    en: 'English',
    logout: 'Are you sure you want to log out?',
    cookiesInvalid: 'Your session has expired. You may stay at this page or log in again.',
    sysHint: 'System Prompt',
    reLogin: 'Log In Again',
    networkError: 'Connection error',
    timeOut: 'Session expired.',
    systemInterface: 'System interface error',
    copy: 'Copy',
    clipboardSuccess: 'Copied successfully',
    clipboardError: 'Copy failed',
    dim: 'Statistical Dimensions',
    days: 'Day(s)',
    years: 'Year(s)',
    remark: 'Remark',
    updateData: 'Refresh',
    enable: 'Enable',
    disable: 'Disable',
    keywordPlace: 'Please enter a keyword.',
    enableState: 'Enable State',
    sureClose: 'Are you sure you want to disable?',
    sureOpen: 'Are you sure you want to enable?',
    ownOrg: 'Enterprise',
    targetOrg: 'Target Enterprise',
    targetOrgPh: 'Target Enterprise',
    notFoundResult: 'No results found.',
    seeChildrenOrgData: 'Display subordinate enterprise data',
    applicationScenarios: 'Application Scenarios',
    all: 'All',
    default: 'Default',
    selected: 'Selected',
    qrCode: 'QR Code',
    startTime: 'Start Time',
    endTime: 'End Time',
    signalEnable: 'enable',
    signalDisable: 'disable',
    batchSet: 'Send Command in Batches',
    weeks: {
      sun: 'Sun.',
      mon: 'Mon.',
      tues: 'Tue.',
      wed: 'Wed.',
      thur: 'Thur.',
      fri: 'Fri.',
      sat: 'Sat.' // Fixed Sequence
    },
    deviceKeyOrName: 'Device Name/DeviceKey',
    unit: 'Unit',
    product: 'Product',
    details: 'Details',
    batchControl: 'Batch Control',
    select: 'Select',
    allList: 'All devices',
    selectable: 'Selectable',
    selected: 'Selected'
  },
  navbar: {
    personal: 'Account Center',
    company: 'Platform Settings',
    logout: 'Logout',
    auditLog: 'Operation Logs',
    themeOptions: 'Theme Settings',
    menuStyle: 'Menu Mode',
    save: 'Save',
    reset: 'Reset',
    dataView: 'Data Screen',
    yhs: 'Data Screen'
  },
  m: {
    app: {
      demo: 'Menu'
    },
    dashboard: 'Dashboard',
    product: 'Product',
    device: 'Device',
    agent: 'Agent',
    end: 'End User',
    company: 'Enterprise',
    sys: 'System',
    monitor: 'Monitoring',
    device_list: 'Device List',
    data_view_btn: 'Data Screen',
    group: 'Installation Site',
    device_map: 'Device Map',
    group_list: 'Installation List',
    person_list: 'Contact List',
    device_fence: 'Geofence',
    agent_list: 'Agent List',
    agent_select: 'Query Agent',
    agent_add: 'Add Agent',
    agent_update: 'Edit Agent',
    agent_delete: 'Delete Agent',
    agent_export: 'Export Agent',
    device_share: 'Distributed Device',
    expense: 'Expense',
    expense_charge: 'Expense Charge',
    expense_institution: 'Expense Institution',
    end_user: 'End User',
    end_user_detail: 'End User Details',
    end_feedback: 'Feedback',
    end_question: 'FAQs',
    category: {
      title: 'Product Category Management',
      add_btn: 'Add Category',
      choose_category: 'Category Choose',
      app_detail_btn: 'App Details Management',
      miniApp_detail_btn: 'Mini Program Details Management',
      admin_detail_btn: 'Server Details Management',
      table_Level1: 'First Class',
      table_Level2: 'Secondary Class',
      table_apppath: 'App Path',
      table_servepath: 'Server Path',
      app_detail_name: 'Name',
      app_detail_path: 'Path',
      save_validate_error: 'Please fill in the form.',
      can_not_null: 'cannot be empty',
      category_dialog_title: 'Edit or Add Product Category',
      no_select_category_sub: 'Please select a subcategory.',
      detailName: 'Name',
      webDetailName: 'Web Details',
      appDetailName: 'App Details',
      miniAppDetailName: 'Mini Program Details',
      canNotNull: 'cannot be empty'
    },
    letter: {
      detail: 'Query Details',
      announcement: 'System Message',
      deviceMsg: 'Device Message',
      allread: 'All Read',
      table_title: 'Title',
      table_time: 'Creation Time',
      type: 'Type',
      table_release_time: 'Release Time',
      status: 'Status',
      product_name: 'Product Name',
      product_key: 'ProductKey',
      eq_number: 'DeviceKey',
      device_name: 'Device Name',
      device_key: 'DeviceKey',
      warn_type: 'Alert Type',
      warn_time: 'Alert Time',
      createdNotice: 'Create System Message',
      fixedTop: 'Scroll Top',
      accpected: 'Recipient',
      releaseNow: 'Immediately',
      releaseDelay: 'Specific Time',
      scroll: 'Scroll',
      all: 'All Enterprises',
      designated: 'Specific Enterprise',
      temporary: 'Temporarily Save',
      edit_header: 'Edit/Create Message',
      show_header: 'View Message',
      canNot: '{0} cannot be empty.',
      status1: 'Draft',
      status2: 'To be released',
      status3: 'Released',
      status4: 'Release failed',
      feedbackMsg: 'Feedback Message'
    },
    sys_aggregation: 'Data Aggregation Management',
    sys_pro_category: 'Product Category',
    sys_category: 'Category Management',
    sys_letter: 'Message',
    sys_letter_admin: 'Message Management',
    sys_letter_edit: 'Edit Message',
    sys_user: 'User',
    sys_user_select: 'Query User',
    sys_user_add: 'Add User',
    sys_user_update: 'Edit User',
    sys_user_delete: 'Delete User',
    sys_user_export: 'Export User',
    sys_user_import: 'Import User',
    sys_reset_password: 'Reset Password',
    sys_role: 'Role',
    sys_role_select: 'Query Role',
    sys_role_add: 'Add Role',
    sys_role_update: 'Edit Role',
    sys_role_delete: 'Delete Role',
    sys_role_export: 'Export Role',
    sys_menu: 'Menu',
    sys_menu_select: 'Query Menu',
    sys_menu_add: 'Add Menu',
    sys_menu_update: 'Edit Menu',
    sys_menu_delete: 'Delete Menu',
    sys_dept: 'Department',
    sys_dept_select: 'Query Department',
    sys_dept_add: 'Add Department',
    sys_dept_update: 'Edit Department',
    sys_dept_delete: 'Delete Department',
    sys_post: 'Position',
    sys_post_select: 'Query Position',
    sys_post_add: 'Add Position',
    sys_post_update: 'Edit Position',
    sys_post_delete: 'Delete Position',
    sys_post_export: 'Export Position',
    sys_dict: 'Dictionary',
    sys_dict_select: 'Query Dictionary ',
    sys_dict_add: 'Add Dictionary ',
    sys_dict_update: 'Edit Dictionary ',
    sys_dict_delete: 'Delete Dictionary ',
    sys_dict_export: 'Export Dictionary ',
    sys_config: 'System Configuration',
    sys_config_select: 'Query Parameter',
    sys_config_add: 'Add Parameter',
    sys_config_update: 'Edit Parameter',
    sys_config_delete: 'Delete Parameter',
    sys_config_export: 'Export Parameter',
    sys_notice: 'System Message',
    sys_notice_select: 'Query System Message',
    sys_notice_add: 'Add System Message',
    sys_notice_update: 'Edit System Message',
    sys_notice_delete: 'Delete System Message',
    sys_log: 'Log',
    sys_log_opera: 'Operation Log',
    sys_log_opera_select: 'Query Operation Log',
    sys_log_opera_delete: 'Delete Operation Log',
    sys_log_opera_export: 'Export Log',
    sys_log_login: 'Login Log',
    sys_login_select: 'Query Login Log',
    sys_login_delete: 'Delete Login Log',
    job_log: 'Task Logs',
    monitor_online: 'Online User',
    m_online_select: 'Online Search',
    m_batch_logout: 'Forced Logout in Batches',
    m_force_logout: 'Forced Logout',
    monitor_time: 'Timed Task',
    m_task_select: 'Query Task',
    m_task_add: 'Add Task',
    m_task_update: 'Edit Task',
    m_task_delete: 'Delete Task',
    m_status_update: 'Modify Status',
    m_task_export: 'Export Task',
    user_profile: 'Account Center',
    company_config: 'Platform Settings',
    auditlog_config: 'Operation Log',
    company_role: 'Permission Settings',
    dict_data: 'Dictionary Data',
    function: 'Feature Definition',
    notice_email: 'Email Notification',
    notice_sms: 'SMS Notification',
    notice_voice: 'Voice Notification',
    notice_app: 'App Notification',
    group_manage_device: 'Device',
    group_add: 'Device Installation Site',
    device_detail: 'Device Details',
    device_status: 'Device Overview',
    gateway_detail: 'Gateway Details',
    sub_device_detail: 'Gateway Sub-device Details',
    fence_add: 'Draw Geofence',
    fence_bind: 'Bind Device',
    scooter_opera: 'Operations',
    scooter_shop: 'Authorized Store',
    scooter_repair: 'Maintenance Request',
    scooter_lose: 'Theft Report',
    lose_manual: 'Manual',
    store_info: 'Store Information',
    sim: 'SIM Card',
    device_info: 'Device Information',
    device_attr: 'Device Properties',
    device_mileage: 'Mileage Report',
    device_fault: 'Fault Log',
    device_alarm: 'Alert Log',
    device_set: 'Send Command',
    device_sim: 'SIM Card',
    appVersion: 'APP Version',
    sys_notify: 'Notification',
    sys_notify_sms: 'SMS Template',
    sys_notify_email: 'Email Template',
    sys_notify_voice: 'Voice Template',
    sys_notify_app: 'App Push Template',
    m_agent_select: 'Query Agent',
    m_agent_add: 'Add Agent',
    m_agent_update: 'Edit Agent',
    m_agent_delete: 'Delete Agent',
    m_agent_export: 'Export Agent',
    m_user_select: 'Query User',
    m_user_add: 'Add User',
    m_user_update: 'Edit User',
    m_user_delete: 'Delete User',
    m_user_export: 'Export User',
    m_user_import: 'Import User',
    m_reset_password: 'Reset Password',
    m_role_select: 'Query Role',
    m_role_add: 'Add Role',
    m_role_update: 'Edit Role',
    m_role_delete: 'Delete Role',
    m_role_export: 'Export Role',
    m_menu_select: 'Query Menu',
    m_menu_add: 'Add Menu',
    m_menu_update: 'Edit Menu',
    m_menu_delete: 'Delete Menu',
    m_dept_select: 'Query Department',
    m_dept_add: 'Add Department',
    m_dept_update: 'Edit Department',
    m_dept_delete: 'Delete Department',
    m_post_select: 'Query Position',
    m_post_add: 'Add Position',
    m_post_update: 'Edit Position',
    m_post_delete: 'Delete Position',
    m_post_export: 'Export Position',
    m_dict_select: 'Query Dictionary',
    m_dict_add: 'Add Dictionary',
    m_dict_update: 'Edit Dictionary',
    m_dict_delete: 'Delete Dictionary',
    m_dict_export: 'Export Dictionary',
    m_sys_config_select: 'Query Parameter',
    m_sys_config_add: 'Add Parameter',
    m_sys_config_update: 'Edit Parameter',
    m_sys_config_delete: 'Delete Parameter',
    m_sys_config_export: 'Export Parameter',
    m_notice_select: 'Query System Message',
    m_notice_add: 'Add System Message',
    m_notice_update: 'Edit System Message',
    m_notice_delete: 'Delete System Message',
    m_add_group: 'Add Installation Site',
    m_edit_group: 'Edit Installation Site',
    m_group_batch_set: 'Set in Batches',
    m_group_manage_dev: 'Manage Device',
    m_group_set_person: 'Set Contact Person',
    m_group_edit: 'Edit',
    m_group_delete: 'Delete',
    m_person_add: 'Add',
    m_person_edit: 'Edit',
    m_person_delete: 'Delete',
    sys_upload: 'File',
    sys_dashboard: 'Dashboard',
    sys_dashboard_data: 'Data Source Configuration',
    sys_dashboard_card: 'Dashboard Configuration',
    appmanage: 'App Version',
    fault_warning: 'Fault and Alert',
    fault_warning_detail: 'Detail Of Fault and Alert',
    operation_management: 'Operation & Maintenance',
    operation_my_order: 'My Ticket',
    operation_repair: 'Device Maintenance',
    operation_manege: 'Ticket Management',
    send_order_select: 'Maintenance Personnel',
    syu_web_menu: 'Web Menu',
    syu_wx_app_menu: 'Mini Program Menu',
    group_set_code: 'Installation-Field Settings',
    wxapp: {
      m_index: 'Home Page',
      m_msg: 'Message',
      m_statistics: 'Statistics',
      m_mine: 'My',
      m_deviceList: 'Device List',
      m_devDetail: 'Device Details',
      m_portInfo: 'Port Information',
      enablePort: 'Enable Port',
      disablePort: 'Disabled Port',
      m_orderRecord: 'Order Records',
      orderDetail: 'Order Details',
      refundBtn: 'Refund',
      m_deviceStatus: 'Device Status',
      m_deviceAlarm: 'Fault and Alert',
      m_workOrder: 'Ticket',
      m_alarm: 'Alert',
      m_question: 'Problem',
      addWOBtn: 'Ticket Request',
      forceEndWO: 'Force Close',
      endWO: 'Resolve Ticket',
      deliveryWO: 'Assign Ticket',
      cancelWO: 'Cancel',
      alarmToWO: 'Create Ticket',
      ignoreAlarm: 'Ignore',
      handleAlarm: 'Process',
      questionToWO: 'Create Ticket',
      handleQuestion: 'Process',
      m_user_info: 'Account Information',
      backWO: 'Reassign'
    },
    fault_warn_manage: 'Fault and Alert',
    fault_warn_contact: 'Contact',
    common_screen_view: 'Data Screen',
    company_organization: 'Enterprise Management',
    company_organ_role: 'Manage Role',
    company_organ_user: 'Manage User',
    posigroup: 'Installation Site',
    fault_warn_config: 'Configuration',
    idst_scen_admin: 'Industry Scenario Management',
    idst_scen_man: 'Industry Scenario Management',
    idst_scen_orgtype: 'Enterprise Type Management',
    idst_scen_set: 'Scenario Configuration',
    idst_scen_menu: 'Menu Management',
    idst_scen_role: 'Role Management',
    org_type_admin: 'Enterprise Type Management',
    product_custom: 'Custom Field Settings',
    project_manage: 'Report Management',
    project_manage_cust: 'Data Report',
    project_manage_rule: 'Reporting Plan',
    configuration_list: 'Configuration Management',
    product_dcmp: 'Permission Configuration-Data Collection Configuration',
    product_action_set: 'Action Settings'
  },
  valid: {
    required: 'Required',
    userName: 'Username cannot be empty.',
    passWord: 'Password cannot be empty.',
    code: 'CAPTCHA cannot be empty.',
    name: 'Name cannot be empty.',
    normal: 'Only Chinese characters and alphanumeric characters are supported.',
    filedName: 'Field name cannot be empty.',
    templateName: 'Template name cannot be empty.',
    templateContent: 'Template content cannot be empty. ',
    checkPeople: 'Please select one recipient at least.',
    leastOne: 'Please specify one recipient at least. ',
    removeAlarmParmValue: 'Please select a threshold value for resolving the alert.',
    sendNotice: 'Please select whether to send a notification after the alert is resolved.',
    ruleName: 'Supports Chinese, English uppercase and lowercase letters, numbers, as well as special characters hyphen (-), underscore (_), and space. Special characters cannot be used at the beginning or end.',
    fence: {
      length: 'The length of a geofence name cannot exceed 30 characters.',
      repeat: 'The geofence name you entered already exists!',
      format: 'Please enter a valid geofence name.'
    },
    agent: {
      required: 'Please enter the agent name.',
      repeat: 'The agent name entered already exists!'
    },
    mobile: {
      required: 'Please enter a phone number.',
      format: 'Please enter a valid phone number.',
      repeat: 'The phone number entered already exists!'
    },
    sysAccount: {
      required: 'Please enter the login username.',
      format: 'Please enter at least 6 alphanumeric characters.',
      format2: 'Login username must be 6-20 alphanumeric characters.',
      repeat: 'The login username entered already exists!'
    },
    email: {
      required: 'Please enter the email address.',
      format: 'Please enter a valid email address.',
      repeat: 'Duplicate email address'
    },
    label: {
      repeat: 'The label entered already exists!'
    },
    uploadFiles: 'Please upload a file.',
    nickName: {
      required: 'Please enter user nickname.',
      format: 'Please enter Chinese characters, or alphanumeric characters.',
      repeat: 'The nickname entered already exists!'
    },
    phoneNumber: {
      required: 'Phone number cannot be empty.',
      format: 'Please enter a valid phone number.'
    },
    password: {
      required: 'Please enter the password.',
      format: 'The password must be 8-20 characters, consisting of uppercase and lowercase letters and numbers.'
    },
    equalToPassword: 'The two passwords entered are inconsistent.',
    oldPassword: 'Old password cannot be empty.',
    confirmPassword: 'Confirm password cannot be empty.',
    uploadImg: 'Invalid image format. Please upload images in JPG or PNG format.',
    access: {
      AccessKeyRequired: 'AccessKey cannot be empty.',
      AccessSecretRequired: 'AccessSecret cannot be empty.'
    },
    domain: {
      required: 'Please enter a domain name.',
      repeat: 'Duplicate domain name'
    },
    loginTemplate: 'Please select a login form.',
    loginPageLogo: 'Please upload the logo for the login page.',
    sysInnerPageLogo: 'Please upload the logo for site pages.',
    loginBg: 'Please upload the background image for the login page.',
    numAlp: 'Please enter alphanumeric characters.',
    systemName: 'Please enter a website name.',
    systemEnName: 'Please enter a website English name.',
    url: 'Please enter a correct URL.'
  },
  place: {
    enter: 'Please enter',
    select: 'Please select',
    selectDeadline: 'Please select the deadline.',
    selectHandleStatus: 'Please select the process status.',
    selectDate: 'Please select the date.',
    selectCompany: 'Please select the enterprise.',
    selectProduct: 'Please select the product.',
    selectLocationType: 'Please select the positioning method.',
    queryEnterprise: 'Enterprise',
    selectTriggerState: 'Please select the triggering event.',
    selectFenceType: 'Please select the geofence type.',
    selectEffectiveType: 'Please select the effective period.',
    selectOperationState: 'Please select the operation status.',
    selectTenant: 'Please select the manufacturer.',
    selectAgent: 'Please select the agent.',
    selectCountry: 'Please select the country or region.',
    selectState: 'Please select the state.',
    selectProvince: 'Please select the province.',
    selectCity: 'Please select the city.',
    selectArea: 'Please select the county.',
    enterDeviceKey: 'Please enter the DeviceKey.',
    queryFenceName: 'Geofence Name',
    enterFenceName: 'Please enter the geofence name.',
    enterPhone: 'Please enter the phone number.',
    enterAgentName: 'Please enter the agent name.',
    enterParentName: 'Please enter the direct upper-level enterprise.',
    enterSysAccount: 'Please enter the login account.',
    enterLinkMain: 'Please enter the contact person.',
    enterAdd: 'Please enter the detailed address.',
    enterEmail: 'Please enter the email address.',
    enterUserId: 'Please enter the user ID.',
    enterQuestion: 'Please enter the question description.',
    enterAnswer: 'Please enter the solution.',
    enterOldPassword: 'Please enter the old password.',
    enterNewPassword: 'Please enter a new password.',
    enterEventName: 'Please enter the event name.',
    confirmPassword: 'Please confirm your password.',
    placeContent: 'Please enter content.',
    selectTemplate: 'Please select template',
    service: 'Service Agreement',
    placeZhService: 'Please enter the agreement name (Chinese).',
    placeZhServiceUrl: 'Please enter a valid URL link (Chinese).',
    placeEnService: 'Please enter the agreement name (English).',
    placeEnServiceUrl: 'Please enter a valid URL link (English).',
    privacy: 'Privacy Policy',
    placeZhPrivacy: 'Please enter the privacy policy name (Chinese).',
    placeZhPrivacyUrl: 'Please enter a valid URL link (Chinese).',
    placeEnPrivacy: 'Please enter the privacy policy name (English).',
    placeEnPrivacyUrl: 'Please enter a valid URL link (English).',
    placeInt: 'Please enter the correct number.',
    ownOrg: 'Please select your enterprise.',
    address: 'Please select an address.',
    applicationScenarios: 'Please select an application scenario.',
    aboutUs: 'About Us',
    sureWithinRange: 'Please ensure that the selected number does not exceed {0}.',
    noSelected: 'No data is selected, please select it on the left.',
    noSelectable: 'There is no options.'
  },
  onlineStatus: {
    title: 'Online Status',
    offline: 'Offline',
    online: 'Online'
  },
  clearType: {
    item1: 'Device automatic release',
    item2: 'Manual release'
  },
  deviceNoticeType: {
    fault: 'fault',
    alarm: 'Alarm',
    faultRelease: 'Fault release',
    alarmRelease: 'Alarm release',
    faultReleaseDetail: 'Fault release details',
    alarmReleaseDetail: 'Alarm release details',
    fenceIn: 'Electronic fence entry',
    fenceOut: 'Electronic fence out of bounds',
    fenceDouble: 'Electronic fence bidirectional ',
    fenceClose: 'Electronic fence closed',
    removed: 'has removed'
  },
  operatingStatus: {
    title: 'Operating Status',
    inactivated: 'Inactivated',
    normal: 'Normal',
    warn: 'Alert',
    error: 'Fault',
    errorwarn: 'Fault+Alert',
    info: 'Information Event',
    erroring: 'Fault',
    errorFix: 'Resolve Fault',
    warning: 'Alert',
    warnFix: 'Resolve Alert',
    fence: 'Geofence',
    fenceIn: 'Enter Event',
    fenceOut: 'Exit Event',
    message: 'Message'
  },
  expenseStatus: {
    title: 'Expense Status',
    off: 'Off',
    trial: 'Trial',
    normal: 'Normal',
    overdue: 'Overdue'
  },
  handleStatus: {
    title: 'Processing Status',
    text1: 'Processed',
    text2: 'Unprocessed'
  },
  login: {
    title: 'Log In',
    place: {
      user: 'Please enter the username.',
      password: 'Please enter the password.',
      code: 'Please enter the CAPTCHA.'
    },
    codeLogin: 'Log In via SMS Verification Code',
    findPassword: 'Forgot Password',
    confirmLogin: 'Confirm',
    logging: 'Logging In',
    oauthLogin: 'Authorized Login',
    question: "Can't access your account?",
    faq: 'FAQs',
    otherWay: 'Other Login Options',
    howregister: 'How to register?',
    easyPwd: 'The password is too simple. Please create a strong password. <br /> The password must be 8 to 20 characters, consisting of uppercase and lowercase letters and numbers.',
    serVerHistory: 'Service Agreement history version',
    priVerHistory: 'Privacy policy history version',
    version: 'Version',
    update: 'Update',
    remark: 'Update description'
  },
  findPassword: {
    title: 'Forgot Password',
    pwd: 'Password',
    accountTip: 'Please enter the login account.',
    accountPlaceholder: 'Username',
    enterAccount: 'Please enter the username.',
    noAccount: 'The username does not exist.',
    backLogin: 'Back to Login',
    sendTip: 'A password reset link will be sent to the following email address. Please confirm whether you are using the email address',
    sendEmail: 'Send Email',
    sendSuccess: 'The email has been sent to your email address successfully!',
    sendSuccessTip: 'If you have not received the email, please check your spam folder or try again.',
    close: 'Close',
    resend: 'Resend',
    mailSource: 'Please pay attention to the email from {0}.',
    resetPassword: 'Reset Password',
    linkFailure: 'Sorry, the reset link has expired!',
    newPasswordLabel: 'New Password (8-20 characters consisting of uppercase and lowercase letters and numbers)',
    newPasswordPlaceholder: 'Please enter your new password.',
    confirmPasswrodLabel: 'Confirm Password',
    confirmPasswrodPlaceholder: 'Please confirm your new password.',
    confirmUpdate: 'Confirm',
    updateSuccess: 'Modified successfully',
    goLogin: 'Go to login',
    passwrodTip: 'Please enter a new password.',
    IllegalPassword: 'Illegal password',
    inconformityPassword: 'The two passwords entered are inconsistent.',
    passwrodAgenTip: 'Please enter your password again.',
    notLogin: 'Not Logged In',
    newPsw: 'Set a new password',
    newPswAgain: 'Reenter password',
    enterSmsCode: 'Enter the verification code',
    sendSmsCode: 'Get verification code',
    smsCodeTip: 'The verification code is sent successfully. It is valid within 5 minutes.',
    findByPhone: 'Phone retrieval password',
    findByEmail: 'Email retrieval password',
    submit: 'Submit',
    toLogin: 'The password is changed successfully, and the login page is about to jump.'
  },
  dashboard: {
    online: {
      title1: 'Online',
      title2: 'Online Rate',
      onlineDevice: 'Online Device',
      offlineDevice: 'Offline Device',
      tipTitle: 'Device Status'
    },
    area: {
      title: 'Region Distribution',
      cityTitle: 'City',
      countryTitle: 'Country',
      numTitle: 'Device(s)',
      percentTitle: 'Percentage'
    },
    active: {
      title: 'Line Chart of Daily Active Devices',
      tip: 'Daily Active Devices'
    },
    alert: {
      title: 'Alert Statistics',
      tip: 'Alert(s)'
    },
    malfunction: {
      title: 'Fault Statistics',
      tip: 'Fault(s)'
    },
    deviceTotal: 'Total Devices',
    equipment: 'Normal',
    Alarmequipment: 'Alert(s)',
    Faultyequipment: 'Fault(s)',
    deviceActive: 'Active Devices Rate',
    devicecount: 'Offline',
    devicerate: 'Online Rate',
    onlinedevicesNum: 'Online',
    OfflineDevicerate: 'Offline Rate',
    userTotal: 'Total Users',
    userActive: 'Active User Rate',
    allProduct: 'All Products',
    today: 'Today',
    weekDate: 'Last 7 Days',
    monthDate: 'Last 30 Days',
    startDateRange: 'Start Date',
    endDateRange: 'End Date',
    dateSelect: {
      today: 'Daily',
      week: 'Weekly',
      month: 'Monthly'
    },
    countType: {
      day: 'Day',
      month: 'Month',
      year: 'Year'
    },
    unit: {
      kw: 'kW',
      kwh: 'kW·h',
      kwp: 'kWp',
      days: 'Day(s)',
      tree: ' ',
      tonne: 'Ton(s)'
    },
    timeSelect: 'Select Time',
    cancelEdit: 'Cancel',
    saveEdit: 'Save',
    kanbanEdit: 'Edit',
    kanbanReset: 'Reset',
    kanbanConfig: 'Dashboard Configuration',
    resetSuccess: 'Reset successfully',
    total: 'Total',
    noRanking: 'No ranking information available.',
    resetTips: 'Resetting the dashboard will clear your current dashboard components and layout configuration and restore default system  settings. Do you want to continue?'
  },
  dataScreen: {
    emsTitle: 'EMS Dashboard',
    socialContribution: 'Carbon Neutrality Contribution',
    cumulativeEnergySavings: 'Coal Saved',
    emissionReductionCO2: 'CO2 Avoided',
    equivalentTreesPlanted: 'Equivalent Trees Planted',
    powerStation: 'Power Station',
    powerStationCount: 'Total Stations',
    powerStationInfo: 'Power Station Overview',
    powerStationRanking: 'Power Station Ranking',
    generatedEnergy: 'Power Generation',
    profitAnalysis: 'Profit Analysis',
    curveAnalysis: 'Curve Analysis',
    coulometricAnalysis: 'Power Analysis',
    energyConsumptionAnalysis: 'Energy Consumption Analysis',
    allStationDeviceOnline: 'Stations of All Device Online',
    partStationDeviceOnline: 'Stations of part Device Offline',
    noStationDeviceOnline: 'Stations of All Device Offline',
    ranking: 'Ranking',
    profit: 'Profit',
    powerStationType: {
      home: 'Residential',
      commercial: 'Commercial',
      industrial: 'Industrial',
      floor: 'Ground'
    },
    powerStationSum: {
      dailyGeneration: 'Generation Today',
      totalGeneration: 'Total Generation',
      dailyDischarge: 'Discharging Today',
      totalDischarge: 'Total Discharging',
      dailyCharge: 'Charging Today',
      totalCharge: 'Total Charging'
    },
    props: {
      solarPower: 'Generation Power',
      loadPower: 'Total Load Power',
      batteryPowerBatteryStatus1: 'Battery Discharging Power',
      batteryPowerBatteryStatus0: 'Battery Charging Power',
      solarPanelPowerGeneration: 'Power Generation',
      loadPowerConsumption: 'Consumption',
      batteryDischarge: 'Battery Discharging',
      batteryCharge: 'Battery Charging',
      gridConnectedQuantity: 'Sold',
      electricitPurchase: 'Purchased',
      batteryPower: 'Battery Power',
      batteryPowerNegative: 'Battery Discharging Power',
      batteryPowerPositive: 'Battery Charging Power',
      soc: 'Battery SOC'
    },
    unit: {
      tree: '',
      tonne: 'Ton(s)',
      kwh: 'kW·h'
    }
  },
  product: {
    batchSet: 'Label in batch',
    labelManage: 'Label Management',
    transparentTransmission: 'Transparent Transmission',
    tsl: 'TSL Model  ',
    search: {
      connProtocol: 'All Access Protocols',
      dataFormat: 'All Data Formats',
      productName: 'Please enter a product name.'
    },
    tableTitle: {
      productName: 'Product Name',
      vendorName: 'Manufacturer',
      connProtocol: 'Access Protocol',
      dataFormat: 'Data Format',
      status: 'Status'
    },
    status: {
      published: 'Released',
      unPublished: 'Not Released'
    },
    actionBtn: {
      device: 'Device List',
      function: 'Feature Definition',
      category: 'Category Settings'
    },
    queue: 'Message Queue',
    queuePlace: 'Please enter the message queue.',
    queueDisable: 'Please disable the queue first.',
    queueStatus: {
      title: 'Queue Listening',
      stop: 'Not Started',
      start: 'Running',
      empty: 'Idle',
      enable: 'Enable',
      disable: 'Disable'
    },
    startSuccess: 'Enabled successfully',
    closeSuccess: 'Disabled successfully',
    function: {
      tabAttr: 'Property',
      tabEvent: 'Event',
      tabService: 'Service',
      tabCustom: 'Custom Field',
      dataAcq: 'Data Storage Services',
      timedRefresh: 'Timed Refresh',
      month: 'month(s) ago',
      week: 'week(s) ago',
      day: 'day(s) ago',
      hour: 'hour(s) ago',
      min: 'minute(s) ago',
      just: 'Just now',
      attr: {
        autoRefresh: 'Gather',
        addLabel: 'Add Label',
        editLabel: 'Edit Label',
        edit: 'Edit Property',
        editWarning: 'Do not allow private page editing.',
        labelFormatLimit: 'Only 20 labels can be added.',
        labelInputPh: 'Please enter the label name.',
        labelColorPh: 'Please select the label color.',
        labelSelectPh: 'Please select the label.',
        tableName: 'Property Name',
        tableSubType: 'R/RW',
        tableCode: 'Identifier',
        tableDataType: 'Data Type',
        tableIcon: 'Icon',
        tableUnit: 'Unit',
        tableDisplay: 'Display Device Detail Property',
        tableSort: 'Sort',
        subTypeR: 'Read Only',
        subTypeW: 'Write Only',
        subTypeRW: 'RW',
        placeName: 'Please enter the property name.',
        placeIcon: 'Click to select Icon.',
        placeUnit: 'Please enter the unit.',
        placeParm: 'Please enter the parameter name.',
        placeIntMap: 'The default is type INT.',
        paramEditor: 'Param Editor',
        typeMapping: 'Type Mapping',
        typeMappingTip: 'Note: If Type Mapping is set, then Display Device Search Condition cannot be set',
        typeTime: 'TIME',
        typeWeek: 'WEEK',
        sensorIcon: 'Sensor Icon',
        enableReport: 'Enable Report',
        parmName: 'Parameter Name',
        allowControl: 'Remotely Controlled',
        allowSearch: 'Display Device Search Condition',
        allowTable: 'Display Device List',
        attrSet: 'Property Settings',
        tableDisplayTip: 'This command is used to configure the properties to be displayed on the Device Details - Page',
        allowControlTip: 'This command is used to set properties that can be remotely controlled on the Device Details - Command Delivery Page',
        allowSearchTip: 'Used to configure Device List-Search Condition',
        allowTableTip: 'Used to configure Device List-Display',
        xcxDeviceDisplayTip: 'Used to configure Device List-Display properties for display in the mini-program',
        xcxMountDisplayTip: 'Used to configure Installation Site-Display properties for display in the mini-program',
        mapDisplayTip: 'Used to configure Device Map properties for display in the pop-up window',
        labelName: 'Label',
        labelColor: 'Color',
        xcxDeviceDisplay: 'Display Applet Device List',
        xcxMountDisplay: 'Display Applet Installation Site',
        mapDisplay: 'Display Applet Device Map',
        warningText: {
          display: 'Are you sure to {0} in "Device Detail"?',
          enableControl: 'Are you sure to {0} in "Send Command"?',
          tableDisplay: 'Are you sure to {0} in "Device Table"?',
          searchDisplay: 'Are you sure to {0} in "Device Search"?',
          xcxDeviceDisplay: '',
          xcxMountDisplay: '',
          mapDisplay: 'Are you sure to {0} in "Device Map"?'
        }
      },
      event: {
        tableName: 'Event Name',
        tableCode: 'Identifier',
        tableSubType: 'Event Type',
        warn: 'Alert',
        info: 'Message',
        error: 'Fault',
        unknown: 'Unknown',
        config: 'Event Configure',
        parmName: 'Parameter Name',
        parmType: 'Parameter Type',
        parmValue: 'Value',
        placeName: 'Please enter the event name.',
        placeParmName: 'Please select the parameter name.',
        placeParmType: 'Please enter the parameter type.',
        noConfig: 'Non-configurable',
        configSuccess: 'Configured successfully',
        downloadLog: 'Download Records',
        dateRange: 'Please select a time range',
        solvedValue: 'Solved {0} Value',
        solvedNotice: 'Notification of {0} Resolved'
      },
      service: {
        tableName: 'Service Name',
        tableCode: 'Code',
        enableTip: 'Are you sure you want to enable the control of {0} ?',
        disableTip: 'Are you sure you want to disable the control of {0} ?'
      },
      custom: {
        overallOverview: 'Overview',
        addField: 'Add Field',
        editField: 'Edit Field',
        filedName: 'Field Name',
        fieldType: 'Field Type',
        configuration: 'Field Configuration',
        filedCode: 'Field Code',
        required: 'Required',
        placeFiledName: 'Please enter the field name.',
        placeFiledCode: 'Please enter the field code.',
        validRequired: 'Please select whether it is required.',
        desc1: 'The custom field will be used when you import device(s).',
        desc2: 'Custom fields record the special attributes of a device, such as manufacture date and device unique identification.',
        confirm1: 'Are you sure you want to delete the field name',
        confirm2: '?',
        fliedPosi: 'Field Display Location',
        display: 'Device List',
        search: 'Device Search Condition',
        xcxDisplay: 'Applet Device List',
        xcxIsSearch: 'Applet Device Search Condition',
        businessOnly: 'Visible only to the manufacturer'
      },
      action: {
        service: 'Service',
        property: 'Property',
        presetInfo: 'After pre-setting is enabled, you need to configure the downlink data in the following part to implement one-click operation in the device list.',
        topInfo: 'Select the corresponding feature and setting values, and configure the operation button in the list. You can configure up to four single operations.',
        name: 'Operation Name',
        relationProp: 'Associated Property',
        only: 'Single Operation',
        multi: 'Multiple Operation',
        add: 'Add Operation',
        preSet: 'Preset',
        type: 'Issue Type',
        setProperty: 'Set Value',
        inputParameter: 'Enter Parameter'
      }
    },
    propController: {
      title: 'Property Controller',
      filterPh: 'Please enter property name or property code to search.',
      tips: 'When the {0} is {1}, selected items will be filtered and invisible'
    },
    fileImgTip: 'Recommended size: 800*800 pixels. Format: JPG and PNG.',
    fileImgSizeTip: 'The uploaded image cannot exceed 500 KB.',
    place: {
      productName: 'Please enter ProductKey or product name.',
      nameOrPk: 'ProductKey or product name.',
      appPageName: 'Please select QuecOne details.',
      miniAppPageName: 'Please select Mini Program details.',
      webPageName: 'Please select SaaS details.'
    },
    deviceNum: 'Number of Devices',
    accessType: 'Device Type',
    netWay: 'Network Connection Method',
    appPageName: 'QuecOne Details',
    miniAppPageName: 'Mini Program Details',
    webPageName: 'SaaS Details',
    category: 'Product Category',
    no_category: 'No category info, please enable product.',
    categorySetiing: 'Category Settings',
    qrCodeSetiing: 'QR Code Settings',
    dataFormat1: 'Queue listening for transparent transmission/custom products is not supported.',
    qrcodeType: 'QR Code Type',
    qrcodeSetTips: 'ps: The networking method is wifi or the device type is the sub-device QR code type only SN does not have IMEI',
    custSet: 'Custom Field Settings',
    ext: {
      string: 'String',
      date: 'Date',
      enum: 'Enum',
      enumItem: 'Enum Items',
      addEnumItem: 'Add Enum Item',
      enumItemRequired: 'At least one Enum Item is required',
      enumItemNotEmpty: 'The Enum Item cannot be empty',
      tips: {
        extTypes: 'Field types include characters, dates, and radio selections.',
        extTypeString: 'String: Recording of machine text and numerical information, such as production batches, etc.',
        extTypeDate: 'Date: Record date information related to the device, such as the date the device left the factory, etc.',
        extTypeEnum: 'Enum: Record device model information, such as device model.'
      }
    },
    dataAcquisition: 'Data Acquisition Configuration',
    snNum: 'Device SN',
    imei: 'Module IMEI',
    PK: 'ProductKey',
    vendorName: 'Manufacturer',
    booking: 'Product Activation',
    actionSet: 'Action Settings',
    openBookingTips: 'Please enable Product Activation.',
    setBtn: 'Settings',
    settings: 'The product is enabled successfully, whether the product is configured.',
    refresh: 'Refresh',
    analyze: 'Data Analysis',
    debug: 'Debug Analysis',
    analyzeTips: 'Only four charts can be added. After the charts are configured, you can view the device statistics chart on the “Device Details” page.',
    aggregation: {
      title: 'Data Aggregation',
      add: 'Add Configuration',
      preConfig: 'Pre-configured Data',
      preTip: 'View the properties of the product that already support data aggregation by default.',
      countType: 'Statistical Method',
      tip1: '1. Data is aggregated only after the configuration takes effect. Historical data cannot be aggregated.',
      tip2: '2. A maximum of 10 properties can be configured. The properties not configured cannot be selected and collected in “Running Records”.',
      tip3: '3. Current value: The value of the data reported by the device during the statistical period. Total value: Total value of the data reported by the device since the configuration takes effect.',
      preTip1: '1. The following table lists the pre-configured properties that support data aggregation by default. The following properties do not occupy the quantity configured for product data aggregation.',
      preTip2: '2. When the property name is the same, the manually configured data shall prevail.',
      over10: 'Failed. There already are 10 properties.',
      addTips: 'Only the properties in the "Read and Write" or "Write Only" type and with INT, FLOAT, DOUBLE, BOOL, ENUM and STRUCT as the data type can be configured.',
      noCategories: 'No categories available, please select the category you want to add'
    }
  },
  notice: {
    email: 'Email Notification',
    sms: 'SMS Notification',
    titleEmail: 'Edit Email Template',
    titleSms: 'Edit SMS Recipient',
    titleVoice: 'Edit Voice Template',
    titleApp: 'Edit App Template',
    emailTemplate: 'Email Template',
    templateMgt: 'Template',
    placeContent: 'Please enter content.',
    receiver: 'Recipient Settings',
    endUserPush: 'Bound User',
    orgPush: 'Associated Enterprise',
    fixedPush: 'Specific Email Address',
    fixedRecipient: 'Specific Recipient',
    recipient: 'Recipient',
    addTemplate: 'Create Template',
    tableSelect: 'Select',
    tableName: 'Name',
    tableContent: 'Content',
    templateName: 'Template Name',
    placeTemplateName: 'Please enter the template name.',
    placeTemplateContent: 'Please enter template content.',
    templateContent: 'Template Content',
    insert: 'Insert',
    insertData: 'Insert Data',
    warnContent: 'Alert Information',
    sendDate: 'Sending Time',
    action: 'Action',
    editEmailTemplate: 'Edit Email Template',
    confirm1: 'Are you sure you want to delete',
    confirm2: '?',
    addEmailTemplate: 'Add Email Template',
    valid: {
      templateId: 'Please select the email template.',
      template: 'Please select the template.',
      templateType: 'Please select the template type.',
      templateCode: 'Please enter the template code.',
      language: 'Please select the language.',
      params: 'Please select the parameter.',
      sysParams: 'Please select the system parameter.',
      templateTitle: 'Please input the template title.'
    },
    voice: 'Voice Notification',
    app: 'App Notification',
    language: 'Language',
    tenantName: 'Applicable Enterprise',
    setDefault: 'Set as Default',
    sign: {
      title: 'Signature',
      add: 'Add Signature',
      name: 'Name',
      default: 'Default',
      edit: 'Edit Signature'
    },
    temp: {
      title: 'Template',
      add: 'Add Template',
      code: 'Template Code',
      type: {
        name: 'Template Type',
        item1: 'Fault',
        item2: 'Resolve Fault',
        item3: 'Alert',
        item4: 'Resolve Alert',
        item5: 'Geofence'
      },
      default: 'Default',
      param: 'Parameter',
      details: 'Template Details',
      edit: 'Edit Template',
      tips: 'The template does not require parameter configuration.'
    },
    rules: {
      signName: 'Please input signature name.'
    },
    sure: {
      sign: 'Are you sure you want to set this signature as <span style="color:#456ff7"> default signature </span>?',
      temp: 'Are you sure you want to set this template as <span style="color:#456ff7"> default template </span>?'
    },
    setSucc: 'Set successfully',
    product: 'Applicable Product',
    paramMap: 'Parameter Mapping',
    details: 'Details',
    sysParams: 'System Parameter',
    commonParams: 'Common Parameter Mapping',
    tslParams: 'TSL Parameter Mapping',
    triggerFault: 'A Fault Is Triggered',
    triggerAlarm: 'An Alert Is Triggered',
    dismissFault: 'Resolve Fault',
    dismissAlarm: 'Resolved Alert',
    triggerFence: 'A Geofence Event Is Triggered'
  },
  device: {
    dvLowerCase: 'device',
    dvUpperCase: 'Device',
    inputLabel: 'Please enter the label',
    labelRename: 'Label Rename',
    inputProp: 'Property Name',
    selectProduct: 'Please select a product.',
    selectCompany: 'Please select an enterprise.',
    placeDeviceKey: 'Please enter the DeviceKey.',
    placeDeviceSn: 'Device SN',
    validDeviceKey: 'Please enter 4 to 32 alphanumeric characters.',
    checkFile: 'Please import files in XLS or XLSX format!',
    downDeviceImpTemplate: 'Download Device Import Template',
    downGroupImpTemplate: 'Download Installation Site Import Template',
    deviceImpTemplate: 'Device Import Template',
    impTemplate: 'Import Template',
    groupImpTemplate: 'Installation Site Import Template',
    importRecords: 'Device Import Records',
    importDetails: 'Import Details',
    importTotal: 'Total Import(s)',
    importSuccessNum: 'Successful Import(s)',
    importSuccess: 'Imported successfully',
    importFailNum: 'Failed Import(s)',
    importStatus: 'Status',
    importTime: 'Time',
    importDevice: 'Import Device',
    exportDevice: 'Export Device',
    importSubmitted: 'Import is submitted.',
    importResults: 'Import Result',
    importDeviceDetails: 'Device Import Details',
    processFinish: 'Processed',
    processing: 'Processing',
    downDetails: 'Download Import Results',
    noProducts: 'No product',
    confirmDelDevice: 'Are you sure you want to delete the device?',
    deviceName: 'Device Name',
    deviceSn: 'Device SN',
    orgName: 'Enterprise',
    lastOnlineTime: 'Previous Online Time',
    listField: 'Listed Parameter',
    customField: 'Custom Field',
    listFieldFilter: 'Set Listed Parameter',
    deviceDtl: 'Device Details',
    gatewayDtl: 'Gateway Details',
    subordinateGateway: 'Gateway',
    subset: 'Sub-device',
    locationInfo: 'Locations',
    export: 'Export Device',
    singleSend: 'Send Command',
    singleSendTip: 'Single-device operation: The single-device operation of the product can realize the command control of a single device',
    batchSend: 'Send Command in Batches',
    batchSendTip: 'Multi-device operation in product configuration - operation settings can realize batch command control for multiple devices',
    mapLine: 'Map Line Sorting',
    basicInfo: {
      deviceKey: 'DeviceKey',
      name: 'Device Name',
      sn: 'Device SN',
      statusInfo: 'Status Information',
      productInfo: 'Product Information',
      productsOrg: 'Organization Of Product',
      devicesOrg: 'Organization Of Device',
      qrcode: 'Device QR Code ',
      modelSpec: 'Module Model',
      activationTime: 'Activation Time',
      editInfo: 'Edit Information',
      dataTime: 'Data Update Time',
      signal: 'Signal Strength',
      errorEvent: 'Error Event',
      warningEvent: 'Warning Event',
      valid: {
        deviceName: 'Please enter the device name.'
      },
      detail: 'Details'
    },
    remainingDays: {
      title: 'Remaining Available',
      dayValid: 'Please enter the days between 6 and 500'
    },
    deviceAttr: 'Device Property',
    customCol: 'Expand Fields',
    toSelect: 'Select',
    onlyOneProduct: 'Only from the same product can be selected',
    exportSelection: 'Selection Of {0}',
    unfold: 'Display',
    putAway: 'Hide',
    tslGroup: {
      showAll: 'Show all labels',
      showVisible: 'Show visible labels'
    },
    location: {
      deviceNo: 'DeviceKey',
      state: {
        title: 'Status',
        offline: 'Offline',
        online: 'Online'
      },
      lal: 'Coordinates',
      lalInfo: 'Coordinates Info',
      locate: 'Location',
      signal: 'Signal Strength',
      view: 'View',
      time: 'Positioning Time',
      targeting: 'Positioning System',
      satellite: 'Number of Satellites',
      manualPunctuation: 'Set Location Manually',
      deviceReport: 'Reported By Device',
      editLocation: 'Edit Locations',
      mountPH: 'Please enter and select a mount.'
    },
    mileage: {
      title: 'Mileage Report',
      placeMonth: 'Select month',
      milage: 'Mileage',
      day: 'Daily'
    },
    faultLog: {
      title: 'Fault Log',
      troubleshoot: 'Resolve Fault',
      faultName: 'Fault Name',
      faultTime: 'Fault Time',
      statusRecord: 'Recorded Details',
      currentValue: 'Current Value',
      deviceStatus: 'Device Status',
      message1: 'Please select a fault first.',
      message2: 'Please do not select the resolved fault.',
      successMsg: 'Resolved.'
    },
    warnLog: {
      title: 'Alert Log',
      disarm: 'Resolve Alert',
      alarmName: 'Alert Name',
      alarmTime: 'Alert Time',
      message: 'Please select an alert first.'
    },
    setInstruction: {
      title: 'Send Command',
      attribute: 'Set Property',
      sendControl: 'Send Command',
      setValue: 'Configured Value',
      msg1: 'Sent successfully',
      msg2: 'Please select a property.',
      msg3: 'Please assign a value to the property.',
      msg4: 'Please enter the configured value.',
      serviceCall: 'Service Call',
      returnResult: 'Return Result',
      returnValue: 'Return Value',
      confirmAction: 'Are you sure you want to perform the action?',
      selectDevice: 'Please select devices to be operated in batches.',
      batch: 'In Batches',
      batchRecord: 'In Batches Record'
    },
    sim: {
      title: 'SIM Card',
      supplier: 'Operator',
      setMeal: 'Data Plan',
      networkType: 'Network Type',
      active: 'Activation Status',
      activateTime: 'Activation Date',
      expiryDate: 'Billing Cycle End Date',
      status: 'Status',
      flow: 'Data Used in Current Month',
      residueFlow: 'Remaining Data',
      cardType: 'SIM Card Type',
      setmealmonths: 'Plan Period (Unit: Month)',
      cardOrderNo: 'Order Number',
      cardOrderType: {
        title: 'Order Type',
        text1: 'Data Plan Renewal',
        text2: 'Recharge Data'
      },
      cardOrderStatus: {
        title: 'Order Status',
        notPay: 'Unpaid',
        payComplete: 'Recharging',
        orderComplete: 'Recharged successfully',
        orderFailure: 'Recharge failed'
      },
      paymentType: {
        title: 'Payment Type',
        wechat: 'WeChat Pay',
        alipay: 'AliPay',
        huifu: 'Hui Fu',
        enterprise: 'Enterprise Direct Charge'
      },
      cardProductName: 'Product Name',
      cardOperatorName: 'Operator',
      createTime: 'Order Time',
      completeTime: 'Completion Time',
      cardRenewalAmount: 'Order Amount',
      yuan: 'RMB',
      retry: 'Retry',
      refund: 'Refund',
      placeOrderNo: 'Please enter the order number.',
      placeIccid: 'Please enter the ICCID.',
      placeOrderStatus: 'Please select the order status.',
      deviceKey: 'DeviceKey'
    },
    map: {
      all: 'All',
      collectStatus: {
        title: 'Following Status',
        text1: 'Follow',
        text2: 'Unfollow',
        text3: 'Not Follow'
      },
      track: 'Trajectory Playback',
      noLocation: 'No location',
      add: 'Please enter the location of the device.'
    },
    trackPlay: {
      distance: 'Total Mileage',
      speed: 'Speed',
      tips: 'Tips: Up to the record of 3 days can be queried.',
      stayMark: 'Geofence Starting Point',
      timeSelect: 'Date',
      showFence: 'Display Geofence',
      trackLine: 'Trajectory Map',
      trackDetail: 'Trajectory Details',
      hdop: 'GPS Signal',
      noFence: 'No geofence',
      drivingTips: 'No driving records during the selected period.',
      getAdd: 'Click to Obtain Address'
    },
    fence: {
      fenceName: 'Geofence Name',
      triggerConditions: 'Triggering Event',
      fenceType: 'Geofence Type',
      effectiveType: 'Effective Time',
      effectiveType0: 'Long Term',
      effectiveType1: 'Time Range',
      isEnabled: 'Enable/Disable',
      createTime: 'Creation Time',
      viewDevice: 'Device',
      sure: 'Are you sure you want to delete the geofence?',
      bindDevice: 'Bind Device',
      unbindDevice: 'Unbind Device',
      batchAdd: 'Add in Batches',
      deviceTips: 'Please select a device first.',
      noneTips: 'Please select a {0} first.',
      trigger: {
        item1: 'Exit Event',
        item2: 'Enter Event',
        item3: 'Enter/Exit Event',
        item4: 'Disabled'
      },
      startTime: 'Start Time',
      endTime: 'End Time',
      drawTips1: 'Click to start drawing the geofence and double click to end drawing. ',
      drawTips2: 'Drag the mouse to draw the geofence and release to end drawing.',
      polygon: 'Polygon',
      round: 'Circular',
      empty: 'Clear',
      hefei: 'Hefei',
      drawTips: 'Please draw the geofence first.',
      openTimeTips: 'Please enable a time range first.',
      effectTimeTips: 'Please select the range of effective time.',
      confirm: 'Confirm'
    },
    sn: 'Generate SN',
    generateSum: 'Number of Generations',
    snValid: 'The generated quantity cannot be empty.',
    snMsgSuccess: 'Generated successfully',
    snList: 'SN List',
    add: 'Add Device',
    singleAdd: 'Add a Single',
    batchImport: 'Add in Batches',
    run: {
      title: 'Running Records',
      more: 'More',
      newChart: 'Make a Chart',
      chartTips: 'Only 4 charts can be added. The charts configured by the manufacturer cannot be edited or deleted',
      chartYTips: 'When the range is not set, it is automatically calculated to ensure the uniform distribution of the coordinate axis scale.',
      minmax: 'The maximum value needs to be greater than the minimum value.',
      heading: 'Title',
      dataType: 'Data Type',
      chartType: 'Chart Type',
      yunit: 'Y-axis (Unit)',
      dataSource: 'Data Source',
      countType: 'Statistical Method',
      place: {
        title: 'Please enter a title.',
        dataType: 'Please select a data type.',
        chartType: 'Please select a chart type.',
        dataSource: 'Please select a data source.',
        countType: 'Please select a statistical method.'
      },
      singleChoice: 'Single-Choice',
      multipleChoice: 'Multiple-Choice',
      dataTypeOptions: {
        item1: 'Bool Type',
        item2: 'Integer Type',
        item3: 'Enumeration Type',
        item4: 'Date Type',
        item5: 'Text Type'
      },
      chartTypeOptions: {
        item1: 'Bar Chart',
        item2: 'Line Chart',
        item3: 'Pie Chart'
      },
      countTypeOptions: {
        item1: 'Maximum Value',
        item2: 'Minimum Value',
        item3: 'Average Value',
        item4: 'Non-aggregated Data',
        item5: 'Total Quantity',
        item6: 'Percentage',
        item7: 'Increment'
      },
      editChart: 'Edit the Chart',
      validDataSource: 'Please select 10 data sources'
    },
    realtime: {
      openInNewWindow: 'Open In New Window',
      historyLine: 'History Curve',
      realtimeLine: 'Realtime Curve',
      pause: 'Pause'
    },
    history: {
      title: 'History Data',
      showItemEdit: 'Edit Display Item',
      field: 'Field',
      sortedSucc: 'Sorted successfully',
      validExport: 'Please tick the export item.',
      maxSize: 'A maximum of 150 items can be exported for historical data.',
      selectAll: 'Select All',
      rangeSetting: 'Range Setting',
      exportExcel: 'Export Excel',
      download: {
        btnName: 'Download PDF',
        tip: 'Only 5 attributes and 1000 pieces can be downloaded.',
        setting: 'Setting',
        tableName: 'Table Title',
        ph: 'Please enter a table title.'
      }
    },
    event: {
      title: 'Event Records'
    },
    productPickTip: 'Please select the product first.',
    dataUpdateTs: 'Data Update Time',
    exports: {
      title: 'Export Item',
      tip: 'You can download up to 10,000 pieces of history data every time.',
      tip2: 'You can download up to 10,000 pieces of data every time.'
    },
    powerSta: 'Electricity Statistics',
    endOrgName: 'Binding terminal mechanism',
    dataLog: {
      title: 'Data Log',
      content: 'Content',
      detail: 'Details',
      failReason: 'Fail Reason',
      sendState: {
        title: 'Status',
        pending: 'Pending',
        sendSuc: 'Sended successfully',
        sendFail: 'Send failed'
      }
    },
    serviceLog: {
      title: 'Service Log',
      valueSet: 'Configured Value',
      valueReturn: 'Return Value',
      sendTime: 'Send Time'
    },
    prop: {
      refresh: 'Refresh',
      tip: {
        fold: 'Fold Display: Fold and display struct and array attributes',
        tile: 'Tile Display: Display all attribute data in a flat view'
      }
    },
    host: {
      title: 'host',
      name: 'Host Name',
      sn: 'Host IMEI',
      mount: 'Partition',
      zoneName: 'Partition Name',
      addHost: 'Add Host',
      distributeHost: 'Distribute Host',
      recycleHost: 'Recycle Host',
      template: 'Download host import template',
      allHost: 'All {0}s',
      selectedHost: 'Selected {0}s',
      nameAndIMEI: 'Host Name/Host IMEI',
      pwd: 'Host Password',
      importTemp: 'Device Host Template',
      confirmDel: 'Are you sure you want to delete the host?',
      confirmUnbind: 'Are you sure you want to unbind the host?',
      bind: 'Add Host',
      unbind: 'Unbind Host',
      ph: {
        name: 'Please enter the host name',
        sn: 'Please enter the host IMEI',
        snTip: 'After setting the host IMEI/login account, it cannot be modified. Please pay attention to storage',
        mount: 'Please select the partition you are in'
      }
    }
  },
  agent: {
    recovery: {
      record: 'Device Recycle Records',
      title: 'Recycle Device',
      btnName: 'Recycle',
      tsCreateTime: 'Time',
      org: 'Recycle Enterprise',
      orgPh: 'Please select an Recycle enterprise.',
      product: 'Product',
      view: 'View',
      note: 'The devices are currently being imported and distributed. Please refresh the device list later to view them, or click “View” below to view the device import records.',
      confirm: 'Confirm',
      orgSelectTitle: 'Please select a recycling organization.',
      selected: 'Already Selected',
      loading: 'Loading...',
      noMore: 'No data',
      nameDkSn: 'Device Name/DeviceKey/Device SN',
      extFieldCode: 'Custom Field',
      exportDetails: 'Device Import Details',
      recycle: 'Device Recycle',
      confirmRecycle: 'Confirm'
    },
    allocationRecord: 'Device Distribution Records',
    agentName: 'Enter agent. ',
    parentName: 'Direct Upper-Level Enterprise',
    attribution: 'Location',
    opStatus: {
      title: 'Operation Status',
      item1: 'Enabled',
      item2: 'Disabled'
    },
    linkman: 'Contact Person',
    linkphone: 'Phone Number',
    allocationDevice: 'Distribute Device',
    addAgent: 'Add an Agent',
    sysAccount: 'Account',
    loginAccount: 'Account',
    sysAccountTips: 'Please create an account for your agent.',
    assignProduct: 'Distribute Product',
    address: 'Detailed Address',
    email: 'Email Address',
    emailTips: 'The email address is used to receive the initial password and reset the password.',
    uploadTips: 'Distribute the device to agents by importing DeviceKey through an excel file. ',
    downTemplate: 'Download Device Distribution Template',
    deviceAllocation: 'Device Distribution',
    deviceAllocated: 'Distributed Device',
    activation: 'Activate',
    manual: 'Distribute Manually',
    batch: 'Distribute in Batches',
    appAssign: 'Distribute in Mini Program',
    importTotal: 'Total',
    importSuccessNum: 'Success',
    importFailNum: 'Failure',
    importStatus: 'Status',
    importTime: 'Distributed Time',
    type: 'Distribute Method',
    location: 'Device Distribution',
    viewLocation: 'View Device Location',
    statistics: 'Device Statistics',
    orgNameTip: 'Enterprise Before Distribution',
    assignOrgConfirm: 'Distribution Confirm',
    assignOrg: 'Enterprise of Distributed Device',
    assignOrgName: 'Enterprise of Distributed Device',
    assignOrgNameTip: 'Enterprise After Distribution',
    assignDetails: 'Device Distribution Details',
    template: {
      title: 'Device Distribution Template',
      tip1: '1. Only support files in “xlsx” or “xls” format, less than 2 M',
      tip2: '2. A maximum of 1000 devices can be distributed in a single batch. Please note that a larger number of devices may require additional time for verification and import.',
      tip3: '3. 3. After uploading the file and distributing the devices, you can view details of devices that failed to be uploaded in “Device List - More Actions - Import Records.”'
    }
  },
  user: {
    asyncUser: 'Synchronous User',
    userId: 'User ID',
    avatar: 'Profile Photo',
    nickname: 'Nickname',
    source: 'Source',
    address: 'Address',
    createTime: 'Registration Time',
    uploadTips: 'Are you sure you want to export all role data?',
    gender: {
      title: 'Gender',
      item1: 'Male',
      item2: 'Female',
      item3: 'Secret'
    },
    langTips: 'We will send you notifications for alerts and faults by email, SMS and other means in the language you set.',
    timezoneTips: 'Please refresh the page in time for the configuration to take effect after updating your time zone or computer system time zone.',
    lastLoginTime: 'Previous Online Time',
    boundDevice: 'Bound Device',
    unbindTips: 'Are you sure you want to unbind the device?',
    profile: {
      personalInfo: 'Personal Information',
      sysAccount: 'Account',
      nickName: 'Nickname',
      phoneNumber: 'Phone Number',
      email: 'Email Address',
      department: 'Department',
      ownedRole: 'Role',
      basicInfo: 'Basic Information',
      changePassword: 'Change Password',
      resetPwd: {
        oldPassword: 'Old Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password'
      },
      userAvatar: {
        uploadAvatarTitle: 'Profile Image',
        select: 'Select',
        submit: 'Submit',
        modifyAvatar: 'Change Profile Image'
      },
      cancelEdit: 'Cancel',
      editInfo: 'Edit',
      saveInfo: 'Save',
      createTime: 'Creation Time',
      // 2.1.5
      feedback: 'Feedback',
      successful: 'Feedback Successfully',
      num: 'Number',
      handle: 'Process',
      suggestion: 'Handling Suggestion',
      placeSuggestion: 'Please enter the handling suggestion',
      record: 'Process Records',
      pending: 'Pending',
      success: 'Feedback Successfully'
    },
    sysLanguage: 'Language',
    placeSysLanguage: 'Please select a language.',
    timeZone: 'Time Zone',
    placeTimeZone: 'Please select the timezone.',
    adaptive: 'Auto',
    auditLog: {
      username: 'Username',
      module: 'Menu',
      operation: 'Operation',
      time: 'Time',
      user: 'User',
      ip: 'IP Address',
      describe: 'Description'
    },
    // 终端用户反馈
    feedback: {
      replyStatus: {
        true: 'Replied',
        false: 'No Reply'
      },
      feedbackDetail: 'Feedback Details',
      feedbackMsg: 'Content',
      feedbackImg: 'Picture',
      feedbackTime: 'Time',
      appName: 'App',
      confirmReply: 'Reply',
      replyPh: 'Please enter a reply, and it will not be modified.',
      replyConfirmPh: 'The content cannot be changed, are you sure to reply?'
    },
    banner: {
      link: 'Image Link',
      linkTips: 'This passage mentions the need to configure third- party links on the WeChat Official Account Platform and undergo domain verification for successful redirection.',
      domain: 'Business Domain Name',
      interval: 'Interval',
      title: 'Banner Title',
      pic: 'Banner Image',
      ph: {
        link: 'Please fill in the image redirection link. If not filled in, it will not redirect',
        size: 'Suggested size 351X118',
        accept: 'JPG、PNG and JPEG formats are supported',
        limit: 'No more than 2 MB',
        supportTypes: 'JPG、PNG and JPEG formats are supported.'
      }
    }
  },
  system: {
    userStatus: {
      title: 'Status',
      item1: 'Enabled',
      item2: 'Disabled'
    },
    place: {
      deptName: 'Please enter the department name.',
      nickName: 'Please enter the nickname.',
      roleName: 'Please enter the role name.',
      roleKey: 'Please enter the role ID.',
      userPwd: 'Please enter the password.',
      leader: 'Please enter the contact person.',
      postCode: 'Please enter the position code.',
      postName: 'Please enter the position name.',
      selectDept: 'Please select a department.',
      selectRole: 'Please select a role.',
      selectHigherDept: 'Please select an upper-level department.'
    },
    userId: 'User ID',
    nickName: 'Nickname',
    dept: 'Department',
    userPwd: 'Password',
    post: 'Position',
    role: 'Role',
    importUpdateTips: 'Whether to refresh the existing data.',
    downTemp: 'Download Template',
    validNickName: 'Nickname must be Chinese characters or alphanumeric characters.',
    addUser: 'Add User',
    editUser: 'Edit User',
    resetPwdTip1: 'Please enter',
    resetPwdTip2: 'a new password.',
    resetPwdTip3: 'Your password has been reset successfully. The new password is',
    delUserTips: 'Are you sure you want to delete',
    exportTips: 'Are you sure you want to export the data?',
    userImport: 'Import User',
    roleName: 'Role Name',
    roleKey: 'Role ID',
    labelCode: 'Role Label',
    roleStatus: 'Role Status',
    roleId: 'Role Code',
    roleDefault: 'Default Role',
    roleSort: 'Display Order',
    dataPermission: 'Data Permission',
    menuPermission: 'Menu Permission',
    loading: 'Loading...',
    autScope: 'Permission Scope',
    dataScope1: 'All Data',
    dataScope2: 'Custom Data',
    dataScope3: 'Data of the Department',
    dataScope4: 'Data of the Department and Its Subordinate(s)',
    dataScope5: 'Self-owned Data Only',
    validRoleName: 'Role name cannot be empty.',
    validRoleKey: 'Role ID cannot be empty.',
    validRoleSort: 'Display order cannot be empty.',
    addRole: 'Add Role',
    editRole: 'Edit Role',
    allocationDataPermission: 'Grant Data Permission',
    delRoleTips: 'Are you sure you want to delete the role ID?',
    deptName: 'Department Name',
    deptStatus: 'Status',
    higherDept: 'Upper-Level Department',
    validHigherDept: 'The upper-level department cannot be empty.',
    leader: 'Contact Person',
    addDept: 'Add Department',
    editDept: 'Edit Department',
    delDeptTips: 'Are you sure you want to delete',
    postCode: 'Position Code',
    postName: 'Position Name',
    postStatus: 'Status',
    postId: 'Position Code',
    addPost: 'Add Position',
    editPost: 'Edit Position',
    delPostTips: 'Are you sure you want to delete',
    dashboard: {
      globalParams: 'Global Parameter',
      dataSourceName: 'Data Source Chinese Name',
      dataEnName: 'Data Source English Name',
      reqUrl: 'Interface URL',
      reqWay: 'Request Method',
      dataType: 'Data Type',
      parameter: 'Parameter',
      createParameters: 'Create Parameter',
      parameterName: 'Parameter Name',
      paramCode: 'Parameter ID',
      nameCn: 'Chinese Name',
      nameEn: 'English Name',
      staticParameters: 'Static Parameter',
      dynamicParameters: 'Interface Request Parameter',
      configStaticParameters: 'Configure Static Parameter',
      showValueCode: 'Display Parameter',
      actualValueCode: 'Actual Parameter',
      defaultValue: 'Default',
      place: {
        isShow: 'Please select whether to display.',
        reqWay: 'Please select the request method.',
        dataType: 'Please select a data type.',
        paramCode: 'Please enter the parameter ID.',
        nameCn: 'Please enter the Chinese name.',
        nameEn: 'Please enter the English name.',
        dataName: 'Please enter the data source Chinese name.',
        dataEnName: 'Please enter the data source English name.',
        reqUrl: 'Please enter the interface URL.',
        pageSize: 'Please enter the number of items to be displayed.',
        cardType: 'Please select a card type.',
        cardStyle: 'Please select a card style.',
        fieldCnName: 'Please enter the Chinese name of the interface.',
        fieldEnName: 'Please enter the English name of the interface.'
      },
      typeOptions: {
        0: 'Column Chart//Curve Chart',
        1: 'Pie Chart',
        2: 'Map',
        3: 'Number',
        4: 'List',
        5: 'Ranking'
      },
      addDataSource: 'Add Data Source',
      pageNumberItem: 'Number of Displayed Items per Page',
      showNumber: 'Number of Items to Be Displayed.',
      pageSize: 'Number of Items per Page',
      editParameters: 'Edit Parameter',
      editDataSource: 'Edit Data Source',
      addGlobalParameters: 'Add Global Parameter',
      editGlobalParameters: 'Edit Global Parameter',
      cardName: 'Card Name',
      cardStyle: 'Card Style',
      cardCnName: 'Chinese Name',
      cardEnName: 'English Name',
      jumpUrl: 'Redirect URL',
      cardType: 'Card Type',
      dataSourceConfig: 'Data Source',
      cardTypeOptions: {
        0: 'Column Chart',
        1: 'Curve Chart',
        2: 'Pie Chart',
        3: 'Map',
        4: 'Number Card',
        5: 'Card Deck',
        6: 'List',
        7: 'Ranking'
      },
      addCard: 'Add Card',
      editCard: 'Edit Card',
      index: 'Index',
      selectDataSource: 'Please select a data source.',
      selectIcon: 'Please select an icon.',
      fieldMapping: 'Interface Field Mapping',
      refreshField: 'Refresh Field',
      fieldName: 'Field Name',
      color: 'Color',
      most4Index: 'Add at most 4 indexes.',
      leftIndicator: 'First from the Left',
      leftSecIndicator: 'Second from the Left',
      mapIndicators: 'Map Index',
      rankingIndex: 'Ranking Index',
      upperIndex: 'Top Index',
      centralIndex: 'Central Index',
      lowerIndex: 'Bottom Index'
    },
    operationRecord: {
      type: 'Type',
      operator: 'Operator',
      content: 'Content',
      time: 'Time'
    },
    roleWebMenuEdit: 'Web Menu Permission',
    roleWxAppMenuEdit: 'Mini Program Menu Permission',
    screen: {
      add: 'Add Data Screen',
      edit: 'Edit Data Screen',
      copy: 'Copy Data Screen',
      copyName: 'Create a copy',
      name: 'Data Screen Name',
      stop: 'STOP',
      unPublished: 'Un Published',
      delTip: 'Are you sure you want to {0} this dashboard?',
      none: 'No dashboard information available, please add new',
      inherit: 'INHERIT',
      self: 'SELF',
      copyOne: '{0} selected for copying',
      toSelf: 'No inheritable dashboards available, please design on your own',
      addWay: 'Add Method',
      inheritPerm: 'Inherit Permissions',
      unInherit: 'Do not enable',
      inheritOrg: 'Enable (by organizational type)',
      inheritAll: 'Enable (all subordinate organizations)',
      place: {
        name: 'Please enter the screen name',
        addWay: 'Please select the creation method',
        inheritPerm: 'Please select the inheritance permission',
        inheritUrl: 'Please select the inherited screen'
      }
    }
  },
  company: {
    companySetting: 'Platform Settings',
    platformSetting: 'Platform Settings',
    platformCustomSetting: 'Customization',
    dataChannelSetting: 'Data Channel',
    paymentSetting: 'WeChat Pay Settings',
    notificationSetting: 'Notifications',
    thirdPartyAccessSetting: 'Third-Party Access',
    securitySetting: 'Security Settings',
    wxSetting: 'Mini Program Settings',
    connectionSucceeded: 'Connected successfully',
    ConnectionTest: 'Connection Test',
    notice: {
      title: 'Notifications',
      emailConfig: 'Email Settings',
      senderNickname: 'Sender Name',
      emailAccount: 'Email Account',
      emailPassword: 'Authorization Code',
      smtpServer: 'SMTP Server',
      smtpPort: 'SMTP Port',
      sceneConfig: 'Options',
      warningNotice: 'Alerts',
      faultNotice: 'Faults',
      fenceNotice: 'Geofence Events',
      validSenderNickname: 'Sender name cannot be empty.',
      validEmailAccount: 'Email account cannot be empty.',
      validSmtpServer: 'SMTP server can not be empty.',
      validSmtpPort: 'SMTP port can not be empty.',
      validWarningNotice: 'Please set whether to send alert notifications via email.',
      validFaultNotice: 'Please set whether to send fault notifications via email.',
      validFenceNotice: 'Please set whether to send Geofence event notifications via email.',
      smsSign: 'SMS Signature',
      appPush: 'Push Notification Settings',
      validAppKeyIos: 'AppKey for iOS cannot be empty.',
      validAppKeyAndroid: 'AppKey for Android cannot be empty.',
      validIosApnsEnv: 'Please select the APNs Environment for iOS.',
      validProductId: 'Please select the applicable product.',
      regionId: 'Server Location',
      validAccessKeyId: 'Please enter AccessKey.',
      validRegionId: 'Please enter the server location.',
      validSecret: 'Please enter the AppSecret.'
    },
    oemConf: {
      enable: 'Enterprise Customization',
      templateSelection: 'Login Page',
      officialWebsite: 'Official Website',
      configureDomain: 'Domain',
      customize: 'Custom',
      recordQuery: 'Verify Domain',
      siteName: 'Website Name',
      loginPageLogo: 'Logo for Login Page',
      tipUploadLogo: 'Click to Upload Logo',
      systemInsideBrightLogo: 'Bright Color Logo',
      systemInsideDarkLogo: 'Dark Color Logo',
      websiteIcon: 'Website Icon',
      tipUploadFavicon: 'Click to upload a website icon.',
      loginBgImage: 'Login Background Image',
      tipUploadBgImage: 'Click to upload a login background image.',
      logo: {
        size: 'Size: 100*100 pixels.',
        format: 'Format: JPG and PNG.'
      },
      ico: {
        size: 'Size: 32*32 pixels.',
        format: 'Format: ICO.'
      },
      uploadBg: {
        size: 'Size: 1920*970 pixels.',
        format: 'Format: JPG and PNG.'
      },
      siteDescription: 'Website Description',
      websiteKeywordsDesc: 'Website Keywords (Multiple keywords should be separated by English commas.)',
      copyrightStatement: 'Copyright',
      caseNumber: 'ICP Number',
      legalStatementAndPrivacyPolicy: 'Legal Statement and Privacy Policy',
      basicConfig: 'Basic Configuration',
      advancedConfig: 'Advanced Configuration',
      enableAdvancedConfig: 'Enable Advanced Configuration',
      siteEnName: 'Website English Name',
      dmpOauthLoginLogo: 'Logo of Authorized Login',
      dmpOauthLoginCn: 'Authorized Login Description in Chinese',
      dmpOauthLoginEn: 'Authorized Login Description in English',
      place: {
        domainType: 'Please select the domain type.'
      }
    },
    pay: {
      wechatPay: 'WeChat Pay',
      businessNumber: 'Merchant ID',
      businessKey: 'Merchant Key',
      CertificateUpload: 'Certificate',
      validAppid: 'App ID cannot be empty.',
      validMchId: 'Merchant ID cannot be empty.',
      validMchKey: 'Merchant Key cannot be empty.',
      validCertificateUrl: 'Please upload a certificate.',
      validTradeType: 'Please select a payment method.',
      validReferer: 'Please enter an authorized domain.',
      tradeType: {
        title: 'Payment Method',
        item1: 'In-App Payment',
        item2: 'Official Account Payment/Mini Program Payment',
        item3: 'H5 Payment'
      },
      referer: 'Authorized Domain'
    },
    safe: {
      title: 'Security Settings',
      loginCaptcha: 'Login CAPTCHA',
      loginLock: 'Account Lockout',
      ruleSet: 'Lockout Policy',
      ruleSetText1: 'minute(s), after',
      ruleSetText2: 'invalid login attempts, your account will be locked for',
      ruleSetText3: 'minute(s)',
      authCodeLogin: 'Login via SMS Verification Code',
      resetPwd: 'Forgot Password',
      dmpOauthLogin: 'Login via  Developer Center Account',
      validLoginCaptcha: 'Please select whether to enable login CAPTCHA.',
      validLoginLock: 'Please select whether to enable account lockout.',
      validTimeRange: 'Please enter a time range.',
      validFailNum: 'Please specify the number of invalid log-in attempts.',
      validLockTime: 'Please enter the lockout duration.',
      validAuthCodeLogin: 'Please select whether to enable login via SMS verification code.',
      validResetPwd: 'Please select whether to enable password reset.',
      validDmpOauthLogin: 'Please select whether to enable login via  Developer Center account.',
      loginQuestion: 'Login Issues',
      privacy: 'Privacy Policy',
      helpDoc: 'Documentation',
      validLoginQuestion: 'Please select whether to enable login issues.',
      validPrivacy: 'Please select whether to enable the privacy policy.',
      validHelpDoc: 'Please select whether to enable documentation.'
    },
    third: {
      title: 'Third-Party Access',
      appKey: 'Private Key',
      validAppId: 'App ID cannot be empty.',
      validAppKey: 'Private Key cannot be empty.',
      validAppToken: 'Token cannot be empty.',
      validAesKey: 'AES Key cannot be empty.'
    },
    sim: {
      title: ' Global Connectivity Authorization',
      validKey: 'AppKey cannot be empty.',
      validSecret: 'AppSecret cannot be empty.'
    },
    wx: {
      appName: 'Applet Name',
      logo: 'Applet Logo',
      limit: 'PDF format is supported. No more than 1 MB.',
      tabTips: 'JPG and PNG formats are supported. Suggested size 80X80, no more than 1 MB',
      logoFormat: 'JPG, JPEG, PNG and GIF formats are supported.',
      tabTitle: 'Bottom Navigation Bar',
      tabName: 'Navigation Bar Name',
      customName: 'Custom Name',
      menuName: 'Menu Name',
      tabText: 'Navigation Bar Text',
      workbench: 'Workbench',
      statistics: 'Statistics Page',
      view: 'About Page',
      bgc: 'Background',
      company: 'Company Name',
      copyRight: 'Copyright Marking',
      customConf: 'Custom Configuration',
      valLength: 'Must be a combination of English or numeric characters with a length of {0}-{1}',
      place: {
        appName: 'Please enter the Applet Name',
        logo: 'Please enter the Applet Logo'
      }
    }
  },
  gdpr: {
    title: 'Quectel Privacy Policy/Quectel Service Agreement',
    time1: 'Update Date: June 30, 2022',
    time2: 'Effective Date: June 30, 2022',
    item1: 'Quectel Privacy Policy',
    item2: 'Quectel Service Agreement',
    text: 'I have read and agree to Quectel Privacy Policy and Quectel Service Agreement.',
    agree: 'Agree',
    refuse: 'Disagree',
    validGdpr: 'Please check "I have read and agree to Quectel Privacy Policy and Quectel Service Agreement" first.',
    success: 'You have read and agreed to Quectel Privacy Policy and Quectel Service Agreement.',
    logout: 'By disagree to the agreements, you will log out from the system. Are you sure you want to continue?'
  },
  tagsView: {
    refresh: 'Refresh',
    closeCurrent: 'Close',
    closeOthers: 'Close Other Tabs',
    closeRight: 'Close Tabs to the Right',
    closeAll: 'Close All Tabs'
  },
  dict: {
    dictName: 'Dictionary Name',
    dictType: 'Dictionary Type',
    status: 'Dictionary Status',
    place: {
      dictName: 'Please enter the dictionary name.',
      dictType: 'Please enter the dictionary type.',
      dictLabel: 'Please enter a dictionary label.',
      dataLabel: 'Please enter a data label.',
      dataValue: 'Please enter the data key value.'
    },
    valid: {
      dictName: 'Dictionary name cannot be empty.',
      dictType: 'Dictionary type cannot be empty.',
      dataLabel: 'Data label cannot be empty.',
      dataValue: 'Data key value cannot be empty.',
      dataSort: 'Data sequence cannot be empty.'
    },
    clearCache: 'Clear Cache',
    dictId: 'Dictionary Number',
    addTitle: 'Add Dictionary Type',
    editTitle: 'Modify Dictionary Type',
    delTips: 'Are you sure you want to delete this data item?',
    exportTips: 'Are you sure you want to export all types of data items?',
    clearCacheSuccess: 'Cleared successfully',
    dictLabel: 'Dictionary Label',
    dataLabel: 'Data Label',
    dataStatus: 'Data Status',
    dictCode: 'Dictionary Code',
    dictValue: 'Dictionary Key',
    dataValue: 'Data Key',
    dictSort: 'Dictionary Sort',
    dataSort: 'Data Sort',
    addDictData: 'Add Dictionary Data',
    editDictData: 'Modify Dictionary Data'
  },
  config: {
    place: {
      configName: 'Please enter the parameter name.',
      configKey: 'Please enter the parameter key name.',
      configValue: 'Please enter the parameter key value.'
    },
    valid: {
      configName: 'The parameter name cannot be empty.',
      configKey: 'The parameter key name cannot be empty.',
      configValue: 'The parameter key value cannot be empty.'
    },
    configName: 'Parameter Name',
    configKey: 'Key Name',
    configType: 'System Internal',
    configId: 'Primary Key',
    configValue: 'Key Value',
    addConfig: 'Add Parameter',
    editConfig: 'Edit Parameter'
  },
  companyList: {
    userName: 'Account',
    linkPhoneNumber: 'Phone Number',
    viewProduct: 'View Product',
    screen: 'Dataview',
    role: 'Permission Configuration',
    vendorRole: 'Manufacturer Permission',
    agencyRole: 'Agent Permission',
    proPage: 'Product Personality Page',
    editRole: 'Edit Permission',
    initRole: 'Initialize Role Management',
    unit: 'Unit',
    storageDuration: 'Storage Cycle',
    storageDurationText: 'Storage Cycle of MO Data',
    mongo: 'Service Data Storage',
    mongoStorage: 'Service Data Storage Space',
    oss: 'Data Acquisition',
    ossStorage: 'Original File Storage Space',
    uploadScript: 'Upload Script',
    raw: 'Transparent Transmission',
    ttlv: ' TSL Model',
    dcmp: {
      service: 'Data Storage Service',
      increase: 'Added Yesterday',
      current: 'Used'
    },
    rules: {
      storageDuration: 'Please enter the storage cycle of MO data.',
      mongoStorage: 'Please enter the storage space of business data.',
      ossStorage: 'Please enter the storage space of original file.',
      scriptPath: 'Please upload the script file.',
      dataType: 'Please select a data type.',
      unit: 'Please select the unit.',
      initRole: 'Please add an initialization role label.',
      scriptVersion: 'Please add the script.'
    }
  },
  oauth: {
    title: 'Enter User Information',
    desc: 'At the first time you use the system, you need to complete the following information to log in to the system, receive notifications, and retrieve your password.',
    userName: 'Username',
    password: 'Password',
    phonenumber: 'Linked Phone Number',
    email: 'Linked Email Address',
    submit: 'Submit and Log in'
  },
  menu: {
    menuName: 'Chinese Name',
    menuNameEn: 'English Name',
    status: 'Status',
    configBase: 'Basic Settings',
    configSystem: 'System Settings',
    tips: {
      visible: 'Used to set the visible of current menu in the left navbar.',
      menuCode: 'Used to control menu hierarchy.',
      otherName: 'Used to set default display state and DCMP logic.',
      path: 'Used to set the target URL.'
    },
    place: {
      menuName: 'Please enter the Chinese name.',
      menuNameEn: 'Please enter the English name.',
      status: 'Status',
      parentId: 'Please select a parent menu.',
      icon: 'Click to Select Icon',
      otherName: 'Please enter the menu nickname.',
      menuCode: 'Please enter a menu code.',
      path: 'e.g., detail or order-detail, using full URL if external.',
      routeName: 'e.g., CompanyRoleDetail',
      component: 'e.g., product/custom/index',
      perms: 'e.g., system:agent:list',
      redirect: 'e.g., /company/role'
    },
    orderNum: 'Order',
    menuCode: 'Menu Code',
    perms: 'Permission Identifier',
    component: 'Component Path',
    parentId: 'Parent Menu',
    menuType: {
      title: 'Menu Type',
      item1: 'Directory',
      item2: 'Menu',
      item3: 'Button'
    },
    icon: 'Menu Icon',
    otherName: 'Menu Nickname',
    isFrame: 'External URL',
    path: 'Route Address',
    routeName: 'Route Name',
    visible: 'Display Status',
    isCache: {
      title: 'Cache',
      item1: 'Cache',
      item2: 'Not Cache'
    },
    redirect: 'Route Redirect'
  },
  rightToolbar: {
    hideSearch: 'Hide Search',
    showSearch: 'Display Search',
    refresh: 'Refresh',
    showColumn: 'Display Hidden Columns',
    show: 'Display',
    hide: 'Hide',
    showOrHide: 'Display/Hide'
  },
  dataFormatOptions: {
    item1: 'Transparent Transmission/Custom',
    item2: 'JSON (Standard Data Format)'
  },
  group: {
    nogroup: 'No statistics about other device properties have been created,',
    create: 'Create Now',
    remarker: 'Location information is marked.',
    name: 'Name',
    address: 'Detailed Address',
    mountAddress: 'Location',
    editGroupBtn: 'Edit Installation Site',
    delGroupBtn: 'Delete',
    exactMark: 'Precision Marking',
    contactPerson: 'Contact Person',
    upPerson: 'Superior Contact',
    enterName: 'Please enter the installation site name.',
    enterNameLengthLimit: 'The length cannot exceed 256 characters.',
    enterAdress: 'Please enter the installation address.',
    enterAdressLengthLimi: 'The length cannot exceed 1024 characters.',
    bind: 'Add Device',
    unbind: 'Unbind Device',
    deviceKey: 'DeviceKey',
    deviceName: 'Device Name',
    productName: 'Product Name',
    send: 'Send',
    product: 'Control Product',
    unbindTips: 'Are you sure you want to unbind the device?',
    picture: 'Live Image',
    warnDeviceALL: 'Device with Alerts/Device Total',
    place: {
      mount: 'Select installation site.',
      name: 'Please enter the installation site name.',
      address: 'Please enter the installation address.',
      contactPerson: 'Please select the contact person.',
      detailAddress: 'Please enter the detailed address.',
      filter: 'Enter keywords to filter.',
      belongGroup: 'Please select the group to which it belongs.'
    },
    deleteGroupMountTips: 'Are you sure you want to delete？',
    belongGroup: 'Installation Site',
    addGroup: 'Add',
    editGroup: 'Edit',
    selectDevTips: 'Please select the corresponding device.',
    uploadList: 'Upload Device List',
    sort: 'Order',
    toSorted: ' To be sorted',
    showContain: 'Display subordinate data',
    configuration: 'Installation Field Settings',
    batch: {
      action_switch: 'Action Switch',
      cloud: 'Cloud Timing',
      local: 'Local Timing',
      cycle: 'Cycle Timing',
      count: 'Countdown Timing',
      del: {
        title: 'Delete Timing',
        cloud: 'Delete All Cloud Timing Tasks',
        local: 'Delete All Local Timing Tasks',
        cycle: 'Delete All Cycle Timing Tasks',
        count: 'Delete All Countdown Timing Tasks'
      },
      batchTitle: 'Batch {0}',
      setTime: 'Set Timing',
      addSetTime: 'Add Timing',
      noSetTime: 'No timing tasks currently set, command cannot be issued, please add timing tasks first',
      maxSize: 'Maximum of {0} timing tasks can be added',
      switch_mode: 'Set Switch Mode',
      power_alert: 'Set Power-off Alarm Enable',
      task: 'Task {0}',
      switch: 'Switch',
      noSwitch: 'No action is set for any switch, command issuance failed, please set the switches to be operated.',
      mode: 'Switch Mode',
      action: 'Action',
      powerStatus: 'Power-On Mode',
      delayTime: 'Momentary Time (seconds)',
      cycleTip: 'Cycle Rule (Alternating)',
      startTime: 'Enable Time',
      cloudTime: 'Countdown Time',
      status: 'Task Status',
      repeat: 'Repeat',
      ph: {
        switch: 'Please select a switch',
        mode: 'Please select switch mode',
        action: 'Please select a action',
        powerStatus: 'Please select power-on mode',
        delayTime: 'Enter {0}~{1} seconds',
        date: 'Select date',
        time: 'Select time',
        select: 'Please select {0}',
        startTime: 'Please select enable time',
        startTxt: 'On Duration',
        endTxt: 'Off Duration',
        repeat: 'Not checking means it only takes effect once',
        noSelectSwitch: 'For Task {0}, no switch is selected, command issuance failed, please select the switches to be operated.',
        noSwitch: 'For Task {0}, no action is set for any switch, command issuance failed, please set the switches to be operated.'
      },
      timingTip: 'After batch action, all devices with the original "{0}" will be overwritten, please operate with caution.',
      noAction: 'No Action',
      weeks: {
        mon: 'Mon.',
        tues: 'Tue.',
        wed: 'Wed.',
        thur: 'Thur.',
        fri: 'Fri.',
        sat: 'Sat.',
        sun: 'Sun.'
      }
    }
  },
  areaManage: {
    zoneName: 'Area Name',
    add: 'Add Area',
    zoneList: 'Area List',
    overview: 'Overview',
    hostList: 'Host List',
    baseInfo: 'Base Info',
    callRule: 'Call Rule',
    receiver: 'Emergency Contact',
    forwardSwitch: 'Call Forwarding',
    callDuration: 'Call Duration',
    pollDuration: 'Poll Duration',
    pollNum: 'Polls',
    times: 'times',
    forwardZoneId: 'Strategy',
    place: {
      zoneName: "Please enter the zone's name.",
      address: 'Please click the location button to select your region.',
      detailAddress: 'Please enter the full address.',
      forwardZoneId: 'Please select a transfer area.'
    },
    receiverTip: 'When all contacts cannot be contacted through the host APP, the platform will notify the following emergency contacts by voice or SMS.'
  },
  person: {
    add: 'Add a Contact Person',
    name: 'Name',
    mobile: 'Phone Number',
    smsNotice: 'SMS Notification',
    voiceNotice: 'Voice Notification',
    enterName: "Please enter the contact person's name.",
    enterNameLengthLimit: 'The length cannot exceed 50 characters.',
    enterMobile: "Please enter the contact person's phone number.",
    enterAdressLengthLimi: 'Please enter a valid phone number.',
    deleteTips: 'Once deleted, the contact person is not reachable to the associated device installation site. Are you sure you want to delete the contact person?'
  },
  scooter: {
    operate: {
      storeName: 'Store Name',
      addStore: 'Add a Store',
      area: 'Region',
      address: 'Address',
      businessTime: 'Business Hours',
      contactWay: 'Contact Way',
      storeImg: 'Photos',
      locationView: 'View Location',
      storeLocation: 'Geo-Location',
      agent: 'Agent',
      shopOwner: 'Storekeeper',
      telephone: 'Phone Number',
      areaSelect: 'Region',
      countryOrRegion: 'Country or Region',
      specificLocation: 'Detailed Address',
      uploadTips: 'An image of 600*600 pixels is recommended. The image format can only be JPG/JPEG/PNG, and the size cannot exceed 200 KB.',
      remark: 'Remark',
      notFoundAdd: 'The address is not found on the map.',
      selectStoreTips: 'Please select the store address from the dropdown list of detailed addresses.',
      editSuccess: 'Edited successfully',
      uploadImgTips: 'Wrong file format. The image can only be JPG/JPEG/PNG.',
      uploadImgSizeTips: 'File size limit exceeded. The size cannot exceed 200 KB.',
      stolenAdd: 'Theft Location',
      place: {
        enterStoreName: 'Please enter the store name.',
        enterShopOwner: "Please enter the storekeeper's name.",
        enterSpecificLocation: 'Please enter the detailed address.',
        enterRemark: 'Please enter the remark.',
        enterDevType: 'Please enter the vehicle model.',
        startTime: 'Please select the start time.',
        endTime: 'Please select the end time.',
        selectTheft: 'Please select the theft/lost device type.',
        selectDevManual: 'Please select a vehicle manual.'
      },
      vin: 'Vehicle Identification Number',
      devType: 'Vehicle Model',
      user: 'End User',
      repairStore: 'Store Name',
      questionImg: 'Pictures',
      reportTime: 'Request Time',
      addRepair: 'Add a Maintenance Request ',
      tenantName: 'Manufacturer',
      loseType: {
        title: 'Theft/Lost Device Type',
        item1: 'Vehicle',
        item2: 'Battery'
      },
      reporterName: 'Reported By',
      loseRemark: 'Additional Information',
      loseTime: 'Theft Time',
      loseAddress: 'Theft Location',
      realTimeTrack: 'Real-time Tracking',
      addLose: 'Report a New Theft/Lost',
      addManual: 'Add a Manual',
      carManual: 'Vehicle Manual',
      down: 'Download',
      uploadFormatTips: 'Wrong file format. Only PDF file is supported.',
      uploadSizeTips: 'File size limit exceeded. The size cannot exceed',
      uploadedTips: 'Please remove the existing file before uploading a new file.'
    }
  },
  upload: {
    tagsTitle: 'My Label',
    queryParams: {
      fileType: 'Please select a file type.',
      fileName: 'Please enter the file name.',
      fileTag: 'Please select a label.',
      uploadBtn: 'Upload',
      file: 'Please select a file.',
      enter: 'Please enter a label name and then click "Add".'
    },
    table: {
      typeObj: {
        1: 'Image',
        2: 'Document',
        3: 'Audio File',
        4: 'Video File',
        5: 'Compressed Files',
        6: 'Other'
      },
      fileType: 'File Type',
      fileTag: 'Label',
      view: 'Preview',
      fileName: 'File Name',
      fileSize: 'Size',
      mimeType: 'MIME Type',
      select: 'Select',
      dest: 'File Storage Location'
    },
    preview: 'Image Preview',
    addFiles: 'Add a File',
    fileSelection: 'Select a File',
    validNull: 'Please do not upload an empty file.'
  },
  task: {
    running: 'Running Task',
    queryParams: {
      taskType: 'Please select the task type.',
      batchTaskStatus: 'Please select the batch task status.'
    },
    table: {
      batchTaskNo: 'Batch ID',
      taskType: 'Task Type',
      totalNum: 'Total Tasks',
      successNum: 'Successful Tasks',
      failNum: 'Failed Tasks',
      tsFinishTime: 'Completion Time',
      batchTaskStatus: 'Task Status',
      timeUsed: 'Duration',
      batchTaskProgress: 'Progress',
      taskTypeOptions: {
        1: 'Device Import',
        2: 'Device Distribution'
      },
      batchTaskStatusOptions: {
        1: 'Preparing',
        2: 'Waiting',
        3: 'Processing',
        4: 'Processed'
      }
    },
    taskDetails: {
      table: {
        taskTag: 'DeviceKey',
        taskStatus: 'Import Status',
        errorMsg: 'Failure Reason',
        dataPayload: 'Payload Data',
        taskStatusOptions: {
          0: 'All',
          1: 'Waiting to Import',
          2: 'Importing',
          3: 'Successful',
          4: 'Failed'
        }
      }
    }
  },
  validation: {
    title: 'Please complete the security verification.',
    toRight: 'Swipe right to complete verification',
    successful: 'Verified successfully.',
    failure: 'Verification failed.'
  },
  appmanage: {
    versionType: 'Type',
    appVersion: 'App Version',
    plappVersion: 'App version cannot be empty.',
    downloadAddress: 'Download URL',
    pldownloadAddress: 'The download URL cannot be empty.',
    versionDetail: 'Version Details',
    plversionDetail: 'Please enter version details.',
    publishState: 'Status',
    verify: 'Verification Account ',
    verifyManage: 'Verification Account Management',
    rulapp: 'App version number is started with V and only supports numbers and the character ".". The format is xx.xx.xx.',
    state0: 'To be Verified',
    state1: 'Released',
    state2: 'Unshelve',
    delete01: 'Are you sure you want to delete this version?',
    publish01: 'Are you sure you want to release this version?',
    deletever: 'Are you sure you want to delete this verification account?',
    nofile: 'No files are selected.'
  },
  notFound: {
    error401: '401 Error',
    error401p1: 'Sorry, you do not have permission to access this page.',
    error401p2: 'The attempted operation is invalid.',
    error401p3: 'You can go back to the homepage to have a try.',
    backHome: 'Homepage',
    error404: '404 Not Found Error',
    error404p1: 'Sorry, the page you are looking for does not exist.',
    error404p2: 'Try the following:',
    error404p3: 'Check network connnection',
    error404p4: 'Check the proxy server and firewall'
  },
  faultWarning: {
    fault: 'Fault Log',
    warn: 'Alert Log',
    type: 'Type',
    name: 'Name',
    relieve: 'Resolve in Batches',
    remark: 'Add Remarks in Batches',
    viewTip1: 'The device has been distributed and cannot be operated.',
    viewTip2: 'The device has been recycled and cannot be operated.',
    deviceName: 'Device Name',
    faultAlert: 'Are you sure you want to resolve the fault?',
    warningAlert: 'Are you sure you want to resolve the alert?',
    batchAlert: 'Are you sure you want to resolve {0} in batches?',
    typeAlerts: 'alerts',
    typeFaults: 'faults',
    handleTime: 'Handle Time',
    handler: 'Handler',
    method: 'Operation Method',
    deviceStatus: 'Device Status',
    outputParams: 'Output Params',
    otherParams: 'Other Params',
    statusRecord: 'Recorded Operations',
    noticeRecord: 'Notification Record',
    success: 'success',
    failure: 'failure',
    commit: 'Sending',
    searchKey: 'Alarm Number/Recipient/Event Name',
    ntcRecordList: {
      eventTime: 'Event time',
      noticeType: 'Notice Type',
      eventType: 'Event Type',
      noticeTime: 'Notification Time',
      noticeNumber: 'Notification number',
      callNumber: 'Call number',
      policeReceiver: 'Police receiver',
      noticeStatus: 'Notification Status',
      alarmName: 'Alarm name',
      faultName: 'Fault name'
    },
    faultDetail: {
      name: 'Fault Name',
      happenTime: 'Fault Time'
    },
    alertDetail: {
      name: 'Alert Name',
      happenTime: 'Alert Time'
    }
  },
  operationManagement: {
    place: {
      date: 'Please select a date.',
      type: 'Select ticket type.',
      desc: 'Enter ticket description.',
      address: 'Please enter an address.',
      status: 'Please select a ticket status.',
      orderNum: 'Please enter a Ticket ID.',
      handlerName: 'Please select a maintenance personnel.',
      name: 'Please enter a ticket name.',
      deviceKey: 'Please select a device or enter a DeviceKey.',
      deviceName: 'Please enter a DeviceKey.',
      hanldeName: 'Please enter a contact person.',
      feedbackDesc: 'Please enter your feedback.',
      appointTime: 'Please select a maintenance date.',
      uploadImg: 'Please Upload Image'
    },
    orderNum: 'Ticket ID',
    type: 'Ticket Type',
    status: 'Ticket Status',
    name: 'Ticket Name',
    desc: 'Description',
    submitTime: 'Submission Time',
    finished: 'Resolve Ticket',
    sendOrder: 'Assign Ticket',
    back: 'Reassign Ticket',
    force: 'Force Close',
    detail: {
      productName: 'Product',
      company: 'Enterprise'
    },
    appointTime: 'Maintenance Date',
    overdue: 'Maintenance date expired. Please reselect.',
    operator: 'Operator',
    feedback: 'Feedback',
    handlerName: 'Maintenance Personnel',
    uploadImg: 'Upload Image',
    addBtn: 'Request',
    addTitle: 'Ticket Request',
    repaieList: 'Device Maintenance List',
    manageList: 'Ticket Management List',
    myOrderList: 'My Ticket List',
    turnOrder: 'Create Ticket',
    info: 'Ticket Information',
    turn: 'Ticket Flow',
    imageLimit: 'Recommended file size: no more than {0} MB.',
    imageFileLimit: 'Recommended file size: no more than {0} MB.',
    supportTypes: 'JPG, JPEG, PNG and GIF formats are supported.',
    turnOrderStatus: 'Status',
    notTurned: 'Uncreated',
    hasPassed: 'Created',
    location: 'Location'
  },
  commonScreen: {
    save: 'Save',
    publish: 'Release',
    back: 'Back',
    edit: 'Edit',
    control: 'Console',
    titleCnName: 'Chinese Title',
    titleEnName: 'English Title',
    logo: 'Logo',
    product: 'Product',
    timeRange: 'Statistical Day',
    dataSource: 'Data Source',
    value: 'Value',
    unit: 'Unit',
    titleSetting: 'Screen Title Configuration',
    cardSetting: 'Card Configuration',
    dataSourceSetting: 'Data Source Configuration',
    addIndexs: 'Add Index',
    dataType: 'Data Type',
    index: 'Index',
    topIndex: 'Top Index',
    centerIndex: 'Central Index',
    bottomIndex: 'Bottom Index',
    tips: {
      save: 'Draft saved successfully',
      publish: 'Released successfully'
    },
    place: {
      titleCnName: 'Please enter the Chinese title.',
      titleEnName: 'Please enter the English title.',
      product: 'Please select a product.',
      timeRange: 'Please enter the statistical day.',
      value: 'Please enter a value.',
      style: 'Please select a card style.',
      dataType: 'Please select a data type.'
    },
    staticData: 'Static Data'
  },
  faultWarnManage: {
    keyword: 'Contact Person, Phone Number, Email Address | Contact Person, Email Address',
    delTips: 'Are you sure you want to delete this contact?',
    contact: {
      name: 'Contact Name',
      email: 'Email Address',
      phone: 'Phone Number',
      updateTime: 'Update Time',
      permission: 'Fault and Alert Access Level',
      addTips: 'Enter at least either phone number or email address. | Enter email address at least.',
      place: {
        receiver: 'Please select the contact. ',
        orgId: 'Please select the enterprise.',
        name: 'Please enter the name of contact person.',
        email: 'Please enter the email address of contact person.',
        phone: 'Please enter the phone number of contact person.',
        permission: 'Please select the access level for faults and alerts.'
      }
    },
    config: {
      name: 'Configuration Name',
      wayMode: 'Notification Method',
      device: 'Device',
      receiver: 'Contact',
      place: {
        wayMode: 'Please select a notification method.',
        name: 'Please enter the configuration name.',
        device: 'Corresponding Device',
        receiver: 'Please select the contact.'
      }
    }
  },
  orgTypeMange: {
    orgType: 'Enterprise Type',
    orgTypeSet: 'Enterprise Type Configuration',
    orgTypeName: 'Enterprise Type Name',
    currentApplicationIndustryScenarios: 'Current Application Industry Scenarios',
    isPlat: 'Whether it is a platform or not',
    isManufacturers: 'Whether it is a manufacturer or not',
    place: {
      orgType: 'Please enter the enterprise type.',
      orgTypeName: 'Please enter the enterprise type name.',
      isPlat: 'Please select whether it is a platform or not.',
      isManufacturers: 'Please select whether it is a manufacturer or not.'
    }
  },
  organization: {
    direct: 'Direct parent enterprise',
    orgType: 'Enterprise Type',
    status: 'Enterprise Status',
    orgName: 'Enterprise',
    recycling: 'Recycling Enterprise',
    beRecycleOrg: 'Enterprise of Recycled Device',
    belongOrg: 'Enterprise',
    remark: 'Description',
    userRoles: 'User Role',
    nickName: 'Contact Person',
    address: 'Enterprise Address',
    enableState: 'Enabling status',
    createTime: 'Creation Time',
    industryScenarios: 'Industry Scenarios',
    roleName: 'Role Name',
    roleSort: 'Role Sort',
    roleStatus: 'Role Status',
    userName: 'System Account',
    userNickName: 'Nickname',
    roleNames: 'Role',
    userStatus: 'User Status',
    showContain: 'Display subordinate enterprise data',
    showContainInfo: 'When this option is checked, the device list will display subordinate enterprise device data.',
    showPowerContainInfo: 'When this option is checked, the device list will display subordinate enterprise station data.',
    showRecordContainInfo: 'When this option is checked, the device list will display subordinate enterprise record data.',
    operate: {
      add: 'Create',
      toUser: 'Manage User',
      toRole: 'Manage Role',
      moreOperation: 'More Actions',
      handleBatchDisable: 'Disable in Batches',
      handleBatchEnable: 'Enable in Batches',
      handlePermissionType: 'Edit Permission Type',
      handlePermission: 'Enterprise Web Menu',
      handlePermissionWechat: 'Enterprise Applet Menu'
    },
    permission: {
      webPermission: 'Web Menu Permission',
      miniPermission: 'Mini Program Menu Permission'
    },
    place: {
      nickName: 'Please select a contact person.',
      orgType: 'Please select an enterprise type.',
      orgStatus: 'Please select the enterprise status',
      operationStatus: 'Please select the operation status.',
      org: 'Please select an enterprise.',
      orgBelong: 'Please select an enterprise.',
      orgName: 'Please enter an enterprise.',
      roleOrgan: 'Please select an enterprise.',
      roleStatus: 'Please select a role status.',
      userStatus: 'Please select a user status.',
      roleName: 'Please enter a role name.',
      address: 'Please enter an enterprise address.',
      remark: 'Please enter a description.',
      roles: 'Please select a role.'
    },
    confirm: {
      deleteOrg: 'Are you sure you want to delete this enterprise?',
      deleteRole: 'Are you sure you want to delete this role?',
      deleteUser: 'Are you sure you want to delete this user?',
      batchDisable: 'Are you sure you want to disable in batches?',
      batchEnable: 'Are you sure you want to enable in batches?',
      roleEnable: 'Are you sure you want to enable {0}?',
      roleDisable: 'Are you sure you want to disable {0}?',
      userEnable: 'Are you sure you want to enable this user?',
      userDisable: 'Are you sure you want to disable this user?'
    },
    actionRole: 'Are you sure you want to {0} the {1}?',
    orgEndDevice: 'Equipment'
  },
  configuration: {
    cgsName: 'Configuration Name',
    cgsType: 'Configuration Type',
    cgsDesc: 'Configuration Description',
    cgsDesign: 'Configuration Design',
    place: {
      cgsName: 'Please input the configuration name.',
      cgsType: 'Please select a configuration type.',
      cgsDesc: 'Please enter the description.'
    },
    confirm: {
      delete: 'Are you sure to delete the configuration?'
    }
  },
  idstScenarios: {
    industryScenarios: 'Industry Scenario Name',
    code: 'Code',
    permissionType: 'Enterprise Permission Type',
    scenariosSet: 'Scenario Configuration',
    orgTypeName: 'Enterprise Type Name',
    orgTypeSet: 'Enterprise Type Configuration',
    menuManage: 'Menu Management',
    roleName: 'Role Name',
    roleTag: 'Role Label',
    roleStatus: 'Role Status',
    domain: 'Domain Name',
    idstStatus: 'Industry Scenario Description',
    upOrg: 'Upper-Level Enterprise',
    downOrg: 'Subordinate Enterprise',
    roleManage: 'Role Management',
    upControlAllDown: 'Upper-level enterprise controls all subordinate enterprises',
    upControlNextDown: 'Upper-level enterprise controls direct subordinate enterprises',
    upNotControlDown: 'Upper-level enterprise does not control any subordinate enterprises',
    defaultMenu: 'System Default Menu List',
    idstScenariosMenu: 'Scenario Menu',
    multiUse: 'Reference in Batches',
    multiDel: 'Delete in Batches',
    tips: {
      idstTips: 'ps: SaaS cannot be deleted in scenarios where manufactures have settled in.'
    },
    place: {
      industryScenarios: 'Please enter the name of the industry scenario.',
      code: 'Please enter the code.',
      clientId: 'Please enter client_id.',
      lientSecret: 'Please enter client_secret.',
      roleName: 'Please enter a role name.',
      roleTag: 'Please enter a role label.',
      roleStatus: 'Please select the role status.',
      idstStatus: 'Please enter a description of the industry scenario.',
      domain: 'Please enter a domain name.',
      orgTypeName: 'Please select the enterprise type name.',
      upOrg: 'Please select an upper-level enterprise.',
      downOrg: 'Please select a subordinate enterprise.'
    }
  },
  projectManage: {
    customReport: {
      add: 'Add Report Plan',
      edit: 'Edit Report Plan',
      reportName: 'Report Name',
      reportTime: 'Report Generation Time',
      category: 'Statistical Dimension',
      categoryContent: 'Statistical Information',
      reportRule: 'Report Rules',
      reportPlan: 'Report Plan',
      reportPlanAdd: 'Add Plan',
      reportPlanSet: 'Configure Report Plan',
      statisticalContentDialog: 'Statistical Content',
      selected: 'Already Selected',
      statisticalCircle: 'Statistical Period',
      reportType: 'Report Types',
      statisticalInfo: 'Report Information',
      statisticalContent: 'Report Content',
      is_enabled: 'Enable',
      download: 'File Download',
      circleForSta: 'Periodic Cyclic Statistics',
      custtimeSta: 'Custom Time Based Statistics',
      mount: 'Installation Area',
      productDevice: 'Devices of the Product',
      productProp: 'Product Property',
      dataSource: 'Data Source',
      dataSourceName: 'Data Source Name',
      statisticalMode: 'Statistical Method',
      isChildOrg: 'View Subordinates',
      isStaChild: 'Statistics for Subordinate Enterprise Devices',
      chartReport: 'Graphic Report',
      dataReport: 'Data Report',
      downloadDataReport: 'Download Data Report',
      downloadChartReport: 'Download Graphic Report',
      multiDownloadDataReport: 'Download Data Reports in Batches',
      multiDownloadChartReport: 'Download Graphic Reports in Batches',
      tableZip: 'Data Report Package',
      chartZip: 'Graphic Report Package',
      statisticalOpts: {
        alarmCount: 'Count Of Alarm',
        errorCount: 'Count Of Fault',
        completedOrder: 'Order Count(Completed)',
        backlog: 'Order Count(Unfinished)',
        newOrder: 'Order Count(Add)',
        orderCount: 'Order Count',
        activated: 'Count Of Activated'
      },
      place: {
        reportName: 'Please enter a report name.',
        productProps: 'Please select properties.',
        period: 'Please select a statistical period',
        reportType: 'Please select at least one report type.',
        saveDuration: 'Please select a retention period.'
      },
      tips: {
        duration: 'After report generating, export as soon as possible, or it will be cleared after setting days',
        statisticalContentFormBefore: 'Graphical reports are supported only for the total number of alerts, faults, and tickets.',
        device: 'If the device is empty, all devices of the current product are selected by default.',
        statisticalMode: 'Please fill in the statistical method.',
        add: 'You can add up to 20 plans.',
        action: 'The plan is enabled and cannot be operated.',
        pdf: 'After you select Generate PDF, the PDF report contains graphical charts and raw data, and only supports a maximum of 10 attribute statistics, and the graphical chart only supports the display of numeric attributes.',
        circle: 'Periodic cyclic statistics: Select the statistical cycle, and corresponding reports will be generated the next day after the end of each cycle.',
        custom: 'Custom time based statistics: Select the statistical period, and if the configured end time is earlier than the current time, the report will be generated the next day; if the configured end time is later than the current time, the report will be generated the day after the end time. The earliest selectable time period is five years prior to the current date, and the latest is one year after the current date. The maximum selectable time span is three years.',
        isEnabled: 'A maximum of five plans can be started',
        period: 'A maximum of 1w values can be downloaded for each report. If more values are not displayed, select a reasonable statistical period',
        notEnable: 'The schedule is invalid and cannot be turned on again',
        notEdit: 'The schedule is invalid and cannot be edited again'
      },
      aggregatedData: 'Aggregated Data',
      duration: 'Retention Time',
      statisticalType: 'Statistical Type',
      saveDuration: 'Remaining Duration (Days)'
    },
    snapshot: {
      add: 'Add Snapshot Plan',
      edit: 'Edit Snapshot Plan',
      name: 'Snapshot Plan',
      planName: 'Plan Name',
      interval: 'Snapshot Interval',
      record: 'Snapshot Record',
      tip: 'A maximum of three snapshots can be created for each organization',
      exportsTips: 'Export the report as soon as possible after it is generated. Otherwise, the report content will be periodically cleared {0} later',
      toParamRecord: 'Param Record'
    },
    paramRecord: {
      title: 'Record Of Param',
      recent3days: 'Recent 3 Days',
      lastWeek: 'Last Week',
      lastMonth: 'Last Month',
      hideAll: 'Hide All',
      showAll: 'Show All'
    }
  },
  rules: {
    props: {
      ruleName: 'Rule Name',
      ruleDesc: 'Rule Description',
      triggerMode: 'Trigger Mode',
      triggerDevices: 'Trigger Device',
      actuatorDevices: 'Executive Device',
      enableState: 'Enabled State',
      triggerTime: 'Trigger Time',
      triggerLogical: 'Trigger Logic',
      actRate: 'Execution Frequency',
      actRatePeriod: 'Time Interval',
      timerOnce: 'Once Only',
      timerRepeat: 'Repeat',
      trigger: {
        name: 'Flip-flop',
        type: 'Flip-flop Type',
        compare: 'Operator',
        event: 'Incident',
        count: 'Reporting Times',
        onlineStatus: 'In/offline State',
        duration: 'Duration'
      },
      actuator: {
        name: 'Actuator',
        type: 'Actuator Type',
        isDelay: 'Delay Or Not',
        delayTime: 'Delayed Execution Time',
        sms: 'Sms Push',
        voice: 'Voice Push',
        email: 'Email Push',
        reverseFlag: 'Out-of-time Trigger'
      },
      prop: 'Stats'
    },
    log: {
      record: 'Linkage Record',
      detail: 'Rule Details',
      time: 'Linkage Time',
      result: 'Execution Result',
      resultSuccess: 'Executed Successfully',
      resultFail: 'Execution Failure',
      failReason: 'Cause Of Failure'
    },
    action: {
      add: 'New Rule',
      addTrigger: 'Add Trigger',
      addActuator: 'Add Actuator',
      set: 'Set As'
    },
    place: {
      ruleName: 'Please enter a rule name',
      triggerMode: 'Select A Trigger Mode',
      triggerTime: 'Please select a trigger time',
      triggerLogical: 'Please Select Trigger Logic',
      actRate: 'Please select the execution frequency',
      actRatePeriod: 'Please select a time interval',
      week: 'Please select at least one date',
      prop: 'Please Select Properties',
      event: 'Please Select An Event',
      trigger: {
        required: 'Please add at least one trigger',
        type: 'Select A Trigger Type',
        compare: 'Please Select An Operator',
        event: 'Please Select An Event',
        onlineStatus: 'Please Select In/offline State'
      },
      actuator: {
        required: 'Please add at least one actuator',
        type: 'Please select an actuator type',
        isDelay: 'Select whether to delay execution',
        delayTime: 'Select a delayed execution time',
        sms: 'Please Select Sms Push',
        voice: 'Please Select Voice Push',
        email: 'Please Select Email Push'
      }
    },
    tips: {
      deleteTitle: 'Delete Prompt',
      deleteMsgEnabled: 'This rule is enabled. Determine whether to delete this rule?',
      deleteMsgDisabled: 'Whether to delete the rule?',
      triggerConditions: 'Trigger Condition',
      actions: 'Execution Action',
      actuatorRateTypes: {
        once: 'Single execution: When the trigger condition is reached, the linkage is executed only once. Then the linkage is disabled.',
        merge: 'Merge execution: Within a certain period of time, only one execution occurs when the trigger condition is met.',
        always: 'Continuous execution: Execution is performed every time the trigger condition is reached.'
      },
      inside: 'By default, this parameter is triggered within the specified time range. If this parameter is selected, this parameter can be triggered outside the specified time range.'
    }
  },
  tsl: {
    attribute: 'Property Control',
    service: 'Service Call',
    send: 'Send Command',
    sendValue: 'Send Property',
    notEmpty: 'cannot be empty',
    column: {
      name: 'Parameter Name',
      code: 'Identifier',
      upValue: 'Current Value',
      downValue: 'Configured Value',
      unit: 'Unit'
    },
    tips: {
      raw: 'Please Hex encode the original content and send it. The device receives the original content.'
    },
    validate: {
      raw: 'Only data in hexadecimal is supported (0-9, A-F).',
      arrayLen: 'The maximum length of array is',
      noProp: 'No TSL property selected! Please select.',
      notEmpty: 'TSL property to be sent cannot be empty.',
      notMatch: 'Enumeration field matching error.'
    }
  },
  notification: {
    tempZH: 'Chinese Template',
    tempEN: 'English Template',
    sysZH: 'Chinese System',
    sysEN: 'English System',
    tempList: 'Template List',
    zh: 'Chinese',
    en: 'English',
    common: {
      addAccount: 'Add Account',
      editAccount: 'Edit Account',
      addTemplate: 'Create Template',
      editTemplate: 'Edit Template',
      tempContent: 'Content',
      code: 'Template Code',
      tempMap: 'Template Variable Mapping',
      tempParams: 'Parameter',
      sysParams: 'System Parameter',
      msgContent: 'Content of Notice',
      confirmEnableChange: 'Current enterprise has enabled another account, are you sure you want to change the account?',
      confirmCloseCurrent: 'This account is enabled, are you sure you want to disable it?',
      alertEnableChange: 'This account is enabled, and the previous account has been disabled.',
      filterOrg: 'Please enter and select the Enterprise Name/ID.',
      orgNameAndId: 'Enterprise Name / ID',
      insert: 'Insert Data',
      allProducts: 'All Products',
      state: {
        enable: 'Enable',
        disable: 'Disable'
      }
    },
    sms: {
      account: {
        title: 'SMS Account',
        name: 'SMS Account Name',
        tunnel: 'SMS Channel',
        orgName: 'Enterprise',
        orgId: 'Enterprise ID',
        enableState: 'Account State',
        orgFees: 'After successfully creating a new account, please configure the package in the "Institutional Fees - Default Subordinate Price Configuration" section as soon as possible',
        place: {
          accountName: 'Please enter the SMS account name.',
          tunnelType: 'Please select the SMS channel.',
          state: 'Please select the account state.',
          accessKey: 'Please enter AccessKey.',
          accessSecret: 'Please enter AccessSecret.',
          publicAccount: 'Set it as a public account',
          max50: 'The length cannot exceed 50 characters.',
          orgId: 'Please enter the enterprise ID.'
        }
      },
      temp: {
        title: 'SMS Template',
        name: 'Template Name',
        state: 'Template State',
        account: 'SMS Account',
        org: 'Applicable Enterprise',
        product: 'Applicable Product',
        type: 'Template Type',
        signature: 'Associated Signature',
        code: 'Template Code',
        place: {
          templateName: 'Please enter the name of the SMS template.',
          accountId: 'Please search and select a SMS account.',
          max50: 'The length cannot exceed 50 characters.',
          orgId: 'Please select an applicable enterprise.',
          templateType: 'Please select the template type.',
          languageTemps: 'Please select at least one language template.',
          product: 'Please select the applicable product.',
          sign: 'Please select the associated signature.',
          tempCode: 'Please enter the template code.'
        }
      }
    },
    voice: {
      account: {
        title: 'Voice Account',
        name: 'Voice Account Name',
        tunnel: 'Voice Channel',
        orgName: 'Enterprise',
        orgId: 'Enterprise ID',
        enableState: 'Account State',
        numberType: 'Number Type',
        phoneNumber: 'Phone Number',
        place: {
          accountName: 'Please enter the voice account name.',
          tunnelType: 'Please select the voice channel.',
          state: 'Please select the account status.',
          accessKey: 'Please enter AccessKey.',
          accessSecret: 'Please enter AccessSecret.',
          publicAccount: 'Set it as a public account',
          orgId: 'Please enter the enterprise ID.',
          phoneNumber: 'Please enter the phone number.'
        },
        numberTypeOpts: {
          public: 'Public',
          private: 'Private'
        }
      },
      temp: {
        title: 'Voice Template',
        name: 'Template Name',
        state: 'Template State',
        account: 'Template Account',
        org: 'Applicable Enterprise',
        product: 'Applicable Product',
        type: 'Template Type',
        signature: 'Associated Signature',
        code: 'Template Code',
        place: {
          templateName: 'Please enter the voice template name.',
          accountId: 'Please search and select a template account.',
          max50: 'The length cannot exceed 50 characters.',
          orgId: 'Please select an applicable enterprise.',
          templateType: 'Please select the template type.',
          languageTemps: 'Please select at least one language template.',
          product: 'Please select the applicable product.',
          extraInfo: 'Please enter the template content.',
          tempCode: 'Please enter the template code.'
        }
      }
    },
    mail: {
      account: {
        title: 'Email Account',
        name: 'Email Account Name',
        ownOrgId: 'Enterprise ID',
        senderNiceName: "Sender's Name",
        senderAccount: "Sender's Account",
        mailAuthorizationCode: 'Email Authorization Code',
        smtpServer: 'SMTP Server',
        smtpPort: 'SMTP Port',
        enableState: 'Account State',
        place: {
          accountName: 'Please enter the email account name.',
          senderNiceName: "Please enter the sender's name.",
          senderAccount: "Please enter the sender's account.",
          mailAuthorizationCode: 'Please enter the email authorization code.',
          smtpServer: 'Please enter the SMTP server.',
          smtpPort: 'Please enter the SMTP port.',
          state: 'Please select the account status.',
          orgId: 'Please enter the enterprise ID.',
          publicAccount: 'Set it as a public account'
        }
      },
      temp: {
        title: 'Email Template',
        name: 'Email Template Name',
        account: 'Template Account',
        state: 'Template State',
        org: 'Applicable Enterprise',
        product: 'Applicable Product',
        type: 'Template Type',
        place: {
          templateName: 'Please enter an email template name.',
          accountId: 'Please search and select a template account.',
          max50: 'The length cannot exceed 50 characters.',
          orgId: 'Please select an applicable enterprise.',
          templateType: 'Please select the template type.',
          product: 'Please select the applicable product.'
        }
      }
    },
    applet: {}
  },
  expense: {
    bracketLeft: '（',
    bracketRight: '）',
    voicePrice: 'Voice Price',
    smsPrice: 'SMS Price',
    devicePrice: 'Device Price',
    optionType: 'Option Type',
    optionCount: 'Option Counts',
    minutes: '(minutes)',
    count: '(count)',
    guiding: 'Guide Price',
    subPrimary: 'Subordinate Default Price (yuan)',
    explanation: 'Explanation:',
    addExplanation: 'When configuring prices, it is recommended to gradually decrease the unit price in a stepwise manner as the package increases.',
    editExplanationFirst: '1. The current configuration price cannot exceed 130% of the cost price, otherwise it cannot be saved;',
    editExplanationSecond: '2. When configuring prices, it is recommended to gradually decrease the unit price in a stepwise manner as the package increases.',
    unit: {
      voice: 'Minute(s)',
      sms: 'Piece(s)',
      times: 'Unit(s)'
    },
    settings: {
      notice: {
        name: 'Expense Notification',
        shortage: 'Expense Shortage',
        deviceRenewal: 'Device Renewal',
        smsRenewal: 'Sms Renewal',
        mailbox: 'Email',
        range: 'Range',
        rangeSelf: 'Current Enterprise',
        rangeBelow: 'Current and Children Enterprise',
        expiredSim: 'Expiration time of Sms',
        expiredDevice: 'Expiration time of device',
        conditionDevice: 'Condition of enable Sms Renewal',
        conditionSim: 'Condition of enable Device Renewal',
        phone1: 'Phone 1',
        phone2: 'Phone 2',
        phone3: 'Phone 3',
        unit: 'Days',
        tip: 'The notice time will be',
        smsLess: 'Short Message Remaining Below',
        voiceLess: 'Speech Residual Below',
        informMe: 'Inform Me When',
        fillZero: '0 Indicates No Reminder',
        period: 'Notification Cycle',
        continuously: 'Continuous',
        days: 'Day',
        inform: 'Notification'
      },
      credit: {
        name: 'Credit Account',
        appSetting: 'App Settings',
        mchSetting: 'Merchant Settings',
        appName: 'App Name',
        appSecret: 'AppSecret',
        token: 'Token',
        noticeKey: 'Message Encryption Key',
        mchId: 'Merchant ID',
        apiV3: 'APIv3 Key',
        apiCertificate: 'API Certificate',
        apiCertificateSN: 'API Certificate SN',
        apps: 'Associated Application',
        place: {
          appName: 'Please enter the application name',
          appId: 'Please Enter AppID',
          appSecret: 'Please Enter The Key',
          token: 'Please Enter Token (token）',
          msgSecret: 'Please enter the message encryption key',
          mchId: 'Please Enter MerchantID',
          mchKey: 'Please enter the APIv3 key',
          cert: 'Please select the API certificate',
          certPassword: 'Please enter the API certificate serial number',
          appConfigNo: 'Please Select Associated Application'
        }
      },
      profit: {
        name: 'Profit Account',
        subTitle1: 'Related Distribution Accounts',
        subTitle2: 'Proofread your wechat account information',
        step1: 'First Step',
        step2: 'The Second Step',
        form1: {
          accountName: 'Name of the distribution account',
          phone: 'Mobile Phone Number',
          enterprise: 'Enterprise Name',
          wechat: 'Wechat Account Number',
          place: {
            accountName: 'Please enter your account name',
            realName: 'Please enter the business name',
            channelAccount: 'Please enter your wechat account number'
          }
        }
      },
      sim: {
        name: ' Global Connectivity Authorization'
      }
    },
    charge: {
      itemName: {
        paid: 'Paid',
        used: 'Used',
        rest: 'Rest',
        expired: 'Expired',
        froze: 'Froze'
      },
      restTipByVoice: 'The remaining voice can be purchased by lower level institutions, gifted to lower level institutions',
      restTipBySms: 'The remaining SMS can be purchased by lower level institutions, gifted to lower level institutions',
      expiredTipByVoice: 'After each purchase of voice service, there will be a certain validity period. When the validity period is exceeded, the status will change to "expired", and expired voice can no longer be used',
      expiredTipBySms: 'After each purchase of SMS service, there will be a certain validity period. When the validity period is exceeded, the status will change to "expired", and expired SMS can no longer be used',
      record: 'Purchase Record',
      buyServiceVoice: 'Purchase Voice Service',
      buyServiceSms: 'Buy Sms Service',
      serviceVoice: 'Voice Service',
      serviceSms: 'SMS Service',
      buyVoice: 'Voice Purchase',
      buySms: 'SMS Purchase',
      tips: 'Important: The {0} Account has been disabled and {0} notifications cannot be sent. Contact a higher authority.',
      selfTips: 'Important: You are using the {0} Account by yourself. No need to buy',
      packageChoose: 'Select Package',
      sellingPrice: 'Amount Paid',
      payType: 'Payment Method',
      payTypeOpts: {
        wechat: 'Wechat'
      },
      expireDate: 'Maturity Time',
      chargeError: 'No Purchase Package',
      purchaseNotes: 'Purchase Notes',
      first: '1. Online payment currently only supports "WeChat payment";',
      second: '2. The purchased service can be refunded on the same day without trial use;',
      three: '3. Please use the purchased service within its validity period. Expired services cannot be used;',
      four: '4. If you need to issue an invoice, you can contact business personnel for offline recharge.'
    },
    institution: {
      orgId: 'ID',
      voice: 'Remaining Voice (Minutes)',
      sms: 'Remaining SMS (Pieces)',
      present: 'Present',
      priceSetting: 'Price Setting',
      defaultConfig: 'Default Price of Subordinates',
      presentForm: {
        title: 'Complimentary Communication Service',
        count: 'Counts Of {0}',
        balance: 'My Balance Of {0}:',
        toOrg: 'Send To Qrganization'
      },
      place: {
        notEmpty: 'The quantity cannot be empty',
        max: 'The quantity cannot be greater than the balance',
        voiceCount: 'Please enter the number of voices',
        smsCount: 'Please enter the number Of short messages',
        overflow: 'Overbalance',
        needCount: 'Please enter the quantity to be donated'
      }
    },
    priceConfig: {
      addOption: 'New Package',
      editOption: 'Editing Package',
      priceSetting: 'Allocation Guidance Unit Price',
      dateSetting: 'Configuration Validity Period',
      name: 'Product Name',
      packageCount: 'Package Count',
      guidingPrice: 'Guiding Price (RMB)',
      defaultPrice: 'Subordinate Default Price (RMB)',
      unitPrice: 'Unit Price',
      pricePrefix: 'Guide Unit Price',
      costPerUnit: 'yuan per {0}',
      datePrefix: 'Validity Period',
      dateSuffix: 'months',
      service: '{0}Service',
      child: 'Junior',
      self: 'Current',
      parent: 'Guide',
      voice: {
        title: 'Subordinate Voice Price',
        priceTitle: 'Set the cost price of voice service fee',
        dateTitle: 'Set the validity period of voice service fees',
        priceTips: 'Guide price is the cost price of the product',
        dateTips: 'Voice services automatically become invalid when their validity period expires'
      },
      sms: {
        title: 'Subordinate SMS Price',
        priceTitle: 'Set the cost price of SMS service fee',
        dateTitle: 'Configure the validity period of the SMS service fee',
        dateTips: 'When the SMS service expires, it automatically becomes invalid'
      },
      device: {
        title: 'Subordinate Device Price',
        isFree: 'Free',
        useYears: 'Service Years',
        duration: 'Qualifying Hours',
        defaultEnable: 'Usable Default Price',
        defaultCharge: 'Renew Default Price',
        settingTitle: 'Equipment Price Allocation',
        enablePrice: '{0} Usable Price（RMB）',
        renewalPrice: '{0} Renew Price（RMB）',
        priceType: 'Price Type',
        priceTypeOpts: {
          free: 'Free',
          notFree: 'Charge'
        },
        life: 'Lifelong'
      },
      place: {
        minInt: 'Please enter an integer greater than 0',
        min: 'The subordinate default price shall not be less than the guide price',
        inputCount: 'Please enter the number of packages',
        inputPrice: 'Please Enter The Price',
        useYears: 'Please select the service life',
        rightPrice: 'Please enter the correct price',
        overflow: 'If the price exceeds 30% of the guide price, the platform will not support it for the time being, please contact the higher authority for handling',
        singlePrice: 'Make sure the latter price under the former price'
      }
    },
    income: {
      list: {
        orderNo: 'Order no',
        orderType: 'Order type',
        paymentOrg: 'Payment org',
        receiveOrg: 'Receive org',
        incomeMoney: 'Income amount of this institution (RMB)',
        orderTime: 'Order time',
        voicePurchase: 'Voice purchase',
        smsPurchase: 'Sms purchase',
        deviceEnable: 'Device enable',
        deviceRenewal: 'Device renewal',
        sumIncome: 'Accumulated income of this institution',
        yuan: 'CNY',
        incomeDetail: 'Income detail',
        incomeLevel: 'Income level',
        incomeOrg: 'Income org',
        incomeAccount: 'Income account',
        incomeAbleMoney: 'Amount of income receivable',
        realIncomeMoney: 'Actual income amount'
      }
    },
    device: {
      all: 'All',
      continuationFeetrafficCard: 'Continuation fee traffic card',
      cardRecharge: 'Card Recharge',
      deviceNo: 'DeviceKey',
      orgName: 'Affiliated institution',
      productName: 'product Name',
      cardExpirationTime: 'CardExpiration Time',
      cardExpirationStatus: 'CardExpiration Status',
      flowPacketRecharge: 'FlowPacket Recharge',
      expirationTime: 'Expiration Time',
      totalMoney: 'Total Money',
      iccid: 'ICCID',
      unexpired: 'Unexpired',
      expired: 'Expired',
      renewalStatus: 'Renewal Status',
      cardRenew: 'Card Renew',
      setMeal: 'SetMeal',
      cardNums: 'Number of cards (pieces)',
      time: 'time',
      renewPrice: 'Renewal unit price(yuan)',
      subtotalMoney: 'Subtotal amount (yuan)',
      trialStatus: 'Trial status',
      useRemainTime: 'Use remaining time(Day)',
      enablePrice: 'Enable pricing(yuan)',
      createTime: 'creation date',
      enableDeviceTime: 'Device activation time',
      deviceExpireTime: 'Device expiration time',
      deviceExpireStatus: 'Device expiration status',
      deviceCnt: 'Number of devices',
      enableYears: 'Life',
      enablePriceYuan: 'Activation price',
      enableDevice: 'Device enable',
      renewDevice: 'Device renewal',
      onlyOneProduct: 'Only batch activation of devices under the same product is supported, operation unsuccessful',
      productKey: 'ProductKey',
      totalDevice: 'Total device',
      clearAll: 'Clear all',
      retainAll: 'Retain All',
      howHandleData: 'How to handle data during the probationary period',
      units: 'units',
      yuan: 'yuan',
      trial: 'Trial',
      enable: 'Enable',
      simCharge: 'Card recharge',
      simRenew: 'Card renewal',
      enableDeviceDetail: 'Device activation order details',
      renewDeviceDetail: 'Device renewal Order Details',
      no: 'Serial Number',
      renewAfterExpireTime: 'Expiration time after renewal',
      enableAfterExpireTime: 'Expiration time after activation',
      unknow: 'Ominous',
      mb: 'MB',
      curMonth: 'The same month',
      deviceFeeState: 'Tariff status',
      deviceActivationTime: 'Device activation time',
      serviceLife: 'Service life',
      deviceExpirationTime: 'Device expiration time',
      deviceExpirationState: 'Device expiration status',
      joinRenew: 'Join Renewal',
      stopRenew: 'Suspend renewal',
      scanCodePay: 'Scan code payment',
      simStatus: 'SIM card status',
      residueFlow: 'Residual flow',
      deviceKey: 'DeviceKey',
      purchaseDescription: 'Purchase description',
      cardRechargeDescFirst: '1. Online payment currently only supports "WeChat payment";',
      cardRechargeDescSecond: '2. The purchased traffic package does not support refunds, please purchase with caution;',
      cardRenewDescSecond: '2. The renewed SIM does not support refunds. Please purchase with caution;',
      deviceStartDescSecond: '2. Once the device is activated, it cannot be refunded. Please purchase with caution;',
      deviceRenewDescSecond: '2、After renewing the device, a refund cannot be made. Please purchase with caution;',
      cardRechargeDescThree: '3. Please use the traffic package within its validity period. Expired traffic packages cannot be used;',
      cardRechargeDescFour: '4. If you need to issue an invoice, you can contact business personnel for offline recharge.',
      expenseStatusOpt: {
        onTrial: 'On Trial',
        trialing: 'Trial In Progress',
        trialed: 'Probed',
        beEnabled: 'Waitting To Use',
        launching: 'Using',
        expired: 'Expired'
      },
      trialStatusOpt: {
        noTrial: 'No Trial',
        notTried: 'Not Tried'
      },
      deviceNotice: {
        title: 'Device Notification',
        allocation: 'Allocation Records',
        allocatingCount: 'Allocation Count',
        distributeDate: 'Allocation Time',
        flowType: 'Flow Type',
        batchGive: 'Batch Present'
      },
      flowTypeOpts: {
        present: 'Present',
        expire: 'Expired',
        use: 'Use',
        firstAllocated: 'First Allocated',
        autoAllocated: 'Auto Allocated',
        delDevice: 'Delete Device'
      },
      place: {
        flowPacket: 'Select a traffic package',
        purchaseNums: 'Purchase quantity',
        paymentAmount: 'Payment Amount',
        payType: 'Payment method',
        alipay: 'Alipay',
        wechatPayment: 'Wechat Payment',
        cancel: 'Cancel',
        toPay: 'To Pay',
        directCharging: 'Direct charging',
        pk: 'Please enter ProductKey',
        deviceNo: 'Please enter DeviceKey',
        iccid: 'Please enter iccid',
        renewalStatus: 'Please select a renewal status',
        waitRenew: 'Wait renew',
        stopRenew: 'Stop renew',
        renew: 'Renew',
        operate: 'Operate',
        cardExpirationTime: 'Please select the card expiration time',
        within7Day: 'Within 7 days',
        within30Day: 'Within 30 days',
        within60Day: 'Within 60 days',
        within90Day: 'Within 90 days',
        expired: 'Expired',
        deviceFeeStatus: 'Please select the tariff status',
        enabled: 'To be enabled',
        try: 'On trial',
        normal: 'Normal',
        expire: 'Expire',
        deviceExpirationTime: 'Please select the device expiration time',
        deviceExpirationStatus: 'Please select the device expiration status',
        perMonth: 'Months',
        halt: 'Halt',
        cancellation: 'Pre cancellation number',
        cancelNumber: 'Cancel a number',
        toBeActivated: 'To be activated',
        unknown: 'Unknown',
        testNormal: 'Normal testing period',
        testHalt: 'Test period shutdown',
        warranty: 'Shutdown warranty number'
      }
    },
    order: {
      orderNo: 'Order No',
      giveOrg: 'Contributor',
      receiver: 'Receiver',
      receiverType: 'Receiver Type',
      receiverOpts: {
        org: 'Organization',
        device: 'Device'
      },
      expireTime: 'Expired Time',
      msgNum: 'SMS Quantity(Pieces)',
      voiceNum: 'Voice Quantity(Minutes)',
      orderTime: 'Order Time',
      payOrder: 'Paid Record',
      giftOrder: 'Gift Record',
      payOrg: 'Payment Institution',
      orderType: 'Order Type',
      orderNum: 'Order Quantity',
      orderMoney: 'Order Amount (yuan)',
      payType: 'Payment Channels',
      orderStatus: 'Order Status',
      refundMoney: 'Refund Amount',
      refund: 'Drawback',
      define: 'Define',
      smsRemaining: 'Sms remaining',
      smsFailure: 'Sms failure',
      voiceRemaining: 'Voice remaining',
      voiceFailure: 'Voice failure',
      giveOrderDetail: 'Gift Order Detail',
      iccid: 'ICCID',
      flowPacketName: 'Traffic packet name',
      flowPacketType: 'Traffic packet type',
      flowPacketSize: 'Traffic packet size',
      startTime: 'Entry-into-force time',
      expirationTime: 'Expiration time',
      setMealName: 'Package Name',
      renewPeriod: 'Renewal period (months)',
      beforeRenewExpirationTime: 'Expiration time before renewal',
      afterRenewExpirationTime: 'Expiration time after renewal',
      payOrgId: 'Payment Institution ID',
      remainNum: 'Remaining quantity',
      failureNum: 'Number of failures',
      refundMoneyYuan: 'Refund amount (yuan)',
      orderDetail: ' Order details',
      cardRecharge: 'Card recharge',
      cardRenew: 'Card renewal',
      voice: 'Voice',
      sms: 'Sms',
      min: 'min',
      piece: 'Piece',
      orderFee: 'Amount',
      refundAble: 'Refundable amount',
      publicNetwork: 'Public network',
      directional: 'Directional',
      wechat: 'Wechat',
      place: {
        orderNo: 'Please enter the order no',
        orderType: 'Please select the order type',
        orderStatus: 'Please select the order status',
        voiceService: 'Voice Services',
        smsService: 'SMS service',
        deviceStart: 'Device Enable',
        deviceRenew: 'Device renewal',
        cardRecharge: 'Card recharge',
        cardRenew: 'Card renewal',
        toBePaid: 'To be paid',
        closed: 'Closed',
        paid: 'Paid',
        fullRefund: 'Refunded',
        completed: 'Completed'
      }
    }
  },
  colSet: {
    title: 'List Field Settings',
    sort: 'Sort',
    show: 'Display',
    name: 'Name',
    reset: 'Reset',
    success: 'Set successfully'
  },
  power: {
    name: 'Station Name',
    type: 'Station Type',
    sysType: 'System Type',
    add: 'Add',
    curPower: 'Current Generation Power',
    curGeneration: 'Current Generation',
    totalGeneration: 'Total Generation',
    manager: 'Contact Person',
    device: 'Manage Device',
    person: 'Contact Person',
    zone: 'Timezone',
    installCapacity: 'Installed Capacity',
    createTime: 'Establishment Date',
    img: 'Station Image',
    remark: 'Remarks',
    ownerInfo: 'Owner Information',
    ownerName: 'Owner Name',
    ownerPhone: 'Phone Number',
    gridConnected: 'On-grid Type',
    energyCapacity: 'Eergy Capacity',
    avgElectricityPrice: 'Average Electricity Price',
    unit: 'kW·h',
    countryCode: 'International calling code',
    place: {
      name: 'Please enter the station name.',
      manager: "Please enter the contact person's name.",
      zone: 'Please select the timezone.',
      type: 'Please select a power station type.',
      sysType: 'Please select a system type.',
      installCapacity: 'Please enter the installed capacity.',
      createTime: 'Please select the establishment date.',
      remark: 'Please enter remarks.',
      ownerName: "Please enter the owner's name.",
      ownerPhone: "Please enter the owner's phone number.",
      gridConnected: 'Please select the on-grid type.',
      energyCapacity: 'Please select the on-grid type',
      avgElectricityPrice: 'Please enter the average electricity price.',
      enterprise: 'Please select the enterprise this station belongs to',
      detailAddress: 'Please enter the detailed address',
      address: 'Please enter the station address'
    },
    tips: {
      type: 'The actual application scenario of the station.',
      sysType: 'The power generation, consumption, storage, and on/off-grid capabilities of this station.',
      manager: 'Used to receive the device alert or fault notifications of the power station.',
      noManager: 'No contact person yet. Please add a contact person first in "Fault and Alert" > "Contact".'
    },
    overView: 'Station Overview',
    info: 'Station Information',
    sysAccount: 'System Information',
    editInfo: 'Edit',
    detail: {
      contribution: 'Carbon Neutrality Contribution',
      coal: 'Coal Saved',
      carbonDioxide: 'CO2 Avoided',
      household: 'Household Electricity for a Family of Three',
      energyFlow: 'Energy Flow',
      photovoltaic: 'PV',
      powerGrid: 'Grid',
      house: 'Home',
      battery: 'Battery',
      realTimeStatus: 'Real-time Status',
      power: 'Power',
      realTime: '{0}',
      solar: 'Generation',
      load: 'Consumption',
      grid: 'Grid',
      generation: 'Generation {0}',
      consumption: 'Consumption {0}',
      daily: 'Today',
      monthly: 'This Month',
      yearly: 'This Year',
      total: 'Total',
      gridConnectedQuantity: 'Sold',
      elePurchase: 'Purchased',
      batteryCharge: 'Charging',
      batteryDischarge: 'Discharging'
    }
  },
  ag: {
    name: 'Machine Name',
    speed: 'Speed',
    height: 'Altitude',
    ggA: 'Positioning Status',
    detail: {
      baseInfo: 'Machine Information',
      propInfo: 'Machine Property',
      log: 'Operation Records',
      set: 'Machine Control',
      unlock: 'Unlock Device',
      lock: 'Lock Device',
      unlockTip: 'After you select “Unlock Device”, an unlock command will be sent to the device, and the device will be unlocked. Are you sure you want to unlock the device?',
      lockTip: 'After you select “Lock Device”, a lock command will be sent to the device, and the device will be locked and cannot be operated. Are you sure you want to lock the device?',
      deviceIsOffline: 'Device is offline.'
    },
    work: {
      id: 'Operation ID',
      num: 'Operation Code',
      type: 'Operation Type',
      status: 'Operation Status',
      plotId: 'Operation Filed ID',
      workStartTimeBegin: 'Operation Start Date (From)',
      workStartTimeEnd: 'Operation Start Date (To)',
      width: 'Machine Width (m)',
      acres: 'Operation Area (mu)',
      length: 'Operation Length (m)',
      duration: 'Operation Duration',
      workStartTime: 'Operation Start Time',
      workEndTime: 'Operation End Time',
      noWorkLog: 'The device did not report any trace log',
      typeOpts: {
        item0: 'Ridge',
        item1: 'Sow',
        item2: 'Fertilize',
        item3: 'Harvest',
        item4: 'Others'
      },
      statusOpts: {
        item1: 'In progress',
        item2: 'Completed'
      }
    },
    land: {
      id: 'Field ID',
      plotId: 'Field Code'
    },
    line: {
      type: 'Operation Type',
      plotId: 'Field Code',
      deviceName: 'Operation Name',
      jonLineInfo: 'Work Line Info',
      drawPolyline: 'Draw Polyline',
      openEditor: 'Open Editor',
      closeEditor: 'Close Editor',
      clearLine: 'Clear Line',
      drawTips: 'Please Draw Line.'
    }
  }
}
