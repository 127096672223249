const getDefaultState = () => {
  return {
    baseInfo: (sessionStorage.getItem('baseInfo') && JSON.parse(sessionStorage.getItem('baseInfo'))) || [],
    fenceInfo: (sessionStorage.getItem('fenceInfo') && JSON.parse(sessionStorage.getItem('fenceInfo'))) || {},
    runStatus: sessionStorage.getItem('runStatus') || '1', // 运动状态，'1'：开始，'0'：结束
    runProgress: sessionStorage.getItem('runProgress') || 0, // 运动进度
    runProgressClick: sessionStorage.getItem('runProgressClick') || 0, // 点击进度条-已运动进度
    updateDetail: { activeName: '' } // 更新设备详情数据
  }
}

const state = getDefaultState()

const mutations = {
  SET_BASE_INFO: (state, baseInfo) => {
    state.baseInfo = baseInfo
    try {
      sessionStorage.setItem('baseInfo', JSON.stringify(baseInfo))
    } catch (e) {

    }
  },
  SET_FENCE_INFO: (state, fenceInfo) => {
    state.fenceInfo = fenceInfo
    try {
      sessionStorage.setItem('fenceInfo', JSON.stringify(fenceInfo))
    } catch (e) {

    }
  },
  SET_START_FLAG: (state, runStatus) => {
    state.runStatus = runStatus
    try {
      sessionStorage.setItem('runStatus', runStatus)
    } catch (e) {

    }
  },
  SET_RUN_PROGRESS: (state, runProgress) => {
    state.runProgress = runProgress
    try {
      sessionStorage.setItem('runProgress', runProgress)
    } catch (e) {

    }
  },
  SET_RUN_PROGRESS_CLICK: (state, runProgressClick) => {
    state.runProgressClick = runProgressClick
    try {
      sessionStorage.setItem('runProgressClick', runProgressClick)
    } catch (e) {

    }
  },
  SET_UPDATE_DETAIL_STATUS: (state, updateDetail) => {
    state.updateDetail = updateDetail
  }
}

const actions = {
  setBaseInfo ({ commit, dispatch }, baseInfo) {
    return new Promise(resolve => {
      commit('SET_BASE_INFO', baseInfo)
      resolve()
    })
  },
  setFenceInfo ({ commit, dispatch }, fenceInfo) {
    return new Promise(resolve => {
      commit('SET_FENCE_INFO', fenceInfo)
      resolve()
    })
  },
  setRunStatus ({ commit, dispatch }, runStatus) {
    return new Promise(resolve => {
      commit('SET_START_FLAG', runStatus)
      resolve()
    })
  },
  setRunProgress ({ commit, dispatch }, runProgress) {
    return new Promise(resolve => {
      commit('SET_RUN_PROGRESS', runProgress)
      resolve()
    })
  },
  setRunProgressClick ({ commit, dispatch }, runProgressClick) {
    return new Promise(resolve => {
      commit('SET_RUN_PROGRESS_CLICK', runProgressClick)
      resolve()
    })
  },
  setUpdateDetailStatus ({ commit, dispatch }, updateDetail) {
    return new Promise(resolve => {
      commit('SET_UPDATE_DETAIL_STATUS', updateDetail)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
