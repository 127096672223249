// 自定义筛选表头
<template>
  <div class="filter-header inline-block" @click.stop>
    <el-popover ref="popover" placement="bottom" trigger="click" @hide="handleHide">
      <!-- 文本框 -->
      <div v-if="filterType == 'text'">
        <el-input
          v-model.trim="text"
          size="mini"
          clearable
          placeholder="请输入查询内容"
          @keyup.native.enter="confirm()"
        />
      </div>

      <!-- 复选框-->
      <div v-else-if="filterType == 'checkbox'">
        <!-- 全选 -->
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" class="filter-all" @change="handleSelectAll">
          {{ $t('device.history.selectAll') }}
        </el-checkbox>
        <el-checkbox-group v-model="checkbox" class="filter-radio" @change="handleCheckedChange">
          <el-checkbox v-for="item in filters" :key="item.value" :label="item.value">
            {{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
      </div>

      <!--单选按钮-->
      <div v-else-if="filterType === 'radio'">
        <el-radio-group v-model="radio">
          <el-radio v-for="item in filters" :key="item.value" :label="item.value" border size="mini">
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>

      <el-divider class="m-t-4 m-b-4" />

      <!-- 筛选-->
      <div class="fl">
        <el-button type="text" @click="confirm">{{ $t('common.select') }}</el-button>
      </div>

      <!-- ICON-->
      <span
        slot="reference"
        onselectstart="return false"
        oncontextmenu="return false"
        class="header-label"
        :class="labelColor"
      >
        <i class="iconfont iconfilter m-r-4" />
      </span>
    </el-popover>
  </div>
</template>

<script>
/**
 * table头部筛选器
 * 勾选状态-点击“筛选”按钮，才会更新状态进行搜索
 * 勾选状态-不点击“筛选”按钮，关闭时恢复上一次勾选
 */
export default {
  name: 'FilterHeader',
  props: {
    filterCode: { type: String, default: '' },
    filterType: { type: String, default: 'text' },
    customArrList: { type: Array, default: () => [] },
    filterKey: { type: Object, default: () => {} } // 过滤器格式化key
  },
  data() {
    return {
      text: '',
      radio: '',
      checkbox: [],
      checkAll: false,
      isIndeterminate: false,
      filterValue: [] // 存储筛选状态，不点筛选时，恢复上一次选择
    }
  },
  computed: {
    /** 格式化筛选器数据 */
    filtersList: {
      get() {
        let filterKey = this.filterKey
        let list = this.customArrList
        return (
          list?.map(item => ({
            label: filterKey.dict ? item.dictLabel : item[filterKey.label],
            value: filterKey.dict ? item.dictValue : item[filterKey.value]
          })) || []
        )
      }
    },

    /** 返回自定义的筛选器数据 */
    filters() {
      if (this.customArrList.length) {
        return this.filterKey ? this.filtersList : this.customArrList
      } else {
        return undefined
      }
    },

    // 有值，Icon高亮
    labelColor() {
      switch (this.filterType) {
        // 文本框
        case 'text':
          if (this.text) {
            return 'primary'
          }
          return ''
        // 复选框
        case 'checkbox':
          if (this.checkbox.length > 0) {
            return 'primary'
          }
          return ''
        // 单选按钮
        case 'radio':
          if (this.radio !== '') {
            return 'primary'
          }
          return ''
      }
      // ....数值范围/数值范围/下拉框
      return ''
    }
  },
  methods: {
    // 筛选搜索
    confirm() {
      this.$refs.popover.doClose()
      this.filterValue = this.checkbox
      this.$emit('tableFilter', {
        filterValue: this.filterValue,
        filterCode: this.filterCode
      })
    },
    handleCheckedChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.filters.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.filters.length
    },
    handleSelectAll(val) {
      this.checkbox = val ? this.filters.map(item => item.value) : []
      this.isIndeterminate = false // 重置半选状态
    },
    // 关闭时，更新icon状态（是否点击筛选）
    handleHide() {
      this.checkbox = this.filterValue?.length ? this.filterValue : []
      this.handleCheckedChange(this.checkbox)
    }

    /** 复选框生成过滤值 全选为null */
    // handleFilterValue () {
    //   // let total = this.customArrList.length
    //   // this.filterValue = total === this.checkbox.length ? null : this.checkbox
    //   this.filterValue = this.checkbox
    // }
  }
}
</script>

<style lang="scss" scoped>
.header-label {
  user-select: none;
}

.filter {
  &-all {
    margin: 8px 0;
  }
  &-radio {
    display: flex;
    flex-direction: column;
    .el-checkbox {
      display: block;
      margin: 8px 0;
    }
  }
}

.iconfont {
  font-size: 12px;
}
</style>
