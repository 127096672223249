// websocket实例，单个浏览器选项卡下共用一个实例，即一个ws连接，多个订阅；跳转到新选项卡时（如实时曲线新页面打开），重新建立新ws连接，执行init()
import Paho from 'paho-mqtt'
import { contractExtension, getWSSetting } from '@/api/topic'
export default class SocketService {
  enable = true
  connected = false
  onError = null
  onMessage = {} // 存放成功后的回调函数，子集以topic命名

  constructor() {
    this._checkEnabled()
    this.connectTimer = null
    this.ws = null
    this.timers = {} // 存放定时器，子集以topic命名
  }

  init () {
    getWSSetting().then(res => {
      let { wsHost, wsPort, wsPath, clientId, wsProtocol, username, password } = res.data
      this.ws = new Paho.Client(wsHost, wsPort, wsPath, clientId)
      this.ws.connect({
        useSSL: wsProtocol === 'wss',
        timeout: 3,
        keepAliveInterval: 30,
        userName: username,
        password: password,
        onSuccess: () => {
          this.connected = true
          // console.log('ws 连接成功')
          clearTimeout(this.connectTimer)
        },
        onFailure: (message) => {
          this.connectTimer = setTimeout(this.init, 5000) // 每5s重连一次
          console.log('连接失败：' + message.errorMessage)
        }
      })
      this.ws.onMessageArrived = msg => {
        if (msg && msg.payloadString) {
          this._handleMessage(msg.topic, msg.payloadString)
        }
      }
    })
  }

  _checkEnabled () {
    if (!window.WebSocket) {
      const err = new Error('Mqtt Browser does not support WebSocket.')
      this._handleError(err)
      this.enable = false
      throw err
    }
  }

  // 订阅对应服务
  subscribe (topic, contract) {
    let topics = [topic]
    let { contractWS, timers } = this
    this.ws.subscribe(topics, {
      onSuccess: function () {
        // console.log(`%c订阅成功：${topic}`, 'color: green')
        if (contract) {
          contractWS(topics)
          if (!timers[topic])// 若该topic未曾启动续约定时器，则设置为新定时器
          { timers[topic] = setInterval(() => { contractWS(topics) }, 1000 * 60 * 5) } // 每5min续约一次
        }
      },
      onFailure: function () {
        console.log('websocket 订阅失败')
      }
    })
  }

  // 结束订阅
  unsubscribe (topic) {
    let { timers } = this
    this.ws.unsubscribe([topic], {
      onSuccess: () => {
        if (timers[topic]) { clearInterval(timers[topic]) } // 清除续约定时器
        // console.log('关闭订阅：' + topic)
      }
    })
  }

  // 续约
  contractWS (topics) {
    contractExtension(topics).then(res => {
      // console.log('websocket 续约' + (res.data ? '成功' : '失败'))
    })
  }

  _handleError (e) {
    console.log('ws error', e)
    if (typeof this.onError === 'function') {
      this.onError(e)
    }
  }

  _handleMessage (topic, e) {
    if (this.onMessage[topic]) this.onMessage[topic](e) // 触发topic对应的onMessage函数
  }

  send (message) {
    // ClientImpl.prototype.send
    this.ws.send(message)
  }

  close () {
    // ClientImpl.prototype.disconnect
    this.ws.disconnect()
    this.connected = false
    Object.keys(this.timers).forEach(topic => {
      clearInterval(this.timers[topic])
    })
    this.onMessage = {}
  }
}
