import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'

import ElementLocale from 'element-ui/lib/locale'
import ElementenLocale from 'element-ui/lib/locale/lang/en'
import ElementzhLocale from 'element-ui/lib/locale/lang/zh-CN'

import enLocale from '@/lang/en'
import zhLocale from '@/lang/zh'

// 行业应用翻译文案
import { appZhLocale } from '@/app/lang/zh'
import { appEnLocale } from '@/app/lang/en'

// 本地语言包json
import pkgZh from './packages/zh.json'
import pkgEn from './packages/en.json'

const appZh = { app: { ...appZhLocale } }
const appEn = { app: { ...appEnLocale } }
Vue.use(VueI18n)

// 语言环境信息
const messages = {
  'zh-CN': { ...zhLocale, ...ElementzhLocale, ...appZh, ...pkgZh },
  'en-US': { ...enLocale, ...ElementenLocale, ...appEn, ...pkgEn }
}

// 获取语言环境并保留状态：生产环境以VUE_APP_ENV_TYPE自动切换
export function getLanguage () {
  if (process.env.NODE_ENV === 'development') {
    const cookieLanguage = Cookies.get('lang')
    if (cookieLanguage) return cookieLanguage
  }
  let env = process.env
  if (env.VUE_APP_ENV_TYPE === 'CHINA') {
    Cookies.set('lang', 'zh-CN')
    return 'zh-CN'
  } else {
    Cookies.set('lang', 'en-US')
    return 'en-US'
  }
}

const i18n = new VueI18n({
  locale: getLanguage(),
  messages
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n
