import { getUserZoneTime } from './core'

/**
 * 当天00:00
 * @param stamp
 * @returns {dayjs.Dayjs}
 */
export function getDateStart (stamp) {
  return getUserZoneTime(stamp).startOf('date')
}

/**
 * 当月1日上午 00:00
 * @param stamp
 * @returns {dayjs.Dayjs}
 */
export function getMonthStart (stamp) {
  return getUserZoneTime(stamp).startOf('month')
}

/**
 * 当年一月1日上午 00:00
 * @param stamp
 * @returns {dayjs.Dayjs}
 */
export function getYearStart (stamp) {
  return getUserZoneTime(stamp).startOf('year')
}

/**
 * 当天23:59
 * @param stamp
 * @returns {dayjs.Dayjs}
 */
export function getDateEnd (stamp) {
  return getUserZoneTime(stamp).endOf('date')
}

/**
 * 获取最近N天的时间范围数组
 * @param {number} count 即 N 内部会做向上取整，最小值为1
 * @return {(dayjs.Dayjs|dayjs.Dayjs)[]}
 */
export function getRecentDays (count = 1) {
  // 取大于等于1的整数
  const days = Math.max(1, Math.ceil(count))

  const stamp = Date.now()
  const todayStart = getDateStart(stamp)

  // 近n天包含当天，目标天数需要减1
  const targetStart = todayStart.subtract(days - 1, 'day')
  const todayEnd = getDateEnd(stamp)

  return [targetStart, todayEnd]
}
