import request from '@/utils/request'

// 批量任务列表接口
export function getTaskList (query) {
  return request({
    url: '/system/task',
    method: 'get',
    params: query
  })
}
// 批量任务明细
export function getDetail (query) {
  return request({
    url: '/system/task/detail',
    method: 'get',
    params: query
  })
}
// 运行中的任务（当前用户创建的且任务状态为进行中的任务）
export function getRunning (query) {
  return request({
    url: '/system/task/running',
    method: 'get',
    params: query
  })
}
// 导出
export function detailExport (data) {
  return request({
    url: '/system/task/record/download',
    responseType: 'blob',
    method: 'post',
    data
  })
}
