
import { enableScreenList } from '@/api/login'
export default {
  data () {
    return {
      dataViewPermiList: []
    }
  },
  methods: {
    getDataView () {
      const views = [
        { command: 'common', permis: 'dataview:common', name: this.$t('navbar.dataView') },
        { command: 'yhs', permis: 'dataview:yhs', name: this.$t('navbar.yhs') },
        { command: 'ems', permis: 'dataview:ems', name: this.$t('dataScreen.emsTitle') }
      ]
      const permis = this.$store.state.user.permissions
      let screenList = []
      enableScreenList()
        .then(res => {
          screenList = res.data
        }).then(() => {
          this.dataViewPermiList = [
            ...views.filter(ele => permis.includes(ele.permis)),
            ...screenList.map(item => Object.assign({}, item, { command: 'screenManage', name: item.screenName }))
          ]
          this.$store.dispatch('DataView', this.dataViewPermiList)
        })
    },
    toDaTan (action, params) {
      const { id, screenName, url } = params
      const query = {
        url: url || undefined,
        action,
        screenId: id || undefined,
        otherTitle: screenName
      }
      const r = this.$router.resolve({
        path: '/screen-iframe',
        query
      })
      window.open(r.href, '_blank')
    }
  }
}
