<template>
  <div v-if="!item.hidden">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
        !item.alwaysShow
      "
    >
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)" class="child-item">
        <span @click="handleSave">{{ titleFormat(onlyOneChild) }}</span>
      </app-link>
    </template>

    <div v-else class="menu-item" :class="activeClass === true ? 'active' : ''" @click="activeClass = !activeClass">
      <p v-if="item.meta" class="child-item">
        {{ titleFormat(item) }}
        <i class="el-icon-arrow-right" />
      </p>
      <child-item
        v-for="child in item.children"
        :key="child.path"
        :item="child"
        :base-path="resolvePath(item.path)"
        class="nest-menu"
        @saveRouter="handleSave"
      />
    </div>
  </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils/validate'
import AppLink from './Link'
import { generateText } from '@/utils/aep'
import Cookies from 'js-cookie'
export default {
  name: 'ChildItem',
  components: { AppLink },
  props: {
    item: {
      type: Object,
      required: true
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data () {
    this.onlyOneChild = null
    return {
      activeClass: false
    }
  },
  watch: {
    $route (to, from) {
      this.activeClass = false
    }
  },
  methods: {
    handleSave () {
      this.$emit('saveRouter')
    },
    hasOneShowingChild (children = [], parent) {
      if (!children) {
        children = []
      }
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false
        } else {
          this.onlyOneChild = item
          return true
        }
      })

      if (showingChildren.length === 1) {
        return true
      }

      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath (routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    },
    titleFormat (item) {
      let lang = Cookies.get('lang')
      return (lang === 'zh-CN' ? item?.meta?.title : item?.meta?.titleEn) || generateText.call(this, 'm', item?.meta?.otherName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables';

.child-item {
  display: flex !important;
  height: 45px;
  padding-left: 12px;
  border-radius: 8px 0 0 8px;
  margin: 5px 0;
  font-size: 14px;
  color: #666;
  line-height: 45px;
  align-items: center;
  cursor: pointer;

  &::before {
    display: block;
    width: 8px;
    height: 8px;
    border: 1px solid #9f9f9f;
    border-radius: 100%;
    margin-right: 10px;
    content: '';
  }

  &:hover {
    background: -webkit-linear-gradient(left, #0a0c2d, blue); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #0a0c2d, #3450c9); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #0a0c2d, #3450c9); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #0a0c2d, #3450c9); /* 标准的语法 */
    color: #fff;

    &::before {
      border-color: #fff;
      background: #fff;
    }
  }
}

.router-link-active {
  background: -webkit-linear-gradient(left, #0a0c2d, blue); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(right, #0a0c2d, #3450c9); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(right, #0a0c2d, #3450c9); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right, #0a0c2d, #3450c9); /* 标准的语法 */
  color: #fff;

  &::before {
    border-color: #fff;
    background: #fff;
  }
}

.menu-item {
  .child-item {
    position: relative;

    i {
      position: absolute;
      right: 27px;
    }
  }

  .nest-menu {
    display: none;

    .child-item {
      padding-left: 30px;

      &::before {
        display: none;
      }
    }
  }
}

.active {
  border-radius: 8px 0 0 8px;

  .child-item {
    i {
      -ms-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);

      /* IE 9 */

      /* Firefox */

      /* Safari 和 Chrome */

      /* Opera */
    }
  }

  .nest-menu {
    display: block;
  }
}

.theme-light {
  .router-link-active {
    background: #f1f3f9;
    color: $mainColor;

    &::before {
      border-color: #456ff7;
      background: #456ff7;
    }
  }

  .child-item {
    color: #666;

    &:hover {
      background: #f1f3f9;
      color: #666;

      &::before {
        border-color: #456ff7;
        background: #456ff7;
      }
    }
  }
}
</style>
