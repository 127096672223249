/* eslint-disable no-useless-escape */
export function isExternal (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export function validEmail (str) {
  return (str && str.length > 2 && str.length < 30) && /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(str)
}

export function validMobile (str) {
  return /^1(3|4|5|6|7|8|9)\d{9}$/.test(str)
}

export function validCode (str) {
  return /^\d{4}$/.test(str)
}

// 数字,字母或组合
export function validateNumAndEn (val) {
  return /^[0-9a-zA-Z]*$/g.test(val)
}

// 字母数字下划线中横线
export function validvin (val) {
  return !/^[\w_-]+$/g.test(val)
}
