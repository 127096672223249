<template>
  <el-dialog
    :visible.sync="open"
    :title="$t('colSet.title')"
    width="700px"
    :close-on-click-modal="false"
    append-to-body
    custom-class="tc"
  >
    <el-row>
      <el-col class="header-cell" :span="10">{{ $t('colSet.name') }}</el-col>
      <el-col class="header-cell" :span="4">{{ $t('colSet.sort') }}</el-col>
      <el-col class="header-cell" :span="10">{{ $t('colSet.show') }}</el-col>
    </el-row>
    <div :id="'colSetting' + tableName">
      <el-row v-for="(row, index) in list" :key="row.code + index" class="row">
        <el-row>
          <el-col class="row-cell" :span="10" :title="row.name"> {{ row.name }}</el-col>
          <el-col class="row-cell" :span="4">
            <i class="sort-icon" />
          </el-col>
          <el-col class="row-cell" :span="10">
            <el-switch v-model="row.visible" />
          </el-col>
        </el-row>
        <template v-if="row.children">
          <el-row v-for="child in row.children" :key="child.code">
            <el-col class="row-cell" :span="10" :title="child.name">
              {{ child.name }}
            </el-col>
          </el-row>
        </template>
      </el-row>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" plain class="fl" @click="reset"> {{ $t('colSet.reset') }} </el-button>
      <el-button @click="close">
        {{ $t('common.cancel') }}
      </el-button>
      <el-button type="primary" @click="submit">
        {{ $t('common.confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { setCols } from '@/api/colSetting'
import Sortable from 'sortablejs'
export default {
  name: 'ColSetting',
  props: {
    visible: { type: Boolean, default: false }, // 弹窗显示
    tableName: { type: String, default: '' }, // 列表名称，用于绑定列信息
    customCols: { type: Array, default: () => [] }, // 自定义列的信息
    defaultCols: { type: Array, default: () => [] }// 初始列信息
  },
  data () {
    return {
      list: []
    }
  },
  computed: {
    open: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    open: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.list = this.cloneDeep(this.customCols).filter(item => !item.hidden) // 每次打开还原指最新保存配置
          this.startRowDrop()
        }
      }
    }
  },
  methods: {
    // 行拖拽
    startRowDrop () {
      document.body.ondrop = event => {
        event.preventDefault()
        event.stopPropagation()
      }
      this.$nextTick(() => { this.rowDrop() })
    },
    rowDrop () {
      const tbody = document.querySelector('#colSetting' + this.tableName)
      const _this = this
      Sortable.create(tbody, {
        animation: 150,
        chosenClass: 'sortable-chosen',
        onEnd ({ newIndex, oldIndex }) {
          const currRow = _this.list.splice(oldIndex, 1)[0]
          _this.list.splice(newIndex, 0, currRow)
        }
      })
    },
    reset () {
      this.list = this.cloneDeep(this.defaultCols).filter(item => !item.hidden)
    },
    submit () {
      setCols(this.tableName, this.list).then(res => {
        this.msgSuccess(this.$t('colSet.success'))
        this.$emit('refreshCols', this.list)
        this.close()
      })
    },
    close () {
      this.open = false
    }
  }
}
</script>
<style lang="scss" scoped>
.header-cell {
  border-bottom: 1px solid #e8e8e8;
  background-color: #f8f8f9;
  font-weight: 600;
  color: #515a6e;
}

.row {
  border-bottom: 1px solid #e8e8e8;
  cursor: move;
}

.header-cell,
.row-cell {
  overflow: hidden;
  height: 40px;
  padding: 0 8px;
  font-size: 13px;
  text-align: center;
  text-overflow: ellipsis;
  line-height: 40px;
  white-space: nowrap;
  word-break: break-word;
}
</style>
