<template>
  <div class="notice-card-wrapper">
    <div class="header">
      <div class="title">
        <div class="message">
          <div ref="innerNotice" class="inner-container" v-html="handleNotice" />
          <!-- <span>{{ handleNotice }}</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SystemNotice',
  props: {
    notice: {
      type: String,
      default: ''
    }
  },
  computed: {
    // 过滤图片
    handleNotice () {
      let con = this.notice
      let imgs = []
      con = con.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, function (match, capture) {
        imgs.push(`<a href="${capture}" target="_blank"></a>`) // capture图片地址 img标签中src内容   match // img标签整体
        return `<span style="cursor: pointer;color:#456ff7;"  onclick="textCariysekpPreView('${capture}')"   >【图片】</span>`
      })
      return con
    }
  },
  mounted () {
    const dom = this.$refs.innerNotice
    if (this.$refs.innerNotice) {
      const w = dom.offsetWidth
      const vw = document.body.offsetWidth - 200
      const time = 30
      let s = w <= vw ? time : Math.floor(time * w / vw)
      dom.style.animationDuration = `${s}s`
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.notice-card-wrapper {
  .inner-container {
    display: flex;
    width: 100%;
    margin-left: calc(100vw - 200px);
    white-space: nowrap;

    //animation: myMove 30s linear infinite;
    animation-name: myMove;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;

    * {
      display: inline-block !important;
    }

    &:hover {
      animation-play-state: paused;
    }
  }
  @keyframes myMove {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-100%);
    }
  }
}
</style>
