<template>
  <div>
    <el-table ref="table" :border="border" highlight-selection-row v-bind="$attrs" v-on="$listeners">
      <el-table-column
        v-if="hasSelection"
        type="selection"
        align="center"
        width="40"
        :reserve-selection="true"
        fixed="left"
      />
      <slot name="selection" />
      <template v-for="(col, index) in columns">
        <!--普通列 -->
        <TableColumn
          v-if="!col.children && (col.visible !== undefined ? col.visible : true)"
          :key="col.code + index + col.width"
          v-bind="col"
          @getTableFilter="getList"
        >
          <!-- 插槽透传 -->
          <template v-for="name in Object.keys($scopedSlots)" #[name]="{ scope }">
            <slot :name="name" :row="scope.row" :col="scope.column" :index="scope.$index" />
          </template>
        </TableColumn>

        <!--含子集列 -->
        <el-table-column
          v-if="col.children && (col.visible !== undefined ? col.visible : true)"
          :key="col.code + index + col.width"
          :label="col.otherName || col.name"
          :align="col.align"
          :width="col.width"
          show-overflow-tooltip
        >
          <TableColumn v-for="(child, i) in col.children" :key="child.code + i + child.width" v-bind="child">
            <!-- 插槽透传 -->
            <template v-for="name in Object.keys($scopedSlots)" #[name]="{ scope }">
              <slot :name="name" :row="scope.row" :col="scope.column" :index="scope.$index" />
            </template>
          </TableColumn>
        </el-table-column>
      </template>
      <!-- 操作栏 -->
      <el-table-column
        v-if="actions.length"
        :label="$t('common.action')"
        fixed="right"
        :min-width="actionMinWidth"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template #header>
          <span :title="$t('common.action')" class="table-title">{{ $t('common.action') }}</span>
          <el-tooltip v-if="showActionTip" popper-class="el-tooltip" :content="actionTip" placement="top">
            <i class="el-icon-warning-outline tip-icon ml5" />
          </el-tooltip>
        </template>
        <template slot-scope="{ row, $index }">
          <TipButton
            v-for="(btn, btnIndex) in actions.slice(0, actions.length > 3 ? 2 : 3)"
            :key="btn.name + btnIndex || btn.label"
            v-hasPermi="btn.permi"
            type="text"
            :disabled="btn.disabled && btn.disabled(row, $index)"
            :is-del="btn.isDel"
            :tip="btn.tip"
            @click="btn.click(row)"
          >
            {{ btn.name || $t(btn.label) }}
          </TipButton>
          <slot name="action" :row="row" :index="$index" />
          <el-dropdown v-if="actions.length > 3" class="ml0" trigger="hover">
            <el-button type="text">
              {{ $t('device.run.more') }}
              <i class="el-icon-arrow-down" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(btn, btnIndex) in actions.slice(2)"
                :key="btn.name + btnIndex || btn.label"
                v-hasPermi="btn.permi"
                :disabled="btn.disabled && btn.disabled(row, $index)"
                class="table-action-drop-item"
                :class="[{ 'table-action-drop-del-item': btn.isDel }]"
                @click.native.stop="btn.click(row)"
              >
                {{ btn.name || $t(btn.label) }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :pagercount="pagerCount"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>
<script>
import TableColumn from './TableColumn'
export default {
  name: 'Table',
  components: { TableColumn },
  props: {
    hasSelection: { type: Boolean, default: false }, // 是否存在复选框
    actionMinWidth: { type: Number, default: 100 }, // 操作栏最小宽度
    border: { type: Boolean, default: true },
    total: { type: Number, default: 0 }, // 翻页场景的总行数
    queryParams: { type: Object, default: () => ({}) }, // 查询参数
    columns: { type: Array, default: () => [] }, // 列信息
    actions: { type: Array, default: () => [] }, // 操作栏信息
    showActionTip: { type: Boolean, default: false }, // 是否展示操作栏Tip
    actionTip: { type: String, default: null }, // 操作栏Tip
    pagerCount: { type: [String, Number], default: 7 } // 页码按钮的数量
  },
  methods: {
    getList (data) {
      let form = this.queryParams
      form[data.filterCode] = data.filterValue
      this.$emit('getList', form)
    }
  }
}
</script>
