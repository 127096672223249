<template>
  <div
    :class="settings.sideTheme === 'theme2' || settings.sideTheme === 'theme4' ? 'theme-dark' : 'theme-light'"
    :style="{
      backgroundColor:
        settings.sideTheme === 'theme2' || settings.sideTheme === 'theme4'
          ? themeMixinVars.menuBg
          : themeMixinVars.menuLightBg
    }"
    class="navbar"
  >
    <!--list 弹窗-->
    <TaskRunData
      v-if="taskRunVisible"
      ref="addDialog"
      :task-run-data="taskRunList"
      :title="$t('task.running')"
      :visible.sync="taskRunVisible"
    />
    <div class="tags-view fl">
      <TagsView />
    </div>

    <div class="right-menu">
      <!-- 文档中心 -->
      <div v-if="docUrl && confInfo.helpDoc === 1" class="panel">
        <a :href="docUrl" style="font-size: 22px; color: inherit" target="_blank">
          <i class="el-icon-document" />
        </a>
      </div>

      <!-- 站内信 -->
      <div v-hasPermi="['system:message']" class="panel notify" @click="$router.push({ name: 'Letter' })">
        <el-badge :hidden="UnReadCount === 0" :max="99" :value="UnReadCount">
          <i class="iconfont icontongzhi" />
        </el-badge>
      </div>

      <!-- 语言 -->
      <div v-if="showLangChange" class="panel">
        <el-dropdown class="lang" trigger="click" @command="changeLang">
          <span style="font-size: 20px">
            <svg-icon icon-class="language" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh-CN">简体中文</el-dropdown-item>
            <el-dropdown-item command="en-US">English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <!-- 大屏 -->
      <div class="panel data-screens">
        <el-dropdown>
          <span class="el-dropdown-link">
            <a v-if="dataViewPermissonList?.length" class="full-screen flex-center" type="text">
              <img alt="" src="../../../app/assets/images/dashboardCharts/icon.gif" />
            </a>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in dataViewPermissonList"
              :key="index"
              :command="item.command"
              @click.native.stop="toScreen(item.command, item)"
            >
              {{ item.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <!-- 个人信息-->
      <div class="panel">
        <el-dropdown class="avatar-container hover-effect" trigger="click">
          <div class="avatar-wrapper">
            <!--          <img :src="avatar" class="user-avatar" />-->
            <div class="user-avatar" style="font-size: 0">
              <el-image :src="avatar" fit="cover" style="width: 24px; height: 24px; border-radius: 100%">
                <div slot="error" class="flex-center" style="width: 100%; height: 100%; background: #eee">
                  <i class="el-icon-user" style="font-size: 18px" />
                </div>
              </el-image>
            </div>
            <span class="user-name">{{ name }}</span>
            <i class="el-icon-arrow-down user-icon" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-hasPermi="['user:profile']" @click.native="handleDeploy('PersonalCenter')">
              {{ $t('navbar.personal') }}
            </el-dropdown-item>
            <el-dropdown-item
              v-if="pan === true && (orgtype === 1 || orgtype === 2)"
              v-hasPermi="['user:company']"
              @click.native="handleDeploy('CompanySetting')"
            >
              {{ $t('navbar.company') }}
            </el-dropdown-item>
            <el-dropdown-item v-hasPermi="['user:log']" @click.native="handleDeploy('AuditLog')">
              {{ $t('navbar.auditLog') }}
            </el-dropdown-item>
            <el-dropdown-item divided @click.native="logout">
              <span>{{ $t('navbar.logout') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <!-- 主题风格设置 -->
      <div class="panel theme" @click.stop="setting = true">
        <i class="el-icon-more" style="font-size: 18px" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TaskRunData from './TaskRunData'
import TagsView from '../TagsView'
import { getUnReadCount } from '@/api/login'
import ThemeMixin from '@/components/mixins/theme'
import getConfInfo from '@/views/system/user/profile/getConfInfo'
import getDataView from '@/views/system/screenManage/getDataView'
export default {
  components: {
    TaskRunData,
    TagsView
  },
  mixins: [ThemeMixin, getConfInfo, getDataView],
  inject: ['adminReload'],
  data () {
    return {
      taskRunVisible: false,
      // 定时（进行中的：如果返回的数据不为空，要一直轮询调用接口，更新进度。每3秒轮询一次）
      timer: null,
      taskRunList: [],
      chartData: 0,
      // 成功数
      successNum: 0,
      // 失败数
      failNum: 0,
      // 总数
      totalNum: 0,

      // 语言切换按钮展示
      showLangChange: process.env.NODE_ENV === 'development',
      docUrl: this.$store.state.env.VUE_APP_DOC_URL || '',
      confInfo: {}
      // dataViewPermissonList: []
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'device',
      'name',
      'pan',
      'orgtype',
      'userId',
      'tenantId',
      'taskRunData',
      'UnReadCount',
      'orgId'
    ]),
    ...mapState(['settings', 'screenList']),
    setting: {
      get () {
        return this.$store.state.settings.showSettings
      },
      set (val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
      }
    },
    dataViewPermissonList: {
      get () {
        return this.$store.state.user.screenList
      },
      set (val) {
        this.$store.dispatch('DataView', val)
      }
    }
  },
  created () {
    if (!!this.taskRunData && this.taskRunData.length > 0) {
      this.taskRun()
    }
    this.getConfInfo()
    this.getDataView()
    this.getUnReadCount()
  },
  methods: {
    toScreen (e, info) {
      switch (e) {
        case 'common':
          this.toCommonScreen()
          break
        case 'yhs':
          this.toFullScreen()
          break
        case 'screenManage':
          this.toDaTan('VIEW', info)
          break
        default:
          break
      }
    },
    // 通用大屏
    toCommonScreen () {
      const router = this.$router.resolve({
        path: '/commonScreen',
        query: { tenantId: this.tenantId }
      })
      window.open(router.href, '_blank')
    },
    // 英汉思
    toFullScreen () {
      // eslint-disable-next-line no-case-declarations
      const router = this.$router.resolve({
        path: '/dataScreen'
      })
      window.open(router.href, '_blank')
    },
    async getUnReadCount () {
      const response = await getUnReadCount()
      if (response.data !== undefined) {
        this.$store.dispatch('getUnReadCount', response.data)
      }
    },
    async logout () {
      this.$confirm(this.$t('common.logout'), this.$t('common.prompt'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.href = '/login'
        })
      })
    },
    // 切换语言
    changeLang (command) {
      this.$i18n.locale = command
      this.$store.dispatch('app/setLanguage', command)
      this.adminReload()
    },
    /** 个人中心、企业配置、审计日志 */
    handleDeploy (name) {
      this.$router.push({ name })
    },
    // 如果有执行中的任务调用此方法
    taskRun () {
      this.taskRunList = this.taskRunData
      this.timer = setInterval(() => {
        this.$store.dispatch('GetTaskRunning').then(res => {
          if (res.data && res.data.length > 0 && this.chartData < 1) {
            this.taskRunList = res.data
            this.successNum = 0
            this.failNum = 0
            this.totalNum = 0
            this.taskRunList.forEach(item => {
              this.successNum += item.successNum
              this.failNum += item.failNum
              this.totalNum += item.totalNum
              this.chartData = (this.successNum + this.failNum) / this.totalNum
            })
          } else {
            clearInterval(this.timer)
          }
        })
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  position: relative;
  display: flex;
  height: 50px;
  background: #fff;
  justify-content: space-between;

  .tags-view {
    position: relative;
    height: 100%;
    flex: 1;
  }

  .right-menu {
    display: flex;
    float: right;
    max-width: 500px;
    height: 100%;
    font-size: 0;
    justify-content: flex-end;
    align-items: center;

    .panel {
      display: flex;
      height: 36px;
      padding: 0 12px;
      border-radius: 4px;
      margin: 0;
      color: #525252;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 0;
      cursor: pointer;

      &:hover {
        color: #000;
      }
    }
  }
}

.notify {
  .icontongzhi {
    // UI提供的icon尺寸偏小 需特殊处理
    font-size: 24px;
  }

  /deep/ .el-badge__content.is-fixed {
    top: 10px;
    height: auto;
  }
}

.lang {
  color: inherit;
}

.data-screens {
  padding: 0;

  .full-screen {
    height: 24px;
    cursor: pointer;

    i {
      margin-left: 10px;
      color: #0f0f14;
      font-size: 14px;
      font-weight: 600;
    }
  }
  img {
    width: 84px;
    height: 28px;
  }
}

.avatar-container {
  padding-right: 8px;

  &.hover-effect {
    transition: background 0.3s;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  &::after {
    display: block;
    clear: both;
    content: '';
  }

  .avatar-wrapper {
    position: relative;
    display: flex;
    float: right;
    height: 100%;
    align-items: center;

    .user-avatar {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      cursor: pointer;
    }

    .user-name {
      margin-left: 10px;
      font-size: 14px;
    }

    .user-icon {
      margin-left: 10px;
      font-size: 12px;
      cursor: pointer;
    }
  }
}

.theme {
  cursor: pointer;

  i {
    font-size: 16px;
    transform: rotate(90deg);
  }
}

.theme-dark {
  .panel {
    color: #ccc !important;

    &:hover {
      color: #fff !important;
    }
  }

  .user-name,
  .user-icon {
    color: #fff !important;
  }

  .theme {
    i {
      color: #fff;
    }
  }
}
</style>
