import Cookies from 'js-cookie'
import { uniqBy } from 'lodash-es'
import { getStamp } from '@/utils/date'
const util = {}

/**
 * 数字转成汉字
 * @params num === 要转换的数字
 * @return 汉字
 * */
util.toChinesNum = (n) => {
  if (!Number.isInteger(n) && n < 0) {
    throw Error('请输入自然数')
  }
  const digits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  const positions = ['', '十', '百', '千', '万', '十万', '百万', '千万', '亿', '十亿', '百亿', '千亿']
  const charArray = String(n).split('')
  let result = ''
  let prevIsZero = false
  // 处理0  deal zero
  for (let i = 0; i < charArray.length; i++) {
    const ch = charArray[i]
    if (ch !== '0' && !prevIsZero) {
      result += digits[parseInt(ch)] + positions[charArray.length - i - 1]
    } else if (ch === '0') {
      prevIsZero = true
    } else if (ch !== '0' && prevIsZero) {
      result += '零' + digits[parseInt(ch)] + positions[charArray.length - i - 1]
    }
  }
  // 处理十 deal ten
  if (n < 100) {
    result = result.replace('一十', '十')
  }
  return result
}

/**
 * 深拷贝
 * @obj any
 * @return obj
 * */
util.deepCopy = (obj) => {
  if (obj instanceof Object) {
    const newObj = {}
    if (Array.isArray(obj)) {
      const arr = []
      obj.forEach(item => {
        arr.push(util.deepCopy(item))
      })
      return arr
    } else {
      for (const key in obj) {
        const value = obj[key]
        if (typeof value === 'function') {
          newObj[key] = value.bind(newObj)
        } else if (typeof value === 'object') {
          if (Array.isArray(value)) {
            newObj[key] = []
            value.forEach(item => {
              newObj[key].push(util.deepCopy(item))
            })
          } else {
            newObj[key] = util.deepCopy(value)
          }
        } else {
          newObj[key] = value
        }
      }
    }
    return newObj
  } else {
    return obj
  }
}

/**
 * 根据code获取物模型对应属性
 * @param {*} tsl 物模型
 * @param {*} code 唯一标识
 * @returns
 */
util.getTslByCode = (tsl, code) => {
  let array = tsl ? [...tsl] : []
  let temp = null
  function findId (t, c) {
    let arr = t ? [...t] : []
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index]
      if (element.code === code) {
        temp = { ...element }
        return
      } else if (element.specs && element.specs.length) {
        findId(element.specs, code)
      }
    }
  }
  findId(array, code)
  return temp
}
/**
 * 根据物模型枚举值获取prop值
 * @param {*} specs
 * @param {*} value
 */
util.getENUMBySpecValue = (tsl, value, prop = 'name') => {
  let val = null
  if (tsl?.dataType === 'ENUM') {
    tsl.specs.forEach(item => {
      if (item.value === value) {
        val = item[prop]
      }
    })
  }
  return val
}
/**
 * 根据物模型获取单位
 * @param {*} specs
 * @param {*} value
 */
util.getUnitbyTsl = (tsl) => {
  let val = null
  if (tsl?.specs && tsl?.specs[0]) {
    val = tsl?.specs[0].unit
  }

  return val
}

util.isEmpty = (obj) => {
  if (typeof obj === 'undefined' || obj === null || obj.toString().trim() === '') {
    // alert("null or undefined or 空字符串");
    return true
  }
  return false
}

/**
 * 保留两位小数
 * @param num
 * @param isRate 是否为率
 * @return {*}
 */
util.parseNum = function (num, isRate, decimal, isZeroRateToGangGang) {
  decimal = decimal || decimal === 0 ? decimal : 2
  if (util.isNumber(num)) {
    if (util.isInteger(num)) {
      if (isRate) {
        if (isZeroRateToGangGang && ComputeTool.add(num, 0) === 0) {
          return '--'
        } else {
          return (ComputeTool.add(num, 0) * 100).toFixed(decimal) + '%'
        }
      } else {
        return num
      }
    } else {
      if (isRate) {
        if (isZeroRateToGangGang && ComputeTool.add(num, 0) === 0) {
          return '--'
        } else {
          return (ComputeTool.add(num, 0) * 100).toFixed(decimal) + '%'
        }
      } else {
        return Number(ComputeTool.add(num, 0).toFixed(decimal))
      }
    }
  } else {
    // 1/0
    if (num === 'Infinity' || num == null) {
      return '--'
    }
    return num
  }
}

/**
 * 判断是否为整数
 * @param obj
 * @return {boolean}
 */
util.isInteger = function (obj) {
  return Math.floor(obj) === obj
}

const ComputeTool = {}
/**
 * 按指定键值排序列表，默认不传asc是降序排序。
 * @param list 列表 [{age: 1}, {age: 2}, {age: 1.5}]
 * @param key 键值
 * @param asc 排序后的列表
 */
ComputeTool.sortByKey = function (list, key, asc) {
  list = list.slice(0)
  asc = asc || false
  return asc
    ? list.sort(ComputeTool.compare(key)).reverse()
    : list.sort(ComputeTool.compare(key))
}

// 浮点数计算
/**
 * 加法
 * @param arg1
 * @param arg2
 * @return {number}
 */
ComputeTool.add = function (arg1, arg2) {
  let r1, r2, m, c
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  c = Math.abs(r1 - r2)
  m = Math.pow(10, Math.max(r1, r2))
  if (c > 0) {
    const cm = Math.pow(10, c)
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace('.', ''))
      arg2 = Number(arg2.toString().replace('.', '')) * cm
    } else {
      arg1 = Number(arg1.toString().replace('.', '')) * cm
      arg2 = Number(arg2.toString().replace('.', ''))
    }
  } else {
    arg1 = Number(arg1.toString().replace('.', ''))
    arg2 = Number(arg2.toString().replace('.', ''))
  }
  return (arg1 + arg2) / m
}

/**
 * 比较方法
 * @param property 键值
 * @return {Function} 比较结果
 */
ComputeTool.compare = function (property) {
  return function (a, b) {
    const value1 = a[property]
    const value2 = b[property]
    let result = 0
    // 百分数计算
    if (
      (typeof value1 === 'string' && value1.indexOf('%') > -1) ||
      (typeof value2 === 'string' && value2.indexOf('%') > -1)
    ) {
      result = parseFloat(value2) - parseFloat(value1)
    } else {
      result = value2 > value1 ? 1 : value2 < value1 ? -1 : 0
    }
    return result
  }
}

/**
 * 判断是否有值
 * @param value
 * @return {boolean}
 */
ComputeTool.isValue = function (value) {
  let result = true
  if (!value || value === '-') {
    result = false
  }
  return result
}

// 数字格式化，千分符  12,345.67
util.toThousands = function (num) {
  if (isNaN(num) || typeof num === 'string') {
    return num
  }

  num = (num || 0).toString()
  let result = ''
  let symbol = ''
  const numArr = num.split('.')
  num = numArr[0]
  if (num[0] === '-') {
    num = num.substr(1)
    symbol = '-'
  }
  while (num.length > 3) {
    result = ',' + num.slice(-3) + result
    num = num.slice(0, num.length - 3)
  }
  if (num) {
    result = num + result
  }
  if (numArr.length > 1) {
    result += '.' + numArr[1]
  }
  return symbol + result
}

/**
 * 判断数组是否包含该对象
 * arr 搜索数据 [1,2,3]   [{id:1},{id:2},{id:3}]
 * ele 搜索对象 2 {id:1}
 * prop 如果需要根据id 就算'id'
 */
util.arrIncludesByEle = function (arr, ele, prop) {
  // Object.prototype.toString.call(obj) === '[object Object]'
  arr = arr || []
  ele = ele || ''
  // 是否包含状态
  let flag = false
  // 对应数组的ele
  let obj = null
  // 搜索对象是object的话 进入复杂方法
  if (Object.prototype.toString.call(ele) === '[object Object]') {
    // 如果根据prop搜索
    arr.forEach((item) => {
      if (prop) {
        // 有prop只比较prop
        if (item[prop] === ele[prop]) {
          flag = true
          obj = { ...item }
        }
      } else {
        // 没有prop整个值比较
        if (JSON.stringify(item) === JSON.stringify(ele)) {
          flag = true
          obj = { ...item }
        }
      }
    })
  } else {
    // 普通方法直接includes
    flag = arr.includes(ele)
    obj = ele
  }
  return { flag, obj }
}
/**
 * 判断是否为数字
 * @param num
 * @return {boolean}
 */
util.isNumber = function (num) {
  const number = +num

  if (number - number !== 0) {
    // Discard Infinity and NaN
    return false
  }

  if (number === num) {
    return true
  }

  if (typeof num === 'string') {
    // String parsed, both a non-empty whitespace string and an empty string
    // will have been coerced to 0. If 0 trim the string and see if its empty.
    if (number === 0 && num.trim() === '') {
      return false
    }
    return true
  }
  return false
}

// 树变列表
util.treeTolist = function (tree) {
  const list = []
  const queue = [...tree]
  while (queue.length) {
    const node = queue.shift()
    const children = node.children
    if (children) {
      queue.push(...children)
    }
    list.push(node)
  }
  return list
}

// toTree 数组变树
util.listToTree = function (list, idProp = 'id', pidProp = 'pid') {
  const tree = []

  for (const node of list) {
    // 如果没有pid就可以认为是根节点
    if (!node[pidProp]) {
      let p = { ...node }
      p.children = getChildren(p[idProp], list)

      tree.push(p)
    }
  }
  function getChildren (id, list) {
    const children = []
    for (const node of list) {
      if (node[pidProp] === id) {
        children.push(node)
      }
    }

    for (const node of children) {
      const children = getChildren(node[idProp], list)
      if (children.length) {
        node.children = children
      }
    }

    return children
  }

  return tree
}

// 封装方法:根据提供的属性去重 数组根据prop去重
util.arrDistinctByProp = function (arr, prop) {
  return uniqBy(arr, prop)
}

//
util.arrayFindLabelByValue = function (arr, value, valueProp = 'dictValue', labelProp = 'dictLabel') {
  let str = ''
  let idx = null
  if (arr.length <= 0) { return str }
  idx = arr.findIndex(ele => {
    return ele[valueProp] === value
  })
  if (idx !== -1) {
    str = arr[idx][labelProp]
    return str
  }
}
/**
 * 解决Vue Template模板中无法使用可选链的问题
 * @param obj
 * @param rest
 * @returns {*}
 */
util.optionalChaining = (obj, ...rest) => {
  let tmp = obj
  for (let key in rest) {
    let name = rest[key]
    tmp = tmp?.[name]
  }
  return tmp || ''
}

/**
 * 生成随机ID
 * @param {object} min 最小长度
 * @param {object} max 最大长度
 * @return {String} 随机字符串(由二十六个大写英文字母和0-9组成)
 */
util.randomRange = (min, max) => {
  let returnStr = ''
  const range = max ? Math.round(Math.random() * (max - min)) + min : min
  const charStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  for (let i = 0; i < range; i++) {
    let index = Math.round(Math.random() * (charStr.length - 1))
    returnStr += charStr.substring(index, index + 1)
  }
  return returnStr
}

util.formatDate = (date, fmt) => {
  fmt = fmt || 'yyyy-MM-dd hh:mm:ss'
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  // eslint-disable-next-line no-unused-vars
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length)
      )
    }
  }
  return fmt
}
/**
 * 获取机构树对应第一个值
 * @param {*} tree 树数组
 * @param {*} isChildren 是否子级第一个值
 */
util.getOrgTreeDefaultValue = (tree, isChildren) => {
  let val = null
  if (tree?.length) {
    val = tree[0].id
    if (isChildren) {
      val = tree[0]?.children?.length ? tree[0]?.children[0].id : null
    }
  }
  return val
}

/**
 * 判断是否为整形
 */
util.isInteger = function (obj) {
  return typeof obj === 'number' && obj % 1 === 0
}

/**
 * 处理物模型数据
 * 1、添加defaultValue push值的时候添加
 * 2、生成custCode 一般用于表单校验
 * 3、处理boolean类型specs里面value为字符串问题
 */
util.handleTsl = (senData) => {
  if (!senData?.length) return []
  senData = senData.map(ele => {
    ele.defaultValue = null
    // int code 全部转换成string
    if (util.isInteger(ele.code)) {
      ele.code = ele.code.toString()
    }
    ele.custCode = ele.code

    if (ele.dataType === 'STRUCT') {
      ele.specs.forEach(element => {
        if (util.isInteger(element.code)) {
          element.code = element.code.toString()
        }
        element.custCode = `${ele.code}.${element.code}`
        element = handleNoramlSpecs(element)
      })
      ele.defaultValue = util.getDefaultValueByTSLItem(ele)
    } else if (ele.dataType === 'ARRAY') {
      if (ele.specs[0].dataType === 'STRUCT') {
        ele.specs[0].specs.forEach(element => {
          if (util.isInteger(element.code)) {
            element.code = element.code.toString()
          }
          element = handleNoramlSpecs(element)
        })
      }
    } else {
      // 普通类型
      ele = handleNoramlSpecs(ele)
    }
    ele.defaultValue = util.getDefaultValueByTSLItem(ele)

    /**
     * 对普通类型TSL Item做specs处理
     * @param {*} tslItem
     */
    function handleNoramlSpecs (tslItem) {
      if (tslItem.dataType === 'BOOL') {
        // 返回的upvalue是boolean 但是spec里面是string 要做转换
        tslItem.specs = tslItem.specs.map(e => {
          e.value = e.value === 'true'
          return e
        })
      } else if (tslItem.dataType === 'INT' || tslItem.dataType === 'FLOAT' || tslItem.dataType === 'DOUBLE') {
        tslItem.specs[0].min = tslItem.specs[0].min ? parseFloat(tslItem.specs[0].min) : tslItem.specs[0].min
        tslItem.specs[0].max = tslItem.specs[0].max ? parseFloat(tslItem.specs[0].max) : tslItem.specs[0].max
        // 默认步长为1
        tslItem.specs[0].step = tslItem.specs[0].step ? parseFloat(tslItem.specs[0].step) : 1
      } else if (tslItem.dataType === 'TEXT') {
        tslItem.maxLength = tslItem.specs[0].length ? parseInt(tslItem.specs[0].length) : tslItem.specs[0].length
      }
      return tslItem
    }
    return ele
  })

  return senData
}

/**
 * 传入tsl item 获取对应默认值
 */
util.getDefaultValueByTSLItem = (tslItem) => {
  let defaultValue = null
  if (tslItem.dataType === 'STRUCT') {
    defaultValue = {}
    tslItem.specs.forEach(element => {
      defaultValue[element.code] = getNormal(element)
    })
  } else if (tslItem.dataType === 'ARRAY') {
    if (tslItem.specs[0].dataType === 'STRUCT') {
      defaultValue = [{}]
      tslItem.specs[0].specs.forEach(element => {
        defaultValue[0][element.code] = getNormal(element)
      })
    } else {
      // 普通数组 + 数值类型需要特殊处理
      if (tslItem.specs[0].dataType === 'INT' || tslItem.specs[0].dataType === 'FLOAT' || tslItem.specs[0].dataType === 'DOUBLE') {
        defaultValue = [0]
      } else {
        defaultValue = [getNormal(tslItem.specs[0])]
      }
    }
  } else {
    defaultValue = getNormal(tslItem)
  }
  // 普通类型处理
  function getNormal (ele) {
    let defau = null
    if (ele.dataType === 'BOOL') {
      defau = true
    } else if (ele.dataType === 'INT' || ele.dataType === 'FLOAT' || ele.dataType === 'DOUBLE') {
      // 数值默认值 没有值就默认0 有值就是min值
      defau = ele.specs[0]?.min || 0
    } else if (ele.dataType === 'ENUM') {
      defau = ele.specs[0].value
    } else if (ele.dataType === 'TEXT') {
      defau = ''
    } else if (ele.dataType === 'DATE') {
      // 日期默认值选当前时间戳
      defau = getStamp(new Date().getTime())
    }
    return defau
  }
  return defaultValue
}

/**
 * 获取当前路由的title
 * @param {*} route
 * @returns
 */
util.getMenuTitle = function (route) {
  return Cookies.get('lang') === 'zh-CN' ? route?.meta?.title : route?.meta?.titleEn
}

export default util
