<template>
  <div
    :style="{
      height: '100%',
      width: variables.sideBarWidth
    }"
  >
    <logo />
    <main-menu />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Logo from './Logo'
import MainMenu from './MainMenu'
import variables from '@/assets/styles/variables.scss'

export default {
  components: { Logo, MainMenu },
  computed: {
    ...mapState(['settings']),
    ...mapGetters(['sidebarRouters', 'sidebar']),
    activeMenu () {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    variables () {
      return variables
    }
  },
  data () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.app-name {
  position: relative;
  display: flex;
  z-index: 1;
  height: 30px;
  margin-top: -13px;
  margin-bottom: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;

  span {
    padding: 3px 5px;
    border: 1px solid #fff;
    font-size: 12px;
    color: #fff;
  }
}
</style>
