export default {
  state: {
    VUE_APP_ENV_TYPE: null,
    VUE_APP_ENV: null,
    VUE_APP_BASE_API: null,
    VUE_APP_DOC_URL: null
  },
  mutations: {
    SET_ENV_INFO: (state) => {
      let env = process.env
      let {
        VUE_APP_ENV_TYPE,
        VUE_APP_ENV,
        VUE_APP_BASE_API,
        VUE_APP_DOC_URL
      } = env
      state.VUE_APP_ENV_TYPE = VUE_APP_ENV_TYPE
      state.VUE_APP_ENV = VUE_APP_ENV
      state.VUE_APP_BASE_API = VUE_APP_BASE_API
      state.VUE_APP_DOC_URL = VUE_APP_DOC_URL
    }
  },
  actions: {
    getEnvInfo ({ commit }) {
      commit('SET_ENV_INFO')
    }
  }
}
