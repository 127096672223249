<template>
  <div class="top-right-btn">
    <el-row>
      <el-tooltip
        v-if="showQuery"
        popper-class="q-tooltip"
        class="item"
        :content="$t('rightToolbar.' + (showSearch ? 'hideSearch' : 'showSearch'))"
        placement="top"
      >
        <el-button size="mini" circle icon="el-icon-search" @click="toggleSearch()" />
      </el-tooltip>
      <el-tooltip
        v-if="showRefresh"
        popper-class="q-tooltip"
        class="item"
        :content="$t('rightToolbar.refresh')"
        placement="top"
      >
        <el-button size="mini" circle icon="el-icon-refresh" @click="$emit('queryTable')" />
      </el-tooltip>
      <el-tooltip v-if="customCols" popper-class="q-tooltip" class="item" :content="$t('colSet.title')" placement="top">
        <el-button size="mini" circle icon="el-icon-menu" @click="showColumn()" />
      </el-tooltip>
    </el-row>
    <ColSetting
      v-if="firstShow"
      :table-name="tableName"
      :visible.sync="open"
      :default-cols="defaultCols"
      :custom-cols="customCols"
      @refreshCols="$emit('refreshCols', $event)"
    />
  </div>
</template>
<script>
import ColSetting from '@/components/ColSetting'
export default {
  name: 'RightToolbar',
  components: { ColSetting },
  props: {
    showSearch: { type: Boolean, default: true },
    showRefresh: { type: Boolean, default: true },
    showQuery: { type: Boolean, default: true },
    tableName: { type: String, default: '' },
    defaultCols: { type: Array, default: () => [] },
    customCols: { type: Array, default: () => [] }
  },
  data () {
    return {
      open: false,
      firstShow: false
    }
  },
  methods: {
    // 搜索
    toggleSearch () {
      this.$emit('update:showSearch', !this.showSearch)
    },
    // 打开显隐列dialog
    showColumn () {
      this.open = true
      this.firstShow = true
    }
  }
}
</script>
