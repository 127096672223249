import store from '@/store'
import * as dayjs from 'dayjs'
const utc = require('dayjs/plugin/utc')
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(utc)
dayjs.extend(customParseFormat)

/**
 * 将偏移量字符串转化为带符号分钟数
 * @param {string} offset 偏移量
 * @returns {number} 带符号分钟数
 * @private
 */
export function _offset2minutes (offset) {
  // TODO 添加正则校检
  // 获取分隔符位置
  const indexOfSplit = offset.lastIndexOf(':')
  // 小时段
  const strHours = offset.substring(indexOfSplit - 2, indexOfSplit)
  // 分钟段
  const strMinutes = offset.substring(indexOfSplit + 1)
  // 标识
  const isNegative = offset[0] === '-'
  // 计算总分钟数
  const minutes = Number(strHours) * 60 + Number(strMinutes)
  // TODO 校检时区偏移量是否合法 通常是大于30的

  return isNegative ? minutes * (-1) : minutes
}

/**
 * @desc 获取日期对象
 * @desc 不改变时间，只基于个人中心用户做时区偏移
 * @param {number} stamp 时间戳
 * @returns {dayjs.Dayjs} 基于偏移的日期
 */
export function getUserZoneTime (stamp) {
  const userOffset = store.state.app.offset
  const offsetMinutes = _offset2minutes(userOffset)
  return dayjs.utc(stamp).utcOffset(offsetMinutes)
}

// 保留本地时间字符 只改变偏移量
export function keepLocalTime (stampOrIsoString) {
  const userOffset = store.state.app.offset
  const offsetMinutes = _offset2minutes(userOffset)
  // 2023-12-31T00:00:00+08:00
  const localTime = dayjs(stampOrIsoString)
  // 2023-12-31T00:00:00-09:00
  return localTime.utcOffset(offsetMinutes, true)
}

/**
 * @desc 使用字符串+format 创建基于UserZone的实例
 * @desc 与element使用的format标准不同
 * @param {string} str 时间日期字符
 * @param {string} format 参考 https://day.js.org/docs/en/parse/string-format
 * @returns {number} 所生成的时间戳
 */
export function createUserZoneTime (str, format = 'YYYY-MM-DD HH:mm:ss') {
  const userOffset = store.state.app.offset
  const offsetMinutes = _offset2minutes(userOffset)
  const time = dayjs(str, format).utcOffset(offsetMinutes, true)
  return time.valueOf()
}

export function formatTimeClient (stampOrIsoString, format = 'YYYY-MM-DD HH:mm:ss') {
  const time = dayjs(stampOrIsoString)
  return time.format(format)
}
