<template>
  <div>
    <div class="flex">
      <el-select v-model="selectedCode" :placeholder="$t('power.countryCode')" filterable @change="handleCodeChange">
        <el-option
          v-for="(item, index) in filteredOptions"
          :key="item.phone_code + item.country_code + index"
          :label="`+${item.phone_code} (${lang === 'zh-CN' ? item.chinese_name : item.english_name})`"
          :value="item.phone_code"
        />
      </el-select>
      <el-input
        v-model="localPhone"
        class="m-l-4"
        :placeholder="ph"
        :disabled="!selectedCode"
        :minlength="5"
        :maxlength="20"
        clearable
        @change="handleCodeChange"
      />
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  props: {
    placeholder: { type: String, default: '' },
    phoneCode: { type: String, default: '' },
    phone: { type: String, default: '' }
  },
  data () {
    return {
      selectedCode: this.phoneCode || '86',
      phoneList: require('@/assets/json/phoneCode.json'),
      localPhone: this.phone,
      lang: Cookies.get('lang'),
      searchInput: ''
    }
  },
  computed: {
    ph () { return this.placeholder || this.$t('place.enterPhone') },
    filteredOptions () {
      if (this.searchInput) {
        const inputLower = this.searchInput.toLowerCase()

        return this.phoneList.filter(item => {
          if (this.lang === 'zh-CN') {
            return (
              item.phone_code.includes(inputLower) ||
              item.chinese_name.includes(inputLower)
            )
          } else {
            return (
              item.phone_code.includes(inputLower) ||
              item.english_name.includes(inputLower)
            )
          }
        })
      } else {
        return this.phoneList
      }
    }
  },
  watch: {
    phoneCode (newPhoneCode) {
      this.selectedCode = newPhoneCode
    },
    phone (newPhone) {
      this.localPhone = newPhone
    }
  },
  methods: {
    handleCodeChange () {
      this.$emit('getPhone', { phoneCode: this.selectedCode, phone: this.localPhone })
    }
  }
}
</script>
