/**
 * 操作权限处理
 */

import store from '@/store'

export default {
  inserted (el, binding, vnode) {
    const permissions = store.getters && store.getters.orgPermissionType
    if (permissions !== 1 && permissions !== 2) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
}
