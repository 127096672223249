import request from '@/utils/request'

// 登录方法
export function login (userName, password, uuid, systemDomain, random) {
  const data = {
    userName,
    password,
    uuid,
    systemDomain,
    random
  }
  return request({
    url: '/sysuser/login/pwdAuth',
    method: 'post',
    data: data
  })
}

// 退出方法
export function logout () {
  return request({
    url: '/sysuser/onlineUser/out',
    method: 'get'
  })
}

// 获取dmp授权登录地址
export function oauthDmp (query) {
  return request({
    url: '/sysuser/login/dmpRender',
    method: 'get',
    params: query
  })
}

/**
 * DMP授权登录
 * @param query.code dmp授权code
 * @param query.systemDomain 域名
 * @returns {Promise}
 */
export function dmpAuth (query) {
  return request({
    url: '/sysuser/login/dmpAuth',
    method: 'get',
    params: query
  })
}

// 根据域名查询OEM
export function getLoginPageConfInfo (query) {
  return request({
    url: '/system/company/conf/getLoginPageConfInfo',
    method: 'get',
    params: query
  })
}

// 确认隐私协议
export function readClause () {
  return request({
    url: '/sysuser/user/readClause',
    method: 'put'
  })
}

// DMP授权注册校验
export function registerCheck (data) {
  return request({
    url: '/sysuser/login/registerCheck',
    method: 'post',
    data: data
  })
}

// DMP授权注册登录
export function registerLogin (data) {
  return request({
    url: '/sysuser/login/registerLogin',
    method: 'post',
    data: data
  })
}

// 刷新token
export function tokenRefresh (query) {
  return request({
    url: '/sysuser/login/refresh/token',
    method: 'get',
    params: query
  })
}

// 检测是否服务升级
export function maintenanceGet (query) {
  return request({
    url: '/maintenance/get',
    method: 'get',
    params: query
  })
}

// 获取当前用户的平台个性化配置
export function getCurUserPlatformOemConf (query) {
  return request({
    url: '/system/company/conf/getCurUserPlatformOemConf',
    method: 'get',
    params: query
  })
}

// 获取未读信息条数
export function getUnReadCount () {
  return request({
    url: '/notice/systemNotice/getUnReadCount',
    method: 'get'
  })
}

// 获取未读信息条数
export function getUnReadCountRequest () {
  return request({
    url: '/notice/systemNotice/getUnReadCount',
    method: 'get'
  })
}

// 获取已启用的大屏列表
export function enableScreenList (params) {
  return request({
    url: '/system/screen/enableScreenList',
    method: 'get',
    params
  })
}
