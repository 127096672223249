<template>
  <div id="app">
    <router-view v-if="isRouterAlive" v-loading="!isRouterAlive" />
  </div>
</template>

<script>
export default {
  name: 'App',
  provide () {
    return {
      adminReload: this.reload,
      changeLanguage: this.changeLanguage
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  computed: {
  },

  created () {
    if (window) {
      window.aep = {
        toggle: this.toggleLanguage,
        change: this.changeLanguage
      }
    }
  },

  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },

    changeLanguage (code) {
      this.$i18n.locale = code
      this.$store.dispatch('app/setLanguage', code)
      this.reload()
    },

    toggleLanguage () {
      const codes = ['en-US', 'zh-CN']
      const current = this.$store.state.app.language
      const currentIndex = codes.findIndex(n => n === current)
      const targetIndex = currentIndex + 1 === codes.length ? 0 : currentIndex + 1
      const target = codes[targetIndex]
      this.changeLanguage(target)
    }
  }
}
</script>
