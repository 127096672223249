import request from '@/utils/request'

// 获取路由
export const getRouters = (query) => {
  return request({
    url: '/sysuser/userCenter/menuPermission/web',
    method: 'get',
    params: query
  })
}
