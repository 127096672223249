<template>
  <div
    class="sidebar"
    :class="settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4' ? 'theme-dark' : 'theme-light'"
  >
    <el-scrollbar
      class="el-scrollbar-bg"
      view-class="scrollbar-wrapper"
      :native="true"
      :style="{
        backgroundColor:
          settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4' ? variables.menuBg : variables.menuLightBg
      }"
    >
      <div ref="mainMenu" class="main-menu">
        <sidebar-item
          v-for="(route, index) in sidebar"
          :key="route.path + index"
          :item="route"
          :base-path="route.path"
          @overChildMenu="showChildMenu"
          @outChildMenu="showChild = false"
          @handleClick="getActiveRouter(route)"
        />
      </div>
    </el-scrollbar>

    <div
      v-if="showChild"
      class="child-menu child-menu-top"
      @mouseenter="mouseenterChildMenu"
      @mouseleave="mouseleaveChildMenu"
    >
      <el-scrollbar view-class="scrollbar-wrapper" :native="true">
        <child-menu :list="childMenu" :title="mainTitle" :base-path="mainPath" @handleClick="getActiveRouter" />
      </el-scrollbar>
    </div>

    <div class="child-menu" :class="{ hide: isHide || !activeRouter.length }">
      <el-scrollbar view-class="scrollbar-wrapper" :native="true">
        <child-menu :list="activeRouter" :title="activeTitle" :base-path="activeBasePath" />
      </el-scrollbar>
      <img v-if="!isHide" :src="narrow" alt="" class="narrow" @click="handleSwitch" />
    </div>
    <img v-if="isHide && activeRouter.length" :src="expand" alt="" class="expand" @click="handleSwitch" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
import ChildMenu from './ChildMenu'
import variables from '@/assets/styles/variables.scss'
import Cookies from 'js-cookie'

export default {
  components: { SidebarItem, ChildMenu },
  computed: {
    ...mapGetters(['sidebarRouters', 'switchClass']),
    ...mapState([{
      storeActiveRouter: state => state.user.activeRouter
    }, 'settings']),
    variables () {
      return variables
    }
  },
  data () {
    return {
      showChild: false,
      childMenu: [],
      mainMenu: null, // 当前移上去
      mainTitle: '',
      mainPath: '',
      activeRouter: [],
      activeTitle: '',
      activeBasePath: '',
      isHide: false,
      narrow: require('@/assets/images/sidebar-narrow.png'),
      expand: require('@/assets/images/sidebar-expand.png'),
      sidebar: [],
      lang: Cookies.get('lang')
    }
  },
  watch: {
    storeActiveRouter () {
      this.getActiveRouter()
    }
  },
  mounted () {
    this.addSidebar()
    this.$nextTick(() => {
      this.getActiveRouter()
    })
    if (this.switchClass) {
      this.isHide = this.switchClass
    }
  },

  methods: {
    addSidebar () {
      let sidebarRouters = [...this.sidebarRouters]
      // 根据后端返回进行排序
      for (let i = 0; i < sidebarRouters.length; i++) {
        let cur = sidebarRouters[i]
        cur.children && cur.children.sort(function (a, b) {
          if (Number(a.orderNum) < Number(b.orderNum)) {
            return -1
          } else if (Number(a.orderNum) === Number(b.orderNum)) {
            return 0
          } else {
            return 1
          }
        })
      }

      this.sidebar = sidebarRouters.sort(function (a, b) {
        if (Number(a.orderNum) < Number(b.orderNum)) {
          return -1
        } else if (Number(a.orderNum) === Number(b.orderNum)) {
          return 0
        } else {
          return 1
        }
      })
    },
    showChildMenu (childMenu, mainMenu) {
      this.childMenu = childMenu
      this.mainMenu = mainMenu
      // mouse-是否有子菜单（用于点击事件getActiveRouter）
      if (this.childMenu.length > 0) {
        this.showChild = true
        this.mainTitle = this.lang === 'zh-CN' ? mainMenu?.meta?.title : mainMenu?.meta?.titleEn
        this.mainPath = mainMenu.path
      }
    },
    mouseenterChildMenu () {
      this.showChild = true
      this.$set(this.mainMenu, 'active', true)
    },
    mouseleaveChildMenu () {
      this.showChild = false
      this.$set(this.mainMenu, 'active', false)
    },
    getActiveRouter (e) {
      // 点击动画-需要隐藏mouse出来的child菜单
      this.mainTitle = ''
      this.showChild = false
      // 是否有子菜单-弹出click出来的
      if (this.childMenu.length > 0) {
        this.isHide = false
        this.$store.dispatch('SwitchClass', this.isHide)
      }

      if (JSON.parse(localStorage.getItem('activeRouter'))) {
        this.activeRouter = JSON.parse(localStorage.getItem('activeRouter'))
        this.activeTitle = this.getTitleByRouter(e, this.lang)
        this.activeBasePath = localStorage.getItem('activeBasePath')
      } else {
        this.activeRouter = []
        this.activeTitle = ''
        this.activeBasePath = ''
      }
    },

    /**
     * 根据router获取一级路由title
     * 由于历史遗留设计问题，先使用这种不优雅的方式解决BUG
     * 后续有时间可对此组件进行重构
     */
    getTitleByRouter (router, lang) {
      if (router && router.meta) {
        const title = lang === 'zh-CN' ? router.meta.title : router.meta.titleEn
        return title || ''
      }
      try {
        const domList = this.$refs.mainMenu
        const domActive = domList.getElementsByClassName('router-link-active')[0]
        return domActive.getElementsByTagName('span')[0].innerText
      } catch (e) {
        console.log(e)
      }
      return ''
    },

    handleSwitch () {
      this.isHide = !this.isHide
      this.$store.dispatch('SwitchClass', this.isHide)
    },
    mouseChild () {
      // 隐藏mouse出来的child菜单 && 隐藏click出来的
      this.isHide = true
      this.showChild = false
      this.mainMenu && this.$set(this.mainMenu, 'active', false)
      this.$store.dispatch('SwitchClass', this.isHide)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

.el-scrollbar-bg {
  position: relative;
  z-index: 3;
}

.sidebar {
  height: calc(100% - 100px);
}

.child-menu {
  position: fixed;
  left: $sideBarWidth;
  top: 0;
  width: 200px;
  height: 100%;
  background: #fff;
  box-shadow: 3px 0 4px rgba(0, 21, 41, 0.08);
  transition: all 0.28s;
}

.child-menu-top {
  z-index: 10;
}

.main-menu-line {
  display: block;
  width: 77px;
  height: 1px;
  margin: 20px auto;
  background-color: #99b1fe;
  opacity: 0.3;
}

.hide {
  left: -200px;

  // width: 0;
  overflow: hidden;
}

.narrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.expand {
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
  cursor: pointer;
}

.main-menu {
  padding: 8px 0 10px;
}

.theme-light {
  .child-menu {
    background-color: #f5f7fb;
  }
}
</style>
