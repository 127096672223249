// 防止重复提交
export const reSubmit = {
  install (Vue) {
    Vue.directive('dbClick', {
      inserted (el, binding) {
        el.addEventListener('click', e => {
          if (!el.disabled) {
            el.disabled = true
            // el.style.cursor = 'not-allowed'
            el.style.background = '#d5d5d5'
            el.style.borderColor = '#d5d5d5'
            el.style.opacity = 0.5
            setTimeout(() => {
              el.style.cursor = 'pointer'
              el.disabled = false
              el.style.background = ''
              el.style.borderColor = ''
              el.style.opacity = 1
            }, 1000)
          }
        })
      }
    })
  }
}
