import Cookies from 'js-cookie'

const TokenKey = 'Authorization'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getRefreshToken () {
  return Cookies.get('RefreshToken')
}

export function setRefreshToken (token) {
  return Cookies.set('RefreshToken', token)
}

export function removeRefreshToken () {
  return Cookies.remove('RefreshToken')
}
