<template>
  <div>
    <el-dialog
      :title="title"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      append-to-body
      @close="closed"
    >
      <div v-html="$t('login.easyPwd')" />
      <el-form ref="form" :model="user" :rules="rules" label-position="left" @submit.native.prevent>
        <el-form-item :label="$t('user.profile.resetPwd.oldPassword')" prop="oldPassword">
          <el-input v-model="user.oldPassword" :placeholder="$t('place.enterOldPassword')" type="password" />
        </el-form-item>
        <el-form-item :label="$t('user.profile.resetPwd.newPassword')" prop="newPassword">
          <el-input v-model="user.newPassword" :placeholder="$t('place.enterNewPassword')" type="password" />
        </el-form-item>
        <el-form-item :label="$t('user.profile.resetPwd.confirmPassword')" prop="confirmPassword">
          <el-input v-model="user.confirmPassword" :placeholder="$t('place.confirmPassword')" type="password" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">{{ $t('common.confirm') }}</el-button>
        <el-button @click="closed()">{{ $t('common.cancel') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updateUserPwd } from '@/api/system/user'
import { validPassword } from '@/utils/validateMain'
import { Encrypt } from '@/utils/secret'
export default {
  props: {
    formData: {
      type: Object
    }
  },
  data () {
    const equalToPassword = (rule, value, callback) => {
      if (this.user.newPassword !== value) {
        callback(new Error(this.$t('valid.equalToPassword')))
      } else {
        callback()
      }
    }
    const isPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('valid.password.required')))
      } else if (validPassword(value)) {
        callback(new Error(this.$t('valid.password.format')))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: true,
      title: this.$t('common.sysHint'),
      user: {
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined
      },
      // 表单校验
      rules: {
        oldPassword: [
          {
            required: true,
            message: this.$t('valid.oldPassword'),
            trigger: 'blur'
          }
        ],
        newPassword: [
          { required: true, validator: isPassword, trigger: 'blur' }
        ],
        confirmPassword: [
          {
            required: true,
            message: this.$t('valid.confirmPassword'),
            trigger: 'blur'
          },
          { required: true, validator: equalToPassword, trigger: 'blur' }
        ]
      }
    }
  },
  async created () {

  },
  methods: {
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let random = this.randomString(16)
          let params = {
            oldPassword: Encrypt(this.user.oldPassword, random),
            newPassword: Encrypt(this.user.newPassword, random),
            random: random
          }
          updateUserPwd(params).then((response) => {
            this.msgSuccess(this.$t('common.modifySuccess'))
            this.closed(true)
          })
        }
      })
    },
    cancel () { },
    closed (e = false) {
      this.$emit('closeDialog', e)
    }
  }
}
</script>

<style>
</style>
