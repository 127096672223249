/* Layout */
import Layout from '@/layout'
export default [
  {
    path: '/tracker',
    component: Layout,
    hidden: false,
    // redirect: '',
    children: [
    ]
  }
]
