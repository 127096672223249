import store from '@/store'
import * as dayjs from 'dayjs'
import { _offset2minutes } from '@/timezone/core'

// 去掉时区概念的时间戳
export function getStampSearch (stamp) {
  // Because:
  // UTC - Local = new Date().getTimezoneOffset()
  // Target = UTC + _offset2minutes(userOffset)
  // So:
  // Target - Local = x - F(x) = new Date().getTimezoneOffset() + _offset2minutes(userOffset)
  // F(x) = x - (new Date().getTimezoneOffset() + _offset2minutes(userOffset))
  const userOffset = store.state.app.offset
  const offset1 = new Date().getTimezoneOffset()
  const offset2 = _offset2minutes(userOffset)
  return stamp - (offset1 + offset2) * 60 * 1000
}

/**
 * @desc 加上时区概念的时间戳
 * @param {number} stamp 参数最好是使用本地时间 如 Date.now() 或API、组件生成的时间戳
 * @return {number} 只作为临时替代方案
 */
export function getStamp (stamp) {
  // Because:
  // UTC - Local = new Date().getTimezoneOffset()
  // Target = UTC + _offset2minutes(userOffset)
  // So:
  // Target - Local = F(x) - x = new Date().getTimezoneOffset() + _offset2minutes(userOffset)
  // F(x) = x + new Date().getTimezoneOffset() + _offset2minutes(userOffset)
  const userOffset = store.state.app.offset
  const offset1 = new Date().getTimezoneOffset()
  const offset2 = _offset2minutes(userOffset)
  return stamp + (offset1 + offset2) * 60 * 1000
}

// 获取系统时区偏移
export function getOffset () {
  return dayjs().format('Z')
}
