import Cookies from 'js-cookie'
import { getTopRolling } from '@/api/system/letter'
let env = process.env
const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  size: Cookies.get('size') || 'medium',
  language: Cookies.get('lang') || (env.VUE_APP_ENV_TYPE === 'CHINA' ? 'zh-CN' : 'en-US'),
  offset: Cookies.get('offset'),
  zoneCode: Cookies.get('zoneCode'),
  // 站内信轮播flag
  textCarousel: ''
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_SIZE: (state, size) => {
    state.size = size
    Cookies.set('size', size)
  },
  SET_LANGUAGE: (state, language) => {
    state.language = language
    Cookies.set('lang', language)
  },
  SET_OFFSET: (state, offset) => {
    state.offset = offset
    Cookies.set('offset', offset)
  },
  SET_ZONECODE: (state, zoneCode) => {
    state.zoneCode = zoneCode
    Cookies.set('zoneCode', zoneCode)
  },
  SET_TEXTCAROUSEL: (state, textCarousel) => {
    state.textCarousel = textCarousel
  }
}

const actions = {
  toggleSideBar ({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar ({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice ({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setSize ({ commit }, size) {
    commit('SET_SIZE', size)
  },
  setLanguage ({ commit }, language) {
    commit('SET_LANGUAGE', language)
  },
  setOffset ({ commit }, offset) {
    commit('SET_OFFSET', offset)
  },
  setZoneCode ({ commit }, zoneCode) {
    commit('SET_ZONECODE', zoneCode)
  },
  // 获取最新站内信
  GetTextCarousel ({ commit }) {
    return new Promise((resolve, reject) => {
      getTopRolling().then(response => {
        if (response.data) {
          commit('SET_TEXTCAROUSEL', response.data)
        }
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
    //
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
