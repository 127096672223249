import request from '@/utils/request'

// 查询用户列表
export function listUser (query) {
  return request({
    url: '/sysuser/user/page',
    method: 'get',
    params: query
  })
}

// 查询用户详细
export function getUser (query) {
  return request({
    url: '/sysuser/user/get',
    method: 'get',
    params: query
  })
}

// 新增用户
export function addUser (data) {
  return request({
    url: '/sysuser/user/save',
    method: 'post',
    data: data
  })
}

// 修改用户
export function updateUser (data) {
  return request({
    url: '/sysuser/user/update',
    method: 'put',
    data: data
  })
}

// 删除用户
export function delUser (query, data) {
  return request({
    url: '/sysuser/user/delete',
    method: 'delete',
    params: query,
    data

  })
}

// 用户密码重置
export function resetUserPwd (data) {
  return request({
    url: '/sysuser/user/resetPwd',
    method: 'put',
    data
  })
}

// 用户状态修改
export function changeUserStatus (userId, status) {
  const data = {
    userId,
    status
  }
  return request({
    url: '/sysuser/user/changeStatus',
    method: 'put',
    data: data
  })
}

// 查询用户个人信息
export function getUserProfile () {
  return request({
    url: '/sysuser/userCenter/userInfo',
    method: 'get'
  })
}

// 修改用户个人信息
export function updateUserProfile (data) {
  // 新版接口不需要 userId
  const params = Object.assign({}, data)
  delete params.userId
  return request({
    url: '/sysuser/userCenter/updateUserInfo',
    method: 'put',
    data: params
  })
}

// 用户密码重置
export function updateUserPwd (data) {
  return request({
    url: '/sysuser/userCenter/updatePwd',
    method: 'put',
    data
  })
}

// 用户头像上传
export function uploadAvatar (data) {
  return request({
    url: '/sysuser/userCenter/avatar',
    method: 'post',
    params: data
  })
}

// 文件上传
export function streamFile (data) {
  return request({
    url: '/system/file',
    method: 'post',
    data: data
  })
}

// 判断当前用户是否是超级管理员
export function isAdmin () {
  return request({
    url: '/sysuser/user/top',
    method: 'get'
  })
}
