<template>
  <el-input-number v-model="inputNumber" :max="max" :min="min" v-bind="$attrs" :style="stype" v-on="$listeners" />
</template>

<script>

export default {
  props: {
    value: { type: [Number, String], default: 0 },
    stype: { type: String, default: '' },
    max: { type: Number, default: 999999999 },
    min: { type: Number, default: -999999999 }
  },
  computed: {
    inputNumber: {
      get () {
        let num = Number(this.value)
        let len = num.toString().split('.')[1]?.length
        return !this.$attrs.precision && len > 6 ? num.toFixed(6) : num // 当未设置精度precision且输入小数超过6位时，仅保留6位
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
