/**
 * 这是一个axios请求拦截器，用于防止执行重复post、put提交
 * 注意：需要放在首个拦截位置，防止config被修改无法正常生效
 */

export default function interceptors (axios) {
  // 忽略Api队列，例如：/notice/ntcNoticeAccount/account/update
  const ignore = []

  // 处理的请求模式
  const methods = ['post', 'put']

  // 请求队列
  const requestList = []

  // 重复执行错误信息
  const errMsg = { code: 40012, message: '重复请求' }

  // 入口
  axios.interceptors.request.use(config => {
    const { method, url, data } = config
    const path = url.split('?')[0]

    if (methods.includes(method) && !ignore.includes(path)) {
      const req = url + JSON.stringify(data)
      if (requestList.includes(req)) {
        errMsg.url = url
        return Promise.reject(errMsg)
      }
      requestList.push(req)
    }
    return config
  })

  // 出口
  axios.interceptors.response.use(response => {
    const { method, url, data } = response.config
    const path = url.split('?')[0]

    if (methods.includes(method) && !ignore.includes(path)) {
      setTimeout(() => {
        const req = url + JSON.stringify(data)
        requestList.splice(requestList.indexOf(req), 1)
      }, 300)
    }
    return response
  }, error => {
    // 响应错误
    setTimeout(() => {
      requestList.splice(0, requestList.length)
    }, 1000)
    return Promise.reject(error)
  })
}
