import { Routes } from '@/router'
import { getRouters } from '@/api/menu'
import ParentView from '@/components/ParentView'
import Layout from '@/layout'

const permission = {
  state: {
    routes: [],
    addRoutes: [],
    sidebarRouters: [],
    firstRouters: ''
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
      state.routes = Routes.concat(routes)
    },
    SET_SIDEBAR_ROUTERS: (state, routers) => {
      state.sidebarRouters = Routes.concat(routers)
    },
    SET_FIRST_ROUTERS: (state, firstRouters) => {
      state.firstRouters = firstRouters
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes ({ commit }) {
      return new Promise((resolve, reject) => {
        // 向后端请求路由数据
        getRouters().then(res => {
          const sdata = JSON.parse(JSON.stringify(res.data.menus))
          const rdata = JSON.parse(JSON.stringify(res.data.menus))
          const sidebarRoutes = filterAsyncRouter(sdata)
          const rewriteRoutes = filterAsyncRouter(rdata, false, true)

          // 找不到的页面直接跳路由第一个页面
          const hasFirst = sidebarRoutes[0]?.children?.[0]
          const firstOuter = `${sidebarRoutes[0].path}`
          let firstInner = `${sidebarRoutes[0].path}/${sidebarRoutes[0]?.children?.[0].path}`
          firstInner = firstInner.replaceAll('//', '/')

          rewriteRoutes.push({
            path: '*',
            redirect: hasFirst ? firstInner : firstOuter,
            hidden: true
          })

          if (res.data.permissions && res.data.permissions.length) {
            commit('SET_PERMISSIONS', res.data.permissions)
          }
          if (res.data.menus.length > 0) {
            // 设置数采权限
            const dcmp = matchOtherName(res.data.menus, 'product_dcmp')
            commit('SET_PERMISSION_DCMP', Boolean(dcmp))
            commit('SET_FIRST_ROUTERS', sdata[0].children[0].path)
            commit('SET_ROUTES', rewriteRoutes)
            commit('SET_SIDEBAR_ROUTERS', sidebarRoutes)
            resolve(rewriteRoutes)
          } else {
            reject(rewriteRoutes)
          }
        })
      })
    }
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter (asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter(route => {
    if (type && route.children) {
      route.children = filterChildren(route.children)
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        // 行业应用是否采用AEP的Layout风格
        route.component = Layout
      } else if (route.component === 'ParentView') {
        route.component = ParentView
      } else {
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type)
    } else {
      delete route.children
      delete route.redirect
    }
    return true
  })
}

function filterChildren (childrenMap, lastRouter = false) {
  let children = []
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView') {
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          }
          children.push(c)
        })
        return
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
    }
    children = children.concat(el)
  })
  return children
}

function matchOtherName (list, matchName) {
  for (let i = 0; i < list.length; i++) {
    const item = list[i]
    if (item.otherName && item.otherName === matchName) {
      return item
    }
    if (item.children && item.children.length) {
      matchOtherName(item.children, matchName)
    }
  }
}

export const loadView = (view) => { // 路由懒加载
  if (view.indexOf('views/') === -1) {
    return (resolve) => require([`@/views/${view}`], resolve)
  } else {
    return (resolve) => require([`@/app/${view}`], resolve)
  }
}

export default permission
