// 引用AES源码js
import md5 from 'js-md5'
import CryptoJS from 'crypto-js'

// 加密方法
export function Encrypt (word, random) {
  let md5Random = md5(random).substring(8, 24).toUpperCase()
  let key = CryptoJS.enc.Utf8.parse(md5Random.substring(0, 16))
  let iv = CryptoJS.enc.Utf8.parse(md5Random.substring(8, 16) + md5Random.substring(0, 8))
  let srcs = CryptoJS.enc.Utf8.parse(word)
  let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
  return encrypted.ciphertext.toString()
}

// 11位手机号加密
export function getSecretPhone (num) {
  return num.toString().replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
}

// 邮箱加密：@前只保留首位，其他用***代替
export function getSecretEmail (str) {
  return str.replace(/^(.)[^@]+@/, '$1***@')
}
