import Vue from 'vue'
import SvgIcon from '@/components/SvgIcon' // svg component

// register globally
Vue.component('SvgIcon', SvgIcon)

const req = require.context('./svg', false, /\.svg$/)
const reqCgs = require.context('./svg-configuration', false, /\.svg$/)
const reqProp = require.context('./svgProp', true, /\.svg$/)
const reqDashboard = require.context('./svgDashboard', false, /\.svg$/)
const requireAll = (requireContext) => requireContext.keys().map(requireContext)
requireAll(req)
requireAll(reqCgs)
requireAll(reqProp)
requireAll(reqDashboard)
