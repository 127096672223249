// 组件引入mixins后，调用initWS()即可
export default {
  data () {
    return {
      topicArr: [], // 存放本页面所订阅的topic
      isOrg: false, // 判断是否为Layout外的独立页面
      subscribeTimer: {}
    }
  },
  beforeDestroy () {
    this.unsubscribe()
    if (this.isOrg) this.$ws.close() // 原始页面关闭，则断开整个ws连接
  },
  methods: {
    // 开启订阅，定义消息回调，续约contract（如设备属性更新）
    initWS (topic, onMsgArrived, contract) {
      if (!this.$ws.enable) return
      if (this.$ws.connected) {
        this.topicArr.push(topic)
        this.$ws.subscribe(topic, contract)
        this.$ws.onMessage[topic] = onMsgArrived
        this.subscribeTimer[topic] && clearTimeout(this.subscribeTimer[topic])
      } else {
        this.subscribeTimer[topic] = setTimeout(() => {
          this.initWS(topic, onMsgArrived, contract) // ws未连接成功，1s后再次检测是否可订阅
        }, 1000)
      }
    },
    // 结束订阅，清除回调函数
    unsubscribe () {
      this.topicArr?.forEach(topic => {
        this.$ws.unsubscribe(topic)
        delete this.$ws.onMessage[topic]
      })
    }
  }
}
