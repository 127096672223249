<template>
  <el-alert v-if="textCarousel" type="warning" :closable="false" class="alert-box">
    <template slot="title">
      <!-- <component :notice="textCarousel" :is="TextCarousel" v-if="TextCarousel"></component> -->
      <TextCarouselCom :notice="textCarousel" />
    </template>
  </el-alert>
</template>

<script>
// import { getTopRolling } from '@/api/system/letter'
// import store from '@/store'
import { mapGetters } from 'vuex'
import TextCarouselCom from './index.vue'
export default {
  name: 'NoticeAlert',
  components: { TextCarouselCom },
  data: () => ({ notice: null, timer: null }),
  computed: {
    ...mapGetters(['textCarousel'])
  },
  watch: {
    // notice: {
    //   //可监听到对象、数组的变化
    //   handler (val, oldVal) {
    //     store.commit('app/SET_TEXTCAROUSEL', val)
    //   }

    // }
  },
  created () {
    // this.GetRolling()
    // if (this.hasTimer) {
    //   this.timer = setInterval(() => this.GetRolling(), 60 * 1000)
    //   window.BeforeUnloadEvent = function () { this.timer = null }
    // }
  },
  props: {
    hasTimer: {
      type: Boolean,
      default: true
    }
  },
  beforeDestroy () {
    //  this.timer = null
  },
  methods: {
    // async GetRolling () {
    //   const response = await getTopRolling()
    //   if (response?.data) {
    //     this.$set(this, 'notice', response.data)
    //     this.TextCarousel = require('@/components/TextCarousel').default
    //   }
    // }
  }
}
</script>
