/**
 * 通用js方法封装处理
 */

import Cookies from 'js-cookie'
import { getStamp, getStampSearch } from '@/utils/date'
import { isNil, orderBy } from 'lodash-es'

let env = process.env
const baseURL = env.VUE_APP_BASE_API

// 日期格式化
export function parseTime (time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/')
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    const newStamp = getStamp(time)
    date = new Date(newStamp)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return timeStr
}

export function formatTime (data) {
  let date = new Date(data)
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  if (month < 10) month = '0' + month
  if (day < 10) day = '0' + day

  return year + '-' + month + '-' + day
}

/**
 * 秒数转换为时间（时分秒）
 * @param {number} time
 * @return {string}
 */
export function formatSecond (value) {
  let second = parseInt(value) // 秒
  let minute = 0 // 分
  let hour = 0 // 小时
  // 如果秒数大于等于60，将秒数转换成整数
  if (second > 60 || second === 60) {
    minute = parseInt(second / 60) // 获取分钟，除以60取整数，得到整数分钟
    second = parseInt(second % 60) // 获取秒数，秒数取佘，得到整数秒数
    // 如果分钟大于等于60，将分钟转换成小时
    if (minute > 60 || minute === 60) {
      hour = parseInt(minute / 60) // 获取小时，获取分钟除以60，得到整数小时
      minute = parseInt(minute % 60) // 获取小时后取佘的分，获取分钟除以60取佘的分
    }
  }
  return new Date(null, null, null, parseInt(hour), parseInt(minute), parseInt(second))
}

// 表单重置
export function resetForm (refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields()
  }
}

/**
 * @desc 添加日期范围
 * @param {*} params
 * @param {*} dateRange
 * @param {*} propName
 * @param {*} timestampMs V2.7.0 毫秒级查询,默认值为false 。毫秒级别需要一秒的完整周期，结束时间+999
 * @returns
 */

export function addDateRange (params, dateRange, propName, timestampMs = false) {
  let search = { ...params }
  let timestamp_ms = timestampMs ? 999 : 0
  if (dateRange != null && dateRange !== '' && dateRange.length > 0) {
    if (!propName) {
      search.startTime = getStampSearch(dateRange[0])
      search.endTime = getStampSearch(dateRange[1]) + timestamp_ms
    } else {
      search[propName.startTime || 'startTime'] = getStampSearch(dateRange[0])
      search[propName.endTime || 'endTime'] = getStampSearch(dateRange[1]) + timestamp_ms
    }
    search.offset = Cookies.get('offset')
  }
  return search
}

// 回显数据字典
export function selectDictLabel (datas, value, selectKey) {
  let actions = []
  Object.keys(datas).some((key) => {
    let matchValue = selectKey?.value || 'dictValue'
    let matchLabel = selectKey?.label || 'dictLabel'
    if (datas[key][matchValue] === ('' + value) || datas[key][matchValue] === (value)) {
      actions.push(datas[key][matchLabel])
      return true
    }
  })
  return actions.join('')
}
// 回显数据字典（字符串数组）
export function selectDictLabels (datas, value, separator, selectKey) {
  let actions = []
  let matchValue = selectKey?.value || 'dictValue'
  let matchLabel = selectKey?.label || 'dictLabel'
  let currentSeparator = undefined === separator ? ',' : separator
  let temp = value.split(currentSeparator)
  Object.keys(value.split(currentSeparator)).some((val) => {
    Object.keys(datas).some((key) => {
      if (('' + datas[key][matchValue]) === ('' + temp[val])) {
        actions.push(datas[key][matchLabel] + currentSeparator)
      }
    })
  })
  return actions.join('').substring(0, actions.join('').length - 1)
}

// 通用下载方法
export function download (fileName) {
  window.location.href = baseURL + '/system/file/download?fileName=' + encodeURI(fileName) + '&delete=' + true
}

/**
 * 全局Blob文件下载
 * @param {string} fileName 下载后保存的文件名
 * @param {string} extname 下载后保存的文件扩展名, 以.开头
 * @param {Array} array 由ArrayBuffer, ArrayBufferView, Blob, DOMString 等对象构成的 Array, 详见文档
 * @param {Object} options 用于实例化 Blob 对象的 options,详见文档
 * @see https://developer.mozilla.org/zh-CN/docs/Web/API/Blob/Blob
 */
export function downloadBlob (fileName, extname, array, options = undefined) {
  const blob = new Blob(array, options)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = window.URL.createObjectURL(blob)
  link.setAttribute('download', fileName + extname)
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(link.href)
  document.body.removeChild(link)
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} sortKey 排序字段
 */
export function handleTree (data, id, parentId, children, sortKey) {
  let config = {
    id: id || 'id',
    parentId: parentId || 'parentId',
    childrenList: children || 'children'
  }

  let childrenListMap = {}
  let nodeIds = {}
  let tree = []

  for (let d of data) {
    let parentId = d[config.parentId]
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = []
    }
    nodeIds[d[config.id]] = d
    childrenListMap[parentId].push(d)
  }

  for (let d of data) {
    let parentId = d[config.parentId]
    if (nodeIds[parentId] == null) {
      tree.push(d)
    }
  }

  if (sortKey) {
    tree = orderBy(tree, [sortKey], 'asc')
  }

  for (let t of tree) {
    adaptToChildrenList(t)
  }

  function adaptToChildrenList (o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]]
    }
    if (o[config.childrenList]) {
      if (sortKey) {
        o[config.childrenList] = orderBy(o[config.childrenList], [sortKey], 'asc')
      }
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c)
      }
    }
  }

  return tree
}

// 深拷贝
export function deepCopy (mainObj) {
  let objCopy = {}

  for (let key in mainObj) {
    objCopy[key] = mainObj[key] // 将每个属性复制到objCopy对象
  }
  return objCopy
}

/**
 *生成字符- 国际化使用
 * @param {*} lanuageKey 语言对象键值
 * @param {*} title 要转换的值
 */
export function generateText (lanuageKey, title) {
  const key = this.$t(`${lanuageKey}.${title}`)
  if (key) {
    return this.$t(`${lanuageKey}.${title}`)
  }
  return title
}

// HEX转RGB
export function convertHexToRGB (color) {
  if (color.length === 4) {
    let extendedColor = '#'
    for (let i = 1; i < color.length; i++) {
      extendedColor += color.charAt(i) + color.charAt(i)
    }
    color = extendedColor
  }
  const values = {
    r: parseInt(color.substr(1, 2), 16),
    g: parseInt(color.substr(3, 2), 16),
    b: parseInt(color.substr(5, 2), 16)
  }
  return `${values.r}, ${values.g}, ${values.b}`
}

// 随机生成字符串
export function randomString (expect) {
  let str = Math.random().toString(32).substring(2)
  while (str.length < expect) {
    str += Math.random().toString(32).substring(2)
  }
  return str.substring(0, expect)
}

/**
 * 秒数转换时分展示
 * @param {*} seconds
 * @returns
 * 不足1分钟：0小时0分
 * 不足10分钟：0小时1分
 * 大于1小时：1小时1分
 * 大于24小时：24小时1分
 */
export function formatSeconds (seconds) {
  if (isNil(seconds)) {
    return '--'
  }
  const oneMinute = 60
  const oneHour = 3600

  let hours = Math.floor(seconds / oneHour)
  let minutes = Math.floor((seconds % oneHour) / oneMinute)

  return `${hours}小时${minutes}分`
}
