<template>
  <div>
    <el-drawer
      :title="title"
      :visible.sync="visible"
      size="1000px"
      top="50px"
      :append-to-body="true"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :before-close="closeDialog"
    >
      <el-table fit :data="taskRunData">
        <el-table-column :label="$t('task.table.batchTaskNo')" align="left" width="160" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" @click="handleDetail(scope.row)" v-text="scope.row.batchTaskNo" />
          </template>
        </el-table-column>
        <el-table-column :label="$t('task.table.taskType')" align="left" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.taskType === 1" v-text="$t('task.table.taskTypeOptions.1')" />
            <span v-else-if="scope.row.taskType === 2" v-text="$t('task.table.taskTypeOptions.2')" />
          </template>
        </el-table-column>
        <el-table-column :label="$t('task.table.totalNum')" align="left" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-text="scope.row.totalNum" />
          </template>
        </el-table-column>
        <el-table-column :label="$t('task.table.successNum')" align="left" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="primary" v-text="scope.row.successNum" />
          </template>
        </el-table-column>
        <el-table-column :label="$t('task.table.failNum')" align="left" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="danger" v-text="scope.row.failNum" />
          </template>
        </el-table-column>
        <el-table-column :label="$t('task.table.timeUsed')" align="left" show-overflow-tooltip>
          <template slot-scope="scope">
            <!--
              1.如果 tsFinishTime 为null 说明任务还没结束，耗时为当前时间-创建时间即 current timestamp - tsCreateTime
              2.如果tsFinishTime 不为null 则耗时=tsFinishTime - tsCreateTime
            -->
            <span
              v-text="
                scope.row.tsFinishTime
                  ? parseInt((scope.row.tsFinishTime - scope.row.tsCreateTime) / 1000) + 's'
                  : parseInt((Date.parse(getStamp(Date.now())) - scope.row.tsCreateTime) / 1000) + 's'
              "
            />
          </template>
        </el-table-column>
        <el-table-column :label="$t('task.table.batchTaskProgress')" align="left" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="Number((((scope.row.successNum + scope.row.failNum) * 100) / scope.row.totalNum).toFixed(3))"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
    <!--详情 弹窗-->
    <TaskDetail
      v-if="taskDetailVisible"
      ref="addDialog"
      :visible.sync="taskDetailVisible"
      :title="$t('common.seeDetails')"
      :parent-form="form"
    />
  </div>
</template>

<script>
import TaskDetail from '@/views/upload/component/TaskDetail'
import { getStamp } from '@/utils/date'
export default {
  components: {
    TaskDetail
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    taskRunData: {
      type: Array
    }
  },
  data () {
    return {
      getStamp,
      // 详情弹框
      taskDetailVisible: false,
      // 带参数id查详情
      form: {},
      // 定时（进行中的：如果返回的数据不为空，要一直轮询调用接口，更新进度。每3秒轮询一次）
      timer: null

    }
  },
  computed: {
  },
  watch: {
  },
  // created () {
  //   this.$store.dispatch('GetTaskRunning').then((res) => {
  //     this.taskRunData = res.data
  //     if (this.taskRunData.length > 0) {
  //       this.timer = setInterval(() => {
  //         // 运行中的任务（当前用户创建的且任务状态为进行中的任务）
  //         this.$store.dispatch('GetTaskRunning').then(() => { })
  //       }, 3000)
  //     }
  //   })
  // },
  // destroyed () {
  //   clearInterval(this.timer)
  // },
  methods: {
    handleDetail (row) {
      this.title = this.$t('task.running')
      this.taskDetailVisible = true
      this.form = row
    },
    closeDialog () {
      this.$emit('update:visible', false)
      clearInterval(this.timer)
    }
  }
}

</script>

<style lang="scss" scoped>
::v-deep {
  .el-drawer__header {
    margin-bottom: 20px;
    font-weight: bold;
    color: #333;
  }

  .el-table__body-wrapper {
    overflow: auto;
    max-height: calc(100vh - 67px - 44px - 72px);
    overflow-x: hidden;
  }
}
</style>
