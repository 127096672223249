<template>
  <el-table-column
    :key="code + width"
    :prop="code"
    :label="otherName || name"
    :width="width"
    :show-overflow-tooltip="showTip"
    v-bind="$attrs"
  >
    <template #header>
      <FilterHeader
        v-if="headerType === 'checkbox'"
        :filter-code="code"
        filter-type="checkbox"
        :custom-arr-list="filterOpts"
        :filter-key="filterKey"
        @tableFilter="tableFilter"
      />
      <span :title="otherName || name" class="table-title">{{ otherName || name }}</span>
      <el-tooltip v-if="tip" popper-class="el-tooltip" :content="tip" placement="top">
        <i class="el-icon-warning-outline tip-icon ml5" />
      </el-tooltip>
    </template>
    <template slot-scope="scope">
      <span v-if="type === 'time'">{{ parseTime(scope.row[code]) || '--' }}</span>
      <span v-else-if="format">{{ format(scope.row[code]) || '--' }}</span>
      <slot v-else-if="type === 'slot'" :name="code" :scope="scope" />
      <span v-else>{{ scope.row[code] ?? '--' }}</span>
    </template>
  </el-table-column>
</template>
<script>
import FilterHeader from './filterHeader'
export default {
  components: { FilterHeader },
  // 支持透传el-table-column原属性
  props: {
    code: { type: String, default: '' }, // 字段
    name: { type: String, default: '' }, // 默认名
    otherName: { type: String, default: '' }, // 别名
    width: { type: Number, default: 0 }, // 宽度
    tip: { type: String, default: '' }, // 提示
    type: { type: String, default: '' }, // 类型（time自动转时间戳、slot插槽）
    showTip: { type: Boolean, default: true }, // 列内容超出显示tooltip
    format: { type: Function, default: null }, // 内容格式化
    headerType: { type: String, default: '' }, // 类型（checkbox、拓展）
    filterOpts: { type: Array, default: () => [] }, // 过滤器
    filterKey: { type: Object, default: () => {} } // 过滤器格式化key
  },
  methods: {
    tableFilter(data, reset) {
      this.$emit('getTableFilter', data)
    }
  }
}
</script>
<style lang="scss" scoped>
.table-title {
  user-select: text;
}
</style>
