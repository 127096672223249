// Vue
import Vue from 'vue'
import App from './App'
import 'babel-polyfill'

// 路由
import router from '@/router'

// store
import store from '@/store'

// Element
import Element from 'element-ui'
import '@/assets/styles/element-variables.scss'

// 多语言
import i18n from '@/lang'

// Cookies
import Cookies from 'js-cookie'

// 权限控制
import './permission'
import permission from '@/directive/permission'

// 过滤器
import * as filters from '@/filters'

// icon
import '@/assets/icons'
import '@/assets/iconfont/iconfont.css'

// 样式文件
import '@/assets/styles/public.scss'
import '@/assets/styles/main.scss'

import Pagination from '@/components/Pagination' // 分页组件
import DatePicker from '@/components/DatePicker'
import Table from '@/components/Table'
import TableSearch from './components/TableSearch'
import TextLink from '@/components/TextLink'
import RightToolbar from '@/components/RightToolbar' // 自定义表格工具扩展
// import Input from '@/components/Input'
import InputNumber from '@/components/InputNumber'
import Button from '@/components/Button'
import PhoneSelector from '@/components/PhoneSelector'

import { reSubmit } from '@/utils/reSubmit.js' // 防止重复提交
import { dateValid } from '@/utils/dateValid'
import { getDicts } from '@/api/system/dict/data' // 根据字典类型查询字典数据信息
import SocketService from '@/utils/websocket'

import {
  addDateRange,
  convertHexToRGB,
  deepCopy,
  download,
  downloadBlob,
  formatTime,
  handleTree,
  parseTime,
  randomString,
  resetForm,
  selectDictLabel,
  selectDictLabels
} from '@/utils/aep'
import { formatTimeClient } from '@/timezone/core'

import util from '@/utils/prototypeUtils' // 公共方法挂载

import message from '@/utils/resetMessage'
import { cloneDeep } from 'lodash-es' // 深拷贝
import { mergeDateRange } from '@/timezone/elementPicker'

// 设置 element-ui 默认大小
Vue.use(Element, {
  size: Cookies.get('size') || 'medium'
})

Object.keys(filters).forEach(item => {
  Vue.filter(item, filters[item])
})

Vue.use(permission)
Vue.use(reSubmit)
store.dispatch('getEnvInfo')

// 全局方法挂载
// TODO 全局方法建议使用$开头命名 避免冲突
Vue.prototype.getDicts = getDicts
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.$mergeDateRange = mergeDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.$dValid = dateValid
Vue.prototype.$downloadBlob = downloadBlob
Vue.prototype.deepCopy = deepCopy
Vue.prototype.formatTime = formatTime
Vue.prototype.$formatTimeClient = formatTimeClient
Vue.prototype.convertHexToRGB = convertHexToRGB
Vue.prototype.randomString = randomString
Vue.prototype.$util = util
Vue.prototype.$message = message
Vue.prototype.cloneDeep = cloneDeep
Vue.prototype.$ws = new SocketService()

Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: 'success' })
}

Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: 'error' })
}
Vue.prototype.msgWarning = function (msg) {
  this.$message({ showClose: true, message: msg, type: 'warning' })
}

Vue.prototype.$msgInfo = function (msg) {
  this.$message({ showClose: true, message: msg, type: 'info' })
}

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('DatePicker', DatePicker)
Vue.component('RightToolbar', RightToolbar)
Vue.component('Table', Table)
Vue.component('TableSearch', TableSearch)
// Vue.component('ElInput', Input)
Vue.component('InputNumber', InputNumber)
Vue.component('TextLink', TextLink)
Vue.component('TipButton', Button)
Vue.component('PhoneSelector', PhoneSelector)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
