<template>
  <el-date-picker
    v-model="privateVal"
    type="date"
    value-format="yyyy-MM-dd HH:mm:ss"
    v-bind="$attrs"
    @change="onChange"
  />
</template>

<script>
import { createUserZoneTime, getUserZoneTime } from '@/timezone/core'

export default {
  name: 'QuecDatePicker',
  props: {
    value: {
      type: [Number, String],
      default: null
    }
  },

  data () {
    return {
      privateVal: null
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (val) {
        if (!val) {
          this.privateVal = null
        } else {
          const itemInPicker = getUserZoneTime(val).startOf('date')
          this.privateVal = itemInPicker.format('YYYY-MM-DD HH:mm:ss')
        }
      }
    }
  },

  methods: {
    onChange (e) {
      const targetVal = e ? createUserZoneTime(e) : null
      this.$emit('input', targetVal)
    }
  }
}
</script>
