<template>
  <el-form ref="form" :model="queryParams" :inline="true" class="input-search" @submit.native.prevent>
    <el-form-item v-for="(item, prop) in formItems" :key="prop" :prop="prop">
      <!-- 下拉框配置prop: { ph, selectOpts, selectKey: { label, value } } -->
      <el-select
        v-if="item.selectOpts"
        v-model="queryParams[prop]"
        :placeholder="item.ph"
        :clearable="item.clearable !== undefined ? item.clearable : true"
        class="w-220"
        v-bind="item"
        @change="handleQuery"
      >
        <el-option
          v-for="opt in item.selectOpts"
          :key="opt[item.type === 'dict' ? 'dictValue' : item.selectKey?.value || 'value']"
          :label="opt[item.type === 'dict' ? 'dictLabel' : item.selectKey?.label || 'label']"
          :value="opt[item.type === 'dict' ? 'dictValue' : item.selectKey?.value || 'value']"
        />
      </el-select>
      <el-date-picker
        v-else-if="item.type === 'date'"
        v-model="queryParams[prop]"
        value-format="yyyy-MM-dd HH:mm:ss"
        format="yyyy-MM-dd HH:mm"
        :default-time="['00:00:00', '23:59:59']"
        :type="item.dateType || 'daterange'"
        range-separator="-"
        :picker-options="item.pickerOpts || $dValid.todayBefore"
        :start-placeholder="item.ph?.start || $t('dashboard.startDateRange')"
        :end-placeholder="item.ph?.end || $t('dashboard.endDateRange')"
        :style="{ width: item.width ? item.width + 'px' : '220px' }"
        popper-class="no-date-clear"
        v-bind="item"
        @change="val => dateChange(val, item, prop)"
      />
      <!-- 根据字段位置自定义插槽 -->
      <slot v-else-if="item.type === 'slot'" :name="prop" :handle-query="slotQuery" />
      <el-input
        v-else
        v-model.trim="queryParams[prop]"
        :placeholder="item.ph"
        clearable
        suffix-icon="el-icon-search"
        :style="{ width: item.width ? item.width + 'px' : '220px' }"
        v-bind="item"
        @keyup.enter.native="handleQuery"
      />
    </el-form-item>
    <!-- 额外slot内容（用于设备拓展列） -->
    <slot />
    <el-form-item>
      <el-button type="primary" icon="el-icon-search" @click="handleQuery">
        {{ $t('common.search') }}
      </el-button>
      <el-button icon="el-icon-refresh" @click="resetQuery">
        {{ $t('common.reset') }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getUserZoneTime, createUserZoneTime } from '@/timezone/core'

let hasDate = false // 是否含日期字段，用于判断是否清除date

/**
 * @desc 存储日期字段载体
 * @type {Array<string>}
 */
let cacheDateProps = []

export default {
  name: 'TableSearch',
  props: {
    // config 不可变
    formItems: { type: Object, default: () => ({}) },
    // kv 格式 受控
    form: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      queryParams: {}
    }
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.queryParams = { ...this.queryParams, ...val }
      }
    }
  },
  created () {
    this.resetDate()
  },
  methods: {
    /**
     * 日期组件change回调
     * @param {any} val 组件 onchange 事件的默认参数
     * @param {Object} item 当前日期参数 config 项
     * @param {string} prop 当前日期参数在 config 项内的字段名
     */
    dateChange (val, item, prop) {
      console.log('dateChange', val)
      if (val) {
        this.queryParams = this.$mergeDateRange(this.queryParams, val, item.dateKey, true)
      } else {
        this.resetDateItem(prop)
      }
      this.handleQuery()
    },

    slotQuery () {
      this.$nextTick(() => { this.handleQuery() })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
      const form = { ...this.queryParams }
      cacheDateProps.forEach(prop => {
        this.fmt2stamp(prop, form)
        delete form[prop]
      })
      this.$emit('update:form', form)
      if (this.$listeners.handleQuery) {
        this.$listeners.handleQuery()
      } else {
        this.$parent.getList()
      }
    },

    /** 重置按钮操作 */
    resetQuery () {
      this.resetForm('form')
      if (hasDate) {
        this.queryParams.offset = undefined
        this.resetDate()
      }
      this.$emit('update:form', this.queryParams)
      if (this.$listeners.resetQuery) {
        this.$listeners.resetQuery()
      } else {
        this.handleQuery()
      }
    },

    resetDate () {
      cacheDateProps = []
      for (let prop in this.formItems) {
        const item = this.formItems[prop]
        if (item.type === 'date') {
          hasDate = true
          this.resetDateItem(prop)
          cacheDateProps.push(prop) // 存储date字段
        }
      }
    },

    /**
     * 重置
     * @param {string} prop 日期条目字段名
     */
    resetDateItem (prop) {
      const { queryParams, formItems } = this
      const item = formItems[prop]
      this.$set(queryParams, prop, [])
      if (item.defaultValue) {
        queryParams[prop] = item.defaultValue.map(stamp => {
          return getUserZoneTime(stamp).format('YYYY-MM-DD HH:mm:ss')
        })
        queryParams.offset = this.$store.state.app.offset
      }
      queryParams[item.dateKey?.startTime || 'startTime'] = queryParams[prop][0]
      queryParams[item.dateKey?.endTime || 'endTime'] = queryParams[prop][1]
    },

    fmt2stamp (prop, obj) {
      const config = this.formItems[prop]
      const startFieldName = config?.dateKey?.startTime || 'startTime'
      const endFieldName = config?.dateKey?.endTime || 'endTime'
      if (typeof obj[startFieldName] === 'string') {
        obj[startFieldName] = createUserZoneTime(obj[startFieldName])
      }
      if (typeof obj[endFieldName] === 'string') {
        obj[endFieldName] = createUserZoneTime(obj[endFieldName])
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-form-item__content {
  height: 36px;
}
</style>
