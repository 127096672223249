import { login, logout, dmpAuth, tokenRefresh, getCurUserPlatformOemConf, getUnReadCountRequest } from '@/api/login'
import { getUserProfile } from '@/api/system/user'
import { getToken, setToken, removeToken, getRefreshToken, setRefreshToken, removeRefreshToken } from '@/utils/auth'
import { getOrgList } from '@/api/company/organization'
import { randomString } from '@/utils/aep'
import { Encrypt } from '@/utils/secret'
import { getRunning } from '@/api/upload/task'
import { getOffset } from '@/utils/date'
import store from '@/store'
import Cookies from 'js-cookie'
import { getSystemDomain } from '@/utils/utils'

const user = {
  state: {
    token: getToken(),
    refreshToken: getRefreshToken(),
    avatar: '',
    roles: [],
    permissions: [],
    publicKey: '',
    activeRouter: [],
    activeTitle: '',
    activeBasePath: '',
    switchClass: false,
    agentList: [],
    confInfo: {},
    isReadClause: undefined,
    dmpUserInfo: {},
    taskRunData: [],
    companyOem: {},
    UnReadCount: 0,
    controlOrgTree: [], // 可控制机构树
    allOrgChildTree: [], // 可查看所有的机构树
    userInfo: null, // userinfo 对象
    userId: null,
    name: null,
    orgtype: null,
    orgId: null,
    pan: null,
    tenantId: null,
    isShowContainChild: null,
    orgPermissionType: null, // 获取权限机构树
    orgLevel: null,
    timeOffset: null,
    screenList: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_REFRESHTOKEN: (state, refreshToken) => {
      state.refreshToken = refreshToken
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_PERMISSION_DCMP: (state, dcmp) => {
      state.dcmp = dcmp
    },
    SET_PUBLICKEY: (state, publicKey) => {
      state.publicKey = publicKey
    },
    SET_ACTIVEROUTER: (state, activeRouter) => {
      state.activeRouter = activeRouter
    },
    SET_ACTIVETITLE: (state, activeTitle) => {
      state.activeTitle = activeTitle
    },
    SET_ACTIVEBASEPATH: (state, activeBasePath) => {
      state.activeBasePath = activeBasePath
    },
    SET_SWITCHCLASS: (state, switchClass) => {
      state.switchClass = switchClass
    },
    SET_AGENT_LIST: (state, agentList) => {
      state.agentList = agentList
    },
    SET_CONF_INFO: (state, confInfo) => {
      state.confInfo = confInfo
    },
    SET_ISREADCLAUSE: (state, isReadClause) => {
      state.isReadClause = isReadClause
    },
    SET_DMP_USERINFO: (state, dmpUserInfo) => {
      state.dmpUserInfo = dmpUserInfo
    },
    SET_TASK_RUN_DATA: (state, taskRunData) => {
      state.taskRunData = taskRunData
    },
    SET_COMPANY_OEM: (state, companyOem) => {
      state.companyOem = companyOem
    },
    SET_UN_READCOUNT: (state, UnReadCount) => {
      state.UnReadCount = UnReadCount
    },
    SET_CONTROL_ORG_TREE: (state, controlOrgTree) => {
      state.controlOrgTree = controlOrgTree
    },
    SET_ALL_ORG_CHILD_TREE: (state, allOrgChildTree) => {
      state.allOrgChildTree = allOrgChildTree
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
      let { orgType, orgId, isPan, userName, userId, tenantId, isShowContainChild, orgPermissionType, ancestors, timeZoneOffset } = userInfo
      state.userId = userId
      state.name = userName
      state.orgtype = orgType
      state.orgId = orgId
      state.pan = isPan
      state.tenantId = tenantId
      state.isShowContainChild = isShowContainChild
      state.orgPermissionType = orgPermissionType // 获取权限机构树
      state.orgLevel = ancestors?.split(',')?.length
      state.timeOffset = timeZoneOffset // 时区
    },
    SET_SCREEN_LIST: (state, screenList) => {
      state.screenList = screenList
    }
  },

  actions: {
    // 大屏配置
    DataView ({ commit }, screenList) {
      return new Promise(resolve => {
        commit('SET_SCREEN_LIST', screenList)
        resolve()
      })
    },
    // 企业个性化配置
    ConfInfo ({ commit }, confInfo) {
      return new Promise(resolve => {
        commit('SET_CONF_INFO', confInfo)
        localStorage.setItem('confInfo', JSON.stringify(confInfo))
        resolve()
      })
    },
    // 登录
    Login ({ commit, dispatch }, userInfo) {
      let random = randomString(16)
      const username = userInfo.username.trim()
      const password = Encrypt(userInfo.password, random)
      const uuid = userInfo.uuid
      const systemDomain = userInfo.systemDomain
      return new Promise((resolve, reject) => {
        login(username, password, uuid, systemDomain, random).then(res => {
          setToken(res.data.accessToken)
          commit('SET_TOKEN', res.data.accessToken)
          setRefreshToken(res.data.refreshToken)
          commit('SET_REFRESHTOKEN', res.data.refreshToken)

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 刷新token
    RefreshToken ({ commit }, refreshToken) {
      return new Promise((resolve, reject) => {
        const params = {
          refreshToken
        }
        tokenRefresh(params).then(res => {
          setToken(res.data.accessToken)
          commit('SET_TOKEN', res.data.accessToken)
          setRefreshToken(res.data.refreshToken)
          commit('SET_REFRESHTOKEN', res.data.refreshToken)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    DmpLogin ({ commit }, userInfo) {
      const params = {
        code: userInfo.code,
        state: userInfo.state,
        systemDomain: getSystemDomain(),
        callback: userInfo.callback
      }
      return new Promise((resolve, reject) => {
        dmpAuth(params).then(res => {
          if (res.data.tokenInfo) {
            setToken(res.data.tokenInfo.accessToken)
            commit('SET_TOKEN', res.data.tokenInfo.accessToken)
            setRefreshToken(res.data.tokenInfo.refreshToken)
            commit('SET_REFRESHTOKEN', res.data.tokenInfo.refreshToken)
          } else {
            Cookies.set('dmpUserInfo', res.data)
            commit('SET_DMP_USERINFO', res.data)
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        getUserProfile().then(res => {
          if (res && res.code === 200) {
            const user = res.data
            const avatar = user.avatar === '' ? require('@/assets/images/profile.png') : user.avatar
            if (user.timeZoneOffset) {
              store.dispatch('app/setOffset', user.timeZoneOffset)
            } else {
              store.dispatch('app/setOffset', getOffset())
            }
            // v1.8.0 update
            // res.data.permissions 数据迁移到接口 userCenter/menuPermission/web
            if (res.data.roles && res.data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
              commit('SET_ROLES', res.data.roles)
            } else {
              commit('SET_ROLES', ['ROLE_DEFAULT'])
            }
            commit('SET_USERINFO', user)
            commit('SET_AVATAR', avatar)
            commit('SET_ISREADCLAUSE', user.isReadClause)

            dispatch('DataView')// 获取大屏List
            dispatch('app/GetTextCarousel') // 刷新站内信
            dispatch('GetControlOrgTree').finally(() => {
              resolve(res)
            })
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 运行中的任务（当前用户创建的且任务状态为进行中的任务）
    GetTaskRunning ({ commit, state }) {
      return new Promise((resolve, reject) => {
        getRunning().then(res => {
          if (res && res.code === 200) {
            const data = res.data
            commit('SET_TASK_RUN_DATA', data)
            resolve(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    IsReadClause ({ commit }, isReadClause) {
      return new Promise(resolve => {
        commit('SET_ISREADCLAUSE', isReadClause)
        resolve()
      })
    },

    // 退出系统
    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          commit('SET_PERMISSION_DCMP', false)
          commit('SET_ACTIVEROUTER', [])
          commit('SET_ACTIVETITLE', '')
          commit('SET_ACTIVEBASEPATH', '')
          commit('SET_AGENT_LIST', [])
          store.dispatch('tagsView/delAllViews', [])
          removeToken()
          commit('SET_REFRESHTOKEN', '')
          removeRefreshToken()
          localStorage.removeItem('activeRouter')
          localStorage.removeItem('activeBasePath')
          localStorage.removeItem('agentList')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取未读通知数
    getUnReadCount ({ commit }, data) {
      // const response = await getUnReadCount()
      if (data !== undefined) commit('SET_UN_READCOUNT', data)
    },

    // 刷新未读通知数
    refreshUnReadCount ({ commit }) {
      return new Promise((resolve, reject) => {
        getUnReadCountRequest().then(res => {
          commit('SET_UN_READCOUNT', res.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 当前路由
    ActiveRouter ({ commit }, activeRouter) {
      return new Promise(resolve => {
        commit('SET_ACTIVEROUTER', activeRouter.list)
        commit('SET_ACTIVETITLE', activeRouter.title)
        commit('SET_ACTIVEBASEPATH', activeRouter.basePath)
        localStorage.setItem('activeRouter', JSON.stringify(activeRouter.list))
        localStorage.setItem('activeBasePath', activeRouter.basePath)
        resolve()
      })
    },

    // 清除当前路由
    ClearActiveRouter ({ commit }) {
      return new Promise(resolve => {
        commit('SET_ACTIVEROUTER', [])
        commit('SET_ACTIVETITLE', '')
        commit('SET_ACTIVEBASEPATH', '')
        localStorage.removeItem('activeRouter')
        localStorage.removeItem('activeBasePath')
        resolve()
      })
    },

    // 左侧导航开关
    SwitchClass ({ commit }, switchClass) {
      return new Promise(resolve => {
        commit('SET_SWITCHCLASS', switchClass)
        localStorage.setItem('switchClass', switchClass)
        resolve()
      })
    },

    // 用户信息录入后设置token
    SetToken ({ commit }, tokenInfo) {
      return new Promise(resolve => {
        setToken(tokenInfo.accessToken)
        commit('SET_TOKEN', tokenInfo.accessToken)
        setRefreshToken(tokenInfo.refreshToken)
        commit('SET_REFRESHTOKEN', tokenInfo.refreshToken)
        Cookies.set('dmpUserInfo', '')
        commit('SET_DMP_USERINFO', {})
        resolve()
      })
    },

    // 获取企业OEM配置
    GetCompanyOem ({ commit }) {
      return new Promise((resolve, reject) => {
        getCurUserPlatformOemConf().then(res => {
          const data = res.data
          // 判断
          const search = window.location.search // url的query参数
          let otherTitle = new URLSearchParams(search).get('otherTitle')// url中的otherTitle参数（目前用于组态地址）
          if (!otherTitle && data.systemName) {
            let lang = Cookies.get('lang')
            document.title = lang === 'zh-CN' ? data.systemName : data.systemEnName // 无otherTitle，则替换为企业自定义名称
          }
          if (data.systemFavicon) {
            let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
            link.type = 'image/x-icon'
            link.rel = 'shortcut icon'
            link.href = data.systemFavicon
            document.getElementsByTagName('head')[0].appendChild(link)
          }
          commit('SET_COMPANY_OEM', data)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
        resolve()
      })
    },
    // 获取可控制机构树数据
    GetControlOrgTree ({ commit }) {
      // 递归重置叶子节点的 children 以便适配 el-cascader
      function clearLeaf (trees) {
        trees.forEach(tree => {
          if (!tree.children) {
            return true
          }
          if (tree.children.length === 0) {
            tree.children = null
            return true
          }
          if (tree.childCount === 0) {
            tree.children = null
            return true
          }
          clearLeaf(tree.children)
        })
      }
      return new Promise(resolve => {
        getOrgList({
          isTree: true
        }).then(res => {
          if (res.code === 200) {
            const backup = JSON.parse(JSON.stringify(res.data))
            commit('SET_ALL_ORG_CHILD_TREE', JSON.parse(JSON.stringify(backup)))
            clearLeaf(backup)
            commit('SET_CONTROL_ORG_TREE', backup)
          }
        }).finally(() => {
          resolve()
        })
      })
    }
  }
}

export default user
