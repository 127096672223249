import request from '@/utils/request'
// 根据表名获取列配置
export function getCols (name) {
  return request({
    url: '/system/customField/getCustomConfiguration',
    method: 'get',
    params: { name }
  })
}
// 设置对应表名的列配置
export function setCols (name, data) {
  return request({
    url: '/system/customField/saveCustomConfiguration',
    method: 'post',
    data: { name, data }
  })
}
