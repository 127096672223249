<template>
  <div
    class="sidebar-logo-container"
    :style="{
      backgroundColor: settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4' ? '#07081e' : '#fff'
    }"
  >
    <transition name="sidebarLogoFade">
      <router-link class="sidebar-logo-link" :to="'/' + firstRouters">
        <img
          v-if="showLogo"
          class="sidebar-logo"
          :src="
            settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4'
              ? companyOem.systemInsideDarkLogoUrl
              : companyOem.systemInsideBrightLogoUrl
          "
        />
        <h1
          class="sidebar-title"
          :style="{
            color: settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4' ? '#fff' : '#303133'
          }"
          :title="lang === 'zh-CN' ? companyOem.systemName : companyOem.systemEnName"
        >
          {{ lang === 'zh-CN' ? companyOem.systemName : companyOem.systemEnName }}
        </h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import variables from '@/assets/styles/variables.scss'
import Cookies from 'js-cookie'

export default {
  name: 'SidebarLogo',
  data () {
    return {
      lang: Cookies.get('lang')
    }
  },
  computed: {
    variables () {
      return variables
    },
    ...mapState(['settings']),
    ...mapGetters(['firstRouters', 'companyOem']),
    showLogo () {
      if (this.settings.sideTheme === 'theme1' || this.settings.sideTheme === 'theme4') {
        return !!this.companyOem.systemInsideDarkLogoUrl
      } else {
        return !!this.companyOem.systemInsideBrightLogoUrl
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 66px;
  line-height: 66px;

  & .sidebar-logo-link {
    width: 100%;
    height: 100%;

    & .sidebar-logo {
      width: 24px;
      margin-left: 20px;
      vertical-align: middle;
    }

    & .sidebar-title {
      display: inline-block;
      overflow: hidden;
      width: 139px;
      margin: 0;
      margin-left: 12px;
      font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-size: 14px;
      font-weight: 600;
      vertical-align: middle;
      text-overflow: ellipsis;
      line-height: 50px;
      white-space: nowrap;
    }
  }
}
</style>
