<template>
  <div
    :style="{
      height: '100%',
      width: themeMixinVars.twoSideBarWidth,
      backgroundColor:
        settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4'
          ? themeMixinVars.twoMenuBackground
          : themeMixinVars.twoMenuLightBackground
    }"
  >
    <logo />
    <el-scrollbar
      :class="settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4' ? 'theme-dark' : 'theme-light'"
      wrap-class="scrollbar-wrapper"
    >
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :text-color="
          settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4'
            ? themeMixinVars.twoMenuColor
            : themeMixinVars.twoMenuLightColor
        "
        :unique-opened="true"
        active-text-color="#fff"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="(route, index) in sidebarRouters"
          :key="route.path + index"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SidebarItem from './SidebarItem'
import Logo from './Logo'
import ThemeMixin from '@/components/mixins/theme'

export default {
  components: { SidebarItem, Logo },
  mixins: [ThemeMixin],
  computed: {
    ...mapState(['settings']),
    ...mapGetters(['sidebarRouters', 'sidebar']),
    activeMenu () {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    isCollapse () {
      return !this.sidebar.opened
    }
  }
}
</script>
<style lang="scss" scoped>
.el-scrollbar {
  height: calc(100% - 66px) !important;
}
</style>
