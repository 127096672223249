/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL (url) {
  const reg =
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase (str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase (str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets (str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail (email) {
  return !/(^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString (str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray (arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

export function validDeviceKey (val) {
  return !/^[A-Za-z0-9]{4,32}$/.test(val)
}

export function isPrice (val) {
  return !/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(val)
}

export function isNum (val) {
  return !/^[0-9]*$/.test(val)
}

export function qrNum (val) {
  return !/(^([1-9]\d{0,2}|1000)$)/.test(val)
}

// 系统账号
export function validUsername (val) {
  return !/(^[a-zA-Z0-9]{6,20}$)/.test(val)
}

// 密码
export function validPassword (val) {
  return !/(^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{8,20}$)/.test(val)
}

// 昵称
export function validNickname (val) {
  return !/(^[\u4E00-\u9FA5A-Za-z0-9]+$)/.test(val)
}

// 联系电话
// export function validPhone (val) {
//   return !/(^[0-9-]*$)/.test(val)
// }
// export function validPhone (val) {
//   return !/^1(3|4|5|6|7|8|9)\d{9}$/.test(val)
// }
export function validPhone (val) {
  return !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(val)
}

export function validGlobalPhone (val) {
  return !/^\d{5,20}$/.test(val)
}

// 数字和字母
export function validNumAlp (val) {
  return !/(^[A-Za-z0-9]+$)/.test(val)
}

/**
 * 真实文件格式（前4个字节)
 */
export const ImgExt2Hex = {
  'image/jpg': ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3'],
  'image/jpeg': ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3'],
  'image/png': ['89504e47']
}
/**
 * 检测图片是否是预期的类型
 * @param {File} imgFile 图片文件对象
 * @param {string} imgExt 文件预期真实类型
 * @return {boolean} 文件类型是否和预期一致
 */
export const vaildImageType = (imgFile, imgExt) => {
  const FR = new FileReader()
  // eslint-disable-next-line promise/param-names
  return new Promise(reslove => {
    if (imgFile.size <= 0) {
      return reslove(false)
    } else {
      FR.onload = e => {
        const { type } = imgFile
        const correctExtHex = ImgExt2Hex[imgExt]
        let af = e.target.result
        let view = new DataView(af)
        let first4Byte = view.getUint32(0, false) // 获取32bit数
        let hexValue = Number(first4Byte).toString(16)
        if (!type || !correctExtHex) {
          return reslove(false)
        }
        return reslove(correctExtHex.indexOf(hexValue) > -1)
      }
      FR.readAsArrayBuffer(imgFile)
    }
  })
}

/**
 * 匹配http(s)协议的URL
 * @param {string} str
 * @returns {boolean}
 */
export function validHttpPath (str) {
  return /^https?:\/\/.*/.test(str)
}

/**
 * 匹配大驼峰命名
 * @param {string} str
 * @returns {boolean}
 */
export function validPascal (str) {
  return /^[A-Z][a-zA-Z0-9]*$/.test(str)
}

/**
 * 匹配路由局部路径，支持英文字母和短横线
 * @param {string} str
 * @returns {boolean}
 */
export function validRouterPath (str) {
  return /^[a-zA-Z\-]+$/.test(str)
}

/**
 * 匹配路由权限配置格式
 * @param {string} str
 * @returns {boolean}
 */
export function validRouterPermission (str) {
  return /^[a-z]+(:[a-z]+)*$/.test(str)
}

/**
 * 匹配规则引擎名称
 * @description 支持中文、英文大小写、数字及特殊字符中划线（-）、下划线（_）、空格，不得以特殊字符开头和结尾
 * @param val
 * @returns {boolean}
 */
export function validRuleName (val) {
  const regex = /^(?![_\-\s])[\u4e00-\u9fa5a-zA-Z0-9_\-\s]*(?<![_\-\s])$/
  return !regex.test(val)
}
