<template>
  <router-link
    class="logo"
    :to="'/' + firstRouters"
    :style="{
      backgroundColor:
        settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4' ? variables.menuBg : variables.menuLightBg
    }"
  >
    <img
      v-if="showLogo"
      :src="
        settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4'
          ? companyOem.systemInsideDarkLogoUrl
          : companyOem.systemInsideBrightLogoUrl
      "
    />
    <span
      :style="{
        color: settings.sideTheme === 'theme1' || settings.sideTheme === 'theme4' ? '#fff' : '#303133'
      }"
      >{{ lang === 'zh-CN' ? companyOem.systemName : companyOem.systemEnName }}</span>
  </router-link>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import variables from '@/assets/styles/variables.scss'
import Cookies from 'js-cookie'

export default {
  name: 'SidebarLogo',
  data () {
    return {
      lang: Cookies.get('lang')
    }
  },
  computed: {
    variables () {
      return variables
    },
    sideTheme () {
      return this.$store.state.settings.sideTheme
    },
    ...mapState(['settings']),
    ...mapGetters(['firstRouters', 'companyOem']),
    showLogo () {
      if (this.settings.sideTheme === 'theme1' || this.settings.sideTheme === 'theme4') {
        return !!this.companyOem.systemInsideDarkLogoUrl
      } else {
        return !!this.companyOem.systemInsideBrightLogoUrl
      }
    }
  },
  methods: {
    handleRouter () {
      this.$store.dispatch('ClearActiveRouter').then(() => {
        this.$emit('handleClick')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  position: relative;
  display: flex !important;
  overflow: hidden;
  z-index: 1;
  height: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 34%;
    margin-bottom: 10px;
  }

  span {
    display: block;
    overflow: hidden;
    width: 90%;
    margin: 0 auto;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
