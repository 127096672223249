/**
 * src/layout/components/Sidebar/Two/index.vue
 * src/layout/components/Navbar/index.vue
 */

import variables from '@/assets/styles/variables.module.scss'

const ThemeMixin = {
  computed: {
    themeMixinVars () {
      return variables
    }
  }
}

export default ThemeMixin
