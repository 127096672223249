import hasRole from './hasRole'
import hasPermi from './hasPermi'
import hasGlobalPermi from './hasGlobalPermi'
import hasOrgPermission from './hasOrgPermission'

const install = function (Vue) {
  Vue.directive('hasRole', hasRole)
  Vue.directive('hasPermi', hasPermi)
  Vue.directive('hasGlobalPermi', hasGlobalPermi)
  Vue.directive('hasOrgPermission', hasOrgPermission)
}

if (window.Vue) {
  window.hasRole = hasRole
  window.hasPermi = hasPermi
  window.hasGlobalPermi = hasGlobalPermi
  window.hasOrgPermission = hasOrgPermission

  Vue.use(install); // eslint-disable-line
}

export default install
