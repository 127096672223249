module.exports = {
  title: '通用设备管理平台',
  // 侧边栏主题
  sideTheme: 'theme1',
  // 是否系统布局配置
  showSettings: false,
  // 侧边栏风格
  sideStyle: 'menu2',
  // production or development
  errorLog: 'production',
  amapAk: 'fe605a4aac525328be7ef5b6bedafa32',
  amapSk: '661b887f9d67726f1b8d94b212f5ee7e'
}
