<template>
  <div class="drawer-container">
    <div>
      <div class="setting-drawer-content">
        <div class="setting-drawer-title">
          <h3 class="drawer-title">{{ $t('navbar.themeOptions') }}</h3>
        </div>
        <div class="setting-drawer-block-checbox">
          <div class="setting-drawer-block-checbox-item" @click="handleTheme('theme1')">
            <img src="@/assets/images/theme/theme1.svg" />
            <i v-if="sideTheme === 'theme1'" class="el-icon-check" />
          </div>
          <div class="setting-drawer-block-checbox-item" @click="handleTheme('theme2')">
            <img src="@/assets/images/theme/theme2.svg" />
            <i v-if="sideTheme === 'theme2'" class="el-icon-check" />
          </div>
          <div class="setting-drawer-block-checbox-item" @click="handleTheme('theme3')">
            <img src="@/assets/images/theme/theme3.svg" />
            <i v-if="sideTheme === 'theme3'" class="el-icon-check" />
          </div>
          <div class="setting-drawer-block-checbox-item" @click="handleTheme('theme4')">
            <img src="@/assets/images/theme/theme4.svg" />
            <i v-if="sideTheme === 'theme4'" class="el-icon-check" />
          </div>
        </div>
      </div>

      <el-divider />

      <div class="setting-drawer-content">
        <div class="setting-drawer-title">
          <h3 class="drawer-title">{{ $t('navbar.menuStyle') }}</h3>
        </div>
        <div class="setting-drawer-block-checbox">
          <div class="setting-drawer-block-checbox-item" @click="handleMenu('menu1')">
            <img src="@/assets/images/theme/menu1.svg" alt="dark" />
            <i v-if="sideStyle === 'menu1'" class="el-icon-check" />
          </div>
          <div class="setting-drawer-block-checbox-item" @click="handleMenu('menu2')">
            <img src="@/assets/images/theme/menu2.svg" alt="light" />
            <i v-if="sideStyle === 'menu2'" class="el-icon-check" />
          </div>
        </div>
      </div>

      <el-divider />

      <el-button size="small" type="primary" plain icon="el-icon-document-add" @click="saveSetting">{{
        $t('navbar.save')
      }}</el-button>
      <el-button size="small" plain icon="el-icon-refresh" @click="resetSetting">{{ $t('navbar.reset') }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      theme: this.$store.state.settings.theme,
      sideTheme: this.$store.state.settings.sideTheme,
      sideStyle: this.$store.state.settings.sideStyle
    }
  },
  methods: {
    // 切换主题风格
    handleTheme (val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'sideTheme',
        value: val
      })
      this.sideTheme = val
    },
    // 切换菜单风格
    handleMenu (val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'sideStyle',
        value: val
      })
      this.sideStyle = val
    },
    saveSetting () {
      const loading = this.$loading({
        lock: true,
        fullscreen: false,
        text: this.$t('module.theme.save'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      localStorage.setItem(
        'layout-setting',
        `{
            "sideTheme":"${this.sideTheme}",
            "sideStyle":"${this.sideStyle}"
          }`
      )
      setTimeout(loading.close(), 1000)
    },
    resetSetting () {
      this.$loading({
        lock: true,
        fullscreen: false,
        text: this.$t('module.theme.clear'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      localStorage.removeItem('layout-setting')
      // eslint-disable-next-line no-implied-eval
      setTimeout('window.location.reload()', 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.setting-drawer-content {
  .setting-drawer-title {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
  }

  .setting-drawer-block-checbox {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .setting-drawer-block-checbox-item {
      position: relative;
      border-radius: 2px;
      margin-right: 16px;
      cursor: pointer;

      img {
        width: 80px;
      }

      .el-icon-check {
        position: absolute;
        left: 42%;
        top: 27%;
        font-size: 20px;
        font-weight: 700;
        color: #4ecb73;
      }
    }
  }
}

.drawer-container {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;

  .drawer-title {
    margin-bottom: 12px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
  }

  .drawer-item {
    padding: 12px 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }

  .drawer-switch {
    float: right;
  }
}
</style>
