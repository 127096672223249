export default {
  common: {
    fieldName: '请输入字段名称',
    fieldCode: '请输入字段code',
    selectType: '请选择类型',
    createTime: '创建时间',
    updateTime: '更新时间',
    time: '时间',
    confirm: '确定',
    cancel: '取消',
    search: '搜索',
    reset: '重置',
    restore: '恢复默认',
    action: '操作',
    config: '配置',
    edit: '编辑',
    close: '关闭',
    save: '保存',
    success: '成功',
    show: '展示',
    hide: '隐藏',
    open: '开启',
    delete: '删除',
    remove: '移除',
    removeAll: '全部移除',
    change: '替换',
    multiDelete: '批量删除',
    publish: '发布',
    add: '新增',
    addTo: '添加',
    view: '预览',
    next: '下一步',
    prev: '上一步',
    editSuccess: '编辑成功',
    deleteSuccess: '删除成功',
    recycleSuccess: '回收成功',
    paySuccess: '支付成功',
    deleteFail: '删除失败',
    addSuccess: '新增成功',
    addToSuccess: '添加成功',
    publishSuccess: '发布成功',
    modifySuccess: '修改成功',
    bindSuccess: '绑定成功',
    unbindSuccess: '解绑成功',
    unbindCancel: '已取消解绑',
    saveSuccess: '保存成功',
    updateSuccess: '更新成功',
    actionSuccess: '操作成功',
    successfulTip: '{0}成功',
    uploadFail: '上传失败',
    tipUpload: '点击上传',
    tipUploadImg: '请上传".jpg"、".png"格式的图片',
    tipUploadXls: '提示：仅允许导入“xls”或“xlsx”格式文件。',
    tipUploadXls2M: '1.仅允许导入“xls”或“xlsx”格式文件，小于2M',
    tipUploadImg10M: '仅支持上传jpg/png格式文件，小于10M',
    tipUploadPdf: '只能上传PDF格式文件',
    uploadMaxLength: '文件超出个数',
    tipUploadIco: '请上传".ico"格式的图片',
    sure: '确认要',
    sureDel: '是否确认删除?',
    sureUpdate: '是否确认更改?',
    warn: '警告',
    import: '导入',
    export: '导出',
    exportSuccess: '导出成功',
    exportFail: '导出失败',
    importRecords: '导入记录',
    uploadFiles: '上传文件',
    dragFile: '将文件拖到此处，或',
    productName: '产品名称',
    prompt: '提示',
    see: '查看',
    seeDetails: '查看详情',
    basicInfo: '基本信息',
    info: '信息',
    back: '返回',
    start: '开始',
    pause: '暂停',
    noData: '暂无数据',
    noDevice: '暂无设备',
    to: '至',
    minute: '分钟',
    hour: '小时',
    second: '秒',
    location: '位置',
    email: '邮件',
    sms: '短信',
    voice: '语音',
    enter: '进入',
    no: '否',
    yes: '是',
    zh: '中文',
    en: '英文',
    logout: '确定退出系统吗？',
    cookiesInvalid: '登录状态已过期，您可以继续留在该页面，或者重新登录',
    sysHint: '系统提示',
    reLogin: '重新登录',
    networkError: '后端接口连接异常',
    timeOut: '系统接口请求超时',
    systemInterface: '系统接口异常',
    copy: '复制',
    clipboardSuccess: '复制成功',
    clipboardError: '复制失败',
    dim: '统计维度',
    days: '天',
    years: '年',
    remark: '备注',
    updateData: '更新数据',
    enable: '开启',
    disable: '关闭',
    keywordPlace: '请输入关键词',
    enableState: '启用状态',
    sureClose: '确认关闭？',
    sureOpen: '确认开启？',
    ownOrg: '归属机构',
    targetOrg: '目标机构',
    targetOrgPh: '请选择目标机构',
    notFoundResult: '未找到结果',
    seeChildrenOrgData: '显示下级机构数据',
    applicationScenarios: '应用场景',
    all: '全部',
    default: '默认',
    selected: '选中',
    qrCode: '二维码',
    startTime: '开始时间',
    endTime: '结束时间',
    signalEnable: '启用',
    signalDisable: '停用',
    batchSet: '批量指令下发',
    weeks: {
      sun: '星期日',
      mon: '星期一',
      tues: '星期二',
      wed: '星期三',
      thur: '星期四',
      fri: '星期五',
      sat: '星期六' // 固定顺序
    },
    deviceKeyOrName: '设备名称/DeviceKey',
    unit: '单位',
    product: '归属产品',
    details: '详情',
    batchControl: '批量控制',
    select: '筛选',
    allList: '所有设备',
    selectable: '可选',
    selected: '已选'
  },
  navbar: {
    personal: '个人中心',
    company: '企业中心',
    logout: '退出登录',
    auditLog: '审计日志',
    themeOptions: '主题风格设置',
    menuStyle: '菜单模式',
    save: '保存配置',
    reset: '重置配置',
    dataView: '可视化大屏',
    yhs: '数据大屏'
  },
  m: {
    app: {
      demo: '导航'
    },
    dashboard: '数据看板',
    product: '产品管理',
    device: '设备管理',
    agent: '代理商管理',
    end: '终端管理',
    company: '组织管理',
    sys: '系统管理',
    monitor: '系统监控',
    device_list: '设备列表',
    data_view_btn: '数据大屏',
    group: '安装点管理',
    device_map: '设备地图',
    group_list: '安装列表',
    person_list: '负责人列表',
    device_fence: '电子围栏',
    agent_list: '代理商列表',
    agent_select: '代理商查询',
    agent_add: '代理商新增',
    agent_update: '代理商修改',
    agent_delete: '代理商删除',
    agent_export: '代理商导出',
    device_share: '已分配设备',
    expense: '资费管理',
    expense_charge: '资费充值',
    expense_institution: '机构资费',
    end_user: '终端用户',
    end_user_detail: '终端用户详情',
    end_feedback: '用户反馈',
    end_question: '常见问题',
    category: {
      title: '产品品类管理',
      add_btn: '添加品类',
      choose_category: '品类选择',
      app_detail_btn: 'App详情页管理',
      miniApp_detail_btn: '小程序详情页管理',
      admin_detail_btn: '后台详情页管理',
      table_Level1: '一级品类',
      table_Level2: '二级品类',
      table_apppath: 'App路径',
      table_servepath: '后台路径',
      app_detail_name: '详情页名称',
      app_detail_path: '路径',
      save_validate_error: '请完成表单内容',
      can_not_null: '不能为空',
      category_dialog_title: '编辑/添加产品品类',
      no_select_category_sub: '请选择子分类',
      detailName: '详情页名称',
      webDetailName: 'Web端详情页',
      appDetailName: '移动端详情页',
      miniAppDetailName: '小程序详情页',
      canNotNull: '不能为空'
    },
    letter: {
      detail: '查询详情',
      announcement: '通知公告',
      deviceMsg: '设备消息',
      allread: '全部已读',
      table_title: '标题',
      table_time: '创建时间',
      type: '类型',
      table_release_time: '发布时间',
      status: '状态',
      eq_number: 'DeviceKey',
      product_name: '产品名称',
      product_key: 'ProductKey',
      device_name: '设备名称',
      device_key: 'DeviceKey',
      warn_type: '告警类型',
      warn_time: '告警时间',
      createdNotice: '创建公告',
      fixedTop: '置顶滚动',
      accpected: '接收目标',
      releaseNow: '立即发布',
      releaseDelay: '定时发布',
      scroll: '滚动',
      all: '所有企业',
      designated: '指定企业',
      temporary: '暂存编辑',
      edit_header: '编辑/创建站内信',
      show_header: '查看站内信',
      canNot: '{0}不能为空',
      status1: '草稿',
      status2: '计划发布',
      status3: '已发布',
      status4: '发布失败',
      feedbackMsg: '反馈通知'
    },
    sys_aggregation: '数据聚合配置',
    sys_pro_category: '产品品类',
    sys_category: '品类管理',
    sys_letter: '站内信',
    sys_letter_admin: '站内信管理',
    sys_letter_edit: '站内信编辑',
    sys_user: '用户管理',
    sys_user_select: '用户查询',
    sys_user_add: '用户新增',
    sys_user_update: '用户修改',
    sys_user_delete: '用户删除',
    sys_user_export: '用户导出',
    sys_user_import: '用户导入',
    sys_reset_password: '重置密码',
    sys_role: '角色管理',
    sys_role_select: '角色查询',
    sys_role_add: '角色新增',
    sys_role_update: '角色修改',
    sys_role_delete: '角色删除',
    sys_role_export: '角色导出',
    sys_menu: '菜单管理',
    sys_menu_select: '菜单查询',
    sys_menu_add: '菜单新增',
    sys_menu_update: '菜单修改',
    sys_menu_delete: '菜单删除',
    sys_dept: '部门管理',
    sys_dept_select: '部门查询',
    sys_dept_add: '部门新增',
    sys_dept_update: '部门修改',
    sys_dept_delete: '部门删除',
    sys_post: '岗位管理',
    sys_post_select: '岗位查询',
    sys_post_add: '岗位新增',
    sys_post_update: '岗位修改',
    sys_post_delete: '岗位删除',
    sys_post_export: '岗位导出',
    sys_dict: '字典管理',
    sys_dict_select: '字典查询',
    sys_dict_add: '字典新增',
    sys_dict_update: '字典修改',
    sys_dict_delete: '字典删除',
    sys_dict_export: '字典导出',
    sys_config: '系统配置',
    sys_config_select: '参数查询',
    sys_config_add: '参数新增',
    sys_config_update: '参数修改',
    sys_config_delete: '参数删除',
    sys_config_export: '参数导出',
    sys_notice: '通知公告',
    sys_notice_select: '公告查询',
    sys_notice_add: '公告新增',
    sys_notice_update: '公告修改',
    sys_notice_delete: '公告删除',
    sys_log: '日志管理',
    sys_log_opera: '操作日志',
    sys_log_opera_select: '操作查询',
    sys_log_opera_delete: '操作删除',
    sys_log_opera_export: '日志导出',
    sys_log_login: '登录日志',
    sys_login_select: '登录查询',
    sys_login_delete: '登录删除',
    job_log: '调度日志',
    monitor_online: '在线用户',
    m_online_select: '在线查询',
    m_batch_logout: '批量强退',
    m_force_logout: '单条强退',
    monitor_time: '定时任务',
    m_task_select: '任务查询',
    m_task_add: '任务新增',
    m_task_update: '任务修改',
    m_task_delete: '任务删除',
    m_status_update: '状态修改',
    m_task_export: '任务导出',
    user_profile: '个人中心',
    company_config: '企业配置',
    auditlog_config: '审计日志',
    company_role: '权限配置',
    dict_data: '字典数据',
    function: '功能定义',
    notice_email: '邮件通知',
    notice_sms: '短信通知',
    notice_voice: '语音通知',
    notice_app: 'App通知',
    group_manage_device: '设备管理',
    group_add: '设备安装点',
    device_detail: '设备详情',
    device_status: '设备概况',
    gateway_detail: '网关详情',
    sub_device_detail: '网关子设备详情',
    fence_add: '自定义围栏',
    fence_bind: '绑定设备',
    scooter_opera: '运营管理',
    scooter_shop: '授权门店',
    scooter_repair: '在线报修',
    scooter_lose: '失窃上报',
    lose_manual: '说明书',
    store_info: '门店信息',
    sim: 'SIM卡管理',
    device_info: '设备信息',
    device_attr: '设备属性',
    device_mileage: '里程报表',
    device_fault: '故障日志',
    device_alarm: '告警日志',
    device_set: '下发指令',
    device_sim: 'SIM卡',
    appVersion: 'APP版本管理',
    sys_notify: '通知管理',
    sys_notify_sms: '短信模板',
    sys_notify_email: '邮件模板',
    sys_notify_voice: '语音模板',
    sys_notify_app: 'APP推送模板',
    m_agent_select: '代理商查询',
    m_agent_add: '代理商新增',
    m_agent_update: '代理商修改',
    m_agent_delete: '代理商删除',
    m_agent_export: '代理商导出',
    m_user_select: '用户查询',
    m_user_add: '用户新增',
    m_user_update: '用户修改',
    m_user_delete: '用户删除',
    m_user_export: '用户导出',
    m_user_import: '用户导入',
    m_reset_password: '重置密码',
    m_role_select: '角色查询',
    m_role_add: '角色新增',
    m_role_update: '角色修改',
    m_role_delete: '角色删除',
    m_role_export: '角色导出',
    m_menu_select: '菜单查询',
    m_menu_add: '菜单新增',
    m_menu_update: '菜单修改',
    m_menu_delete: '菜单删除',
    m_dept_select: '部门查询',
    m_dept_add: '部门新增',
    m_dept_update: '部门修改',
    m_dept_delete: '部门删除',
    m_post_select: '岗位查询',
    m_post_add: '岗位新增',
    m_post_update: '岗位修改',
    m_post_delete: '岗位删除',
    m_post_export: '岗位导出',
    m_dict_select: '字典查询',
    m_dict_add: '字典新增',
    m_dict_update: '字典修改',
    m_dict_delete: '字典删除',
    m_dict_export: '字典导出',
    m_sys_config_select: '参数查询',
    m_sys_config_add: '参数新增',
    m_sys_config_update: '参数修改',
    m_sys_config_delete: '参数删除',
    m_sys_config_export: '参数导出',
    m_notice_select: '公告查询',
    m_notice_add: '公告新增',
    m_notice_update: '公告修改',
    m_notice_delete: '公告删除',
    m_add_group: '新增安装点',
    m_edit_group: '编辑安装点',
    m_group_batch_set: '批量设置',
    m_group_manage_dev: '管理设备',
    m_group_set_person: '设置负责人',
    m_group_edit: '编辑',
    m_group_delete: '删除',
    m_person_add: '新增',
    m_person_edit: '编辑',
    m_person_delete: '删除',
    sys_upload: '文件管理',
    sys_dashboard: '看板管理',
    sys_dashboard_data: '数据源配置',
    sys_dashboard_card: '看板配置',
    appmanage: 'APP版本管理',
    fault_warning: '故障告警',
    fault_warning_detail: '故障告警详情',
    operation_management: '运维管理',
    operation_my_order: '我的工单',
    operation_repair: '设备保修',
    operation_manege: '工单管理',
    send_order_select: '派单维修人员',
    syu_web_menu: 'Web菜单',
    syu_wx_app_menu: '小程序菜单',
    group_set_code: '安装管理-字段设置',
    wxapp: {
      m_index: '首页',
      m_msg: '消息',
      m_statistics: '统计',
      m_mine: '我的',
      m_deviceList: '设备列表',
      m_devDetail: '设备详情',
      m_portInfo: '端口信息',
      enablePort: '启用端口',
      disablePort: '禁用端口',
      m_orderRecord: '订单记录',
      orderDetail: '订单详情',
      refundBtn: '退款',
      m_deviceStatus: '设备状态',
      m_deviceAlarm: '故障告警',
      m_workOrder: '工单',
      m_alarm: '告警',
      m_question: '问题',
      addWOBtn: '登记工单',
      forceEndWO: '强制结单',
      endWO: '办结',
      deliveryWO: '派单',
      cancelWO: '取消',
      alarmToWO: '转工单',
      ignoreAlarm: '忽略',
      handleAlarm: '处理',
      questionToWO: '转工单',
      handleQuestion: '处理',
      m_user_info: '个人信息',
      backWO: '退单'
    },
    fault_warn_manage: '报警管理',
    fault_warn_contact: '报警联系人',
    common_screen_view: '可视化大屏',
    company_organization: '机构管理',
    company_organ_role: '角色管理',
    company_organ_user: '用户管理',
    posigroup: '安装点管理',
    fault_warn_config: '接警配置',
    idst_scen_admin: '行业场景管理',
    idst_scen_man: '行业场景管理',
    idst_scen_orgtype: '机构类型管理',
    idst_scen_set: '场景配置',
    idst_scen_menu: '菜单管理',
    idst_scen_role: '角色管理',
    org_type_admin: '机构类型管理',
    product_custom: '字段设置',
    project_manage: '报表管理',
    project_manage_cust: '数据报表',
    project_manage_rule: '报表计划',
    configuration_list: '组态管理',
    product_dcmp: '权限配置-数采配置',
    product_action_set: '产品管理-操作设置'
  },
  valid: {
    required: '必填',
    userName: '用户名不能为空',
    passWord: '密码不能为空',
    code: '验证码不能为空',
    name: '名称不能为空',
    normal: '只能输入中英文以及数字',
    filedName: '字段命名不能为空',
    templateName: '模板名称不能为空',
    templateContent: '模板内容不能为空',
    checkPeople: '请勾选接收人员',
    leastOne: '至少填写一个收件人',
    removeAlarmParmValue: '请选择解除告警参数值！',
    sendNotice: '请选择是否需要发送通知！',
    ruleName: '支持中文、英文大小写、数字及特殊字符中划线（-）、下划线（_）、空格，不得以特殊字符开头和结尾',
    fence: {
      length: '电子围栏名称长度不能超过30个字符',
      repeat: '您输入的电子围栏名称已存在',
      format: '请输入正确的电子围栏名称'
    },
    agent: {
      required: '请输入代理商名称',
      repeat: '代理商名称重复！'
    },
    mobile: {
      required: '请输入联系电话',
      format: '请输入正确的联系电话',
      repeat: '联系电话重复！'
    },
    sysAccount: {
      required: '请输入系统账号',
      format: '请输入至少6位字母、数字或组合',
      format2: '请输入6-20位字母或数字',
      repeat: '系统账号重复！'
    },
    email: {
      required: '请输入邮箱',
      format: '请输入正确的邮箱地址',
      repeat: '邮箱重复！'
    },
    label: {
      repeat: '请勿添加重复标签！'
    },
    uploadFiles: '请上传文件',
    nickName: {
      required: '请输入用户昵称',
      format: '请输入中文、字母或数字',
      repeat: '用户昵称重复！'
    },
    phoneNumber: {
      required: '手机号码不能为空',
      format: '请输入正确的手机号码'
    },
    password: {
      required: '请输入密码',
      format: '密码长度8到20位，必须包含大小写字母和数字'
    },
    equalToPassword: '两次输入的密码不一致',
    oldPassword: '旧密码不能为空',
    confirmPassword: '确认密码不能为空',
    uploadImg: '文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件',
    access: {
      AccessKeyRequired: 'AccessKey不能为空',
      AccessSecretRequired: 'AccessSecret不能为空'
    },
    domain: {
      required: '请配置域名',
      repeat: '域名重复！'
    },
    loginTemplate: '请选择登录模板',
    loginPageLogo: '请上传登录页面logo',
    sysInnerPageLogo: '请上传系统内部页面logo',
    loginBg: '请上传登录页背景图',
    numAlp: '请输入字母或数字',
    systemName: '请输入网站名称',
    systemEnName: '请输入网站英文名称',
    url: '请输入正确的URL地址'
  },
  place: {
    enter: '请输入',
    select: '请选择',
    selectDeadline: '请选择截止时间',
    selectHandleStatus: '请选择处理状态',
    selectDate: '请选择日期时间',
    selectCompany: '请选择企业',
    selectProduct: '请选择产品',
    selectLocationType: '请选择定位方式',
    queryEnterprise: '请选择机构',
    selectTriggerState: '请选择触发状态',
    selectFenceType: '请选择围栏类型',
    selectEffectiveType: '请选择围栏生效时间类型',
    selectOperationState: '请选择运营状态',
    selectTenant: '请选择厂商',
    selectAgent: '请选择代理商',
    selectCountry: '请选择国家或地区',
    selectState: '请选择州',
    selectProvince: '请选择省',
    selectCity: '请选择市',
    selectArea: '请选择区县',
    enterDeviceKey: '请输入DeviceKey',
    queryFenceName: '请输入电子围栏名称',
    enterFenceName: '请输入电子围栏名称',
    enterPhone: '请输入手机号码',
    enterAgentName: '代理商名称',
    enterParentName: '直属上级',
    enterSysAccount: '请输入登录账号',
    enterLinkMain: '请输入联系人',
    enterAdd: '请输入详细地址',
    enterEmail: '请输入邮箱',
    enterUserId: '请输入用户ID',
    enterQuestion: '请输入问题描述',
    enterAnswer: '请输入解决方法',
    enterOldPassword: '请输入旧密码',
    enterNewPassword: '请输入新密码',
    enterEventName: '请输入事件名称',
    confirmPassword: '请确认密码',
    placeContent: '请输入内容',
    selectTemplate: '请选择关联模版',
    service: '服务协议',
    placeZhService: '请输入协议名称（中文）',
    placeZhServiceUrl: '请输入有效url链接（中文）',
    placeEnService: '请输入协议名称（英文）',
    placeEnServiceUrl: '请输入有效url链接（英文）',
    privacy: '隐私政策',
    placeZhPrivacy: '请输入隐私政策名称（中文）',
    placeZhPrivacyUrl: '请输入有效url链接（中文）',
    placeEnPrivacy: '请输入隐私政策名称（英文）',
    placeEnPrivacyUrl: '请输入有效url链接（英文）',
    placeInt: '请输入正确数字',
    ownOrg: '请选择归属机构',
    address: '请选择地址',
    applicationScenarios: '请选择应用场景',
    aboutUs: '关于我们',
    sureWithinRange: '请确保已选数量不超过{0}',
    noSelected: '暂无选中数据，请在左侧选择',
    noSelectable: '暂无可选{0}'
  },
  onlineStatus: {
    title: '在线状态',
    offline: '离线',
    online: '在线'
  },
  clearType: {
    item1: '设备自动解除',
    item2: '人工手动解除'
  },
  deviceNoticeType: {
    fault: '故障',
    alarm: '告警',
    faultRelease: '故障解除',
    alarmRelease: '告警解除',
    faultReleaseDetail: '故障解除详情',
    alarmReleaseDetail: '告警解除详情',
    fenceIn: '电子围栏入界',
    fenceOut: '电子围栏出界',
    fenceDouble: '电子围栏双向',
    fenceClose: '电子围栏关闭',
    removed: '已解除'
  },
  operatingStatus: {
    title: '运行状态',
    inactivated: '未激活',
    normal: '正常',
    warn: '告警',
    error: '故障',
    errorwarn: '故障+告警',
    info: '信息事件',
    erroring: '故障',
    errorFix: '解除故障',
    warning: '告警',
    warnFix: '解除告警',
    fence: '电子围栏',
    fenceIn: '入界',
    fenceOut: '出界',
    message: '消息'
  },
  expenseStatus: {
    title: '资费状态',
    off: '待启用',
    trial: '试用',
    normal: '正常',
    overdue: '到期'
  },
  handleStatus: {
    title: '处理状态',
    text1: '已处理',
    text2: '未处理'
  },
  login: {
    title: '登录',
    place: {
      user: '请输入账号',
      password: '请输入密码',
      code: '请输入验证码'
    },
    codeLogin: '验证码登录',
    findPassword: '忘记密码',
    confirmLogin: '确认登录',
    logging: '登 录 中...',
    oauthLogin: '授权登录',
    question: '登录遇到问题？',
    faq: '常见问题',
    otherWay: '其他方式登录',
    howregister: '如何注册？',
    easyPwd: '你的密码过于简单，需修改密码后才能继续访问系统。<br />密码长度8至20位，必须包含大小写字母和数字。',
    serVerHistory: '服务协议历史版本',
    priVerHistory: '隐私协议历史版本',
    version: '版本号',
    update: '更新时间',
    remark: '更新说明'
  },
  findPassword: {
    title: '忘记密码',
    pwd: '密码',
    accountTip: '请输入登录账号',
    accountPlaceholder: '用户名',
    enterAccount: '请输入账号',
    noAccount: '用户名不存在',
    backLogin: '返回登录',
    sendTip: '将往以下邮箱发送重置密码链接，请确认是否您使用的邮箱',
    sendEmail: '发送邮件',
    sendSuccess: '邮件已成功发送到您的邮箱！',
    sendSuccessTip: '如果您未收到邮件，请注意查看垃圾邮件或者重试',
    close: '关闭',
    resend: '重新发送',
    mailSource: '请注意来自“{0}”的邮件',
    resetPassword: '重置密码',
    linkFailure: '抱歉，重置链接已失效！',
    newPasswordLabel: '新密码（8-20位大小写字母，数字组合）',
    newPasswordPlaceholder: '请输入您的新密码',
    confirmPasswrodLabel: '确认密码',
    confirmPasswrodPlaceholder: '请确认您的新密码',
    confirmUpdate: '确认修改',
    updateSuccess: '修改成功',
    goLogin: '去登录',
    passwrodTip: '请输入新密码',
    IllegalPassword: '非法的密码',
    inconformityPassword: '两次输入密码不一致!',
    passwrodAgenTip: '请再次输入密码',
    notLogin: '未登录',
    newPsw: '设置新密码',
    newPswAgain: '再次输入密码',
    enterSmsCode: '请输入验证码',
    sendSmsCode: '获取验证码',
    smsCodeTip: '验证码发送成功,5分钟内有效',
    findByPhone: '手机号找回密码',
    findByEmail: '邮箱找回密码',
    submit: '提交',
    toLogin: '密码修改成功,即将跳转登录页'
  },
  dashboard: {
    online: {
      title1: '设备',
      title2: '在线率',
      onlineDevice: '在线设备',
      offlineDevice: '离线设备',
      tipTitle: '设备状态'
    },
    area: {
      title: '设备地域分布',
      cityTitle: '城市',
      countryTitle: '国家',
      numTitle: '设备数',
      percentTitle: '占比'
    },
    active: {
      title: '设备活跃趋势',
      tip: '活跃数'
    },
    alert: {
      title: '告警统计',
      tip: '告警数'
    },
    malfunction: {
      title: '故障统计',
      tip: '故障数'
    },
    deviceTotal: '设备总数',
    equipment: '正常设备',
    Alarmequipment: '告警设备',
    Faultyequipment: '故障设备',
    onlinedevicesNum: '在线设备数',
    devicerate: '在线设备率',
    devicecount: '离线设备数',
    OfflineDevicerate: '离线设备率',
    deviceActive: '设备活跃率',
    userTotal: '用户总数',
    userActive: '用户活跃度',
    allProduct: '全部产品',
    today: '今天',
    weekDate: '近一周',
    monthDate: '近一月',
    startDateRange: '开始日期',
    endDateRange: '结束日期',
    dateSelect: {
      today: '日',
      week: '周',
      month: '月'
    },
    countType: {
      day: '日',
      month: '月',
      year: '年'
    },
    unit: {
      kw: 'kW',
      kwh: 'kW·h',
      kwp: 'kWp',
      days: '天',
      tree: '棵',
      tonne: '吨'
    },
    timeSelect: '时间筛选',
    cancelEdit: '取消编辑',
    saveEdit: '保存编辑',
    kanbanEdit: '看板编辑',
    kanbanReset: '重置看板',
    kanbanConfig: '首页看板配置',
    resetSuccess: '重置成功',
    total: '总计',
    noRanking: '暂无排名信息',
    resetTips: '重置看板将清除您当前的看板组件和布局配置，恢复到系统默认配置，确认是否继续？'
  },
  dataScreen: {
    emsTitle: '能源管理数据大屏',
    socialContribution: '碳中和贡献',
    cumulativeEnergySavings: '累计节煤',
    emissionReductionCO2: 'CO2减排',
    equivalentTreesPlanted: '等效植树量',
    powerStation: '发电站',
    powerStationCount: '电站总数',
    powerStationInfo: '电站概况',
    powerStationRanking: '电站排名',
    generatedEnergy: '发电量',
    profitAnalysis: '收益分析',
    curveAnalysis: '曲线分析',
    coulometricAnalysis: '电能分析',
    energyConsumptionAnalysis: '能耗分析',
    allStationDeviceOnline: '全部设备在线电站',
    partStationDeviceOnline: '部分设备离线电站',
    noStationDeviceOnline: '全部设备离线电站',
    ranking: '排名',
    profit: '收益',
    powerStationType: {
      home: '家用',
      commercial: '商用',
      industrial: '工业用',
      floor: '地面电站'
    },
    powerStationSum: {
      dailyGeneration: '今日发电量',
      totalGeneration: '累计发电量',
      dailyDischarge: '今日放电量',
      totalDischarge: '累计放电量',
      dailyCharge: '今日充电量',
      totalCharge: '累计充电量'
    },
    props: {
      solarPower: '发电功率',
      loadPower: '负载总功率',
      batteryPowerBatteryStatus1: '电池放电功率',
      batteryPowerBatteryStatus0: '电池充电功率',
      solarPanelPowerGeneration: '发电量',
      loadPowerConsumption: '用电量',
      batteryDischarge: '电池放电量',
      batteryCharge: '电池充电量',
      gridConnectedQuantity: '卖电量',
      electricitPurchase: '购电量',
      batteryPower: '电池功率',
      batteryPowerNegative: '电池放电功率',
      batteryPowerPositive: '电池充电功率',
      soc: '电池SOC'
    },
    unit: {
      tree: '棵',
      tonne: '吨',
      kwh: 'kW·h'
    }
  },
  product: {
    batchSet: '批量设置标签',
    labelManage: '标签管理',
    transparentTransmission: '透传',
    tsl: '物模型',
    search: {
      connProtocol: '全部连接协议',
      dataFormat: '全部数据格式',
      productName: '请输入产品名称'
    },
    tableTitle: {
      productName: '产品名称',
      vendorName: '归属机构',
      connProtocol: '连接协议',
      dataFormat: '数据格式',
      status: '发布状态'
    },
    status: {
      published: '已发布',
      unPublished: '未发布'
    },
    actionBtn: {
      device: '设备列表',
      function: '功能定义',
      category: '品类配置'
    },
    queue: '消息队列',
    queuePlace: '请输入消息队列',
    queueDisable: '请先禁用队列',
    queueStatus: {
      title: '队列监听',
      stop: '未启用',
      start: '运行中',
      empty: '空状态',
      enable: '启用',
      disable: '禁用'
    },
    startSuccess: '启动成功',
    closeSuccess: '关闭成功',
    function: {
      tabAttr: '属性定义',
      tabEvent: '事件定义',
      tabService: '服务定义',
      tabCustom: '字段设置',
      dataAcq: '数据存储服务',
      timedRefresh: '定时刷新',
      month: '月前',
      week: '周前',
      day: '天前',
      hour: '小时前',
      min: '分钟前',
      just: '刚刚',
      attr: {
        autoRefresh: '主动采集',
        addLabel: '新增标签',
        editLabel: '编辑标签',
        edit: '编辑属性',
        editWarning: '设备定制详情页暂不支持编辑',
        labelFormatLimit: '当前仅支持20个自定义标签',
        labelInputPh: '请输入标签名称',
        labelColorPh: '请选择标签颜色',
        labelSelectPh: '请选择标签',
        tableName: '功能名称',
        tableSubType: '读写类型',
        tableCode: '标识符',
        tableDataType: '数据类型',
        tableIcon: '图标',
        tableUnit: '单位',
        tableDisplay: '设备详情属性展示',
        tableSort: '排序',
        subTypeR: '只读',
        subTypeW: '只写',
        subTypeRW: '读写',
        placeName: '请输入功能名称',
        placeIcon: '点击选择图标',
        placeUnit: '请输入单位',
        placeParm: '请输入参数名称',
        placeIntMap: '默认INT类型',
        typeMapping: '类型映射',
        paramEditor: '参数编辑',
        typeMappingTip: '注意：设置“类型映射”后，将不支持配置“设备搜索条件展示”',
        typeTime: '时间',
        typeWeek: '星期值',
        sensorIcon: '传感器图标',
        enableReport: '是否开启报表',
        parmName: '参数名称',
        allowControl: '指令下发控制',
        allowSearch: '设备搜索条件展示',
        allowTable: '设备列表展示',
        attrSet: '属性设置',
        tableDisplayTip: '用于配置“设备详情-设备属性”可展示的属性',
        allowControlTip: '用于配置“设备详情-下发指令”可远程控制的属性',
        allowSearchTip: '用于配置“设备列表”可在搜索栏筛选的属性',
        allowTableTip: '用于配置“设备列表”可在表格中展示的属性',
        xcxDeviceDisplayTip: '用于配置“设备列表”可在小程序中展示的属性',
        xcxMountDisplayTip: '用于配置“安装点”可在小程序中展示的属性',
        mapDisplayTip: '用于配置“设备地图”可在浮窗中展示的属性',
        labelName: '标签名称',
        labelColor: '标签颜色',
        xcxDeviceDisplay: '小程序设备列表展示',
        xcxMountDisplay: '小程序安装点展示',
        mapDisplay: '地图浮窗展示',
        warningText: {
          display: '确定在设备详情中{0}吗？',
          enableControl: '确定在下发指令页{0}的控制吗？',
          tableDisplay: '确定在设备列表中{0}吗？',
          searchDisplay: '确定在设备列表搜索条件中{0}吗？',
          xcxDeviceDisplay: '确定在小程序设备列表中{0}吗？',
          xcxMountDisplay: '确定在小程序安装点页{0}吗？',
          mapDisplay: '确定在设备地图的地图弹窗中{0}吗？'
        }
      },
      event: {
        tableName: '事件名称',
        tableCode: '标识符',
        tableSubType: '事件类型',
        warn: '告警',
        info: '信息',
        error: '故障',
        unknown: '未知',
        config: '事件配置',
        parmName: '参数名称',
        parmType: '参数类型',
        parmValue: '参数值',
        placeName: '请输入事件名称',
        placeParmName: '请选择参数名称',
        placeParmType: '请输入参数类型',
        noConfig: '不可配置！',
        configSuccess: '配置成功',
        downloadLog: '日志下载',
        dateRange: '请选择时间范围',
        solvedValue: '解除{0}参数值',
        solvedNotice: '解除{0}通知'
      },
      service: {
        tableName: '服务名称',
        tableCode: '标识符',
        enableTip: '确定在下发指令页隐藏服务{0}的控制吗？',
        disableTip: '确定在下发指令页开启服务{0}的控制吗？'
      },
      custom: {
        overallOverview: '总体概况',
        addField: '新增字段',
        editField: '编辑字段',
        configuration: '字段设置',
        fieldType: '字段类型',
        filedName: '字段名称',
        filedCode: '字段code',
        required: '是否必填',
        placeFiledName: '请输入字段命名',
        placeFiledCode: '请输入字段code',
        validRequired: '请选择是否必填',
        desc1: '自定义字段在设备导入时使用',
        desc2: '用于记录设备的特殊属性，例如出厂日期、设备独立标识等内容',
        confirm1: '是否确认删除字段名称为',
        confirm2: '的数据项?',
        fliedPosi: '字段显示位置',
        display: '设备列表',
        search: '设备搜索条件',
        xcxDisplay: '小程序设备列表',
        xcxIsSearch: '小程序设备搜索条件',
        businessOnly: '是否仅厂商可见'
      },
      action: {
        service: '服务',
        property: '属性',
        presetInfo: '开启预设置后，需要在下方配置好下发值，实现在设备列表进行一键操作。',
        topInfo: '选择对应的功能及设置值，配置成列表的操作按钮。最多可配置4个单项操作。',
        name: '操作名称',
        relationProp: '关联属性',
        only: '单项操作',
        multi: '多项操作',
        add: '新增操作',
        preSet: '预设置',
        type: '下发类型',
        setProperty: '设置值',
        inputParameter: '输入参数'
      }
    },
    propController: {
      title: '属性隐藏管理',
      filterPh: '请输入功能名称或标识符进行搜索',
      tips: '当{0}为“{1}”时，系统将过滤以下选中属性，不作展示'
    },
    fileImgTip: '只能上传jpg/png格式文件，建议上传尺寸：800*800像素',
    fileImgSizeTip: '上传图片大小不能不超过500kb',
    place: {
      productName: '请输入ProductKey/产品名称',
      nameOrPk: 'ProductKey/产品名称',
      appPageName: '请选择移联万物App详情页',
      miniAppPageName: '请选择小程序详情页',
      webPageName: '请选择SaaS详情页'
    },
    deviceNum: '设备数量',
    accessType: '设备类型',
    netWay: '连网方式',
    appPageName: '移联万物App详情页',
    miniAppPageName: '小程序详情页',
    webPageName: 'SaaS详情页',
    category: '产品品类',
    no_category: '暂无品类数据，请先启用产品',
    categorySetiing: '品类配置',
    qrCodeSetiing: '二维码管理',
    dataFormat1: '暂不支持数据格式为透传/自定义的产品进行队列监听',
    qrcodeType: '二维码类型',
    qrcodeSetTips: 'ps：连网方式是wifi或设备类型是子设备二维码类型只有SN没有IMEI',
    custSet: '字段设置',
    ext: {
      string: '字符',
      date: '日期',
      enum: '枚举',
      enumItem: '字段选择项',
      addEnumItem: '新增枚举值',
      enumItemRequired: '至少需要一个枚举项',
      enumItemNotEmpty: '枚举项不能为空',
      tips: {
        extTypes: '字段类型包含字符、日期和单选。',
        extTypeString: '字符：记录设备文本和数值信息，如生产批次等',
        extTypeDate: '日期：记录设备相关的日期信息，如设备出厂日期等',
        extTypeEnum: '枚举：记录设备相关的型号信息等，如设备型号等'
      }
    },
    dataAcquisition: '数采配置',
    snNum: '设备SN号',
    imei: '模组IMEI',
    PK: 'ProductKey',
    vendorName: '归属机构',
    booking: '产品启用',
    actionSet: '操作设置',
    openBookingTips: '请打开产品启用',
    setBtn: '去配置',
    settings: '产品启用成功，是否进行产品配置',
    refresh: '刷新数据',
    analyze: '数据分析',
    debug: '调试分析',
    analyzeTips: '仅支持添加4个图表，图表配置后，可在设备详情页查看设备统计图表',
    aggregation: {
      title: '数据聚合',
      add: '添加配置',
      preConfig: '预配置数据 ',
      preTip: '查看该产品默认已支持数据聚合的属性',
      countType: '统计方式',
      tip1: '1. 数据仅从配置生效开始统计，无法统计历史数据',
      tip2: '2. 当前数据最多支持配置10个，未配置的数据无法在“运行记录”中选择及聚合统计',
      tip3: '3. 当前值：设备上报数据在统计期间内的数值；累计值：设备上报数据自配置开始至今的累计数值',
      preTip1: '1.列表为该品类下产品默认支持数据聚合统计的预配置数据，以下数据不占用产品数据聚合配置的数量',
      preTip2: '2.数据相同的情况下，产品配置数据的优先级高',
      over10: '当前数据配置已满10个，无法继续添加',
      addTips: '仅支持配置数据类型为整型(INT)、浮点类型(FLOAT)、双精度浮点型(DOUBLE)、布尔类型(BOOL)、枚举类型(ENUM)及结构体(STRUCT)中上述数据类型的参数，且读写类型需为读写或只读',
      noCategories: '暂无品类，请选择您需要的品类添加'
    }
  },
  notice: {
    email: '邮件通知',
    sms: '短信通知',
    titleEmail: '邮件模板编辑',
    titleSms: '短信接收人编辑',
    titleVoice: '语音模板编辑',
    titleApp: 'App模板编辑',
    emailTemplate: '邮件模板',
    templateMgt: '模板管理',
    placeContent: '请输入内容',
    receiver: '接收人员设置',
    endUserPush: '设备绑定用户',
    orgPush: '设备归属组织',
    fixedPush: '固定邮箱',
    fixedRecipient: '固定收件人',
    recipient: '收件人',
    addTemplate: '新建模板',
    tableSelect: '选择',
    tableName: '名称',
    tableContent: '内容',
    templateName: '模板名称',
    placeTemplateName: '请输入模板名称',
    placeTemplateContent: '请输入模板内容',
    templateContent: '模板内容',
    insert: '插入',
    insertData: '插入数据',
    warnContent: '告警内容',
    sendDate: '发送时间',
    action: '动作',
    editEmailTemplate: '修改邮件模板',
    confirm1: '是否确认删除模板名称为',
    confirm2: '的数据项?',
    addEmailTemplate: '新增邮件模板',
    valid: {
      templateId: '请选择邮件模板',
      template: '请选择模板',
      templateType: '请选择模板类型',
      templateCode: '请输入模板code',
      language: '请选择语言',
      params: '请选择参数',
      sysParams: '请选择系统参数',
      templateTitle: '请输入模板标题'
    },
    voice: '语音通知',
    app: 'App通知',
    language: '语言',
    tenantName: '使用企业',
    setDefault: '设为默认',
    sign: {
      title: '签名',
      add: '新增签名',
      name: '签名名称',
      default: '默认签名',
      edit: '编辑签名'
    },
    temp: {
      title: '模板',
      add: '新增模板',
      code: '模板code',
      type: {
        name: '模板类型',
        item1: '故障',
        item2: '解除故障',
        item3: '告警',
        item4: '解除告警',
        item5: '地理围栏'
      },
      default: '默认模板',
      param: '模板参数',
      details: '模板详情',
      edit: '编辑模板',
      tips: '该模板内容无需配置参数'
    },
    rules: {
      signName: '请输入签名名称'
    },
    sure: {
      sign: '确定把该签名设置为<span style="color:#456ff7">默认签名</span>吗？',
      temp: '确定把该模板设置为<span style="color:#456ff7">默认模板</span>吗？'
    },
    setSucc: '设置成功',
    product: '使用产品',
    paramMap: '参数映射',
    details: '详情',
    sysParams: '系统参数',
    commonParams: '通用参数映射',
    tslParams: '物模型参数映射',
    triggerFault: '触发故障',
    triggerAlarm: '触发告警',
    dismissFault: '解除故障',
    dismissAlarm: '解除告警',
    triggerFence: '触发围栏'
  },
  device: {
    dvLowerCase: '设备',
    dvUpperCase: '设备',
    inputLabel: '请输入标签名称',
    labelRename: '标签重命名',
    inputProp: '属性名称',
    selectProduct: '请选择产品',
    selectCompany: '请选择企业',
    placeDeviceKey: '请输入DeviceKey',
    placeDeviceSn: '设备SN',
    validDeviceKey: '请输入4~32位字母或数字',
    checkFile: '请导入“xls”或“xlsx”格式文件！',
    downDeviceImpTemplate: '下载设备导入模板',
    downGroupImpTemplate: '下载安装点导入模板',
    deviceImpTemplate: '设备导入模板',
    groupImpTemplate: '安装点导入模板',
    impTemplate: '导入模板',
    importRecords: '设备导入记录',
    importDetails: '设备导入详情',
    importTotal: '导入总数',
    importSuccessNum: '导入成功数',
    importSuccess: '导入成功',
    importFailNum: '导入失败数',
    importStatus: '导入状态',
    importTime: '导入时间',
    importDevice: '导入设备',
    exportDevice: '导出设备',
    importSubmitted: '导入已提交',
    importResults: '导入结果',
    importDeviceDetails: '设备导入明细',
    processFinish: '处理完毕',
    processing: '处理中',
    downDetails: '下载明细',
    noProducts: '暂无产品',
    confirmDelDevice: '确定删除设备?',
    deviceName: '设备名称',
    deviceSn: '设备SN',
    orgName: '归属企业',
    lastOnlineTime: '最后一次上线时间',
    listField: '列表显示字段',
    customField: '字段设置',
    listFieldFilter: '列表项展示筛选',
    deviceDtl: '设备详情',
    gatewayDtl: '网关详情',
    subordinateGateway: '归属网关',
    subset: '子设备',
    locationInfo: '位置信息',
    export: '导出设备',
    singleSend: '单设备操作',
    singleSendTip: '单设备操作：产品的单设备操作，可实现对单个设备下发指令控制',
    batchSend: '多设备操作',
    batchSendTip: '产品配置-操作设置中的多设备操作，可实现对多个设备批量下发指令控制',
    mapLine: '地图连线排序',
    basicInfo: {
      deviceKey: 'DeviceKey',
      name: '设备名称',
      sn: '设备SN',
      statusInfo: '状态信息',
      productInfo: '产品信息',
      productsOrg: '产品归属机构',
      devicesOrg: '设备归属机构',
      qrcode: '设备二维码',
      modelSpec: '模组型号',
      activationTime: '激活时间',
      editInfo: '编辑信息',
      dataTime: '数据更新时间',
      signal: '网络信号强度',
      errorEvent: '故障事件',
      warningEvent: '告警事件',
      valid: {
        deviceName: '请输入设备名称'
      },
      detail: '明细'
    },
    remainingDays: {
      title: '剩余使用天数',
      dayValid: '剩余使用天数只能设置6-500天'
    },
    deviceAttr: '设备属性',
    customCol: '自定义字段',
    toSelect: '去选择',
    onlyOneProduct: '只支持同类产品选择',
    exportSelection: '{0}选项',
    unfold: '展开',
    putAway: '收起',
    tslGroup: {
      showAll: '显示全部标签',
      showVisible: '只显示可见标签'
    },
    location: {
      deviceNo: '设备号',
      state: {
        title: '状态',
        offline: '设备离线',
        online: '设备在线'
      },
      lal: '经纬度',
      lalInfo: '经纬度信息',
      locate: '定位位置',
      signal: '信号强度',
      view: '查看地址',
      time: '定位时间',
      targeting: '定位方式',
      satellite: '当前卫星数量',
      manualPunctuation: '手动标点',
      deviceReport: '设备上报',
      editLocation: '编辑位置信息',
      mountPH: '请输入查询并选择归属安装点'
    },
    mileage: {
      title: '里程报表',
      placeMonth: '选择月',
      milage: '里程数',
      day: '日'
    },
    faultLog: {
      title: '故障日志',
      troubleshoot: '解除故障',
      faultName: '故障名称',
      faultTime: '故障时间',
      statusRecord: '设备状态记录',
      currentValue: '当前值',
      deviceStatus: '设备状态',
      message1: '请先选择故障',
      message2: '请勿选择已处理',
      successMsg: '解除成功'
    },
    warnLog: {
      title: '告警日志',
      disarm: '解除告警',
      alarmName: '告警名称',
      alarmTime: '告警时间',
      message: '请先选择告警'
    },
    setInstruction: {
      title: '下发指令',
      attribute: '属性控制',
      sendControl: '下发控制',
      setValue: '设定值',
      msg1: '下发成功！',
      msg2: '请选择属性！',
      msg3: '请给属性赋值！',
      msg4: '请填写设定值！',
      serviceCall: '服务调用',
      returnResult: '返回结果',
      returnValue: '返回值',
      confirmAction: '是否确认执行操作',
      selectDevice: '请选择需要批量操作的设备',
      batch: '批量',
      batchRecord: '批量下发记录'
    },
    sim: {
      title: 'SIM卡',
      supplier: '运营商',
      setMeal: '套餐',
      networkType: '网络类型',
      active: '激活状态',
      activateTime: '激活日期',
      expiryDate: '计费结束日期',
      status: '状态',
      flow: '当月已用流量',
      residueFlow: '剩余流量',
      cardType: '卡类型',
      setmealmonths: '套餐周期（单位：月）',
      cardOrderNo: '订单编号',
      cardOrderType: {
        title: '订单类型',
        text1: '套餐续费',
        text2: '流量充值'
      },
      cardOrderStatus: {
        title: '订单状态',
        notPay: '未支付',
        payComplete: '充值中',
        orderComplete: '充值成功',
        orderFailure: '充值失败'
      },
      paymentType: {
        title: '充值渠道',
        wechat: '微信',
        alipay: '支付宝',
        huifu: '汇付',
        enterprise: '企业直充'
      },
      cardProductName: '产品名称',
      cardOperatorName: '运营商名称',
      createTime: '下单时间',
      completeTime: '完成时间',
      cardRenewalAmount: '订单金额',
      yuan: '元',
      retry: '重试',
      refund: '退款',
      placeOrderNo: '请输入订单编号',
      placeIccid: '请输入ICCID',
      placeOrderStatus: '请选择订单状态',
      deviceKey: '关联DeviceKey'
    },
    map: {
      all: '全部',
      collectStatus: {
        title: '收藏状态',
        text1: '收藏',
        text2: '取消收藏',
        text3: '未收藏'
      },
      track: '轨迹回放',
      noLocation: '该设备暂无位置信息',
      add: '请输入设备所在位置'
    },
    trackPlay: {
      distance: '总里程',
      speed: '速度',
      tips: '温馨提示: 最多可查询连续3天的记录',
      stayMark: '停留标记',
      timeSelect: '回放时间',
      showFence: '显示围栏',
      trackLine: '轨迹总览',
      trackDetail: '轨迹明细',
      hdop: 'GPS信号',
      noFence: '暂无围栏信息',
      drivingTips: '该时间段无行驶记录',
      getAdd: '点击获取地址'
    },
    fence: {
      fenceName: '电子围栏名称',
      triggerConditions: '触发状态',
      fenceType: '围栏类型',
      effectiveType: '生效时间',
      effectiveType0: '长期有效',
      effectiveType1: '时间范围',
      isEnabled: '是否启用',
      createTime: '添加时间',
      viewDevice: '查看设备',
      sure: '是否确认删除该电子围栏?',
      bindDevice: '绑定设备',
      unbindDevice: '解绑设备',
      batchAdd: '批量添加',
      deviceTips: '请先选择设备',
      noneTips: '请先选择{0}',
      trigger: {
        item1: '出界',
        item2: '入界',
        item3: '双向',
        item4: '关闭'
      },
      startTime: '开始时间',
      endTime: '结束时间',
      drawTips1: '鼠标单击开始绘制，双击结束',
      drawTips2: '鼠标拖动开始绘制，放开结束',
      polygon: '多边形',
      round: '圆形',
      empty: '清空',
      hefei: '合肥',
      drawTips: '请先绘制电子围栏！',
      openTimeTips: '请先启用一个时间范围！',
      effectTimeTips: '请先选择生效的时间范围！',
      confirm: '确认绑定'
    },
    sn: '生成SN',
    generateSum: '生成数量',
    snValid: '生成数量不能为空',
    snMsgSuccess: '生成成功！',
    snList: 'SN列表',
    add: '添加设备',
    singleAdd: '单个添加',
    batchImport: '批量导入',
    run: {
      title: '运行记录',
      more: '更多',
      newChart: '新建图表',
      chartTips: '仅支持添加4个图表，厂商配置的图表不可编辑或删除',
      chartYTips: '未设置范围图表会自动计算，以保证坐标轴刻度的均匀分布',
      minmax: '最大值需要大于最小值',
      heading: '标题',
      dataType: '统计数据类型',
      chartType: '图表类型',
      yunit: 'Y轴单位',
      dataSource: '数据源',
      countType: '统计方式',
      place: {
        title: '请输入标题',
        dataType: '请选择统计数据类型',
        chartType: '请选择图表类型',
        dataSource: '请选择数据源',
        countType: '请选择统计方式'
      },
      singleChoice: '单选',
      multipleChoice: '多选',
      dataTypeOptions: {
        item1: '布尔类型',
        item2: '数值类型',
        item3: '枚举类型',
        item4: '日期类型',
        item5: '文本类型'
      },
      chartTypeOptions: {
        item1: '柱状图',
        item2: '折线图',
        item3: '饼图'
      },
      countTypeOptions: {
        item1: '最大值',
        item2: '最小值',
        item3: '平均值',
        item4: '非聚合',
        item5: '累计值',
        item6: '百分比',
        item7: '增量'
      },
      editChart: '编辑图表',
      validDataSource: '最多选择{0}个数据源'
    },
    realtime: {
      openInNewWindow: '新窗口打开',
      historyLine: '历史曲线',
      realtimeLine: '实时曲线',
      pause: '暂停更新'
    },
    history: {
      title: '历史数据',
      showItemEdit: '展示项编辑',
      field: '字段',
      sortedSucc: '排序成功',
      validExport: '请勾选需导出项',
      maxSize: '历史数据导出不能超出150项',
      selectAll: '全选',
      rangeSetting: '范围设置',
      exportExcel: '导出Excel',
      download: {
        btnName: '下载PDF',
        tip: 'PDF每次最多支持5个属性且最多1000条数据下载',
        setting: 'PDF设置',
        tableName: '表格名称',
        ph: '请输入表格名称'
      }
    },
    event: {
      title: '事件记录'
    },
    productPickTip: '请先选择产品',
    dataUpdateTs: '数据更新时间',
    exports: {
      title: '导出项',
      tip: '历史数据最多每次下载支持10000条数据',
      tip2: '最多每次下载支持10000条数据'
    },
    powerSta: '电量统计',
    endOrgName: '绑定终端机构',
    dataLog: {
      title: '数据日志',
      content: '下发内容',
      detail: '下发明细',
      failReason: '失败原因',
      sendState: {
        title: '发送状态',
        pending: '待发送',
        sendSuc: '发送成功',
        sendFail: '发送失败'
      }
    },
    serviceLog: {
      title: '服务日志',
      valueSet: '设定值',
      valueReturn: '返回值',
      sendTime: '下发时间'
    },
    prop: {
      refresh: '立即刷新',
      tip: {
        fold: '折叠展示：结构体、数组类属性折叠展示',
        tile: '平铺展示：所有属性数据平铺展示'
      }
    },
    host: {
      title: '主机',
      name: '主机名称',
      sn: '主机IMEI',
      mount: '归属分区',
      zoneName: '分区名称',
      addHost: '添加主机',
      distributeHost: '分配主机',
      recycleHost: '回收主机',
      template: '下载主机导入模板',
      allHost: '所有{0}',
      selectedHost: '已选{0}',
      nameAndIMEI: '主机名称/主机IMEI',
      pwd: '主机访问密码',
      importTemp: '主机导入模板',
      confirmDel: '确定删除主机?',
      confirmUnbind: '确定解绑此主机?',
      bind: '绑定主机',
      unbind: '解绑主机',
      ph: {
        name: '请输入主机名称',
        sn: '请输入主机IMEI',
        snTip: '主机IME作为登录唯一账号，设置后将不可修改',
        mount: '请选择归属分区'
      }
    }
  },
  agent: {
    recovery: {
      record: '设备回收记录',
      title: '回收设备',
      btnName: '回收',
      tsCreateTime: '回收时间',
      org: '回收机构',
      orgPh: '请选择回收机构',
      product: '回收产品',
      view: '去查看',
      note: '设备正在导入并分配，请稍后刷新设备列表查看，或点击下方按钮查看设备导入记录。',
      confirm: '确定分配',
      orgSelectTitle: '回收机构选择',
      selected: '已选设备',
      loading: '加载中...',
      noMore: '没有更多了',
      nameDkSn: '设备名称/DeviceKey/设备SN',
      extFieldCode: '自定义字段',
      exportDetails: '导入设备明细',
      recycle: '设备回收',
      confirmRecycle: '确定回收'
    },
    allocationRecord: '设备分配记录',
    agentName: '代理商名称',
    parentName: '直属上级',
    attribution: '归属地',
    opStatus: {
      title: '运营状态',
      item1: '正常',
      item2: '停运'
    },
    linkman: '联系人',
    linkphone: '手机号码',
    allocationDevice: '分配设备',
    addAgent: '添加代理商',
    sysAccount: '系统账号',
    loginAccount: '登录账号',
    sysAccountTips: '请为你的代理商创建一个账号名',
    assignProduct: '分配产品',
    address: '详细地址',
    email: '邮箱',
    emailTips: '该邮箱将用于接收初始密码与重置密码',
    uploadTips: '通过Excel导入DeviceKey，分配已有设备给下级代理商',
    downTemplate: '下载设备分配模板',
    deviceAllocation: '设备分配',
    deviceAllocated: '已分配设备',
    activation: '激活',
    manual: '选择分配',
    batch: '导入分配',
    appAssign: '小程序分配',
    importTotal: '分配总数',
    importSuccessNum: '分配成功',
    importFailNum: '分配失败',
    importStatus: '分配状态',
    importTime: '分配时间',
    type: '分配方式',
    location: '设备分布位置',
    viewLocation: '查看设备位置',
    statistics: '设备统计',
    orgNameTip: '设备被分配前的归属机构',
    assignOrgConfirm: '分配机构确认',
    assignOrg: '分配机构',
    assignOrgName: '分配机构名称',
    assignOrgNameTip: '设备被分配后的归属机构',
    assignDetails: '设备分配明细',
    template: {
      title: '设备分配模板',
      tip1: '1. 仅允许导入“xls”或“xlsx”格式文件，小于2M。',
      tip2: '2. 单次最多分配1000个设备，单次设备较多时需要较长的校验、导入时间。',
      tip3: '3. 上传文件并分配后，可到设备列表-更多操作-设备导入记录中查看上传失败的设备详情信息。'
    }
  },
  user: {
    asyncUser: '同步用户',
    userId: '用户ID',
    avatar: '头像',
    nickname: '昵称',
    source: '来源',
    address: '地址',
    createTime: '注册时间',
    uploadTips: '是否确认导出所有角色数据项?',
    gender: {
      title: '性别',
      item1: '男',
      item2: '女',
      item3: '保密'
    },
    langTips: '我们将使用您设置的语言发送电子邮件、短信等通知，例如告警、故障消息推送。',
    timezoneTips: '更新所在时区或更新电脑系统时区后，请及时刷新页面，以便配置生效',
    lastLoginTime: '最后上线时间',
    boundDevice: '已绑定设备',
    unbindTips: '确定解绑此设备?',
    profile: {
      personalInfo: '个人信息',
      sysAccount: '系统账号',
      nickName: '用户昵称',
      phoneNumber: '手机号码',
      email: '用户邮箱',
      department: '归属部门',
      ownedRole: '归属角色',
      basicInfo: '基本资料',
      changePassword: '修改密码',
      resetPwd: {
        oldPassword: '旧密码',
        newPassword: '新密码',
        confirmPassword: '确认密码'
      },
      userAvatar: {
        uploadAvatarTitle: '点击上传头像',
        select: '选择',
        submit: '提 交',
        modifyAvatar: '修改头像'
      },
      cancelEdit: '取消编辑',
      editInfo: '编辑信息',
      saveInfo: '保存信息',
      createTime: '创建时间',
      // 2.1.5
      feedback: '我要反馈',
      successful: '反馈成功',
      num: '编号',
      handle: '处理',
      suggestion: '处理意见',
      placeSuggestion: '请输入处理意见',
      record: '处理记录',
      pending: '待处理',
      success: '反馈成功'
    },
    sysLanguage: '系统语言',
    placeSysLanguage: '请选择系统语言',
    timeZone: '所在时区',
    placeTimeZone: '请选择所在时区',
    adaptive: '自适应',
    auditLog: {
      username: '用户名称',
      module: '模块名称',
      operation: '操作名称',
      time: '时间',
      user: '用户',
      ip: 'IP地址',
      describe: '描述'
    },
    // 终端用户反馈
    feedback: {
      replyStatus: {
        true: '已回复',
        false: '未回复'
      },
      feedbackDetail: '反馈详情',
      feedbackMsg: '反馈意见',
      feedbackImg: '反馈图片',
      feedbackTime: '反馈时间',
      appName: '归属应用',
      confirmReply: '确定回复',
      replyPh: '请输入回复，回复后将不可修改',
      replyConfirmPh: '内容回复后将不可更改，确定回复吗？'
    },
    banner: {
      link: '图片跳转链接',
      linkTips: '第三方链接需在微信公众平台配置，并通过业务域名验证，否则将跳转失败。配置方法参考：',
      domain: '业务域名',
      interval: '轮播间隔',
      title: '广告标题',
      pic: '广告图片',
      ph: {
        link: '请填写图片跳转链接，不填写即不跳转',
        size: '建议尺寸：351*118',
        accept: '格式：JPG、PNG、JPEG ',
        limit: '大小：不大于2MB',
        supportTypes: '仅支持上传JPG、JPEG、PNG格式'
      }
    }
  },
  system: {
    userStatus: {
      title: '用户状态',
      item1: '正常',
      item2: '停用'
    },
    place: {
      deptName: '请输入部门名称',
      nickName: '请输入用户昵称',
      roleName: '请输入角色名称',
      roleKey: '请输入角色标识',
      userPwd: '请输入用户密码',
      leader: '请输入负责人',
      postCode: '请输入岗位编码',
      postName: '请输入岗位名称',
      selectDept: '请选择部门',
      selectRole: '请选择角色',
      selectHigherDept: '选择上级部门'
    },
    userId: '用户编号',
    nickName: '用户昵称',
    dept: '部门',
    userPwd: '用户密码',
    post: '岗位',
    role: '角色',
    importUpdateTips: '是否更新已经存在的用户数据',
    downTemp: '下载模板',
    validNickName: '请输入中文、字母或数字',
    addUser: '添加用户',
    editUser: '修改用户',
    resetPwdTip1: '请输入',
    resetPwdTip2: '的新密码',
    resetPwdTip3: '修改成功，新密码是：',
    delUserTips: '是否确认删除系统账号为',
    exportTips: '是否确认导出数据项?',
    userImport: '用户导入',
    roleName: '角色名称',
    roleKey: '角色标识',
    labelCode: '角色标签',
    roleStatus: '角色状态',
    roleId: '角色编号',
    roleDefault: '预设角色',
    roleSort: '显示顺序',
    dataPermission: '数据权限',
    menuPermission: '菜单权限',
    loading: '加载中，请稍候',
    autScope: '权限范围',
    dataScope1: '全部数据权限',
    dataScope2: '自定数据权限',
    dataScope3: '本部门数据权限',
    dataScope4: '本部门及以下数据权限',
    dataScope5: '仅本人数据权限',
    validRoleName: '角色名称不能为空',
    validRoleKey: '角色标识不能为空',
    validRoleSort: '显示顺序不能为空',
    addRole: '添加角色',
    editRole: '修改角色',
    allocationDataPermission: '分配数据权限',
    delRoleTips: '是否确认删除角色标识为',
    deptName: '部门名称',
    deptStatus: '部门状态',
    higherDept: '上级部门',
    validHigherDept: '上级部门不能为空',
    leader: '负责人',
    addDept: '添加部门',
    editDept: '修改部门',
    delDeptTips: '是否确认删除名称为',
    postCode: '岗位编码',
    postName: '岗位名称',
    postStatus: '岗位状态',
    postId: '岗位编号',
    addPost: '添加岗位',
    editPost: '修改岗位',
    delPostTips: '是否确认删除岗位编码为',
    dashboard: {
      globalParams: '全局参数',
      dataSourceName: '数据源中文名称',
      dataEnName: '数据源英文名称',
      reqUrl: '接口地址',
      reqWay: '请求方式',
      dataType: '数据类型',
      parameter: '参数',
      createParameters: '创建参数',
      parameterName: '参数名称',
      paramCode: '参数标识',
      nameCn: '中文名称',
      nameEn: '英文名称',
      staticParameters: '静态参数',
      dynamicParameters: '接口请求参数',
      configStaticParameters: '配置静态参数',
      showValueCode: '展现参数',
      actualValueCode: '实际参数',
      defaultValue: '默认值',
      place: {
        isShow: '请选择是否展示',
        reqWay: '请选择请求方式',
        dataType: '请选择数据类型',
        paramCode: '请输入参数标识',
        nameCn: '请输入中文名称',
        nameEn: '请输入英文名称',
        dataName: '请输入数据源中文名称',
        dataEnName: '请输入数据源英文名称',
        reqUrl: '请输入接口地址',
        pageSize: '请输入展示条数',
        cardType: '请选择卡片类型',
        cardStyle: '请选择卡片样式',
        fieldCnName: '请填写接口字段中文名',
        fieldEnName: '请填写接口字段英文名'
      },
      typeOptions: {
        0: '柱状图/曲线图',
        1: '饼图',
        2: '地图',
        3: '数字',
        4: '列表',
        5: '排行'
      },
      addDataSource: '新增数据源',
      pageNumberItem: '每页展示条数',
      showNumber: '展示条数',
      pageSize: '分页条数',
      editParameters: '编辑参数',
      editDataSource: '编辑数据源',
      addGlobalParameters: '添加全局参数',
      editGlobalParameters: '编辑全局参数',
      cardName: '卡片名称',
      cardStyle: '卡片样式',
      cardCnName: '卡片中文名称',
      cardEnName: '卡片英文名称',
      jumpUrl: '跳转地址',
      cardType: '卡片类型',
      dataSourceConfig: '数据源配置',
      cardTypeOptions: {
        0: '柱状图',
        1: '曲线图',
        2: '饼图',
        3: '地图',
        4: '数字卡片',
        5: '卡片组',
        6: '列表',
        7: '排行'
      },
      addCard: '新增卡片',
      editCard: '编辑卡片',
      index: '指标',
      selectDataSource: '选择数据源',
      selectIcon: '选择图标',
      fieldMapping: '接口字段映射',
      refreshField: '刷新字段',
      fieldName: '字段名',
      color: '颜色',
      most4Index: '最多添加4个指标',
      leftIndicator: '左一指标',
      leftSecIndicator: '左二指标',
      mapIndicators: '地图指标',
      rankingIndex: '排行指标',
      upperIndex: '上部指标',
      centralIndex: '中部指标',
      lowerIndex: '下部指标'
    },
    operationRecord: {
      type: '操作类型',
      operator: '操作人',
      content: '操作内容',
      time: '操作时间'
    },
    roleWebMenuEdit: 'web菜单权限',
    roleWxAppMenuEdit: '小程序菜单权限',
    screen: {
      add: '新增大屏',
      edit: '编辑大屏',
      copy: '复制大屏',
      copyName: '创建副本',
      name: '大屏名称',
      stop: '已停用',
      unPublished: '待发布',
      delTip: '是否确认{0}该大屏？',
      none: '暂无大屏信息，请新增',
      inherit: '继承',
      self: '自主设计',
      copyOne: '已选择{0}进行复制',
      toSelf: '暂无可继承大屏，请自主设计',
      addWay: '新建方式',
      inheritPerm: '继承权限',
      unInherit: '不开启',
      inheritOrg: '开启（按机构类型）',
      inheritAll: '开启（全部下级机构）',
      place: {
        name: '请输入大屏名字',
        addWay: '请选择新建方式',
        inheritPerm: '请选择继承权限',
        inheritUrl: '请选择继承的大屏'
      }
    }
  },
  company: {
    companySetting: '企业配置',
    platformSetting: '平台设置',
    platformCustomSetting: '平台个性化配置',
    dataChannelSetting: '数据通道配置',
    paymentSetting: '微信支付配置',
    notificationSetting: '通知配置',
    thirdPartyAccessSetting: '第三方接入配置',
    securitySetting: '安全设置',
    wxSetting: '微信小程序个性化配置',
    connectionSucceeded: '连接成功',
    ConnectionTest: '连接测试',
    notice: {
      title: '通知配置',
      emailConfig: '邮箱配置',
      senderNickname: '发件人昵称',
      emailAccount: '账号',
      emailPassword: '邮箱授权码',
      smtpServer: 'smtp服务器',
      smtpPort: 'smtp端口',
      sceneConfig: '场景配置',
      warningNotice: '告警通知',
      faultNotice: '故障通知',
      fenceNotice: '电子围栏通知',
      validSenderNickname: '发件人昵称不能为空',
      validEmailAccount: '账号不能为空',
      validSmtpServer: 'SMTP服务器不能为空',
      validSmtpPort: 'SMTP端口不能为空',
      validWarningNotice: '请选择告警通知',
      validFaultNotice: '请选择故障通知',
      validFenceNotice: '请选择电子围栏通知',
      smsSign: '短信签名',
      appPush: 'APP推送通知配置',
      validAppKeyIos: 'iOS系统AppKey不能为空',
      validAppKeyAndroid: '安卓系统AppKey不能为空',
      validIosApnsEnv: '请选择IOSApnsEnv',
      validProductId: '请选择使用产品',
      regionId: '服务器归属区域',
      validAccessKeyId: '请输入AccessKey',
      validRegionId: '请输入服务器归属区域',
      validSecret: '请输入密钥'
    },
    oemConf: {
      enable: '允许企业自定义',
      templateSelection: '模板选择',
      officialWebsite: '官网地址',
      configureDomain: '配置域名',
      customize: '自定义',
      recordQuery: '备案查询',
      siteName: '网站名称',
      loginPageLogo: '登录页面logo',
      tipUploadLogo: '点击上传logo',
      systemInsideBrightLogo: '明色系logo',
      systemInsideDarkLogo: '暗色系logo',
      websiteIcon: '网站图标',
      tipUploadFavicon: '点击上传favicon',
      loginBgImage: '登录背景图',
      tipUploadBgImage: '点击上传登录背景图',
      logo: {
        size: '尺寸：100*100px',
        format: '格式：JPG、PNG'
      },
      ico: {
        size: '尺寸：32*32px',
        format: '格式：ICO'
      },
      uploadBg: {
        size: '尺寸：1920*970px',
        format: '格式：JPG、PNG'
      },
      siteDescription: '网站描述',
      websiteKeywordsDesc: '网站关键词（输入关键词，并以英文逗号“,”隔开）',
      copyrightStatement: '版权说明',
      caseNumber: '备案号',
      legalStatementAndPrivacyPolicy: '法律声明及隐私权政策',
      basicConfig: '基础配置',
      advancedConfig: '高级配置',
      enableAdvancedConfig: '启用高级配置',
      siteEnName: '网站英文名称',
      dmpOauthLoginLogo: '登录授权按钮logo',
      dmpOauthLoginCn: '登录授权指引中文',
      dmpOauthLoginEn: '登录授权指引英文',
      place: {
        domainType: '请选择域名类型'
      }
    },
    pay: {
      wechatPay: '微信支付',
      businessNumber: '商户号',
      businessKey: '商户密钥',
      CertificateUpload: '证书上传',
      validAppid: 'appid不能为空',
      validMchId: '商户号不能为空',
      validMchKey: '商户密钥不能为空',
      validCertificateUrl: '请上传证书',
      validTradeType: '请选择支付方式',
      validReferer: '请填写授权域名',
      tradeType: {
        title: '支付类型',
        item1: 'App支付',
        item2: '公众号支付/小程序支付',
        item3: 'H5支付'
      },
      referer: '授权域名'
    },
    safe: {
      title: '安全设置',
      loginCaptcha: '登录验证码',
      loginLock: '登录锁定',
      ruleSet: '规则设定',
      ruleSetText1: '分钟内，失败',
      ruleSetText2: '次，锁定',
      ruleSetText3: '分钟',
      authCodeLogin: '验证码登录',
      resetPwd: '找回密码',
      dmpOauthLogin: '开发者中心授权登录',
      validLoginCaptcha: '是否开启登录验证码',
      validLoginLock: '是否开启登录锁定',
      validTimeRange: '请输入时间范围',
      validFailNum: '请输入失败次数',
      validLockTime: '请输入锁定时长',
      validAuthCodeLogin: '是否开启验证码登录',
      validResetPwd: '是否开启重置密码',
      validDmpOauthLogin: '是否开启开发者中心授权登录',
      loginQuestion: '登录问题',
      privacy: '隐私协议',
      helpDoc: '帮助文档',
      validLoginQuestion: '是否开启登录问题',
      validPrivacy: '是否开启隐私协议',
      validHelpDoc: '是否开启帮助文档'
    },
    third: {
      title: '第三方接入配置',
      appKey: '密钥',
      validAppId: 'App ID 不能为空',
      validAppKey: '密钥不能为空',
      validAppToken: 'Token 不能为空',
      validAesKey: 'AES Key 不能为空'
    },
    sim: {
      title: 'SIM卡平台授权配置',
      validKey: 'AppKey不能为空',
      validSecret: 'AppSecret不能为空'
    },
    wx: {
      appName: '小程序名称',
      logo: '小程序Logo',
      limit: '仅支持上传PDF格式文件，且大小不能超过1MB',
      tabTips: '仅支持上传JPG、PNG格式，建议尺寸80X80，大小不超过1M',
      logoFormat: '仅支持上传JPG、PNG、SVG、WEBP格式文件',
      tabTitle: '底部导航栏',
      tabName: '导航栏名称',
      customName: '自定义名称',
      menuName: '菜单名称',
      tabText: '导航栏文字',
      workbench: '工作台',
      statistics: '统计页面',
      view: '关于页面',
      bgc: '背景色',
      company: '公司名称',
      copyRight: '版权标识',
      customConf: '个性化配置',
      valLength: '必须为英文或数字，长度为{0}-{1}的字符组合',
      place: {
        appName: '请输入小程序名称',
        logo: '请上传Logo'
      }
    }
  },
  gdpr: {
    title: '移远隐私政策/移远服务协议',
    time1: '2022年6月30日版本',
    time2: '2022年6月30日生效',
    item1: '《移远隐私政策》',
    item2: '《移远服务协议》',
    text: '我已阅读并同意《移远隐私政策》与《移远服务协议》',
    agree: '同意',
    refuse: '拒绝',
    validGdpr: '请先勾选已阅读！',
    success: '已同意《移远隐私政策》与《移远服务协议》',
    logout: '拒绝将注销登出系统，确认继续吗？'
  },
  tagsView: {
    refresh: '刷新页面',
    closeCurrent: '关闭当前',
    closeOthers: '关闭其他',
    closeRight: '关闭右侧',
    closeAll: '关闭所有'
  },
  dict: {
    dictName: '字典名称',
    dictType: '字典类型',
    status: '字典状态',
    place: {
      dictName: '请输入字典名称',
      dictType: '请输入字典类型',
      dictLabel: '请输入字典标签',
      dataLabel: '请输入数据标签',
      dataValue: '请输入数据键值'
    },
    valid: {
      dictName: '字典名称不能为空',
      dictType: '字典类型不能为空',
      dataLabel: '数据标签不能为空',
      dataValue: '数据键值不能为空',
      dataSort: '数据顺序不能为空'
    },
    clearCache: '清理缓存',
    dictId: '字典编号',
    addTitle: '添加字典类型',
    editTitle: '修改字典类型',
    delTips: '是否确认删除该数据项?',
    exportTips: '是否确认导出所有类型数据项?',
    clearCacheSuccess: '清理成功',
    dictLabel: '字典标签',
    dataLabel: '数据标签',
    dataStatus: '数据状态',
    dictCode: '字典编码',
    dictValue: '字典键值',
    dataValue: '数据键值',
    dictSort: '字典排序',
    dataSort: '数据排序',
    addDictData: '添加字典数据',
    editDictData: '修改字典数据'
  },
  config: {
    place: {
      configName: '请输入参数名称',
      configKey: '请输入参数键名',
      configValue: '请输入参数键值'
    },
    valid: {
      configName: '参数名称不能为空',
      configKey: '参数键名不能为空',
      configValue: '参数键值不能为空'
    },
    configName: '参数名称',
    configKey: '参数键名',
    configType: '系统内置',
    configId: '参数主键',
    configValue: '参数键值',
    addConfig: '添加参数',
    editConfig: '修改参数'
  },
  companyList: {
    userName: '账户',
    linkPhoneNumber: '电话',
    viewProduct: '查看产品',
    screen: '管理大屏',
    role: '权限配置',
    vendorRole: '厂商权限',
    agencyRole: '代理商权限',
    proPage: '产品个性页面',
    editRole: '编辑权限',
    initRole: '初始化角色管理',
    unit: '单位',
    storageDuration: '存储周期',
    storageDurationText: '设备上行数据存储周期',
    mongo: '业务数据存储',
    mongoStorage: '业务数据存储空间',
    oss: '数采',
    ossStorage: '原始文件存储空间',
    uploadScript: '上传脚本',
    raw: '透传',
    ttlv: '移远物模型',
    dcmp: {
      service: '数据存储服务',
      increase: '昨日新增',
      current: '已使用'
    },
    rules: {
      storageDuration: '请输入设备上行数据存储周期',
      mongoStorage: '请输入业务数据存储空间',
      ossStorage: '请输入原始文件存储空间',
      scriptPath: '请上传脚本文件',
      dataType: '请选择数据类型',
      unit: '请选择单位',
      initRole: '请添加初始化角色标签',
      scriptVersion: '请添加脚本'
    }
  },
  oauth: {
    title: '用户信息录入',
    desc: '首次使用系统需补充录入以下信息，用于系统账号登录、接收通知信息与找回密码',
    userName: '账号',
    password: '平台登录密码',
    phonenumber: '绑定手机号',
    email: '绑定邮箱',
    submit: '提交并登入'
  },
  menu: {
    menuName: '菜单中文名称',
    menuNameEn: '菜单英文名称',
    status: '菜单状态',
    configBase: '基础配置',
    configSystem: '系统配置',
    tips: {
      visible: '设置当前菜单是否在系统左侧导航栏展示',
      menuCode: '用于后端控制菜单层级关系',
      otherName: '用于前端菜单名称缺省显示和数采特殊逻辑',
      path: '用于设置跳转URL'
    },
    place: {
      menuName: '请输入菜单中文名称',
      menuNameEn: '请输入菜单英文名称',
      status: '菜单状态',
      parentId: '选择上级菜单',
      icon: '点击选择图标',
      otherName: '请输入菜单别名',
      menuCode: '请输入菜单编码',
      path: '如 detail 或 order-detail, 外链时使用完整URL',
      routeName: '如 CompanyRoleDetail',
      component: '如 product/custom/index',
      perms: '如 system:agent:list',
      redirect: '如 /company/role'
    },
    orderNum: '排序',
    menuCode: '菜单编码',
    perms: '权限标识',
    component: '组件路径',
    parentId: '上级菜单',
    menuType: {
      title: '菜单类型',
      item1: '目录',
      item2: '菜单',
      item3: '权限按钮'
    },
    icon: '菜单图标',
    otherName: '菜单别名',
    isFrame: '是否外链',
    path: '路由地址',
    routeName: '路由名称',
    visible: '显示状态',
    isCache: {
      title: '是否缓存',
      item1: '缓存',
      item2: '不缓存'
    },
    redirect: '路由重定向'
  },
  rightToolbar: {
    hideSearch: '隐藏搜索',
    showSearch: '显示搜索',
    refresh: '刷新',
    showColumn: '显隐列',
    show: '显示',
    hide: '隐藏',
    showOrHide: '显示/隐藏'
  },
  dataFormatOptions: {
    item1: '透传/自定义',
    item2: 'JSON(标准数据格式)'
  },
  group: {
    nogroup: '当前暂没创建设备其他属性相关统计，',
    create: '立即创建',
    remarker: '已标记位置信息',
    name: '名称',
    address: '详细地址',
    mountAddress: '所在地区',
    editGroupBtn: '编辑安装点信息',
    delGroupBtn: '删除安装点',
    exactMark: '精确标记',
    contactPerson: '接警人',
    upPerson: '上级接警人',
    enterName: '请输入安装点名称',
    enterNameLengthLimit: '安装点名称长度不能超过256字符。',
    enterAdress: '请输入安装点地址',
    enterAdressLengthLimi: '请输入安装点地址长度不能超过1024字符。',
    bind: '绑定设备',
    unbind: '解绑设备',
    deviceKey: 'DeviceKey',
    deviceName: '设备名称',
    productName: '产品名称',
    send: '发送',
    product: '控制产品',
    unbindTips: '确定解绑此设备?',
    picture: '现场图片',
    warnDeviceALL: '告警设备/设备总数',
    place: {
      mount: '请选择安装点',
      name: '请输入安装点名称',
      address: '请输入安装点地址',
      contactPerson: '请选择接警人',
      detailAddress: '请输入详细地址',
      filter: '输入关键字进行过滤',
      belongGroup: '请选择归属分组'
    },
    deleteGroupMountTips: '是否需要删除？',
    belongGroup: '归属安装点',
    addGroup: '新增',
    editGroup: '编辑',
    selectDevTips: '请选择对应设备',
    uploadList: '上传设备列表',
    sort: '顺序',
    toSorted: ' 待排序',
    showContain: '显示下级数据',
    configuration: '安装点字段设置',
    batch: {
      action_switch: '操作开关',
      cloud: '云端定时',
      local: '本地定时',
      cycle: '循环定时',
      count: '倒计时',
      del: {
        title: '删除定时',
        cloud: '删除全部云端定时任务',
        local: '删除全部本地定时任务',
        cycle: '删除全部循环定时任务',
        count: '删除全部倒计时定时任务'
      },
      batchTitle: '批量{0}',
      setTime: '定时',
      addSetTime: '添加定时',
      noSetTime: '当前无定时任务，不可下发指令，请先添加定时任务',
      maxSize: '最多添加{0}个定时任务',
      switch_mode: '设置开关模式',
      power_alert: '设置断电报警使能',
      task: '任务{0}',
      switch: '开关',
      noSwitch: '所有开关还未设置操作，指令下发失败，请设置需要操作的开关。',
      mode: '开关模式',
      action: '操作',
      powerStatus: '上电模式',
      delayTime: '点动时间（秒）',
      cycleTip: '循环规则（交替）',
      startTime: '启用时间',
      cloudTime: '倒计时时间',
      status: '任务状态',
      repeat: '重复',
      ph: {
        switch: '请选择开关',
        mode: '请选择开关模式',
        action: '请选择操作',
        powerStatus: '请选择上电模式',
        delayTime: '请输入{0}~{1}秒',
        date: '选择时间',
        time: '选择日期',
        select: '请选择{0}',
        startTime: '请选择启用时间',
        startTxt: '开启时长',
        endTxt: '关闭时长',
        repeat: '不勾选即代表只生效一次',
        noSelectSwitch: '任务{0}，未选择任何开关，指令下发失败，请选择需要操作的开关。',
        noSwitch: '任务{0}，所有开关还未设置操作，指令下发失败，请设置需要操作的开关。'
      },
      timingTip: '批量操作后，所有设备原“{0}”将被覆盖，请谨慎操作。',
      noAction: '无操作',
      weeks: {
        mon: '周一',
        tues: '周二',
        wed: '周三',
        thur: '周四',
        fri: '周五',
        sat: '周六',
        sun: '周日'
      }
    }
  },
  areaManage: {
    zoneName: '分区名称',
    add: '新增分区',
    zoneList: '分区列表',
    overview: '分区概况',
    hostList: '主机列表',
    baseInfo: '分区信息',
    callRule: '呼叫规则',
    receiver: '紧急联系人',
    forwardSwitch: '呼叫转接',
    callDuration: '呼叫时长',
    pollDuration: '轮询时长',
    pollNum: '轮询次数',
    times: '次',
    forwardZoneId: '转接策略',
    place: {
      zoneName: '请输入分区名称',
      address: '请点击定位按钮选择所在地区',
      detailAddress: '请输入分区详细地址',
      forwardZoneId: '请选择转接分区'
    },
    receiverTip: '当无法与所有联系人通过主机APP联系时，平台将通过语音、短信通知以下紧急联系人'
  },
  person: {
    add: '添加负责人',
    name: '负责人名称',
    mobile: '负责人手机号',
    smsNotice: '短信通知',
    voiceNotice: '语音通知',
    enterName: '请输入负责人名称',
    enterNameLengthLimit: '负责人名称长度不能超过50字符。',
    enterMobile: '请输入负责人手机号',
    enterAdressLengthLimi: '请输入正确的手机号',
    deleteTips: '关联的安装点将无法通知已删除的负责人。确认删除吗？'
  },
  scooter: {
    operate: {
      storeName: '门店名称',
      addStore: '添加门店',
      area: '地区',
      address: '门店地址',
      businessTime: '营业时间',
      contactWay: '联系方式',
      storeImg: '门店图片',
      locationView: '定位查看',
      storeLocation: '门店位置',
      agent: '代理商',
      shopOwner: '店长',
      telephone: '联系电话',
      areaSelect: '地区选择',
      countryOrRegion: '国家或地区',
      specificLocation: '具体位置',
      uploadTips: '只能上传jpg/jpeg/png格式文件，且不超过200kb，建议上传尺寸：600*600像素',
      remark: '备注',
      notFoundAdd: '未搜索到该地址',
      selectStoreTips: '请在具体位置下拉中选择门店位置',
      editSuccess: '编辑更新成功',
      uploadImgTips: '文件格式错误，只能上传jpg/jpeg/png格式文件',
      uploadImgSizeTips: '文件大小错误，上传图片大小不能超过200kb',
      stolenAdd: '失窃地址',
      place: {
        enterStoreName: '请输入门店名称',
        enterShopOwner: '请输入店长',
        enterSpecificLocation: '请输入具体位置',
        enterRemark: '请输入备注',
        enterDevType: '请输入车辆型号',
        startTime: '请选择开始时间',
        endTime: '请选择结束时间',
        selectTheft: '请选择失窃类型',
        selectDevManual: '请选择车辆说明书'
      },
      vin: '车架号',
      devType: '车辆型号',
      user: '用户',
      repairStore: '报修门店',
      questionImg: '问题图片',
      reportTime: '报修时间',
      addRepair: '新增报修',
      tenantName: '厂商',
      loseType: {
        title: '失窃类型',
        item1: '车辆',
        item2: '电池'
      },
      reporterName: '上报人姓名',
      loseRemark: '失窃描述',
      loseTime: '失窃时间',
      loseAddress: '失窃地点',
      realTimeTrack: '实时跟踪',
      addLose: '新增失窃',
      addManual: '添加说明书',
      carManual: '车辆说明书',
      down: '下载',
      uploadFormatTips: '文件格式错误，只能上传PDF格式文件',
      uploadSizeTips: '文件大小错误，上传文件大小不得超过',
      uploadedTips: '您已上传过文件，如需重新上传请先删除'
    }
  },
  upload: {
    tagsTitle: '我的标签',
    queryParams: {
      fileType: '请选择文件类型',
      fileName: '请输入文件名称',
      fileTag: '请选择标签名称',
      uploadBtn: '上传',
      file: '请选择文件',
      enter: '请输入标签名称，点击enter添加'
    },
    table: {
      typeObj: {
        1: '图片',
        2: '文档',
        3: '音频',
        4: '视频',
        5: '压缩包',
        6: '其它'
      },
      fileType: '文件类型',
      fileTag: '标签名称',
      view: '预览',
      fileName: '文件名称',
      fileSize: '文件大小',
      mimeType: 'Mime类型',
      select: '选择',
      dest: '文件存储位置'
    },
    preview: '图片预览',
    addFiles: '添加文件',
    fileSelection: '文件选择',
    validNull: '请勿上传空文件'
  },
  task: {
    running: '进行中任务',
    queryParams: {
      taskType: '请选择任务类型',
      batchTaskStatus: '请选择批任务状态'
    },
    table: {
      batchTaskNo: '批次号',
      taskType: '任务类型',
      totalNum: '设备总数',
      successNum: '成功数量',
      failNum: '失败数量',
      tsFinishTime: '完成时间',
      batchTaskStatus: '批次任务状态',
      timeUsed: '耗时',
      batchTaskProgress: '进度',
      taskTypeOptions: {
        1: '设备导入',
        2: '设备分配'
      },
      batchTaskStatusOptions: {
        1: '准备中',
        2: '等待中',
        3: '进行中',
        4: '处理结束'
      }
    },
    taskDetails: {
      table: {
        taskTag: 'DeviceKey',
        taskStatus: '导入状态',
        errorMsg: '失败原因',
        dataPayload: '数据载荷',
        taskStatusOptions: {
          0: '全部',
          1: '待导入',
          2: '导入中',
          3: '导入成功',
          4: '导入失败'
        }
      }
    }
  },
  validation: {
    title: '请完成安全验证',
    toRight: '向右滑动完成验证',
    successful: '验证成功',
    failure: '验证失败'
  },
  appmanage: {
    versionType: '版本类型',
    appVersion: 'APP版本号',
    plappVersion: 'APP版本号不能为空',
    downloadAddress: '下载地址',
    pldownloadAddress: '下载地址不能为空',
    versionDetail: '版本详情',
    plversionDetail: '请输入版本详情',
    publishState: '发布状态',
    verify: '验证账号',
    verifyManage: '验证账号管理',
    rulapp: 'APP版本号以V开头，只支持数字和 “ . ”，格式为xx.xx.xx',
    state0: '待验证',
    state1: '已发布',
    state2: '已下架',
    delete01: '是否确认删除该版本？',
    publish01: '是否确认发布该版本？',
    deletever: '是否确认删除该验证账号？',
    nofile: '未选择任何文件'
  },
  notFound: {
    error401: '401错误',
    error401p1: '对不起，您没有访问权限。',
    error401p2: '请不要进行非法操作哦~',
    error401p3: '您可以返回首页试一试。',
    backHome: '返回首页',
    error404: '404错误',
    error404p1: '对不起，您正在寻找的页面不存在。',
    error404p2: '请试试以下办法：',
    error404p3: '检查网络连接',
    error404p4: '检查代理服务器和防火墙'
  },
  faultWarning: {
    fault: '故障日志',
    warn: '告警日志',
    type: '类型',
    name: '名称',
    relieve: '批量解除',
    remark: '批量备注',
    viewTip1: '设备已分配，无法操作',
    viewTip2: '设备已回收，无法操作',
    deviceName: '设备名称',
    faultAlert: '确认要解除故障吗？',
    warningAlert: '确认要解除告警吗？',
    batchAlert: '确认要批量解除{0}吗？',
    typeAlerts: '告警',
    typeFaults: '故障',
    handleTime: '处理时间',
    handler: '处理人',
    method: '处理方式',
    deviceStatus: '设备状态',
    outputParams: '输出参数',
    otherParams: '其他参数',
    statusRecord: '历史处理',
    noticeRecord: '通知记录',
    success: '成功',
    failure: '失败',
    commit: '发送中',
    searchKey: '接警号码/接警人/事件名称',
    ntcRecordList: {
      eventTime: '事件时间',
      noticeType: '通知类型',
      eventType: '事件类型',
      noticeTime: '通知时间',
      noticeNumber: '通知号码',
      callNumber: '接警号码',
      policeReceiver: '接警人',
      noticeStatus: '通知状态',
      alarmName: '告警名称',
      faultName: '故障名称'
    },
    faultDetail: {
      name: '故障名称',
      happenTime: '故障时间'
    },
    alertDetail: {
      name: '告警名称',
      happenTime: '告警时间'
    }
  },
  operationManagement: {
    place: {
      date: '选择日期',
      type: '请选择工单类型',
      desc: '请输入工单描述',
      address: '请输入地址',
      status: '请选择工单状态',
      orderNum: '请输入工单编号',
      handlerName: '请选择售后人员',
      name: '请输入工单名称',
      deviceKey: '请选择设备或输入DeviceKey',
      deviceName: '请输入DeviceKey',
      hanldeName: '请输入联系人名称',
      feedbackDesc: '请输入意见反馈',
      appointTime: '请选择预约时间',
      uploadImg: '请上传图片'
    },
    orderNum: '工单编号',
    type: '工单类型',
    status: '工单状态',
    name: '工单名称',
    desc: '工单描述',
    submitTime: '提交时间',
    finished: '办结',
    sendOrder: '派单',
    back: '退单',
    force: '强制结单',
    detail: {
      productName: '归属产品',
      company: '企业'
    },
    appointTime: '预约时间',
    overdue: '预约时间已失效，请重新选择',
    operator: '操作人',
    feedback: '意见反馈',
    handlerName: '售后人员',
    uploadImg: '上传图片',
    addBtn: '登记',
    addTitle: '登记工单',
    repaieList: '设备保修列表',
    manageList: '工单管理列表',
    myOrderList: '我的工单列表',
    turnOrder: '转工单',
    info: '工单信息',
    turn: '工单流转',
    imageLimit: '图片大小不能超过{0}MB',
    imageFileLimit: '文件大小不能超过{0}MB',
    supportTypes: '仅支持上传JPG、JPEG、PNG、GIF格式',
    turnOrderStatus: '转工单状态',
    notTurned: '未转工单',
    hasPassed: '已转工单',
    location: '定位位置'
  },
  commonScreen: {
    save: '保存',
    publish: '发布',
    back: '返回',
    edit: '编辑',
    control: '控制台',
    titleCnName: '中文标题',
    titleEnName: '英文标题',
    logo: 'Logo',
    product: '产品',
    timeRange: '统计天数',
    dataSource: '数据源',
    value: '值',
    unit: '单位',
    titleSetting: '大屏标题配置',
    cardSetting: '卡片配置',
    dataSourceSetting: '数据源配置',
    addIndexs: '添加指标',
    dataType: '数据类型',
    index: '指标',
    topIndex: '上部指标',
    centerIndex: '中部指标',
    bottomIndex: '下部指标',
    tips: {
      save: '草稿保存成功',
      publish: '发布成功'
    },
    place: {
      titleCnName: '请输入中文标题',
      titleEnName: '请输入英文标题',
      product: '请选择产品',
      timeRange: '请输入统计天数',
      value: '请输入值',
      style: '请选择卡片样式',
      dataType: '请选择数据类型'
    },
    staticData: '静态数据'
  },
  faultWarnManage: {
    keyword: '接警人姓名、手机、邮箱  | 接警人姓名、邮箱',
    delTips: '确认删除该接警人吗？',
    contact: {
      name: '接警人姓名',
      email: '接警人邮箱',
      phone: '接警人手机号',
      updateTime: '更新时间',
      permission: '接警权限',
      addTips: '联系方式至少填写手机、邮箱的其中一种 | 联系方式至少填写邮箱',
      place: {
        receiver: '请选择接警人',
        orgId: '请选择归属机构',
        name: '请输入接警人姓名',
        email: '请输入接警人邮箱',
        phone: '请输入接警人手机号',
        permission: '请选择接警权限'
      }
    },
    config: {
      name: '接警配置名称',
      wayMode: '通知方式',
      device: '设备',
      receiver: '接警人',
      place: {
        wayMode: '请选择通知方式',
        name: '请输入接警配置名称',
        device: '请选择对应设备',
        receiver: '请选择接警人'
      }
    }
  },
  orgTypeMange: {
    orgType: '机构类型',
    orgTypeSet: '机构类型配置',
    orgTypeName: '机构类型名称',
    currentApplicationIndustryScenarios: '当前应用行业场景',
    isPlat: '是否是平台',
    isManufacturers: '是否是厂商',
    place: {
      orgType: '请输入机构类型',
      orgTypeName: '请输入机构类型名称',
      isPlat: '请选择是否是平台',
      isManufacturers: '请选择是否是厂商'
    }
  },
  organization: {
    direct: '该机构归属机构',
    orgType: '机构类型',
    status: '机构状态',
    orgName: '机构名称',
    recycling: '机构名称',
    beRecycleOrg: '被回收机构名称',
    belongOrg: '归属机构',
    remark: '描述',
    userRoles: '用户角色',
    nickName: '联系人',
    address: '地址',
    enableState: '启用状态',
    createTime: '创建时间',
    industryScenarios: '归属行业场景',
    roleName: '角色名称',
    roleSort: '角色排序',
    roleStatus: '角色状态',
    userName: '系统账户',
    userNickName: '用户昵称',
    roleNames: '角色',
    userStatus: '用户状态',
    showContain: '显示下级机构数据',
    showContainInfo: '选中后，本级可看下级的设备数据',
    showPowerContainInfo: '选中后，本级可看下级的电站数据',
    showRecordContainInfo: '选中后，本级可看下级的操作记录数据',
    operate: {
      add: '新建机构',
      toUser: '用户管理',
      toRole: '角色管理',
      moreOperation: '更多操作',
      handleBatchDisable: '批量禁用',
      handleBatchEnable: '批量启用',
      handlePermissionType: '权限类型编辑',
      handlePermission: '企业web菜单',
      handlePermissionWechat: '企业小程序菜单'
    },
    permission: {
      webPermission: 'WEB菜单权限',
      miniPermission: '小程序菜单权限'
    },
    place: {
      nickName: '请输入联系人',
      orgType: '请选择机构类型',
      orgStatus: '请选择机构状态',
      operationStatus: '请选择运营状态',
      org: '请选择机构',
      orgBelong: '请选择归属机构',
      orgName: '请输入机构名称',
      roleOrgan: '请选择机构',
      roleStatus: '请选择角色状态',
      userStatus: '请选择用户状态',
      roleName: '请输入角色名称',
      address: '请输入地址',
      remark: '请输入备注',
      roles: '请选择角色'
    },
    confirm: {
      deleteOrg: '是否确认删除该机构？',
      deleteRole: '是否确认删除该角色？',
      deleteUser: '是否确认删除该用户？',
      batchDisable: '是否确认批量禁用？',
      batchEnable: '是否确认批量启用？',
      roleEnable: '是否确认启用“{0}”？',
      roleDisable: '是否确认停用“{0}”？',
      userEnable: '确认要启用此用户吗？',
      userDisable: '确认要停用此用户吗？'
    },
    actionRole: '是否{0}{1}？',
    orgEndDevice: 'TA的设备'
  },
  configuration: {
    cgsName: '组态名称',
    cgsType: '组态类型',
    cgsDesc: '组态描述',
    cgsDesign: '设计',
    place: {
      cgsName: '请输入组态名称',
      cgsType: '请选择组态类型',
      cgsDesc: '请输入组态描述信息'
    },
    confirm: {
      delete: '确定删除该组态吗?'
    }
  },
  idstScenarios: {
    industryScenarios: '行业场景名称',
    code: '编码',
    permissionType: '机构权限类型',
    scenariosSet: '场景配置',
    orgTypeName: '机构类型名称',
    orgTypeSet: '机构类型配置',
    menuManage: '菜单管理',
    roleName: '角色名称',
    roleTag: '角色标签',
    roleStatus: '角色状态',
    domain: '域名',
    idstStatus: '行业场景描述',
    upOrg: '上级机构',
    downOrg: '下级机构',
    roleManage: '角色管理',
    upControlAllDown: '上级控制所有下级',
    upControlNextDown: '上级控制直属下级',
    upNotControlDown: '上级不可控制下级',
    defaultMenu: '系统默认菜单列表',
    idstScenariosMenu: '场景菜单',
    multiUse: '批量引用',
    multiDel: '批量删除',
    tips: {
      idstTips: 'ps：有厂商入驻的场景SaaS不能删除'
    },
    place: {
      industryScenarios: '请输入行业场景名称',
      code: '请输入编码',
      clientId: '请输入client_id',
      lientSecret: '请输入client_secret',
      roleName: '请输入角色名称',
      roleTag: '请输入角色标签',
      roleStatus: '请选择角色状态',
      idstStatus: '请输入行业场景描述',
      domain: '请输入域名',
      orgTypeName: '请选择机构类型名称',
      upOrg: '请选择上级机构',
      downOrg: '请选择下级机构'
    }
  },
  projectManage: {
    customReport: {
      add: '新增报表计划',
      edit: '编辑报表计划',
      reportName: '报表名称',
      reportTime: '报表生成时间',
      category: '统计维度',
      categoryContent: '统计信息',
      reportRule: '报表规则',
      reportPlan: '报表计划',
      reportPlanAdd: '新建计划',
      reportPlanSet: '配置报表计划',
      statisticalContentDialog: '统计内容',
      selected: '已选项',
      statisticalCircle: '统计周期',
      reportType: '报表类型',
      statisticalInfo: '报表信息',
      statisticalContent: '报表内容',
      is_enabled: '是否启用',
      download: '文件下载',
      circleForSta: '周期循环统计',
      custtimeSta: '自定义时间统计',
      mount: '安装区域',
      productDevice: '产品设备',
      productProp: '产品属性',
      dataSource: '数据源',
      dataSourceName: '数据源名称',
      statisticalMode: '统计方式',
      isChildOrg: '查看下级',
      isStaChild: '统计下级设备',
      chartReport: '图形报表',
      dataReport: '数据报表',
      downloadDataReport: '下载数据报表',
      downloadChartReport: '下载图形报表',
      multiDownloadDataReport: '批量下载数据报表',
      multiDownloadChartReport: '批量下载图形报表',
      tableZip: '批量数据报表',
      chartZip: '批量图形报表',
      statisticalOpts: {
        alarmCount: '告警总数',
        errorCount: '故障总数',
        completedOrder: '工单数(已完成)',
        backlog: '工单数(未完成)',
        newOrder: '新增工单数',
        orderCount: '工单总数',
        activated: '激活数量'
      },
      place: {
        reportName: '请输入报表名称',
        productProps: '请选择产品属性',
        period: '请选择统计周期',
        reportType: '请至少选择一种报表类型',
        saveDuration: '请选择保存时长'
      },
      tips: {
        duration: '报表生成后请尽快导出，大于保存时长报表内容将被循环清除',
        statisticalContentFormBefore: '仅告警总数、故障总数和工单总数支持图形报表',
        device: '设备为空，默认全选当前产品下所有设备',
        statisticalMode: '请填写统计方式',
        add: '最多新增20条计划',
        action: '计划已启用，无法进行操作',
        pdf: '选中生成PDF后，PDF报表里含有图形图表和原始数据，且最多仅支持10个属性统计，图形图表仅支持数值类型的属性展示',
        circle: '周期循环统计：选择统计周期，将在每个周期结束后次日生成相应报表。',
        custom: '自定义时间统计：选择报表统计时间段，若配置的结束时间早于当前，则在次日生成报表；若配置的结束时间晚于当前，则在结束时间的次日生成报表。最早可选择此前5年，最晚可选择此后1年，跨度最长为3年。',
        isEnabled: '最多支持启动5个计划',
        period: '每个报表最多可下载1w个值，多出不展示，请合理选择统计周期',
        notEnable: '计划已失效，无法再次开启',
        notEdit: '计划已失效，无法再次编辑'
      },
      aggregatedData: '聚合数据',
      duration: '保存时长',
      statisticalType: '统计类型',
      saveDuration: '保存剩余时长（天）'
    },
    snapshot: {
      add: '新增快照计划',
      edit: '编辑快照计划',
      name: '快照计划',
      planName: '计划名称',
      interval: '快照时间间隔',
      record: '快照记录',
      tip: '每个机构最多支持启动创建3个快照',
      exportsTips: '报表生成后请尽快导出，否则报表内容{0}后将被循环清除',
      toParamRecord: '参数记录'
    },
    paramRecord: {
      title: '参数记录',
      recent3days: '近 3 天',
      lastWeek: '近 7 天',
      lastMonth: '近 30 天',
      hideAll: '全部隐藏',
      showAll: '全部显示'
    }
  },
  rules: {
    props: {
      ruleName: '规则名称',
      ruleDesc: '规则描述',
      triggerMode: '触发方式',
      triggerDevices: '触发设备',
      actuatorDevices: '执行设备',
      enableState: '启用状态',
      triggerTime: '触发时间',
      triggerLogical: '触发逻辑',
      actRate: '执行频率',
      actRatePeriod: '时间间隔',
      timerOnce: '仅一次',
      timerRepeat: '重复',
      trigger: {
        name: '触发器',
        type: '触发器类型',
        compare: '运算符',
        event: '事件',
        count: '上报次数',
        onlineStatus: '在/离线状态',
        duration: '持续时长'
      },
      actuator: {
        name: '执行器',
        type: '执行器类型',
        isDelay: '是否延时执行',
        delayTime: '延时执行时间',
        sms: '短信推送',
        voice: '语音推送',
        email: '邮箱推送',
        reverseFlag: '时间段以外触发'
      },
      prop: '属性'
    },
    log: {
      record: '联动记录',
      detail: '规则详情',
      time: '联动时间',
      result: '执行结果',
      resultSuccess: '执行成功',
      resultFail: '执行失败',
      failReason: '失败原因'
    },
    action: {
      add: '新建规则',
      addTrigger: '添加触发器',
      addActuator: '添加执行器',
      set: '设置为'
    },
    place: {
      ruleName: '请输入规则名称',
      triggerMode: '请选择触发方式',
      triggerTime: '请选择触发时间',
      triggerLogical: '请选择触发逻辑',
      actRate: '请选择执行频率',
      actRatePeriod: '请选择时间间隔',
      week: '请至少选择一个日期',
      prop: '请选择属性',
      event: '请选择事件',
      trigger: {
        required: '请至少添加一个触发器',
        type: '请选择触发器类型',
        compare: '请选择运算符',
        event: '请选择事件',
        onlineStatus: '请选择在/离线状态'
      },
      actuator: {
        required: '请至少添加一个执行器',
        type: '请选择执行器类型',
        isDelay: '请选择是否延时执行',
        delayTime: '请选择延时执行时间',
        sms: '请选择短信推送',
        voice: '请选择语音推送',
        email: '请选择邮箱推送'
      }
    },
    tips: {
      deleteTitle: '删除提示',
      deleteMsgEnabled: '此规则已启用，是否删除此规则？',
      deleteMsgDisabled: '是否删除此规则？',
      triggerConditions: '触发条件',
      actions: '执行动作',
      actuatorRateTypes: {
        once: '单次执行：当达到触发条件后，仅执行一次，执行后关闭此次联动。',
        merge: '合并执行：在一定时间段内，达到触发条件仅执行一次。',
        always: '连续执行：指每次达到触发条件均会执行。'
      },
      inside: '默认在配置时间段以内触发，勾选此选项后可在配置的时间段以外触发。'
    }
  },
  tsl: {
    attribute: '属性控制',
    service: '服务调用',
    send: '下发控制',
    sendValue: '下发属性',
    notEmpty: '不能为空',
    column: {
      name: '参数名称',
      code: '标识符',
      upValue: '当前值',
      downValue: '设定值',
      unit: '单位'
    },
    tips: {
      raw: '请把原始内容进行Hex编码后发送，设备端收到的是原始内容'
    },
    validate: {
      raw: '仅支持16进制内容输入(\'0-9\',\'A-F\')',
      arrayLen: '数组长度不能超过',
      noProp: '下发属性为空 请选择下发属性',
      notEmpty: '下发属性值不能为空',
      notMatch: '枚举字段匹配错误'
    }
  },
  notification: {
    tempZH: '中文模板',
    tempEN: '英文模板',
    sysZH: '中文系统',
    sysEN: '英文系统',
    tempList: '模板列表',
    zh: '中文',
    en: '英文',
    common: {
      addAccount: '新建账户',
      editAccount: '编辑账户',
      addTemplate: '新建模板',
      editTemplate: '编辑模板',
      tempContent: '模板内容',
      code: '模板Code',
      tempMap: '模板变量映射',
      tempParams: '模板参数',
      sysParams: '系统参数',
      msgContent: '通知内容',
      confirmEnableChange: '当前机构已启用了其他账户，确定需要更换账户？',
      confirmCloseCurrent: '当前账户已启用，确定需要关闭？',
      alertEnableChange: '该账户已启用，原账户已被停用',
      filterOrg: '请输入机构名称/ID进行查询选择',
      orgNameAndId: '机构名称 / ID',
      insert: '插入数据',
      allProducts: '全部产品',
      state: {
        enable: '启用',
        disable: '停用'
      }
    },
    sms: {
      account: {
        title: '短信账户',
        name: '短信账户名称',
        tunnel: '短信通道',
        orgName: '账户归属机构',
        orgId: '账户归属机构ID',
        enableState: '账户状态',
        orgFees: '新建账户成功后，请尽快到“机构资费-下级默认价格配置”中配置套餐',
        place: {
          accountName: '请输入短信账户名称',
          tunnelType: '请选择短信通道',
          state: '请选择账号状态',
          accessKey: '请输入 AccessKeyID',
          accessSecret: '请输入 AccessSecret',
          publicAccount: '设为公版账号',
          max50: '长度最多为50个字符',
          orgId: '请输入归属机构ID'
        }
      },
      temp: {
        title: '短信模板',
        name: '模板名称',
        state: '模板状态',
        account: '短信账户',
        org: '使用机构',
        product: '使用产品',
        type: '模板类型',
        signature: '关联签名',
        code: '模板Code',
        place: {
          templateName: '请输入短信模板名称',
          accountId: '请检索并选择短信账户',
          max50: '长度最多为50个字符',
          orgId: '请选择使用机构',
          templateType: '请选择模板类型',
          languageTemps: '请至少选择一个语言模板',
          product: '请选择使用产品',
          sign: '请选择关联签名',
          tempCode: '请输入模板Code'
        }
      }
    },
    voice: {
      account: {
        title: '语音账户',
        name: '语音账户名称',
        tunnel: '语音通道',
        orgName: '账户归属机构',
        orgId: '账户归属机构ID',
        enableState: '账户状态',
        numberType: '号码模式',
        phoneNumber: '语音号码',
        place: {
          accountName: '请输入语音账户名称',
          tunnelType: '请选择语音通道',
          state: '请选择账号状态',
          accessKey: '请输入 AccessKeyID',
          accessSecret: '请输入 AccessSecret',
          publicAccount: '设为公版账号',
          orgId: '请输入归属机构ID',
          phoneNumber: '请输入语音号码'
        },
        numberTypeOpts: {
          public: '公共模式',
          private: '专属模式'
        }
      },
      temp: {
        title: '语音模板',
        name: '模板名称',
        state: '模板状态',
        account: '模板账户',
        org: '使用机构',
        product: '使用产品',
        type: '模板类型',
        signature: '关联签名',
        code: '模板Code',
        place: {
          templateName: '请输入语音模板名称',
          accountId: '请检索并选择模板账户',
          max50: '长度最多为50个字符',
          orgId: '请选择使用机构',
          templateType: '请选择模板类型',
          languageTemps: '请至少选择一个语音模板',
          product: '请选择使用产品',
          extraInfo: '请输入模板内容',
          tempCode: '请输入模板Code'
        }
      }
    },
    mail: {
      account: {
        title: '邮件账户',
        name: '邮件账户名称',
        ownOrgId: '账户归属机构ID',
        senderNiceName: '发件人昵称',
        senderAccount: '发件人账户',
        mailAuthorizationCode: '邮件授权码',
        smtpServer: 'SMTP 服务器',
        smtpPort: 'SMTP 端口',
        enableState: '账户状态',
        place: {
          accountName: '请输入邮件账户名称',
          senderNiceName: '请输入发件人昵称',
          senderAccount: '请输入发件人账户',
          mailAuthorizationCode: '请输入邮件授权码',
          smtpServer: '请输入SMTP服务器',
          smtpPort: '请输入SMTP端口',
          state: '请选择账号状态',
          orgId: '请输入归属机构ID',
          publicAccount: '设为公版账号'
        }
      },
      temp: {
        title: '邮件模板',
        name: '邮件模板名称',
        account: '模板账户',
        state: '模板状态',
        org: '使用机构',
        product: '使用产品',
        type: '模板类型',
        place: {
          templateName: '请输入邮件模板名称',
          accountId: '请检索并选择模板账户',
          max50: '长度最多为50个字符',
          orgId: '请选择使用机构',
          templateType: '请选择模板类型',
          product: '请选择使用产品'
        }
      }
    },
    applet: {}
  },
  expense: {
    bracketLeft: '（',
    bracketRight: '）',
    voicePrice: '{0}语音价格',
    smsPrice: '{0}短信价格',
    devicePrice: '{0}设备价格',
    optionType: '套餐类型',
    optionCount: '套餐数量',
    minutes: '（分钟）',
    count: '（条）',
    guiding: '指导价格',
    subPrimary: '当前机构价格（元）',
    explanation: '说明：',
    addExplanation: '建议配置价格时，随套餐增大，单价呈阶梯式适量下降。',
    editExplanationFirst: '1、当前配置的价格不允许超过成本价的130%，否则无法保存；',
    editExplanationSecond: '2、建议配置价格时，随套餐增大，单价呈阶梯式适量下降。',
    unit: {
      voice: '分钟',
      sms: '条',
      times: '个'
    },
    settings: {
      notice: {
        name: '资费通知配置',
        shortage: '资费不足通知',
        deviceRenewal: '设备续费通知',
        smsRenewal: '卡续费通知',
        mailbox: '通知邮箱',
        range: '通知范围',
        rangeSelf: '仅本机构通知',
        rangeBelow: '本级机构及下级机构通知',
        expiredSim: '卡即将到期时间',
        expiredDevice: '设备即将到期时间',
        conditionDevice: '加入待续费条件',
        conditionSim: '加入卡待续费条件',
        phone1: '通知电话一',
        phone2: '通知电话二',
        phone3: '通知电话三',
        unit: '天内',
        tip: '通知时间为',
        smsLess: '短信剩余低于',
        voiceLess: '语音剩余低于',
        informMe: '时通知我',
        fillZero: '填 0 时表示不提醒',
        period: '通知周期',
        continuously: '连续',
        days: '天',
        inform: '通知'
      },
      credit: {
        name: '资费收款账户',
        appSetting: '应用配置',
        mchSetting: '商户配置',
        appName: '应用名称',
        appSecret: '密钥',
        token: 'Token（令牌）',
        noticeKey: '消息加密密钥',
        mchId: '商户ID',
        apiV3: 'APIv3密钥',
        apiCertificate: 'API证书',
        apiCertificateSN: 'API证书序列号',
        apps: '关联应用',
        place: {
          appName: '请输入应用名称',
          appId: '请输入AppID',
          appSecret: '请输入密钥',
          token: '请输入Token（令牌）',
          msgSecret: '请输入消息加密密钥',
          mchId: '请输入商户ID',
          mchKey: '请输入APIv3密钥',
          cert: '请选择API证书',
          certPassword: '请输入API证书序列号',
          appConfigNo: '请选择关联应用'
        }
      },
      profit: {
        name: '资费分润账户',
        subTitle1: '关联分润账户',
        subTitle2: '校对微信账户信息',
        step1: '第一步',
        step2: '第二步',
        form1: {
          accountName: '分润账户名称',
          phone: '手机号',
          enterprise: '企业名称',
          wechat: '微信账户号',
          place: {
            accountName: '请输入分润账户名称',
            realName: '请输入企业名称',
            channelAccount: '请输入微信账户号'
          }
        }
      },
      sim: {
        name: 'SIM卡平台授权配置'
      }
    },
    charge: {
      itemName: {
        paid: '已购买',
        used: '已使用',
        rest: '剩余',
        expired: '已过期',
        froze: '已冻结'
      },
      restTipByVoice: '剩余语音可以供下级机构购买，可以赠送给下级机构',
      restTipBySms: '剩余短信可以供下级机构购买，可以赠送给下级机构',
      expiredTipByVoice: '每次购买语音服务后，都会有一定的有效期，当超过有效期后，状态变为“已过期”，已过期的语音不可再使用',
      expiredTipBySms: '每次购买短信服务后，都会有一定的有效期，当超过有效期后，状态变为“已过期”，已过期的短信不可再使用',
      record: '购买记录',
      buyServiceVoice: '购买语音服务',
      buyServiceSms: '购买短信服务',
      serviceVoice: '语音服务',
      serviceSms: '短信服务',
      buyVoice: '语音购买',
      buySms: '短信购买',
      tips: '重要提示：{0}账户已停用，无法发送{0}通知，请联系上级机构处理。',
      selfTips: '正在使用本机构的{0}账户，无需购买',
      packageChoose: '选择套餐',
      sellingPrice: '支付金额',
      payType: '支付方式',
      payTypeOpts: {
        wechat: '微信'
      },
      expireDate: '到期时间',
      chargeError: '无可购买套餐',
      purchaseNotes: '购买须知',
      first: '1、在线支付目前只支持“微信支付”；',
      second: '2、购买的服务在未试用的前提下，当天可退款；',
      three: '3、购买的服务请在有效期内使用，已过期的服务不可使用；',
      four: '4、如需开票可以联系商务人员线下充值。'
    },
    institution: {
      orgId: '机构ID',
      voice: '语音服务剩余（分钟）',
      sms: '短信服务剩余（条）',
      present: '赠送',
      priceSetting: '价格配置',
      defaultConfig: '下级默认价格配置',
      presentForm: {
        title: '通讯服务赠送',
        count: '{0}数量',
        balance: '我的{0}余额：',
        toOrg: '赠送至机构'
      },
      place: {
        notEmpty: '数量不能为空',
        max: '数量不能大于余额',
        voiceCount: '请输入语音数量',
        smsCount: '请输入短信数量',
        overflow: '已超出余额',
        needCount: '请输入需赠送的数量'
      }
    },
    priceConfig: {
      addOption: '新增套餐',
      editOption: '编辑套餐',
      priceSetting: '配置指导单价',
      dateSetting: '配置有效期',
      name: '产品名称',
      packageCount: '套餐数量',
      guidingPrice: '指导价格（元）',
      defaultPrice: '{0}机构价格（元）',
      unitPrice: '单价',
      pricePrefix: '指导单价',
      costPerUnit: '元/{0}',
      datePrefix: '有效期',
      dateSuffix: '个月',
      service: '{0}服务',
      child: '下级',
      self: '当前',
      parent: '指导',
      voice: {
        title: '下级默认语音价格',
        priceTitle: '语音服务费成本价设置',
        dateTitle: '语音服务费有效期配置',
        priceTips: '指导价即产品成本价',
        dateTips: '语音服务超过有效期时，自动失效'
      },
      sms: {
        title: '下级默认短信价格',
        priceTitle: '短信服务费成本价设置',
        dateTitle: '短信服务费有效期配置',
        dateTips: '短信服务超过有效期时，自动失效'
      },
      device: {
        title: '下级默认设备价格',
        isFree: '是否免费',
        useYears: '使用年限',
        duration: '试用时长',
        defaultEnable: '下级默认启用价格',
        defaultCharge: '下级默认续费价格',
        settingTitle: '设备价格配置',
        enablePrice: '{0}启用价格（元）',
        renewalPrice: '{0}续费价格（元）',
        priceType: '价格类型',
        priceTypeOpts: {
          free: '免费',
          notFree: '收费'
        },
        life: '终身'
      },
      place: {
        minInt: '请输入大于0的整数',
        min: '价格不能低于指导价格',
        inputCount: '请输入套餐数量',
        inputPrice: '请输入价格',
        useYears: '请选择使用年限',
        rightPrice: '请输入正确价格',
        overflow: '超出指导价的30%，平台暂不支持，请联系上级机构处理',
        singlePrice: '请确保大套餐单价低于小套餐单价'
      }
    },
    income: {
      list: {
        orderNo: '订单号',
        orderType: '订单类型',
        paymentOrg: '付款机构',
        receiveOrg: '收款机构',
        incomeMoney: '本机构收益金额(元)',
        orderTime: '订单时间',
        voicePurchase: '语音购买',
        smsPurchase: '短信购买',
        deviceEnable: '设备启用',
        deviceRenewal: '设备续费',
        sumIncome: '本机构累计收益',
        yuan: '元',
        incomeDetail: '收益详情',
        incomeLevel: '收益级别',
        incomeAccount: '收益账户',
        incomeOrg: '收益机构',
        incomeAbleMoney: '应收益金额',
        realIncomeMoney: '实际收益金额'
      }
    },
    device: {
      all: '全部',
      continuationFeetrafficCard: '待续费流量卡',
      cardRecharge: '卡充值',
      deviceNo: 'DeviceKey',
      orgName: '归属机构',
      productName: '产品名称',
      cardExpirationTime: '卡到期时间',
      cardExpirationStatus: '卡到期状态',
      flowPacketRecharge: '流量包充值',
      expirationTime: '到期时间',
      totalMoney: '总金额',
      iccid: 'ICCID',
      unexpired: '未到期',
      expired: '已到期',
      renewalStatus: '续费状态',
      cardRenew: '流量卡续费',
      setMeal: '套餐',
      cardNums: '卡数量（张）',
      time: '时间',
      renewPrice: '续费价格（元）',
      subtotalMoney: '小计金额（元）',
      trialStatus: '试用状态',
      useRemainTime: '试用剩余时间（天）',
      enablePrice: '启用价格（元）',
      createTime: '试用日期',
      enableDeviceTime: '设备启用时间',
      deviceExpireTime: '设备到期时间',
      deviceExpireStatus: '设备到期状态',
      deviceCnt: '设备数量（个）',
      enableYears: '使用年限',
      enablePriceYuan: '启用价格',
      enableDevice: '设备启用',
      renewDevice: '设备续费',
      onlyOneProduct: '只支持同一产品下的设备批量启用，操作不成功',
      productKey: 'ProductKey',
      totalDevice: '总设备',
      clearAll: '全部清除',
      retainAll: '全部保留',
      howHandleData: '如何处理试用期内的数据',
      units: '个',
      yuan: '元',
      trial: '试用',
      enable: '启用',
      simCharge: '卡充值',
      simRenew: '卡续费',
      enableDeviceDetail: '设备启用订单详情',
      renewDeviceDetail: '设备续费订单详情',
      no: '序号',
      renewAfterExpireTime: '续费后到期时间',
      enableAfterExpireTime: '启用后到期时间',
      unknow: '不详',
      mb: 'MB',
      curMonth: '当月',
      deviceFeeState: '资费状态',
      deviceActivationTime: '设备启用时间',
      serviceLife: '使用年限',
      deviceExpirationTime: '设备到期时间',
      deviceExpirationState: '设备到期状态',
      joinRenew: '加入续费',
      stopRenew: '暂停续费',
      scanCodePay: '微信扫码支付',
      simStatus: 'SIM卡状态',
      residueFlow: '剩余流量',
      deviceKey: 'DeviceKey',
      purchaseDescription: '购买说明',
      cardRechargeDescFirst: '1、在线支付目前只支持“微信支付”；',
      cardRechargeDescSecond: '2、购买的流量包不支持退款，请谨慎购买；',
      cardRenewDescSecond: '2、已续费的SIM不支持退款，请谨慎购买；',
      deviceStartDescSecond: '2、设备一旦启用，无法退款，请谨慎购买；',
      deviceRenewDescSecond: '2、设备续费后，无法退款，请谨慎购买；',
      cardRechargeDescThree: '3、流量包请在有效期内使用，已过期的流量包不可使用；',
      cardRechargeDescFour: '4、如需开票可以联系商务人员线下充值。',
      expenseStatusOpt: {
        onTrial: '可试用',
        trialing: '试用中',
        trialed: '已试用',
        beEnabled: '待启用',
        launching: '启用中',
        expired: '已到期'
      },
      trialStatusOpt: {
        noTrial: '无试用',
        notTried: '未试用'
      },
      deviceNotice: {
        title: '设备通知服务',
        allocation: '分配记录',
        allocatingCount: '分配次数',
        distributeDate: '分配时间',
        flowType: '分配类型',
        batchGive: '批量赠送'
      },
      flowTypeOpts: {
        present: '赠送',
        expire: '过期',
        use: '使用',
        firstAllocated: '首次分配',
        autoAllocated: '自动分配',
        delDevice: '删除设备'
      },
      place: {
        flowPacket: '选择流量包',
        purchaseNums: '购买数量',
        paymentAmount: '支付金额',
        payType: '支付方式',
        alipay: '支付宝',
        wechatPayment: '微信支付',
        cancel: '取消',
        toPay: '去支付',
        directCharging: '直充',
        pk: '请输入产品ProductKey',
        deviceNo: '请输入DeviceKey',
        iccid: '请输入ICCID',
        renewalStatus: '请选择续费状态',
        waitRenew: '待续费',
        stopRenew: '暂停续费',
        renew: '续费',
        operate: '操作',
        cardExpirationTime: '请选择卡到期时间',
        within7Day: '7天内',
        within30Day: '30天内',
        within60Day: '60天内',
        within90Day: '90天内',
        expired: '已到期',
        deviceFeeStatus: '请选择资费状态',
        enabled: '待启用',
        try: '试用',
        normal: '正常',
        expire: '到期',
        deviceExpirationTime: '请选择设备到期时间',
        deviceExpirationStatus: '请选择设备到期状态',
        perMonth: '个月',
        halt: '停机',
        cancellation: '预销号',
        cancelNumber: '销号',
        toBeActivated: '待激活',
        unknown: '未知',
        testNormal: '测试期正常',
        testHalt: '测试期停机',
        warranty: '停机保号'
      }
    },
    order: {
      orderNo: '订单号',
      giveOrg: '赠送机构',
      receiver: '接收对象',
      receiverType: '接收对象类型',
      receiverOpts: {
        org: '机构',
        device: '设备'
      },
      expireTime: '截止时间',
      msgNum: '短信数量(条)',
      voiceNum: '语音数量(分钟)',
      orderTime: '订单时间',
      payOrder: '付费订单',
      giftOrder: '赠送订单',
      payOrg: '付款机构',
      orderType: '订单类型',
      orderNum: '订单数量',
      orderMoney: '订单金额(元)',
      payType: '支付渠道',
      orderStatus: '订单状态',
      refundMoney: '退款金额',
      refund: '退款',
      define: '确定',
      smsRemaining: '短信剩余',
      smsFailure: '短信失效',
      voiceRemaining: '语音剩余',
      voiceFailure: '语音失效',
      giveOrderDetail: '赠送订单详情',
      iccid: 'ICCID',
      flowPacketName: '流量包名称',
      flowPacketType: '流量包类型',
      flowPacketSize: '流量包大小',
      startTime: '生效时间',
      expirationTime: '到期时间',
      setMealName: '套餐名称',
      renewPeriod: '续费周期(月)',
      beforeRenewExpirationTime: '续费前到期时间',
      afterRenewExpirationTime: '续费后到期时间',
      payOrgId: '付款机构ID',
      remainNum: '剩余数量',
      failureNum: '失效数量',
      refundMoneyYuan: '退款金额(元)',
      orderDetail: '订单详情',
      cardRecharge: '卡充值',
      cardRenew: '卡续费',
      voice: '语音',
      sms: '短信',
      min: '分钟',
      piece: '条',
      orderFee: '订单金额',
      refundAble: '可退金额',
      publicNetwork: '公网',
      directional: '定向',
      wechat: '微信',
      place: {
        orderNo: '请输入订单号',
        orderType: '请选择订单类型',
        orderStatus: '请选择订单状态',
        voiceService: '语音服务',
        smsService: '短信服务',
        deviceStart: '设备启用',
        deviceRenew: '设备续费',
        cardRecharge: '卡充值',
        cardRenew: '卡续费',
        toBePaid: '待支付',
        closed: '已关闭',
        paid: '已支付',
        fullRefund: '已退款',
        completed: '已完成'
      }
    }
  },
  colSet: {
    title: '列表字段设置',
    sort: '字段排序',
    show: '字段显示',
    name: '字段名称',
    reset: '恢复默认',
    success: '设置成功'
  },
  power: {
    name: '电站名称',
    type: '电站类型',
    sysType: '系统类型',
    add: '新建电站',
    curPower: '当前发电功率',
    curGeneration: '当日发电量',
    totalGeneration: '累计发电量',
    manager: '电站接警人',
    device: '设备管理',
    person: '接警人',
    zone: '电站时区',
    installCapacity: '装机容量',
    createTime: '建站日期',
    img: '电站图片',
    remark: '备注信息',
    ownerInfo: '业主信息',
    ownerName: '业主姓名',
    ownerPhone: '业主联系电话',
    gridConnected: '并网类型',
    energyCapacity: '储能容量',
    avgElectricityPrice: '平均用电价',
    unit: 'kW·h',
    countryCode: '国际区号',
    place: {
      name: '请输入电站名称',
      manager: '请选择电站接警人',
      zone: '请选择电站时区',
      type: '请选择电站类型',
      sysType: '请选择系统类型',
      installCapacity: '请输入装机容量',
      createTime: '请选择建站日期',
      remark: '请输入备注信息',
      ownerName: '请输入业主姓名',
      ownerPhone: '请输入业主联系电话',
      gridConnected: '请选择并网类型',
      energyCapacity: '请输入储能容量',
      avgElectricityPrice: '请输入平均用电价',
      enterprise: '请选择电站归属机构',
      detailAddress: '请输入详细地址',
      address: '请输入电站地址'
    },
    tips: {
      type: '代表此电站的实际应用场景',
      sysType: '代表此电站的发电、用电、储能、离并网能力',
      manager: '用于接收电站设备故障告警信息联系人',
      noManager: '暂无接警人，请先前往报警管理>报警联系人进行人员添加'
    },
    overView: '电站概况',
    info: '电站信息',
    sysAccount: '系统信息',
    editInfo: '编辑电站信息',
    detail: {
      contribution: '碳中和贡献',
      coal: '煤炭节约量',
      carbonDioxide: 'CO2减排量',
      household: '三口家庭用电',
      energyFlow: '能量流动',
      photovoltaic: '光伏',
      powerGrid: '电网',
      house: '家',
      battery: '电池',
      realTimeStatus: '实时状态',
      power: '{0}功率',
      realTime: '{0}实时',
      solar: '发电',
      load: '用电',
      grid: '电网',
      generation: '{0}发电量',
      consumption: '{0}用电量',
      daily: '当日',
      monthly: '当月',
      yearly: '当年',
      total: '累计',
      gridConnectedQuantity: '卖电量',
      elePurchase: '购电量',
      batteryCharge: '充电量',
      batteryDischarge: '放电量'
    }
  },
  ag: {
    name: '农机名称',
    speed: '速度',
    height: '海拔',
    ggA: '定位状态',
    detail: {
      baseInfo: '农机信息',
      propInfo: '农机属性',
      log: '作业记录',
      set: '农机控制',
      unlock: '设备解锁',
      lock: '设备锁定',
      unlockTip: '设备锁定关闭后将下发解除锁定指令给设备，设备将解除锁定，是否确认操作？',
      lockTip: '设备锁定开启后将下发锁定指令给设备，设备将被锁定不可操作，是否确认操作？',
      deviceIsOffline: '设备已离线'
    },
    work: {
      id: '作业ID',
      num: '作业编号',
      type: '作业类型',
      status: '作业状态',
      plotId: '作业地块编号',
      workStartTimeBegin: '作业开始日期（起）',
      workStartTimeEnd: '作业开始日期（止）',
      width: '农具宽度（m）',
      acres: '作业面积（亩）',
      length: '作业长度（m）',
      duration: '作业时长',
      workStartTime: '作业开始时间',
      workEndTime: '作业结束时间',
      noWorkLog: '设备未上报作业轨迹信息',
      typeOpts: {
        item0: '起垄',
        item1: '播种',
        item2: '施肥',
        item3: '收割',
        item4: '其他'
      },
      statusOpts: {
        item1: '作业中',
        item2: '已完成'
      }
    },
    land: {
      id: '地块ID',
      plotId: '作业地块编号'
    },
    line: {
      type: '作业线类型',
      plotId: '归属地块编号',
      deviceName: '上报农机名称',
      jonLineInfo: '作业线详情',
      drawPolyline: '绘制折线',
      openEditor: '开始编辑',
      closeEditor: '结束编辑',
      clearLine: '清除绘制',
      drawTips: '请绘制作业线路'
    }
  }
}
