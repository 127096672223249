import i18n from '@/lang'
// 日期格式化
export function formatDates (time, fmt) {
  if (time == null) {
    return '-'
  }
  const date = new Date(time)
  fmt = fmt || 'yyyy-MM-dd hh:mm:ss'
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length))
    }
  }
  return fmt
}

// 设备列表-参数设置-定位方式
export function filterLocationType (status) {
  const mapStatus = {
    0: 'GNSS',
    1: 'LBS',
    3: 'WIFI'
  }
  return mapStatus[status] ? mapStatus[status] : ''
}
// 电子围栏-触发状态
export function filterFenceStatus (status) {
  const mapStatus = {
    0: i18n.t('device.fence.trigger.item1'),
    1: i18n.t('device.fence.trigger.item2'),
    2: i18n.t('device.fence.trigger.item3'),
    3: i18n.t('device.fence.trigger.item4')
  }
  return mapStatus[status] ? mapStatus[status] : ''
}
// 电子围栏-围栏类型
export function filterFenceTypeStatus (status) {
  const mapStatus = {
    0: i18n.t('device.fence.polygon'),
    1: i18n.t('device.fence.round')
  }
  return mapStatus[status] ? mapStatus[status] : ''
}
// 电子围栏-围栏生效时间类型
export function filterEffectiveTypeStatus (status) {
  const mapStatus = {
    0: i18n.t('device.fence.effectiveType0'),
    1: i18n.t('device.fence.effectiveType1')
  }
  return mapStatus[status] ? mapStatus[status] : ''
}
// 电子围栏-通知渠道
export function filterNotifyChannelStatus (status) {
  const mapStatus = {
    100: '邮件通知',
    '010': '手机短信',
    '001': 'APP推送',
    110: '邮件通知，手机短信',
    101: '邮件通知，APP推送',
    '011': '手机短信，APP推送',
    111: '邮件通知，手机短信，APP推送'
  }
  return mapStatus[status] ? mapStatus[status] : ''
}
