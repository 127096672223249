import variables from '@/assets/styles/variables.module.scss'
import defaultSettings from '@/settings'

const { sideTheme, showSettings, sideStyle, amapAk, amapSk } = defaultSettings

const storageSetting = JSON.parse(localStorage.getItem('layout-setting')) || ''

const state = {
  theme: variables.theme,
  sideTheme: storageSetting.sideTheme || sideTheme,
  showSettings,
  sideStyle: storageSetting.sideStyle || sideStyle,
  amapAk,
  amapSk
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  }
}

const actions = {
  changeSetting ({ commit }, data) {
    commit('CHANGE_SETTING', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
