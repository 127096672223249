import request from '@/utils/request'

/**
 * 查询机构列表
 * 匹配状态 用于机构管理list
 * 非匹配状态，childCount 优先级高于 children
 * @param query query fields
 * @param matching childCount 与 children 是否匹配
 * @returns {Promise}
 */
export function getOrgList (query, matching = false) {
  const paramsMatch = matching ? { listType: 'orgManageList' } : {}
  return request({
    url: '/sysuser/org/list',
    method: 'get',
    params: Object.assign(paramsMatch, query)
  })
}

// 获取子集机构类型
export function getOrgTypeListByParentOrgId (query) {
  return request({
    url: '/sysuser/orgTypeConfig/selectOrgTypeListByParentOrgId',
    method: 'get',
    params: query
  })
}

// 获取机构类型
export function getOrgTypeList (query) {
  return request({
    url: '/sysuser/orgTypeConfig/selectOrgTypeList',
    method: 'get',
    params: query
  })
}

// 添加机构
export function addOrg (data) {
  return request({
    url: '/sysuser/org/add',
    method: 'post',
    data: data
  })
}

// 编辑机构
export function updateOrg (data) {
  return request({
    url: '/sysuser/org/edit',
    method: 'post',
    data: data
  })
}

// 删除机构
export function deleteOrg (data) {
  return request({
    url: `/sysuser/org/remove/${data.orgId}`,
    method: 'delete',
    params: data
  })
}

export function getRoleList (query) {
  return request({
    url: '/sysuser/role/list',
    method: 'get',
    params: query
  })
}

// 机构角色列表 用于新增机构用户
export function getRoleListWithUser (query) {
  return request({
    url: '/sysuser/role/ableToDistributeRole',
    method: 'get',
    params: query
  })
}

// 厂商 新增角色
export function addRole (data) {
  return request({
    url: '/sysuser/role/add',
    method: 'post',
    data: data
  })
}

// 厂商 编辑角色
export function editRole (data) {
  return request({
    url: '/sysuser/role/edit',
    method: 'put',
    data: data
  })
}

// 厂商 删除角色
export function deleteRole (data) {
  return request({
    url: '/sysuser/role/del',
    method: 'delete',
    data: data
  })
}

// 角色状态修改
export function changeRoleStatus (ids, status) {
  const data = {
    ids,
    status
  }
  return request({
    url: '/sysuser/role/close/open',
    method: 'put',
    data: data
  })
}

// 机构状态修改
export function changeOrgStatus (id, status) {
  const data = {
    id,
    status
  }
  return request({
    url: '/sysuser/org/changeOrgStatus',
    method: 'post',
    data: data
  })
}

// 获取 WEB 菜单
export function getMenuTreeInWeb (query) {
  return request({
    url: '/sysuser/scene/menu/org/list',
    method: 'get',
    params: query
  })
}

// 配置 WEB 菜单
export function setMenuTreeInWeb (data) {
  return request({
    url: 'sysuser/scene/menu/custom/distribute',
    method: 'post',
    data
  })
}

// 获取小程序菜单
export function getMenuTreeInWechat (query) {
  return request({
    url: '/sysuser/scene/wa/menu/org/list',
    method: 'get',
    params: query
  })
}

// 配置小程序菜单
export function setMenuTreeInWechat (data) {
  return request({
    url: 'sysuser/scene/wa/menu/custom/distribute',
    method: 'post',
    data
  })
}

// 用户列表
export function getUserList (query) {
  return request({
    url: '/sysuser/userv2/page',
    method: 'get',
    params: query
  })
}

// 新增用户
export function addUser (data) {
  return request({
    url: '/sysuser/userv2/save',
    method: 'post',
    data: data
  })
}

// 编辑用户
export function editUser (data) {
  return request({
    url: '/sysuser/userv2/update',
    method: 'put',
    data: data
  })
}

// 删除用户
export function deleteUser (data) {
  return request({
    url: '/sysuser/userv2/delete',
    method: 'delete',
    params: data
  })
}

// 获取用户详情
export function getUserDetail (orgId, userId) {
  return request({
    url: '/sysuser/userv2/get',
    method: 'get',
    params: { orgId, userId }
  })
}

// 更改用户状态
export function changeUserStatus (data) {
  return request({
    url: '/sysuser/userv2/changeStatus',
    method: 'put',
    data: data
  })
}
/**
 * @desc 批量启停用户状态
 * @since 2.3.0
*/
export function batchUserStatus (ids, status) {
  const data = { ids, status }
  return request({
    url: '/sysuser/userv2/batchHandlerSwitch',
    method: 'put',
    data: data
  })
}

/**
 * @desc 优化新增/编辑角色，配置菜单权限
 * @desc 因此在角色管理的新增/编辑角色弹窗，增加菜单配置
 * @since 2.3.0
*/
export function getAddRoleWebMenuList (query) {
  return request({
    url: '/sysuser/scene/menu/getUserMenuList',
    method: 'get',
    query
  })
}
// 角色管理-新增角色-web菜单权限集合获取
export function getAddRoleWebMenuListNew (orgId) {
  return request({
    url: '/sysuser/scene/menu/addRole/getWebMenuList',
    method: 'get',
    params: orgId
  })
}
// 角色管理-编辑角色-web菜单权限集合获取
export function getUpdRoleWebMenuListNew (query) {
  return request({
    url: '/sysuser/scene/menu/updRole/getWebMenuList',
    method: 'get',
    params: query
  })
}

export function getAddRoleWxMenuList (query) {
  return request({
    url: '/sysuser/scene/wa/menu/getWxMenu',
    method: 'get',
    query
  })
}
// 角色管理-新增角色-小程序菜单权限集合获取"
export function getAddRoleWxMenuListNew (orgId) {
  return request({
    url: '/sysuser/scene/wa/menu/addRole/getWxMenuList',
    method: 'get',
    params: orgId
  })
}
// 角色管理-编辑角色-小程序菜单权限集合获取
export function getUpdRoleWxMenuListNew (query) {
  return request({
    url: '/sysuser/scene/wa/menu/updRole/getWxMenuList',
    method: 'get',
    params: query
  })
}
export function getUpdateRole (data) {
  return request({
    url: '/sysuser/role/v2/edit',
    method: 'post',
    data
  })
}

export function reqCreateRole (data) {
  return request({
    url: '/sysuser/role/v2/add',
    method: 'post',
    data
  })
}

