import { getDateStart, getMonthStart, getYearStart, getRecentDays } from '@/timezone/range'
import { keepLocalTime, createUserZoneTime } from '@/timezone/core'

// 判断 Picker 范围

export function beforeWithToday (stampOrIsoStr) {
  const itemInPicker = keepLocalTime(stampOrIsoStr).startOf('date')
  const todayStart = getDateStart(Date.now())
  return !itemInPicker.isAfter(todayStart)
}

export function beforeWithoutToday (stampOrIsoStr) {
  const itemInPicker = keepLocalTime(stampOrIsoStr).startOf('date')
  const todayStart = getDateStart(Date.now())
  return itemInPicker.isBefore(todayStart)
}

export function afterWithToday (stampOrIsoStr) {
  const itemInPicker = keepLocalTime(stampOrIsoStr).startOf('date')
  const todayStart = getDateStart(Date.now())
  return !itemInPicker.isBefore(todayStart)
}

/**
 * Judge if after
 * @param stampOrIsoStr
 * @returns {boolean}
 */
export function afterWithoutToday (stampOrIsoStr) {
  const itemInPicker = keepLocalTime(stampOrIsoStr).startOf('date')
  const todayStart = getDateStart(Date.now())
  return itemInPicker.isAfter(todayStart)
}

export function beforeWithTarget (stampOrIsoStr, count) {
  const itemInPicker = keepLocalTime(stampOrIsoStr).startOf('date')
  const todayStart = getDateStart(Date.now())
  const targetStart = todayStart.subtract(count, 'day')
  return itemInPicker.isBefore(targetStart)
}

export function innerRange (centerStamp, leftDays, rightDays, targetStamp) {
  // 范围中心点
  const centerDate = keepLocalTime(centerStamp)
  // 范围左区间点
  const leftDate = centerDate.subtract(leftDays, 'day')
  // 范围右区间点
  const rightDate = centerDate.add(rightDays, 'day')
  // 目标点
  const targetDate = keepLocalTime(targetStamp).startOf('date')
  // 判断目标是否在范围内
  return targetDate.isAfter(leftDate) && targetDate.isBefore(rightDate)
}

// 获取 Picker Value

export function getPickerValOfDateStart (stamp) {
  return getDateStart(stamp).format('YYYY-MM-DD HH:mm:ss')
}

export function getPickerValOfMonthStart (stamp) {
  return getMonthStart(stamp).format('YYYY-MM-DD HH:mm:ss')
}

export function getPickerValOfYearStart (stamp) {
  return getYearStart(stamp).format('YYYY-MM-DD HH:mm:ss')
}

/**
 * 获取最近N天的时间范围数组 用于给Picker组件赋初值
 * @param {number} count 即 N 必须为大于等于1的整数
 * @param {string} type 为format返回字符，stamp返回时间戳
 * @return {Array}
 */
export function getPickerValOfRecent (count, type = 'format') {
  const [start, end] = getRecentDays(count)
  const fmt = 'YYYY-MM-DD HH:mm:ss'
  switch (type) {
    case 'format':
      return [start.format(fmt), end.format(fmt)]
    case 'stamp':
      return [start.valueOf(), end.valueOf()]
    default:
      return [start, end]
  }
}

// 操作

/**
 * 合并参数和起止时间
 * @param {Object} params
 * @param {Array} dateRange
 * @param {Object} propName
 * @param {string} propName.startTime 输出的开始时间字段名
 * @param {string} propName.endTime 输出的结束时间字段名
 * @param {boolean} timestampMs 是否结束时间取到毫秒级别
 * @return {Object} 带有起止时间戳的kv格式
 */
export function mergeDateRange (params, dateRange, propName = {}, timestampMs = false) {
  let search = { ...params }

  // dateRange 为长度为2的数组
  if (Array.isArray(dateRange) && dateRange.length === 2) {
    const timeStartCode = propName?.startTime || 'startTime'
    const timeEndCode = propName?.endTime || 'endTime'
    search[timeStartCode] = createUserZoneTime(dateRange[0])
    search[timeEndCode] = createUserZoneTime(dateRange[1]) + (timestampMs ? 59999 : 0)
  }
  return search
}
