import { getStamp } from '@/utils/date'
import { afterWithoutToday, beforeWithTarget, innerRange } from '@/timezone/elementPicker'

let pickedStamp = null

export const dateValid = {
  // 今天及以后
  todayAfterTime: {
    disabledDate (time) {
      // 禁用之前的date
      return time.getTime() < getStamp(Date.now()) - 8.64e7
    }
  },
  // 今天及以前
  todayBefore: {
    disabledDate (time) {
      // 禁用以后的date （不含今天）
      return afterWithoutToday(time.getTime())
    }
  },
  // 近30天（轨迹回放需要）
  todayBeforeAndMonth: {
    // 禁用 以后 或 30天以前(不含30)
    disabledDate (time) {
      const stamp = time.getTime()
      return afterWithoutToday(stamp) || beforeWithTarget(stamp, 29)
    }
  },
  // 近31天
  todayBefore31: {
    // 禁用 以后 或 31天以前(不含31)
    disabledDate (time) {
      const stamp = time.getTime()
      return afterWithoutToday(stamp) || beforeWithTarget(stamp, 30)
    }
  },
  // 今天之前任意30天
  todayBeforeThirty: {
    onPick: ({ maxDate, minDate }) => {
      pickedStamp = minDate.getTime()
      if (maxDate) {
        pickedStamp = null
      }
    },
    // 禁用今天之后的date
    // 当选中第一个date时 禁用范围超过30天的
    disabledDate: (time) => {
      const stamp = time.getTime()
      if (pickedStamp) {
        return afterWithoutToday(stamp) || !innerRange(pickedStamp, 30, 30, stamp)
      } else {
        return afterWithoutToday(stamp)
      }
    }
  },
  // 近一个月内，最大区间为七天
  sevenInThirty: {
    onPick: ({ maxDate, minDate }) => {
      pickedStamp = minDate.getTime()
      if (maxDate) {
        pickedStamp = null
      }
    },
    disabledDate: (time) => {
      const stamp = time.getTime()
      if (pickedStamp) {
        return afterWithoutToday(stamp) || beforeWithTarget(stamp, 29) || !innerRange(pickedStamp, 7, 7, stamp)
      } else {
        return afterWithoutToday(stamp) || beforeWithTarget(stamp, 29)
      }
    }
  },
  // 今天之前，最大区间为七天
  sevenInTodayBefore: {
    onPick: ({ maxDate, minDate }) => {
      pickedStamp = minDate.getTime()
      if (maxDate) {
        pickedStamp = null
      }
    },
    disabledDate: (time) => {
      const stamp = time.getTime()
      if (pickedStamp) {
        return afterWithoutToday(stamp) || !innerRange(pickedStamp, 7, 7, stamp)
      } else {
        return afterWithoutToday(stamp)
      }
    }
  }
}
