export default {
  methods: {
    initNoticeWS () {
      let { orgId, userId } = this.$store.state.user
      this.initWS(`event_notice/topic/${orgId}/${userId}`, this.onEventMsg) // 故障告警事件
      this.initWS('station/topic', this.onStationMsg) // 站内信
    },
    // 1-故障，2-解除故障，3-告警，4-解除告警，5-围栏
    getMsgType (type) {
      switch (type) {
        case 1:
          return 'error'
        case 2:
        case 4:
          return 'success'
        case 3:
          return 'warning'
        default:
          return 'info'
      }
    },
    // 设备事件通知
    onEventMsg (data) {
      let obj = JSON.parse(data)
      if (obj.content) {
        let { eventName, eventType, deviceKey, deviceName, time, deviceId, productKey } = JSON.parse(obj.content)
        let title = eventName
        if (obj.type === 3) {
          title = `${this.$t('operatingStatus.fence')}-${obj.noticeType === 'FENCE_OUT' ? this.$t('operatingStatus.fenceOut') : this.$t('operatingStatus.fenceIn')}`// 电子围栏标题
        }
        if (eventType === 2 || eventType === 4) {
          title += this.$t('deviceNoticeType.removed') // xxx已解除
        }
        let h = this.$createElement
        let notice = this.$notify({
          title,
          type: this.getMsgType(eventType),
          dangerouslyUseHTMLString: true,
          message: h('div', [
            h('p', [
              h('span', 'DeviceKey：'),
              h('el-button', {
                class: 'p-0',
                attrs: { type: 'text' },
                on: {
                  click: () => {
                    notice.close() // 关闭当前点击的提示框
                    this.handleToDetail(deviceId, productKey)
                  }
                }
              }, deviceKey)
            ]
            ),
            h('p', `${this.$t('m.letter.device_name')}：${deviceName}`),
            h('p', `${this.$t('m.letter.warn_time')}：${this.parseTime(time)}`)
          ])
        })
        h = null
      }
    },
    // 站内信
    onStationMsg (data) {
      let obj = JSON.parse(data)
      if (obj.content && obj.type === 2) {
        let data = JSON.parse(obj.content)
        this.$store.dispatch('app/GetTextCarousel') // 刷新站内信
        let h = this.$createElement
        let notice = this.$notify.info({
          title: this.$t('m.letter.announcement'),
          dangerouslyUseHTMLString: true,
          message: h('div', [
            h('p', [
              h('span', this.$t('device.run.heading') + '：'),
              h('el-button', {
                class: 'p-0',
                attrs: { type: 'text' },
                on: {
                  click: () => {
                    notice.close() // 关闭当前点击的提示框
                    this.handleToMessage()
                  }
                }
              }, data.title)
            ])
          ])
        })
        h = null
        if (this.$store.state.user.UnReadCount < 99) {
          this.$store.dispatch('refreshUnReadCount') // 刷新未读消息
        }
      }
    },
    // 设备详情跳转
    handleToDetail (deviceId, productKey) {
      this.$router.push({ name: 'deviceDetail', query: { deviceId, productKey } })
    },
    // 站内信跳转
    handleToMessage () {
      this.$router.push({ name: 'Letter' })
    }
  }
}

