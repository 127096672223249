<!-- TipButton 禁用时展示tooltip -->
<template>
  <el-tooltip :content="tip" placement="top" :disabled="!disabled || !tip" class="tip-btn">
    <span>
      <el-button :class="buttonClass" v-bind="$attrs" :disabled="disabled" @click.native.stop="$emit('click')">
        <slot />
      </el-button>
    </span>
  </el-tooltip>
</template>

<script>
export default {
  name: 'TipButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    tip: {
      type: String,
      default: ''
    },
    isDel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonClass () {
      if (this.disabled) {
        // 如果按钮被禁用，返回Element UI默认的禁用样式
        return 'el-button--disabled'
      } else {
        // 如果按钮非禁用，并且是删除按钮，返回红色的样式
        if (this.isDel) {
          return 'delete-btn'
        } else {
          // 如果按钮非禁用，并且不是删除按钮，返回Element UI默认的样式
          return 'el-button--default'
        }
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.tip-btn {
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }
}
</style>
