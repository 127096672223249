var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    height: '100%',
    width: _vm.themeMixinVars.twoSideBarWidth,
    backgroundColor:
      _vm.settings.sideTheme === 'theme1' || _vm.settings.sideTheme === 'theme4'
        ? _vm.themeMixinVars.twoMenuBackground
        : _vm.themeMixinVars.twoMenuLightBackground
  })},[_c('logo'),_c('el-scrollbar',{class:_vm.settings.sideTheme === 'theme1' || _vm.settings.sideTheme === 'theme4' ? 'theme-dark' : 'theme-light',attrs:{"wrap-class":"scrollbar-wrapper"}},[_c('el-menu',{attrs:{"default-active":_vm.activeMenu,"collapse":_vm.isCollapse,"text-color":_vm.settings.sideTheme === 'theme1' || _vm.settings.sideTheme === 'theme4'
          ? _vm.themeMixinVars.twoMenuColor
          : _vm.themeMixinVars.twoMenuLightColor,"unique-opened":true,"active-text-color":"#fff","collapse-transition":false,"mode":"vertical"}},_vm._l((_vm.sidebarRouters),function(route,index){return _c('sidebar-item',{key:route.path + index,attrs:{"item":route,"base-path":route.path}})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }