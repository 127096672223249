import request from '@/utils/request'

// 系统通知信息列表
export function systemNoticeList (query) {
  return request({
    url: '/notice/systemNotice/log/systemNoticeList',
    method: 'get',
    params: query
  })
}

// 设备通知信息列表
export function deviceNoticeList (query) {
  return request({
    url: '/notice/systemNotice/log/deviceNoticeList',
    method: 'get',
    params: query
  })
}

// 系统消息全部已读
export function systemNoticeAllRead () {
  return request({
    url: '/notice/systemNotice/log/systemNoticeAllRead',
    method: 'put'
  })
}

// 设备消息全部已读
export function deviceNoticeAllRead () {
  return request({
    url: '/notice/systemNotice/log/deviceNoticeAllRead',
    method: 'put'
  })
}

// 标记已读
export function read (messageId) {
  return request({
    url: '/notice/systemNotice/log/read',
    method: 'put',
    params: { messageId }
  })
}

// 系统通知信息详情
export function systemNoticeDetail (messageId) {
  return request({
    url: '/notice/systemNotice/log/systemNoticeDetail',
    method: 'get',
    params: { messageId }
  })
}

// 设备通知信息详情
export function deviceNoticeDetail (messageId) {
  return request({
    url: '/notice/systemNotice/log/deviceNoticeDetail',
    method: 'get',
    params: { messageId }
  })
}

// 设备通知信息详情
export function pageList (params) {
  return request({
    url: '/notice/systemNotice/pageList',
    method: 'get',
    params
  })
}

// 删除
export function deleted (id, data) {
  return request({
    url: '/notice/systemNotice/delete',
    method: 'DELETE',
    params: { id },
    data
  })
}

// 新增/编辑系统公告
export function saveOrUpdate (data) {
  return request({
    url: '/notice/systemNotice/saveOrUpdate',
    method: 'post',
    data
  })
}

// 暂存系统公告
export function temporaryStorage (data) {
  return request({
    url: '/notice/systemNotice/temporaryStorage',
    method: 'post',
    data
  })
}

// 查询系统公告详情
export function noticeDetail (id) {
  return request({
    url: '/notice/systemNotice/get',
    method: 'get',
    params: { id }
  })
}

// 获取置顶滚动
export function getTopRolling () {
  return request({
    url: '/notice/systemNotice/getTopRolling',
    method: 'get'
  })
}

// 反馈信息列表
export function getFeedback (params) {
  return request({
    url: '/system/feedBack/list',
    params
  })
}

// 新增反馈信息
export function addFeedback (data) {
  return request({
    url: '/system/feedBack/insert',
    method: 'post',
    data
  })
}

// 处理反馈信息
export function handleFeedback (data) {
  return request({
    url: '/system/feedBack/edit',
    method: 'put',
    params: data
  })
}
