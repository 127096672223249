import request from '@/utils/request'

// 查询 websocket 连接配置信息
export function getWSSetting () {
  return request({
    url: 'system/config/websocket',
    method: 'post'
  })
}

// topic 订阅续约
export function contractExtension (topicList) {
  return request({
    url: 'notice/topic/subscribe',
    method: 'post',
    data: { topicList }
  })
}
