/**
 * 操作国际版本权限处理
 */

import store from '@/store'

export default {
  inserted (el, binding, vnode) {
    let currentType = store.state.env.VUE_APP_ENV_TYPE
    const { value } = binding
    // const allPermission = '*:*:*'
    // const permissions = store.getters && store.getters.permissions

    if (value && value instanceof Array && value.length > 0) {
      const hasPermissions = value.some(permission => {
        return currentType === permission
      })
      if (!hasPermissions) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error('请设置操作权限标签值')
    }

    //   const permissionFlag = value

    //   const hasPermissions = permissions.some(permission => {
    //     return allPermission === permission || permissionFlag.includes(permission)
    //   })
    // console.log(el.parentNode, el.parentNode)
    //   if (!hasPermissions) {
    //     el.parentNode && el.parentNode.removeChild(el)
    //   }
    // } else {
    //   throw new Error('请设置操作权限标签值')
    // }
  }
}
