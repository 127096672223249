<template>
  <div v-if="!item.hidden">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
        !item.alwaysShow
      "
    >
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <div class="main-menu-item" @mouseenter="overMouse([], item)" @click="handleRouter">
          <item :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)" :title="titleFormat(onlyOneChild)" />
        </div>
      </app-link>
    </template>

    <div v-else>
      <app-link :class="{ active: item.active }" :to="resolvePath(item.path)">
        <div
          class="main-menu-item"
          @mouseenter="overMouse(item.children, item)"
          @mouseleave="outMouse(item)"
          @click="handleSaveRouter(item)"
        >
          <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="titleFormat(item)" />
        </div>
      </app-link>
    </div>
  </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils/validate'
import Item from './Item'
import AppLink from './Link'
import { generateText } from '@/utils/aep'
import Cookies from 'js-cookie'
export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  props: {
    item: {
      type: Object,
      required: true
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data () {
    this.onlyOneChild = null
    return {
      timer: null
    }
  },
  mounted () { },
  methods: {
    hasOneShowingChild (children = [], parent) {
      if (!children) {
        children = []
      }
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false
        } else {
          this.onlyOneChild = item
          return true
        }
      })

      if (showingChildren.length === 1) {
        return true
      }

      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath (routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    },
    overMouse (childMenu, mainMenu) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$set(mainMenu, 'active', true)
        this.$emit('overChildMenu', childMenu, mainMenu)
      }, 500)
    },
    outMouse (mainMenu) {
      clearTimeout(this.timer)
      this.$set(mainMenu, 'active', false)
      this.$emit('outChildMenu')
    },
    handleRouter () {
      this.$store.dispatch('ClearActiveRouter').then(() => {
        this.$emit('handleClick')
      })
    },
    handleSaveRouter (router) {
      let activeRouter = {
        list: router.children,
        title: router.meta.otherName,
        basePath: router.path
      }
      this.$store.dispatch('ActiveRouter', activeRouter).then(() => {
        this.$emit('handleClick')
      })
    },
    titleFormat (item) {
      let lang = Cookies.get('lang')
      return (lang === 'zh-CN' ? item?.meta?.title : item?.meta?.titleEn) || generateText.call(this, 'm', item?.meta?.otherName)
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes fadeIn {
  0% {
    opacity: 0; /* 初始状态 透明度为0 */
  }

  20% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.5; /* 中间状态 透明度为0.5 */
  }

  70% {
    opacity: 0.7;
  }

  100% {
    opacity: 1; /* 结尾状态 透明度为1 */
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0; /* 初始状态 透明度为0 */
  }

  20% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.5; /* 中间状态 透明度为0.5 */
  }

  70% {
    opacity: 0.7;
  }

  100% {
    opacity: 1; /* 结尾状态 透明度为1 */
  }
}

.main-menu-item {
  position: relative;
  display: flex !important;
  padding: 30px 0;
  color: #cdced4;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .svg-icon {
    margin-right: 0 !important;
    margin-bottom: 6px;
    font-size: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    width: 104px;
    font-size: 14px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &::after {
    position: absolute;
    display: none;
    top: -4px;
    right: -1px;
    z-index: -1;
    width: 124px;
    height: 118px;
    background-repeat: no-repeat;
    opacity: 0.2;
    content: '';
    background-image: url('../../../../assets/images/sidebar.png');
  }

  &:hover {
    &::after {
      display: block;
    }
  }
}

.router-link-active {
  .main-menu-item {
    color: #0a0c2c;

    &::after {
      display: block;
      -webkit-animation-name: fadein; /* 动画名称 */
      animation-name: fadeIn;
      -webkit-animation-duration: 0.15s; /* 动画持续时间 */
      animation-duration: 0.15s;
      -webkit-animation-iteration-count: 1; /* 动画次数 */
      animation-iteration-count: 1;
      -webkit-animation-delay: 0s; /* 延迟时间 */
      animation-delay: 0s;
      opacity: 1;
    }
  }
}

.theme-light {
  .main-menu-item {
    color: #474747;

    &::after {
      background-image: url('../../../../assets/images/sidebar-light.png');
      opacity: 1;
    }
  }
}
</style>
