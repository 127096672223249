import Vue from 'vue'
import Router from 'vue-router'
import appRouter from '@/app/router'
import Layout from '@/layout'

const originalPush = Router.prototype.push

Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(Router)

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/forget',
    component: (resolve) => require(['@/views/login/forget-password'], resolve),
    hidden: true
  },
  {
    path: '/reset-password',
    component: (resolve) => require(['@/views/login/reset-password'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/agreement',
    component: (resolve) => require(['@/views/login/agreement'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
  },
  {
    path: '',
    component: Layout,
    redirect: 'index'
  },
  {
    path: '/oauth',
    component: (resolve) => require(['@/views/login/oauth'], resolve),
    hidden: true
  },
  {
    path: '/loading',
    component: (resolve) => require(['@/views/login/loading'], resolve),
    hidden: true
  },
  {
    path: '/maintenance',
    component: (resolve) => require(['@/views/maintenance'], resolve),
    hidden: true
  },
  {
    path: '/track-play',
    component: (resolve) => require(['@/views/device/map/trackPlay'], resolve),
    name: 'TrackPlay',
    hidden: true
  },
  {
    path: '/curve-page',
    component: (resolve) => require(['@/views/device/detail/components/DebugAnalyze/CurvePage.vue'], resolve),
    name: 'DeviceCurvePage',
    hidden: true
  },
  {
    path: '/dataScreen',
    component: (resolve) => require(['@/views/data-screen'], resolve),
    hidden: true
  },
  {
    path: '/commonScreen',
    component: (resolve) => require(['@/views/commonScreen'], resolve),
    hidden: true
  },
  {
    path: '/screen-iframe',
    component: (resolve) => require(['@/views/system/screenManage/components/screenIframe'], resolve),
    hidden: true
  }
  /* {
    path: '/IncomeDetail',
    component: (resolve) => require(['@/views/expense/incomeDetail'], resolve),
    hidden: true
  } */
]

function handleRouter () {
  if (Object.keys(appRouter).length !== 0 && Object.keys(appRouter)) {
    return [...constantRoutes, ...appRouter]
  } else {
    return [...constantRoutes]
  }
}

export const Routes = handleRouter()

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: handleRouter()
})
