var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"logo",style:({
    backgroundColor:
      _vm.settings.sideTheme === 'theme1' || _vm.settings.sideTheme === 'theme4' ? _vm.variables.menuBg : _vm.variables.menuLightBg
  }),attrs:{"to":'/' + _vm.firstRouters}},[(_vm.showLogo)?_c('img',{attrs:{"src":_vm.settings.sideTheme === 'theme1' || _vm.settings.sideTheme === 'theme4'
        ? _vm.companyOem.systemInsideDarkLogoUrl
        : _vm.companyOem.systemInsideBrightLogoUrl}}):_vm._e(),_c('span',{style:({
      color: _vm.settings.sideTheme === 'theme1' || _vm.settings.sideTheme === 'theme4' ? '#fff' : '#303133'
    })},[_vm._v(_vm._s(_vm.lang === 'zh-CN' ? _vm.companyOem.systemName : _vm.companyOem.systemEnName))])])
}
var staticRenderFns = []

export { render, staticRenderFns }