<template>
  <div :class="(classObj, { gdprOpen: gdpr })" class="app-wrapper">
    <div v-if="device === 'mobile'" class="drawer-bg" />
    <sidebar />
    <div
      class="main-container"
      :class="{ pcOpenSidebar: isActive && sideStyle === 'menu1' }"
      :style="{
        marginLeft: sideStyle === 'menu1' ? themeMixinVars.sideBarWidth : themeMixinVars.twoSideBarWidth
      }"
    >
      <div
        class="fixed-header"
        :style="{
          width:
            sideStyle === 'menu1'
              ? `calc(100% - ${themeMixinVars.sideBarWidth})`
              : `calc(100% - ${themeMixinVars.twoSideBarWidth})`
        }"
      >
        <navbar />
        <Alert />
      </div>
      <app-main />
      <right-panel>
        <settings />
      </right-panel>
    </div>

    <div v-if="gdpr" class="gdpr-mask">
      <div class="gdpr">
        <div class="gdpr-header clearfix">
          <h1 class="gdpr-header-title fl">
            {{ $t('gdpr.title') }}
          </h1>
          <img class="gdpr-header-logo fr" :src="quectelLogo" alt="" />
        </div>
        <p class="gdpr-version">
          <span>{{ $t('gdpr.time1') }}</span><span>{{ $t('gdpr.time2') }}</span>
        </p>
        <div class="gdpr-tab">
          <div>
            <span :class="{ active: currentType === 'privacy' }" @click="currentType = 'privacy'">{{
              $t('gdpr.item1')
            }}</span>
            <span :class="{ active: currentType === 'service' }" @click="currentType = 'service'">{{
              $t('gdpr.item2')
            }}</span>
          </div>
        </div>
        <div class="gdpr-container">
          <iframe
            v-if="currentType === 'privacy' && envType === 'GLOBAL' && lang === 'en-US'"
            src="/html/ys_zh_en.html?area=GLOBA"
            style="width: 100%; height: 100%; border: none"
          />
          <iframe
            v-if="currentType === 'service' && envType === 'GLOBAL' && lang === 'en-US'"
            src="/html/fw_global_en.html"
            style="width: 100%; height: 100%; border: none"
          />
          <iframe
            v-if="currentType === 'privacy' && envType === 'GLOBAL' && lang === 'zh-CN'"
            src="/html/ys_zh_zh.html?area=GLOBA"
            style="width: 100%; height: 100%; border: none"
          />
          <iframe
            v-if="currentType === 'service' && envType === 'GLOBAL' && lang === 'zh-CN'"
            src="/html/fw_global_zh.html"
            style="width: 100%; height: 100%; border: none"
          />

          <iframe
            v-if="currentType === 'privacy' && envType === 'CHINA' && lang === 'en-US'"
            src="/html/ys_zh_en.html"
            style="width: 100%; height: 100%; border: none"
          />
          <iframe
            v-if="currentType === 'service' && envType === 'CHINA' && lang === 'en-US'"
            src="/html/fw_zh_en.html"
            style="width: 100%; height: 100%; border: none"
          />
          <iframe
            v-if="currentType === 'privacy' && envType === 'CHINA' && lang === 'zh-CN'"
            src="/html/ys_zh_zh.html"
            style="width: 100%; height: 100%; border: none"
          />
          <iframe
            v-if="currentType === 'service' && envType === 'CHINA' && lang === 'zh-CN'"
            src="/html/fw_zh_zh.html"
            style="width: 100%; height: 100%; border: none"
          />
        </div>
        <div class="gdpr-foot clearfix">
          <el-checkbox v-model="gdprChecked">{{ $t('gdpr.text') }}</el-checkbox>
          <div class="gdpr-foot-btn">
            <el-button type="primary" :disabled="!gdprChecked" @click="agree">{{ $t('gdpr.agree') }}</el-button>
            <el-button @click="refuse">{{ $t('common.back') }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <ResetPwd v-if="resetPwdDialog.show" :form-data="resetPwdDialog.form" @closeDialog="closeResetPwdDialog" />
  </div>
</template>

<script>
import RightPanel from '@/components/RightPanel'
import { AppMain, Navbar, Settings, Sidebar } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState, mapGetters } from 'vuex'
import { readClause } from '@/api/login'
import Cookies from 'js-cookie'
import Alert from '@/components/TextCarousel/alert.vue'
import ResetPwd from '@/views/login/components/ResetPwd'
import ThemeMixin from '@/components/mixins/theme'
import websocket from './mixin/websocket'
import noticeWS from './mixin/noticeWS' // 通知ws

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Alert,
    Sidebar,
    ResetPwd
  },
  mixins: [ResizeMixin, ThemeMixin, websocket, noticeWS],
  computed: {
    ...mapState({
      theme: (state) => state.settings.theme,
      sideTheme: (state) => state.settings.sideTheme,
      device: (state) => state.app.device,
      showSettings: (state) => state.settings.showSettings,
      activeRouter: (state) => state.user.activeRouter,
      switchClass: (state) => state.user.switchClass,
      isReadClause: (state) => state.user.isReadClause,
      sideStyle: (state) => state.settings.sideStyle
    }),
    ...mapGetters(['orgtype']),
    classObj () {
      return {
        mobile: this.device === 'mobile'
      }
    }
  },
  watch: {
    activeRouter () {
      this.handleRouter()
    },
    switchClass () {
      this.isActive = !this.switchClass
    }
  },
  data () {
    return {
      isActive: false,
      quectelLogo: require('@/assets/images/quectel-logo.png'),
      currentType: 'privacy',
      gdpr: false,
      gdprChecked: false,
      lang: Cookies.get('lang'),
      sidebarStyle: 'One',
      envType: this.$store.state.env.VUE_APP_ENV_TYPE,
      resetPwdDialog: {
        show: false,
        form: {}
      }
    }
  },
  created () {
    this.$ws.init()
  },
  mounted () {
    if (localStorage.getItem('isValidPassword') && localStorage.getItem('isValidPassword') === 'true') {
      this.openResetPwdDialog()
    }
    this.handleRouter()
    // 隐藏先
    // if (this.isReadClause === 0 && this.orgtype !== 1) {
    //   this.gdpr = true
    // }
    this.isOrg = true
    this.initNoticeWS()
  },
  methods: {
    handleRouter () {
      if (
        JSON.parse(localStorage.getItem('activeRouter')) &&
        !this.switchClass
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    },
    // 同意
    agree () {
      if (!this.gdprChecked) {
        return this.$message.error(this.$t('gdpr.validGdpr'))
      }
      readClause().then((res) => {
        if (res.code === 200) {
          this.$store.dispatch('IsReadClause', 1).then(() => {
            this.$message.success(this.$t('gdpr.success'))
            this.gdpr = false
          })
        }
      })
    },
    // 拒绝
    refuse () {
      this.$confirm(this.$t('gdpr.logout'), this.$t('common.prompt'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.href = '/login'
        })
      })
    },
    openResetPwdDialog (e) {
      this.resetPwdDialog.show = true
    },
    closeResetPwdDialog (e) {
      // 确认修改
      if (e) {
        localStorage.setItem('isValidPassword', 'false')
      }
      this.$store.dispatch('LogOut').then(() => {
        location.href = '/login'
      })

      this.resetPwdDialog.show = false
      this.resetPwdDialog.form = {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixin';
@import '~@/assets/styles/variables';

.app-wrapper {
  @include clearfix;

  position: relative;
  width: 100%;
  height: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }

  .pt84 {
    // padding-top: 84px;
  }
}

.drawer-bg {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
}

.fixed-header {
  position: fixed;
  z-index: 11;
  width: 100%;
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.pcOpenSidebar {
  margin-left: 340px !important;

  .fixed-header {
    width: calc(100% - 340px) !important;
  }
}

.gdprOpen {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.gdpr-mask {
  position: absolute;
  inset: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
}

.gdpr {
  position: absolute;
  inset: 0;
  width: 1160px;
  height: 700px;
  padding: 30px;
  border-radius: 10px;
  margin: auto;
  background-color: #fff;

  &-header {
    &-title {
      font-size: 30px;
      color: #d20030;
    }

    &-logo {
      margin-top: 10px;
    }
  }

  &-version {
    margin: 20px 0;
    font-size: 12px;
    color: #999;

    span {
      margin-right: 30px;
    }
  }

  &-tab {
    div {
      display: inline-block;
      overflow: hidden;
      border-radius: 4px;
      margin-bottom: 10px;
    }

    span {
      display: inline-block;
      height: 36px;
      padding: 0 20px;
      background: #f8f8f8;
      font-size: 15px;
      color: #a1a1a1;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
    }

    .active {
      background: #cdd9ff;
      color: #456ff7;
    }
  }

  &-container {
    height: 430px;
    background: #f2f2f2;

    div {
      height: 430px;
      padding: 10px;
      line-height: 2;
      overflow: hidden auto;
    }

    .p1 {
      text-indent: 2em;
    }

    .p-text-r {
      text-align: right;
    }

    h1 {
      font-size: 20px;
      font-weight: bold;
    }

    .en-p {
      margin: 10px 0;

      &-no {
        display: block;
        float: left;
        width: 3%;
      }

      &-text {
        display: block;
        float: right;
        width: 97%;

        .en-p-no {
          display: block;
          float: left;
          width: 4.5%;
        }

        .en-p-text {
          display: block;
          float: right;
          width: 95.5%;
        }
      }
    }
  }

  &-foot {
    margin-top: 20px;

    .el-checkbox {
      float: left;
      margin-top: 7px;
    }

    &-btn {
      float: right;
    }
  }
}
</style>
